import React from "react";
import { Divider, Grid } from "@material-ui/core";
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import MenuAgency from "../menu/MenuAgency";
import Profile from "../profile/Profile";
import {
  HeaderContainer,
  HeaderContainerRow,
  HeaderContainerRowMenu,
} from "./style";
import SpText from "../../../components/atoms/SpText";

const Header = (props) => {
  return (
    <HeaderContainer>
      <HeaderContainerRow container spacing={2}>
        <Grid item xs={8}>
          <Logo />
        </Grid>
        <Grid item xs={4}>
          <Profile />
        </Grid>
      </HeaderContainerRow>
      <Divider style={{ backgroundColor: "#31ccad" }} />
      <HeaderContainerRowMenu>
        <Menu />
      </HeaderContainerRowMenu>
    </HeaderContainer>
  );
};

export const HeaderAgency = (props) => (
  <HeaderContainer>
    <HeaderContainerRow>
      <Logo />
      <Profile />
    </HeaderContainerRow>
    <Divider style={{ backgroundColor: "#31ccad" }} />
    <HeaderContainerRow>
      <MenuAgency />
    </HeaderContainerRow>
  </HeaderContainer>
);

Header.propTypes = {};

export default Header;
