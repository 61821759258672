import { call } from "./utils";

export const createTrainingTemplate = async (data) =>
  await call({
    url: "/activity/create/training/template",
    data: { data },
  });

export const updateTrainingTemplate = async (data) =>
  await call({
    url: "/activity/update/training/template",
    data: { data },
  });

export const getAllTrainingTemplates = async () =>
  await call({ url: "/activity/get/training/templates/by/professional" });

export const getTrainingTemplateById = async (data) =>
  await call({
    url: "/activity/get/training/template/by/id",
    data: { data },
  });

export const createProtocolTemplate = async (data) =>
  await call({
    url: "/activity/create/protocol/template",
    data: { data },
  });

export const createRehabTemplate = async (data) =>
  await call({
    url: "/activity/create/rehab/template",
    data: { data },
  });

export const updateProtocolTemplate = async (data) =>
  await call({
    url: "/activity/update/protocol/template",
    data: { data },
  });

export const updateRehabTemplate = async (data) =>
  await call({
    url: "/activity/update/rehab/template",
    data: { data },
  });

export const getAllProtocolTemplates = async () =>
  await call({ url: "/activity/get/protocol/templates/by/professional" });

export const getAllAssessmentTemplates = async () =>
  await call({ url: "/activity/get/assessment/templates/by/professional" });

export const getAllRehabTemplates = async () =>
  await call({ url: "/activity/get/rehab/templates/by/professional" });

export const getProtocolTemplateById = async (data) =>
  await call({
    url: "/activity/get/protocol/template/by/id",
    data: { data },
  });

export const getRehabTemplateDetail = async (data) =>
  await call({
    url: "/activity/get/rehab/template/by/id",
    data: { data },
  });

export const createHandbookTemplate = async (data) =>
  await call({
    url: "/activity/create/handbook/templates",
    data: { data },
  });

export const createRiskFactorTemplate = async (data) =>
  await call({
    url: "/activity/create/risk/factor/templates",
    data: { data },
  });

export const getAllRiskFactorTemplates = async () =>
  await call({ url: "/activity/get/risk/factor/templates/by/professional" });

export const getRiskFactorTemplateById = async (data) =>
  await call({
    url: "/activity/get/risk/factor/template/by/id",
    data: { data },
  });

export const getRegionAreaStructureLists = async (data) =>
  await call({
    url: "/activity/get/risk/factor/human/components",
    data: { data },
  });

export const getAllActivityPlanTemplates = async () =>
  await call({ url: "/activity/get/activity/plan/templates/by/professional" });

export const getActivityPlanTemplateById = async (data) =>
  await call({
    url: "/activity/get/activity/plan/template/by/id",
    data: { data },
  });

export const getAllFeedbackParameters = async () =>
  await call({ url: "/activity/get/all/feedback/parameters" });

export const setActivityFeedbackParameters = async (data) =>
  await call({
    url: "/activity/set/activity/feedback/parameters",
    data: { data },
  });

export const getActivityFeedbackParameters = async () =>
  await call({ url: "/activity/get/all/feedback/parameters" });

export const getAllActivitiesTypes = async (data) =>
  await call({
    url: "/activity/get/all/activities/types",
    data: { data },
  });

export const removeTemplateById = async (data) =>
  await call({ url: "/remove/mylab/template", data: { data } });
