import React from "react";

import it_flag from "../../assets/icon/it.png";
import en_flag from "../../assets/icon/en.png";

const language = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  color: "white",
  paddingLeft: ".4em",
};

const imgStyle = {
  padding: " 0 .2em",
  cursor: "pointer",
};

const listLanguage = [
  {
    langId: "it",
    imgSrc: it_flag,
  },
  {
    langId: "en",
    imgSrc: en_flag,
  },
];

const LanguagesFlags = () => {
  const changeLanguage = (lang) => {
    if (localStorage.lang != lang) {
      localStorage.lang = lang;
      document.location.reload(true);
    }
  };

  const LanguageList = () => {
    return listLanguage.map((languageItem) => {
      return (
        <div style={imgStyle} key={languageItem.langId}>
          <img
            src={languageItem.imgSrc}
            alt="language"
            onClick={() => changeLanguage(languageItem.langId)}
          />
        </div>
      );
    });
  };

  return (
    <div style={language}>
      <LanguageList />
    </div>
  );
};

export default LanguagesFlags;
