import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import SpButton from "../../../components/atoms/SpButton";
import PatientsAddSymbols from "../shared/PatientsAddSymbols";
import { labels } from "../../shared/translations";

const PatientsMonitoringStep1 = (props) => {
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [isShowingUpdateSymbol, setIsShowingUpdateSymbol] = useState(false);
  const { setLoading } = props;

  useEffect(() => {
    localStorage.removeItem("pdfScreenshot");
  }, []);

  const monitoring = (
    <>
      <Grid container spacing={3}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3}>
          <SpButton
            text={labels.general.update}
            style={{ width: "100%" }}
            buttonType="accept"
            onClick={() => setIsShowingUpdateSymbol(true)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ minHeight: 400 }}>
          <Sp3DModel
            modelBehaviour="MARKER"
            type="PATIENT"
            setSelectedRegion={setSelectedRegion}
            setSelectedArea={setSelectedArea}
            selectedRegion={selectedRegion}
            selectedArea={selectedArea}
          />
        </Grid>
      </Grid>
    </>
  );
  const addSymbol = (
    <PatientsAddSymbols
      setLoading={setLoading}
      onBackPressed={() => setIsShowingUpdateSymbol(false)}
    />
  );

  return isShowingUpdateSymbol ? addSymbol : monitoring;
};

export default PatientsMonitoringStep1;
