import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import { SpDragDropFileUploader } from "../../components/atoms/SpDragDropFileUploader";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import {
  addResourceToPatientProfessional,
  addResourceToServer,
  getAllResourcesToPatientProfessional,
  getResourceFromServer,
  removeResourceToPatientProfessional,
} from "../../models/actions/Professionals";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import download from "downloadjs";

const util = require("util");

const StyledTransferWrapper = styled(Grid)({
  padding: "3%",
  backgroundColor: "#333333",
  borderColor: "#31ccad",
  border: "1px solid",
});

const PatientsResources = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [files, setFiles] = useState([]);
  const [uploadLimitSize, setUploadLimitSize] = useState("");
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const onLoadingData = async () => {
    try {
      const res = await getAllResourcesToPatientProfessional({
        id_patient: patId,
      });

      if (res) {
        setUploadLimitSize(res.uploadSizeLimit);
        const nextFiles = res.files?.map((i) => ({
          id: i?.id,
          name: i?.name,
          size: i.size,
          extension: i.extension,
          url: i.url,
        }));

        setFiles(nextFiles ?? []);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      if (responseDetail) {
        setCurrentPatientDetail(responseDetail);
        await onLoadingData();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadFile = async (item) => {
    try {
      const res = await getResourceFromServer({ file: item });
      if (res) {
        download(res);
      } else {
        props.snackbarShowErrorMessage("Errore download file!");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const uploadFiles = async (files) => {
    try {
      setLoading(true);
      await Promise.all(
        files?.map(async (file) => {
          if (file) {
            if (file.size / (1024 * 1024) > uploadLimitSize) {
              throw labels.patient.resourcesUpload.errors.uploadLimit;
            }
            const data = new FormData();
            data.append("file", file);
            const resourceFileData = await addResourceToServer(data);
            if (resourceFileData && !resourceFileData.error) {
              const fileData = {
                name: resourceFileData[0].key.replace("bucketFolder/", ""),
                extension: resourceFileData[0].contentType.substring(
                  resourceFileData[0].contentType.indexOf("/") + 1,
                  resourceFileData[0].contentType.length
                ),
                size: resourceFileData[0].size,
                url: resourceFileData[0].location,
              };
              const result = await addResourceToPatientProfessional({
                id_patient: patId,
                file: fileData,
              });

              if (result) {
                props.snackbarShowMessage(result.message);
                await onLoadingData();
              } else {
                props.snackbarShowMessage(result.error);
              }
            } else {
              props.snackbarShowErrorMessage(resourceFileData.error);
            }
          }
        })
      );
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const removeFile = async (item) => {
    //remove file from server
    await removeResourceToPatientProfessional({
      id_file: item.id,
      id_patient: patId,
    });

    await onLoadingData();
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10} container direction="row" spacing={1}>
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.resourcesUpload.title.toUpperCase()}
              </SpText>
              <SpText variant="h1Subtitle">
                {labels.patient.resourcesUpload.subtitle}
              </SpText>
            </Grid>
            <StyledTransferWrapper item xs={12} container direction="row">
              <Grid item xs={12}>
                <SpText variant={"h1"}>
                  {labels.patient.resourcesUpload.form.title}
                </SpText>
                <SpText variant={"h4ComponentLabel"}>
                  {labels.patient.resourcesUpload.form.subtitle} (
                  {util.format(labels.general.uploadLimitSize, uploadLimitSize)}
                  )
                </SpText>
              </Grid>
              <Grid item xs={12}>
                <SpDragDropFileUploader
                  clickText={labels.patient.resourcesUpload.dd1}
                  fileLimitText={labels.patient.resourcesUpload.dd2}
                  onLoadingData={onLoadingData}
                  onUploadFile={uploadFiles}
                  onRemove={removeFile}
                  onDownload={downloadFile}
                  files={files}
                />
              </Grid>
            </StyledTransferWrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsResources);
