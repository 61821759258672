import React from "react";
import Grid from "@material-ui/core/Grid";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { labels } from "./shared/translations";
import { styled } from "../components/styled";
import { useHistory } from "react-router";

const StyledRowTemplateItem = styled(Grid)({
  border: "1px solid #31ccad",
  padding: "1%",
  marginBottom: "1%",
});

const MyLab = () => {
  const history = useHistory();
  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SpText variant="h1">{labels.mylab.page.title}</SpText>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: "2em" }}
          direction="column"
          container
          spacing={3}
        >
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.activityPlan}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/activity-plan");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/activity-plan/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.excerciseSheet}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/excercise");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/excercise/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.assesment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/assesment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/assesment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.handbook}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/handbook");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/handbook/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.training}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/training");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/training/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.rehab}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/rehab");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/rehab/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.treatment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/treatment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/treatment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.riskFactor}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/risk_factor");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/risk_factor/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.dysfunctionWeights}
              </SpText>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.manage}
                onClick={() => {
                  history.push("/mylab/dysfunction_weights/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        </Grid>
      </Grid>
    </>
  );
};

export default MyLab;