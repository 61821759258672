import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { isMobile } from "react-device-detect";
import PatientsMonitoringAddProms from "../patientsMonitoring/PatientsMonitoringAddProms";
import PatientsMonitoringDetailProms from "../patientsMonitoring/PatientsMonitoringDetailProms";
import { getPromsAnswersByMonitoring } from "../../../models/actions/Proms";
import moment from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import NoData from "../shared/utils/NoData";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
});

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "33%",
  flexDirection: "column",
});

const PatientsMonitoringStep3 = (props) => {
  const [currentPatient, setCurrentPatient] = useState();
  const [displayProms, setDisplayProms] = useState([]);
  const [addPresentationProms, setAddPresentationProms] = useState(false);
  const [detailPresentationProms, setDetailPresentationProms] = useState(false);
  const [promsDetailData, setPromsDetailData] = useState();
  const [selectedProms, setSelectedProms] = useState();

  useEffect(() => {}, []);
  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { endDate } = props;
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const promsByMonitoring = await getPromsAnswersByMonitoring({
        id_patient: patId,
      });
      if (promsByMonitoring) {
        promsByMonitoring.reverse();
        setDisplayProms(promsByMonitoring);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!addPresentationProms) {
      fetchData();
    }
  }, [addPresentationProms]);

  const closeAssessmentAdd = () => {
    setAddPresentationProms(false);
  };
  const handlePromsDetail = async (id_proms) => {
    setSelectedProms(id_proms);
    setDetailPresentationProms(true);
  };

  // Columns
  const headCellsPresentation = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.presentation.detail.stepper.step6.table.header.type.toUpperCase(),
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.presentation.detail.stepper.step6.table.header.date.toUpperCase(),
      isAction: false,
    },
  ];

  const _onRowClick = (proms) => {
    if (proms?.monitoring_proms_answers?.length === 0) {
      props.snackbarShowErrorMessage(labels.patient.proms.errors.no_answers);
    } else {
      handlePromsDetail(proms.id);
    }
  };

  const PromsRow = ({ row }) => (
    <>
      <SpTableRow key={row.id} tabIndex={-1}>
        <SpTableCell
          style={{ cursor: "pointer" }}
          onClick={() => _onRowClick(row)}
        >
          <SpText variant="tableText">
            {psTranslate(row.prom?.name?.toUpperCase())}
          </SpText>
        </SpTableCell>
        <SpTableCell
          style={{ cursor: "pointer" }}
          onClick={() => _onRowClick(row)}
        >
          <SpText variant="tableText">
            {row.date ? moment(row.date).format("DD/MM/yyyy") : ""}
          </SpText>
        </SpTableCell>
      </SpTableRow>
    </>
  );

  return (
    <>
      <StyledBodyColumn>
        <StyledBodyHalfColumnToolbar>
          <SpText variant="h1PageTitle">
            {labels.patient.presentation.detail.stepper.step6.title.toUpperCase()}
          </SpText>
          {endDate &&
            (displayProms.length > 0 ? (
              <SpText variant="h1PageSubtitle">
                {
                  labels.patient.presentation.detail.stepper.step6
                    .closedPresentation
                }
              </SpText>
            ) : (
              <SpText variant="h1PageSubtitle">
                {labels.patient.presentation.detail.stepper.step6.noData}
              </SpText>
            ))}
          {!endDate && !addPresentationProms && !detailPresentationProms && (
            <SpButton
              buttonType="accept"
              variant="none"
              text={labels.patient.presentation.detail.stepper.step6.add.toUpperCase()}
              onClick={() => setAddPresentationProms(!addPresentationProms)}
            />
          )}
          {detailPresentationProms && (
            <SpButton
              buttonType="accept"
              variant="none"
              style={{ width: "100%" }}
              text={labels.patient.presentation.detail.stepper.step6.close.toUpperCase()}
              onClick={() => setDetailPresentationProms(false)}
            />
          )}
        </StyledBodyHalfColumnToolbar>
        {displayProms.length === 0 && (
          <NoData label={labels.patient.monitoring.proms.noProms} />
        )}
        {!addPresentationProms ? (
          displayProms?.length > 0 &&
          !detailPresentationProms && (
            <StyledTableColumn>
              <StyledRow>
                <SpTable
                  headCells={headCellsPresentation}
                  pagination={true}
                  rows={displayProms}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                  tableContainerMaxHeight={
                    isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                  }
                >
                  <PromsRow />
                </SpTable>
              </StyledRow>
            </StyledTableColumn>
          )
        ) : (
          <PatientsMonitoringAddProms
            setLoading={setLoading}
            closeAssessmentAdd={closeAssessmentAdd}
          />
        )}
        {detailPresentationProms && (
          <PatientsMonitoringDetailProms
            closeAssessmentAdd={closeAssessmentAdd}
            proms={selectedProms}
            setLoading={setLoading}
          />
        )}
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PatientsMonitoringStep3);
