import React, { useEffect, useState } from "react";
import { labels } from "../../../../shared/translations";
import {
  averageInRange,
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const StudyAsymmetry = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const isEnabled = () => {
    return (
      genericIsEnabled(
        currStudyParameters,
        study,
        studiesMeasureUnits,
        graphType,
        graphDateView
      ) && currStudyParameters.length == 2
    );
  };

  const asymmetryAverageData = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters (2)
      let paramKey = null;
      const avgParams = Object.entries(subjectData).map(
        ([studyParamKey, studyData]) => {
          paramKey = paramKey ?? studyParamKey;
          return averageInRange(
            dateRange,
            by,
            (range) => range.by("day", { excludeEnd: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            true
          );
        }
      );

      //Diff 2 params
      if (avgParams.length === 2) {
        const param0 = avgParams[0];
        const param1 = avgParams[1];

        accum[subjectName] = {};
        accum[subjectName][paramKey] = Object.entries(param0).reduce(
          (accum, [dayStr, value0]) => {
            const value1 = param1[dayStr];
            if (value0 != null && value1 != null)
              accum[dayStr] =
                (1 - Math.min(value0, value1) / Math.max(value0, value1)) * 100;
            return accum;
          },
          {}
        );
      }

      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && isEnabled()) {
      onFetchDateDaysChange(-getNDaysFromKey(graphDateView?.key));
      newData = {
        [study.key]: {
          "": asymmetryAverageData(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { "": {} } };
    }
    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    />
  );
};

export default StudyAsymmetry;
