import React from "react";
import moment from "moment";
import {
  injuryLocalizationGroups as injuryLocalizationGroupsGeneral,
  selectPatientsInjury as selectPatientsInjuryGeneral,
  selectedGroupsInjury as selectedGroupsInjuryGeneral,
  getAutocomplete as getAutocompleteGeneral,
} from "./GeneralInjuryFunction";
import {
  selectPatientsInjuryRate,
  selectedGroupsInjuryRate,
} from "./TrainingInjuryFunction";
import { getAllCalendarActivityByMonitGroup } from "../../../../models/actions/CalendarActivity";
import { labels } from "../../../shared/translations";
import { matchTrainingArrayUnion } from "./InjuryReportCommonFunction";
const gravityRange = labels.analytics.injuryReport.gravityRange;

export const getAutocomplete = (
  placeholder,
  listItemsSelected,
  listItemAvailable,
  functionAfterClick
) => {
  return getAutocompleteGeneral(
    placeholder,
    listItemsSelected,
    listItemAvailable,
    functionAfterClick
  );
};
export const injuryLocalizationGroups = (
  injuriesPatient,
  regions,
  areas,
  isPercentage
) => {
  return injuryLocalizationGroupsGeneral(
    injuriesPatient,
    regions,
    areas,
    isPercentage
  );
};

export const selectPatientsInjury = (
  selectedInjuryPatient,
  regions,
  areas,
  isPercentage
) => {
  const selectedInjuryPatientFiltered = calculateGravity(selectedInjuryPatient);
  return selectPatientsInjuryGeneral(
    selectedInjuryPatientFiltered,
    regions,
    areas,
    isPercentage
  );
};

export const selectedGroupsInjury = (
  selectedInjuryPatient,
  regions,
  areas,
  isPercentage
) => {
  selectedInjuryPatient.forEach((group) => {
    group.patients = calculateGravity(group.patients);
  });

  return selectedGroupsInjuryGeneral(
    selectedInjuryPatient,
    regions,
    areas,
    isPercentage
  );
};
export const calculateGravity = (selectedInjuryPatient) => {
  const severe = gravityRange.find(({ key }) => key === "severe");
  selectedInjuryPatient.forEach((patient) => {
    let newInjuries = [];
    patient?.injuries.forEach((injury) => {
      if (injury?.end_date) {
        const diffDate = moment(injury?.end_date).diff(
          moment(injury?.start_date),
          "days"
        );
        if (diffDate >= severe.min) newInjuries.push(injury);
      } else if (injury?.estimated_end_date) {
        const diffDate = moment(injury?.estimated_end_date).diff(
          moment(injury?.start_date),
          "days"
        );
        if (diffDate >= severe.min) newInjuries.push(injury);
      }
    });
    patient.injuries = newInjuries;
  });
  return selectedInjuryPatient;
};

export const filterGroupByType = async (groups, dateRange, type) => {
  let temp = [];
  for (let tempGroup of groups) {
    let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
      id_group: tempGroup?.id,
    });
    let item = {};
    item["id"] = tempGroup?.id;
    item["name"] = tempGroup?.name;
    item["patients"] = tempGroup?.patients;

    allCalendarActivity = allCalendarActivity.filter(
      (item) =>
        moment(item?.start_date) > dateRange?.start &&
        moment(item?.start_date) < dateRange?.end
    );
    item["action"] = allCalendarActivity.filter(
      (all) => all?.activity_datum?.activity_type?.key === type
    );
    temp.push(item);
  }
  return temp;
};

export const selectPatientsSevereInjuryRate = async (
  selectedPatients,
  groups,
  dateRange
) => {
  selectedPatients = calculateGravity(selectedPatients);
  // prendo i valori del injury ratio
  const groupFilteredByTraining = await filterGroupByType(
    groups,
    dateRange,
    "training"
  );

  const groupFilteredByMatch = await filterGroupByType(
    groups,
    dateRange,
    "race"
  );

  // prendo i valori del injury ratio
  const selectPatientsInjuryRateResultTraining = selectPatientsInjuryRate(
    selectedPatients,
    groupFilteredByTraining,
    "training"
  );

  const selectPatientsInjuryRateResultMatch = selectPatientsInjuryRate(
    selectedPatients,
    groupFilteredByMatch,
    "match"
  );

  const dataChart = matchTrainingArrayUnion(
    selectPatientsInjuryRateResultTraining,
    selectPatientsInjuryRateResultMatch
  );

  const result = {
    barChart: selectPatientsInjuryRateResultTraining.barChart,
    dataChart: dataChart,
  };
  return result;
};

export const selectedGroupsSevereInjuryRate = async (
  newSelectedGroups,
  dateRange
) => {
  // filtro catturo solo gli infortuni gravi
  newSelectedGroups.forEach((group) => {
    group.patients = calculateGravity(group.patients);
  });
  // filtro in base agli allenamenti
  const groupFilteredByTraining = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "training"
  );

  const groupFilteredByMatch = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "race"
  );

  // prendo i valori del injury ratio
  const selectGroupsInjuryRateValueTraining = selectedGroupsInjuryRate(
    groupFilteredByTraining,
    "training"
  );
  const selectGroupsInjuryRateValueMatch = selectedGroupsInjuryRate(
    groupFilteredByMatch,
    "match"
  );

  const dataChart = matchTrainingArrayUnion(
    selectGroupsInjuryRateValueTraining,
    selectGroupsInjuryRateValueMatch
  );

  const result = {
    barChart: selectGroupsInjuryRateValueTraining.barChart
      ? selectGroupsInjuryRateValueTraining.barChart
      : {},
    dataChart: dataChart,
  };
  return result;
};
