import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  getPatientPastHistory,
  getPatientPastHistoryAnswers,
  updatePatientPastHistory,
} from "../../../models/actions/Patients";
import moment from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import { nanoid } from "nanoid";

const StyledAnswerAddInfo = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingBottom: "2%",
});

const StyledAdditionalInfoContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "3%",
  marginTop: "1%",
  backgroundColor: "#333333",
  borderColor: "#31ccad",
  border: "1px solid",
});

const StyledRowAddInfo = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

const CustomAccordion = styled(Accordion)({
  textAlign: "center",
  maxWidth: "100%",
  marginTop: "1%",
  marginBottom: "1%",
  borderRadius: 0,
  color: "white",
  borderColor: "#31ccad",
  background: "transparent",
  border: "1px solid",
});

//method to render conditional info under the answers
const AdditionalInfo = ({
  control,
  globalIndex,
  register,
  currentAnswer,
  formatAdditionalInfo,
  additionalInfoData,
}) => {
  const watchPastHistoryUserAnswer = useWatch({
    control,
    name: "pastHistoryUserAnswer",
  });
  const currentAnswerValue = watchPastHistoryUserAnswer[globalIndex];

  if (typeof additionalInfoData === "string") {
    additionalInfoData = JSON.parse(
      additionalInfoData
        ?.replace(/\n/g, "")
        .replace(' "', '"')
        .replace(/”/g, '"')
        .replace(/“/g, '"')
    );
  }

  if (
    currentAnswerValue &&
    currentAnswerValue?.id &&
    currentAnswerValue?.id !== "" &&
    currentAnswerValue?.additional_info
  ) {
    let escapedAdditionalInfo;
    if (typeof currentAnswerValue?.additional_info === "string") {
      escapedAdditionalInfo = JSON.parse(
        currentAnswerValue?.additional_info
          ?.replace(/\n/g, "")
          .replace(' "', '"')
          .replace(/”/g, '"')
          .replace(/“/g, '"')
      );
    } else {
      escapedAdditionalInfo = currentAnswerValue?.additional_info;
    }
    //setting the form element to support the input of the additional info
    if (escapedAdditionalInfo && escapedAdditionalInfo !== "null") {
      return (
        <StyledAdditionalInfoContainer>
          {escapedAdditionalInfo.map((info, infoIdx) => {
            switch (info?.type) {
              case "string":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );
              case "string ":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );
              case "date":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      type="date"
                      maxValue={"2100-12-31"}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );
              case "date ":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      type="date"
                      maxValue={"2100-12-31"}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );
              case "number":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      type="number"
                      minValue={0}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );
              case "number ":
                return (
                  <StyledRowAddInfo key={globalIndex + infoIdx}>
                    <SpTextInput
                      inputRef={register}
                      type="number"
                      minValue={0}
                      name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                      label={psTranslate(info.name)}
                    />
                  </StyledRowAddInfo>
                );

              default:
                break;
            }
          })}
          {/* <div onClick={() => append(currentAnswerValue)} style={{ display: "flex", flex: 1, padding: "4%", justifyContent: "center" }}>
						<AddCircleIcon style={{ cursor: "pointer", color: theme.colors.primary.lightBlue }} />
					</div> */}
        </StyledAdditionalInfoContainer>
      );
    } else {
      return null;
    }
  } else {
    if (
      currentAnswer?.additional_info &&
      currentAnswer?.additional_info !== "null" &&
      additionalInfoData?.length > 0
    ) {
      let escapedAdditionalInfo;
      // console.log("array to be searched", additionalInfoData, currentAnswer);
      const answerElementFound = additionalInfoData?.find(
        (addInfo) =>
          addInfo.past_history_answer_element.id_story_answer ==
          currentAnswer?.id
      );
      // console.log("Render component23", answerElementFound);
      if (answerElementFound) {
        if (typeof answerElementFound?.answerElementFound === "string") {
          escapedAdditionalInfo = formatAdditionalInfo(
            answerElementFound?.past_history_answer_element.answerElementFound
          );
        } else {
          escapedAdditionalInfo =
            answerElementFound?.past_history_answer_element.additional_info;
        }
        // console.log("Render component23", escapedAdditionalInfo);

        escapedAdditionalInfo = JSON.parse(escapedAdditionalInfo);
        //setting the form element to support the input of the additional info
        if (escapedAdditionalInfo && escapedAdditionalInfo !== "null") {
          return (
            <StyledAdditionalInfoContainer>
              {escapedAdditionalInfo.map((info, infoIdx) => {
                switch (info?.type) {
                  case "string":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={info.value}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );
                  case "string ":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={info.value}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );
                  case "date":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          type="date"
                          maxValue={"2100-12-31"}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={moment(info.value).format("yyyy-MM-DD")}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );
                  case "date ":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          type="date"
                          maxValue={"2100-12-31"}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={info.value}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );
                  case "number":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          type="number"
                          minValue={0}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={info.value}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );
                  case "number ":
                    return (
                      <StyledRowAddInfo key={globalIndex + infoIdx}>
                        <SpTextInput
                          inputRef={register}
                          type="number"
                          minValue={0}
                          name={`pastHistoryUserAnswer[${globalIndex}].additional_info[${infoIdx}].value`}
                          value={info.value}
                          label={psTranslate(info.name)}
                        />
                      </StyledRowAddInfo>
                    );

                  default:
                    return null;
                    break;
                }
              })}
              {/* <div onClick={() => append(currentAnswerValue)} style={{ display: "flex", flex: 1, padding: "4%", justifyContent: "center" }}>
							<AddCircleIcon style={{ cursor: "pointer", color: theme.colors.primary.lightBlue }} />
						</div> */}
            </StyledAdditionalInfoContainer>
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
};

const PatientsPastHistoryInputStep = (props) => {
  const [pastHistoryData, setPastHistoryData] = useState(null);
  const [patAnswersAddInfoFromServer, setPatAnswersAddInfoFromServer] =
    useState([]);

  const { patId } = useParams();
  const history = useHistory();

  const { control, register, handleSubmit, setValue, getValues, reset } =
    useForm({
      shouldUnregister: false,
    });

  const formatAdditionalInfo = (data) => {
    let results = {},
      formattedJSON;
    if (data) {
      formattedJSON = data
        .replace(/\n/g, "")
        .replace(' "', '"')
        .replace(/”/g, '"')
        .replace(/“/g, '"')
        .replace('"[', "[")
        .replace(']"', "]")
        .replace(/""/g, '"')
        .replace(/,]/g, "]");
    }
    results = JSON.parse(formattedJSON);
    return results;
  };

  const RenderAnswers = () => {
    let globalIndex = 0;
    return pastHistoryData.map((pastHistoryRow, catIdx) => {
      return (
        <CustomAccordion key={nanoid()}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id={catIdx}
          >
            <SpText variant="h4ComponentLabel">
              {psTranslate(pastHistoryRow.category.name)}
            </SpText>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container xs={12} spacing={2}>
              {pastHistoryRow.questions.map(
                (pastHistoryRowQuestion, questIdx) => {
                  globalIndex = globalIndex + 1;

                  return (
                    <Grid item xs={6}>
                      <StyledAnswerAddInfo>
                        <Controller
                          key={nanoid()}
                          render={(props) => (
                            <SpSelect
                              allowDeselect={true}
                              label={psTranslate(
                                pastHistoryRowQuestion.question
                              )}
                              value={props.value}
                              onChange={(e) => {
                                props.onChange(e.target.value);
                                //find the element that contains the additional info
                                const currentAnswerAddInfo =
                                  pastHistoryRowQuestion?.answers.find(
                                    (answer) => answer.id == e.target.value
                                  );
                                const currentFormValues = getValues(
                                  "pastHistoryUserAnswer"
                                );
                                let escapedAdditionalInfo;
                                const answerGivenInFormIndex = currentFormValues
                                  .map((e) => {
                                    return e.id;
                                  })
                                  .indexOf(e.target.value);
                                if (
                                  !getValues(
                                    `pastHistoryUserAnswer[${answerGivenInFormIndex}].additional_info`
                                  )
                                ) {
                                  if (
                                    typeof currentAnswerAddInfo.additional_info ===
                                      "string" &&
                                    currentAnswerAddInfo.additional_info !==
                                      "null"
                                  ) {
                                    escapedAdditionalInfo = JSON.parse(
                                      currentAnswerAddInfo?.additional_info
                                        ?.replace(/\n/g, "")
                                        .replace(' "', '"')
                                        .replace(/”/g, '"')
                                        .replace(/“/g, '"')
                                        .replace('"[', "[")
                                        .replace(']"', "]")
                                        .replace(/""/g, '"')
                                        .replace(/,]/g, "]")
                                    );

                                    if (
                                      escapedAdditionalInfo &&
                                      !escapedAdditionalInfo?.value
                                    ) {
                                      escapedAdditionalInfo =
                                        escapedAdditionalInfo.map((elem) => {
                                          return { ...elem, value: "" };
                                        });
                                    }
                                  } else {
                                    if (
                                      typeof currentAnswerAddInfo.additional_info ===
                                      "object"
                                    ) {
                                      escapedAdditionalInfo =
                                        currentAnswerAddInfo.additional_info;
                                    }
                                  }
                                  setValue(
                                    `pastHistoryUserAnswer[${answerGivenInFormIndex}].additional_info`,
                                    escapedAdditionalInfo
                                  );
                                }
                              }}
                            >
                              {pastHistoryRowQuestion?.answers?.map(
                                (pastHistoryRowAnswer, idx) => (
                                  <SpSelectMenuItem
                                    key={pastHistoryRowAnswer.id}
                                    value={pastHistoryRowAnswer.id}
                                  >
                                    {psTranslate(pastHistoryRowAnswer.answer)}
                                  </SpSelectMenuItem>
                                )
                              )}
                            </SpSelect>
                          )}
                          defaultValue={() => {
                            if (
                              pastHistoryData[catIdx].questions[questIdx]
                                .id_answer &&
                              pastHistoryData[catIdx].questions[questIdx]
                                .id_answer.length > 0
                            ) {
                              return pastHistoryData[catIdx].questions[questIdx]
                                .id_answer[0]?.id;
                            } else {
                              if (
                                pastHistoryData[catIdx].questions[questIdx]
                                  .id_answer
                              ) {
                                if (
                                  pastHistoryData[catIdx].questions[questIdx]
                                    .id_answer.additional_info
                                ) {
                                  const addInfoFormatted = formatAdditionalInfo(
                                    pastHistoryData[catIdx].questions[questIdx]
                                      .id_answer.additional_info
                                  );
                                  //setValue(`pastHistoryUserAnswer[${globalIndex}].additional_info`, addInfoFormatted);
                                }
                                return pastHistoryData[catIdx].questions[
                                  questIdx
                                ]?.id_answer.id;
                              } else {
                                return "";
                              }
                            }
                          }}
                          name={`pastHistoryUserAnswer[${globalIndex}].id`}
                          control={control}
                        />
                        <AdditionalInfo
                          formatAdditionalInfo={formatAdditionalInfo}
                          additionalInfoData={patAnswersAddInfoFromServer}
                          control={control}
                          globalIndex={globalIndex}
                          register={register}
                          currentAnswer={
                            pastHistoryData[catIdx].questions[questIdx]
                              ?.id_answer
                          }
                        />
                      </StyledAnswerAddInfo>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </AccordionDetails>
        </CustomAccordion>
      );
    });
  };

  //Update patient data
  const updateRegistry = async (data) => {
    try {
      const response = await updatePatientPastHistory({
        id_patient: patId,
        answers: data.pastHistoryUserAnswer,
      });
      //if the record as been updated i notify it and update the layout
      if (response) {
        props.snackbarShowMessage(response?.message);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  //Fetching patient data
  const fetchData = async () => {
    try {
      const result = await getPatientPastHistory({ id_patient: patId });
      const dataFromTheServerPastHistory = result;
      if (
        getValues("patientPastHistory")?.length == 0 ||
        !getValues("patientPastHistory")
      ) {
        reset({
          patientPastHistory: dataFromTheServerPastHistory.patientPastHistory,
          patientDetail: dataFromTheServerPastHistory.patientDetail,
          pastHistoryUserAnswer: [],
        });
      }
      setPastHistoryData(dataFromTheServerPastHistory.patientPastHistory);
      const resultPastHistory = await getPatientPastHistoryAnswers({
        id_patient: patId,
      });
      resultPastHistory &&
        setPatAnswersAddInfoFromServer(resultPastHistory.answers);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!pastHistoryData)
    return (
      <SpText variant="h4ComponentLabel">{labels.general.loading_data}</SpText>
    );

  return (
    <>
      {pastHistoryData && (
        <Grid container direction="column">
          <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
            <SpButton
              buttonType={"accept"}
              onClick={() => handleSubmit(updateRegistry)()}
              variant="none"
              text={labels.patient.patientRegistry.submitButton}
            />
          </Grid>
          <Grid
            container
            spacing={2}
            justify="space-evenly"
            direction="column"
            style={{ marginTop: "2%" }}
          >
            <form onSubmit={handleSubmit(updateRegistry)}>
              <Grid
                container
                spacing={2}
                justify="space-evenly"
                direction="column"
              >
                <RenderAnswers />
              </Grid>
            </form>
          </Grid>
          {/*</Grid>*/}
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(PatientsPastHistoryInputStep);
