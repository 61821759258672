import React, { useEffect, useState } from "react";
import SpText from "../../components/atoms/SpText";
import { labels, psTranslate } from "../../pages/shared/translations";
import { Grid } from "@material-ui/core";
import { getResourceFromServer } from "../../models/actions/Professionals";
import SpDialog from "./SpDialog";

const SpExerciseDialog = ({
  dialogExerciseOpen,
  setDialogExerciseOpen,
  exerciseDetail,
}) => {
  const [currentExerciseURL, setCurrentExerciseURL] = useState(null);

  const LabelValue = (props) =>
    props.value && (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    );

  const getCurrentFileUrl = async () => {
    try {
      if (exerciseDetail) {
        const resourceDetail = await getResourceFromServer({
          file: exerciseDetail?.file,
          path: "assets/",
        });
        if (resourceDetail) setCurrentExerciseURL(resourceDetail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log("EXERCISE DETAIL", exerciseDetail);
    getCurrentFileUrl();
  }, [exerciseDetail]);

  return (
    <SpDialog
      open={dialogExerciseOpen}
      setOpen={setDialogExerciseOpen}
      title={labels.mylab.tempAdd.excerciseSheet.dialog.title}
      style={{ marginRight: "60%" }}
    >
      <Grid container xs={12} style={{ marginBottom: "10px" }}>
        <Grid
          item
          xs={8}
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <LabelValue
            label={labels.mylab.tempAdd.excerciseSheet.dialog.content.name}
            value={psTranslate(exerciseDetail?.name)}
          />
          <LabelValue
            label={labels.mylab.tempAdd.excerciseSheet.dialog.content.tags}
            value={psTranslate(exerciseDetail?.tags)}
          />
        </Grid>
        {currentExerciseURL && (
          <Grid item xs={4}>
            <img
              alt={"Gif"}
              src={currentExerciseURL}
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        )}
      </Grid>
    </SpDialog>
  );
};

export default SpExerciseDialog;
