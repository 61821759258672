import React, { useEffect, useRef, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels, psTranslate } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { Controller, useForm } from "react-hook-form";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";
import { Checkbox, Chip, Divider } from "@material-ui/core";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import ReactToPrint from "react-to-print";
import {
  getAssessmentsTemplateCategories,
  getAssessmentTemplateDetail,
  getAssessmentTemplateElementsByCategoryIds,
  saveAssessmentTemplate,
  updateAssessmentTemplate,
} from "../../../models/actions/Assessment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HumanBodyFrontUrl from "../../../assets/human_body_front.png";
import HumanBodyBackUrl from "../../../assets/human_body_back.png";

const StyledColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  paddingTop: "3%",
});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const StyledRowListHeader = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddHandbook = (props) => {
  const [assessmentData, setAssessmentData] = useState([]);
  const [handbookDetailData, setHandbookDetailData] = useState([]);
  const [assessmentsCategory, setAssessmentsCategories] = useState([]);
  const [assessmentElements, setAssessmentElements] = useState([]);
  const [currentQuestionnairesList, setCurrentQuestionnairesList] = useState(
    []
  );
  const componentRef = useRef();
  const history = useHistory();
  const {
    register,
    control,
    watch,
    reset,
    errors,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    shouldUnregister: false,
  });

  const { idElement } = useParams();

  const fetchData = async () => {
    try {
      const response = await getAssessmentsTemplateCategories();
      setAssessmentsCategories(response);
      if (idElement) {
        const responseAssessmentCategory = await getAssessmentTemplateDetail({
          id_template: idElement,
        });
        if (responseAssessmentCategory) {
          //console.log(responseAssessmentCategory.AssesmentElement);
          let newAssessmentData = [];
          responseAssessmentCategory.AssesmentElement.map((ass) => {
            const foundElem = newAssessmentData.find(
              (elem) =>
                elem?.id ==
                ass.assessment_assessment_category.assessment_category.id
            );
            if (!foundElem) {
              newAssessmentData.push({
                id: ass.assessment_assessment_category.assessment_category.id,
                name: ass.assessment_assessment_category.assessment_category
                  .name,
                assessment_elements: [],
              });
              newAssessmentData[
                newAssessmentData.length - 1
              ]?.assessment_elements.push(ass.name);
            } else {
              newAssessmentData[
                newAssessmentData.length - 1
              ].assessment_elements.push(ass.name);
            }
          });
          setAssessmentData(newAssessmentData);
        }
        setValue("name", responseAssessmentCategory.name);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateQuestionnairesList = async (categoryIdsFiltered) => {
    try {
      if (categoryIdsFiltered) {
        const response = await getAssessmentTemplateElementsByCategoryIds({
          id_categories: categoryIdsFiltered,
        });
        await setCurrentQuestionnairesList(response);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const editTemplate = async (data) => {
    try {
      const results = await updateAssessmentTemplate({
        ...data,
        id_assessment_template: idElement,
      });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/handbook");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async (data) => {
    try {
      //console.info("getValues('assessment_element')", getValues("assessment_elements"));
      data.assessment_elements = [].concat(
        ...getValues("assessment_elements")?.map((item) => item)
      );
      data.is_handbook = 1;
      //console.info("Data", data);
      const results = await saveAssessmentTemplate({ ...data });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/handbook");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const RenderAssessmentElementAutocomplete = () => {
    let globalIndex = 0;
    return (
      <Grid container direction="row" xs={12} style={{ marginTop: "1%" }}>
        {currentQuestionnairesList?.map((item, idx) => {
          globalIndex++;
          return (
            <Grid
              direction="column"
              container
              xs={6}
              style={{ padding: "5px" }}
            >
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: "#31ccad",
                }}
              />
              <SpText variant={"h4ComponentLabelColored"}>
                {item?.category?.toUpperCase()}
              </SpText>
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: "#31ccad",
                }}
              />
              <Grid item>
                <Controller
                  id={"item_" + idx}
                  render={(props) => (
                    <SpAutocomplete
                      multiple
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "100%",
                      }}
                      formControlWidth={"100%"}
                      selectPlaceholder={
                        labels.mylab.tempAdd.handbook.selectElements
                      }
                      disableCloseOnSelect
                      options={item?.elements}
                      getOptionLabel={(option) => psTranslate(option.name)}
                      value={assessmentElements[idx]}
                      onChange={(e, newValue) => {
                        let arr = [...assessmentElements];
                        // console.log("arr prima", arr);
                        arr[idx] = newValue;
                        setAssessmentElements(arr);
                        // console.log("arr dopo", arr);
                      }}
                      // getOptionSelected={(option, value) => option.id === value?.id}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{ color: option.selected ? "white" : "black" }}
                        >
                          <Checkbox
                            name={props.id}
                            icon={<CheckBoxOutlineBlankIcon small />}
                            checkedIcon={<CheckBoxIcon small />}
                            style={{
                              marginRight: 5,
                              color: option.selected ? "white" : "black",
                            }}
                            checked={option.selected}
                          />
                          {psTranslate(props.name)}
                        </li>
                      )}
                      // renderInput={(params) => (
                      //     <SpTextField params={params}
                      //                  placeholder={"Seleziona gli elementi..."}/>
                      // )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            style={{
                              backgroundColor: "#31ccad",
                              color: "white",
                            }}
                            // color={}
                            label={psTranslate(option.name)}
                            size="medium"
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                  defaultValue={assessmentElements[idx]}
                  name={`assessment_elements[${idx}]`}
                  control={control}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const AddHandbook = (props) => {
    return (
      <>
        <Grid container direction="column" xs={12} spacing={3}>
          <Grid item xs={12}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.handbook.category}
            </SpText>
          </Grid>
        </Grid>
        <StyledRow>
          <StyledRowListHeader container spacing={1}>
            {assessmentsCategory.map((category, idx) => (
              <Grid key={category.id} item container xs={6} alignItems="center">
                <Grid item xs={2}>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpCheckbox
                        key={category.id}
                        rightlabel
                        variant="column"
                        onChange={async (e) => {
                          props.onChange(e.target.checked);
                          const newCategoriesIdsArray = getValues("category");
                          if (newCategoriesIdsArray) {
                            let foundIds = [];
                            newCategoriesIdsArray.map(
                              (newCategoryElem, idx) => {
                                if (newCategoryElem)
                                  foundIds.push(
                                    newCategoriesIdsArray.indexOf(
                                      newCategoryElem,
                                      idx
                                    )
                                  );
                              }
                            );
                            // setSelectedCategoryIds(foundIds);
                            await updateQuestionnairesList(foundIds);
                          }
                        }}
                        checked={props.value}
                      />
                    )}
                    // defaultValue={fields[idx + 1]}
                    name={`category[${idx + 1}]`}
                    control={control}
                  />
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{ paddingLeft: "5px", alignSelf: "center" }}
                >
                  <SpText variant="h4ComponentLabel">
                    {psTranslate(category.name)}
                  </SpText>
                </Grid>
              </Grid>
            ))}
          </StyledRowListHeader>
        </StyledRow>
        {currentQuestionnairesList.length > 0 && (
          <>
            <RenderAssessmentElementAutocomplete />
          </>
        )}
      </>
    );
    // return (
    //     <div>
    //         <Grid container direction='column' xs={12} spacing={3}>
    //             <Grid item xs={12}>
    //                 <SpText
    //                     variant='h1PageSubtitle'>{labels.patient.presentation.add.stepper.step4.assessmentDetailRequest.categoryTitle}</SpText>
    //             </Grid>
    //         </Grid>
    //         <StyledRow>
    //             <StyledRowListHeader container spacing={1}>
    //                 {assessmentCategories.map((category, categoryIdx) => (
    //                     <Grid key={category.id} item container xs={6} alignItems='center'>
    //                         <Grid item xs={2}>
    //                             <Controller
    //                                 style={{marginTop: "3%"}}
    //                                 render={(props) => (
    //                                     <SpCheckbox
    //                                         key={category.id}
    //                                         rightlabel
    //                                         variant='column'
    //                                         onChange={(e) => {
    //                                             props.onChange(e.target.checked);
    //                                             updateCheckedValues(categoryIdx, category);
    //                                         }}
    //                                         checked={props.checked}
    //                                     />
    //                                 )}
    //                                 defaultValue={""}
    //                                 name={`categories[${categoryIdx}].checked`}
    //                                 control={control}
    //                             />
    //                         </Grid>
    //                         <Grid item xs={10}>
    //                             <SpText variant='checkListLabel'>{category.name}</SpText>
    //                         </Grid>
    //                     </Grid>
    //                 ))}
    //             </StyledRowListHeader>
    //         </StyledRow>
    //     </div>
    // );
  };

  const DetailHandbook = () => {
    return (
      <Grid container xs={12} direction="row">
        <Grid xs={8} item>
          {assessmentData?.map((item) => (
            <Grid
              container
              xs={12}
              style={{
                borderTop: "1px solid #31ccad",
                padding: "8px",
                marginTop: "5px",
              }}
              direction="column"
            >
              <Grid item container direction="row" xs={12}>
                <Grid item xs={12}>
                  <SpText variant="text">{psTranslate(item?.name)}</SpText>
                </Grid>
                {item?.assessment_elements?.map(
                  (currentAssessmentTemplateElement) => (
                    <Grid container xs={12} style={{ marginTop: "5px" }}>
                      <SpText variant="h4ComponentLabel">
                        {psTranslate(currentAssessmentTemplateElement)}
                      </SpText>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid xs={4} container item direction="column">
          <img
            src={HumanBodyFrontUrl}
            alt={"Human body front"}
            style={{ objectFit: "contain", height: "400px" }}
          />
          <img
            src={HumanBodyBackUrl}
            alt={"Human body back"}
            style={{ objectFit: "contain", height: "400px" }}
          />
        </Grid>
      </Grid>
    );
  };

  // const DetailHandbook = () => {
  //     return (
  //         <Grid container direction='column' xs={12} spacing={3}>
  //             <Grid item xs={12}>
  //                 <SpText variant='text'>{labels.mylab.tempDetail.handbook.categoryTitle}</SpText>
  //             </Grid>
  //             {handbookDetailData.map((category, idx) => (
  //                 <Grid item xs={12}>
  //                     <SpText variant='h4ComponentLabel'>{category.name}</SpText>
  //                 </Grid>
  //             ))}
  //         </Grid>
  //     );
  // };

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <Grid container xs={12} direction="row" style={{ padding: "10px" }}>
          <Grid xs={8} item>
            {assessmentData?.map((item) => (
              <Grid
                container
                xs={12}
                style={{
                  borderTop: "1px solid #000",
                  padding: "8px",
                  marginTop: "5px",
                }}
                direction="column"
              >
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={12}>
                    <SpText variant="textPrint">
                      {psTranslate(item?.name)}
                    </SpText>
                  </Grid>
                  {item?.assessment_elements?.map(
                    (currentAssessmentTemplateElement) => (
                      <Grid container xs={12} style={{ marginTop: "5px" }}>
                        <Grid item xs={6} style={{ paddingRight: "5px" }}>
                          <SpText variant="h4ComponentLabelPrint">
                            {psTranslate(currentAssessmentTemplateElement)}
                          </SpText>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            border: "1px solid #000",
                            paddingLeft: "8px",
                          }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid xs={4} container item direction="column">
            <img
              src={HumanBodyFrontUrl}
              alt={"Human body front"}
              style={{ objectFit: "contain", height: "400px" }}
            />
            <img
              src={HumanBodyBackUrl}
              alt={"Human body back"}
              style={{ objectFit: "contain", height: "400px" }}
            />
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <>
      <Grid
        direction="column"
        container
        spacing={2}
        style={{ paddingLeft: "1%" }}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/handbook")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.handbook.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {idElement && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <ReactToPrint
                trigger={() => (
                  <SpButton
                    style={{ marginLeft: "2%" }}
                    text={labels.mylab.tempAdd.handbook.actions.print}
                    buttonType="accept"
                  />
                )}
                content={() => componentRef.current}
              />
              <div style={{ display: "none" }}>
                <ComponentToPrint ref={componentRef} />
              </div>
              <SpButton
                style={{ marginLeft: "2%" }}
                text={
                  idElement
                    ? labels.mylab.tempAdd.handbook.actions.edit
                    : labels.mylab.tempAdd.handbook.actions.create
                }
                buttonType="accept"
                onClick={() =>
                  handleSubmit(idElement ? editTemplate : saveTemplate)()
                }
              />
            </Grid>
          )}
          {!idElement && assessmentElements && assessmentElements.length > 0 && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                text={labels.mylab.tempAdd.assesment.actions.create}
                buttonType="accept"
                variant="none"
                style={{ marginTop: "1%", width: "max-content" }}
                onClick={() => {
                  setValue("assessment_elements", assessmentElements);
                  props.handleSubmit();
                }}
              />
            </Grid>
          )}
        </Grid>
        <form onSubmit={handleSubmit(saveTemplate)}>
          <Grid item xs={6}>
            <SpTextInput
              style={{ width: "95%" }}
              name={`name`}
              inputRef={register({ required: true })}
              label={labels.mylab.tempAdd.assesment.inputs.name}
            />
            {errors.name && (
              <SpText variant="inputError">
                {labels.mylab.tempAdd.assesment.inputs.requiredName}
              </SpText>
            )}
          </Grid>
          <Divider
            style={{
              padding: "1px",
              margin: "2%",
              marginLeft: "0",
              width: "100%",
              backgroundColor: "#31ccad",
            }}
          />

          {!idElement ? (
            <AddHandbook handleSubmit={handleSubmit(saveTemplate)} />
          ) : (
            <DetailHandbook />
          )}
          {assessmentElements && assessmentElements.length > 0 && (
            <Grid item xs={12} container justify={"flex-end"}>
              <SpButton
                text={labels.mylab.tempAdd.assesment.actions.create}
                buttonType="accept"
                variant="none"
                style={{ marginTop: "1%", width: "max-content" }}
                onClick={() => {
                  setValue("assessment_elements", assessmentElements);
                  handleSubmit(saveTemplate)();
                }}
              />
            </Grid>
          )}
        </form>
      </Grid>
    </>
  );
};
export default withSnackbar(AddHandbook);
