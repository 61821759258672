import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getPatientsNames, getStudies } from "../../../models/actions/Patients";
import {
  calculateFinalScore,
  getAllPromsSurveyAnswers,
  getAllPromsSurveys,
} from "../../../models/actions/Proms";
import { dateFormat, rollbar } from "../../../utils/common";
import Report from "./patientsReportFeedback/Report";

const moment = extendMoment(Moment);

const EXCLUDED_STUDIES = [
  "MONOTONY",
  "STRAIN",
  "ACWRRA",
  "ACWREWMA",
  "SYMMETRY",
  "ASYMMETRY",
  "SYMMETRYASS",
];

const PatientsReportsStep2PROMs = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [studies, setStudies] = useState([]);
  const [proms, setProms] = useState([]);
  const [promsData, setPromsData] = useState({});
  const [mainPatientKey, setMainPatientKey] = useState("");
  const [mcidList, setMcidList] = useState(null);

  const { patId } = useParams();

  useEffect(async () => {
    try {
      let [studies, allProms, names] = await Promise.all([
        getStudies(),
        getAllPromsSurveys(),
        getPatientsNames(),
      ]);

      //Set params
      const mainPatientName = names.find((pat) => pat.id == patId).name ?? "";
      setMainPatientKey(mainPatientName);
      studies = studies.filter(
        (study) => !EXCLUDED_STUDIES.includes(study.key)
      );
      setStudies(studies);
      setProms(allProms);
    } catch (error) {
      rollbar.error("PatientsReportsStep2PROMs - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const onNewDataRequest = async (
    _,
    currStudyProms,
    currAdditionalStudySubjects,
    filterRange
  ) => {
    try {
      //get all proms parameters
      const resultPromsAnswers = await getAllPromsSurveyAnswers({
        id_patient: patId,
        starting_date: filterRange.start,
        ending_date: filterRange.end,
        proms: currStudyProms.map((prom) => prom.id),
      });

      //fetchData for proms and calculate the final score
      const newMcidList = new Set();
      const newPromsGraphData = {};
      if (resultPromsAnswers) {
        resultPromsAnswers.forEach((promInstance) => {
          const answer = promInstance.proms_answers[0];
          if (answer) {
            //Obtain date
            const date = moment(promInstance.date).startOf("day");

            //Generate graph data
            const finalScores = calculateFinalScore(
              promInstance.prom,
              promInstance.proms_answers
            );
            newMcidList.add(promInstance.prom.id);

            const name = promInstance.prom.name;
            finalScores.forEach(({ label, value }) => {
              if (!isNaN(value)) {
                const lineKey = `${name} - ${label}`;
                if (!newPromsGraphData[mainPatientKey])
                  newPromsGraphData[mainPatientKey] = {};
                if (!newPromsGraphData[mainPatientKey][lineKey])
                  newPromsGraphData[mainPatientKey][lineKey] = {};
                newPromsGraphData[mainPatientKey][lineKey][
                  date.format(dateFormat)
                ] = parseFloat(value);
              }
            });
          }
        });
      }

      //Format: { proms_name: { proms_label: { subject_name: { day: value, ... }, ...}, ... }, ... }
      setPromsData(newPromsGraphData);
      setMcidList([...newMcidList]);
    } catch (error) {
      rollbar.error("PatientsReportsStep2PROMs - onNewDataRequest", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <Grid item xs={12}>
      <Report
        mainSubjectKey={mainPatientKey}
        studies={studies}
        studyParameters={proms}
        fetchedData={promsData}
        activities={null}
        additionalStudySubjects={{}}
        combinedParams={null}
        onCombinedParamRequest={() => {}}
        onNewDataRequest={onNewDataRequest}
        defaultZero={false}
        config={config}
        setConfig={setConfig}
        requestSaveFlag={requestSaveFlag}
        mcidList={mcidList}
      />
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep2PROMs);
