import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextArea from "../../components/atoms/SpTextArea";
import { theme } from "../../components/theme";
import { setActivityFeedbackParameters } from "../../models/actions/Activity";
import {
  archiveActivityById,
  deleteCalendarActivityById,
} from "../../models/actions/CalendarActivity";
import { rollbar } from "../../utils/common";
import { labels, psTranslate } from "../shared/translations";
import A1_AssessmentDisabledDetails from "./disabledDetails/A1_AssessmentDisabledDetails";
import A2_TreatmentDisabledDetails from "./disabledDetails/A2_TreatmentDisabledDetails";
import A3_ExerciseDisabledDetails from "./disabledDetails/A3_ExerciseDisabledDetails";
import A4_TrainingDisabledDetails from "./disabledDetails/A4_TrainingDisabledDetails";
import A5_RestDisabledDetails from "./disabledDetails/A5_RestDisabledDetails";
import A6_OtherDisabledDetails from "./disabledDetails/A6_OtherDisabledDetails";
import A7_RaceDisabledDetails from "./disabledDetails/A7_RaceDisabledDetails";
import A8_RehabilitationDisabledDetails from "./disabledDetails/A8_RehabilitationDisabledDetails";
import A1_AssessmentInputDetails from "./inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "./inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "./inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "./inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "./inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "./inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "./inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "./inputDetails/A8_RehabilitationInputDetails";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

const DISABLED_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentDisabledDetails,
  A2_TreatmentDisabledDetails,
  A3_ExerciseDisabledDetails,
  A4_TrainingDisabledDetails,
  A5_RestDisabledDetails,
  A6_OtherDisabledDetails,
  A7_RaceDisabledDetails,
  A8_RehabilitationDisabledDetails,
];

const AgendaCalendarEventCard = ({
  event,
  setOpenDetailActivity,
  fetchData,
  updateActivity,

  createActivityObject,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  archiveEnabled = true,
  forceDisabled = false,
  allowFeedback = true,

  isGroupCard = false,

  ...props
}) => {
  const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
  const history = useHistory();

  const { handleSubmit, register, setValue, getValues, control } = useForm({
    shouldUnregister: false,
  });

  const isDisabled = () => {
    return (
      forceDisabled ||
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent"
    );
  };

  const deleteCalendarActivity = async (e) => {
    e.stopPropagation();

    try {
      if (event.is_from_template) {
        await updateActivity(event, true /*delete*/);
      } else {
        const result = await deleteCalendarActivityById({
          id_activity: event.id_activity_group ? null : event.id,
          id_activity_group: event.id_activity_group, //for groups
        });
        if (result.error) {
          props.snackbarShowErrorMessage(result.error);
        } else {
          await fetchData();
          props.snackbarShowMessage(result.message);
        }
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - deleteCalendarActivity", error);
      props.snackbarShowErrorMessage(error);
    }
    setOpenDetailActivity(false);
  };

  const updateActivityFeedback = async (data) => {
    try {
      const answers = data.feed_resp_ans.filter((a) => a);
      const result = await setActivityFeedbackParameters({
        feedback_parameters_answers: answers,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result?.message);
        await fetchData();
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - updateActivityFeedback", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateActivityContents = async (data) => {
    const updatedEvnt = {
      id: isGroupCard ? null : event.id,
      id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
      start_date: event.start_date,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
    };
    await updateActivity(updatedEvnt);
    setOpenDetailActivity(false);
    await fetchData();
  };

  const archiveActivity = async (status) => {
    try {
      const result = await archiveActivityById({
        id: isGroupCard ? null : event.id,
        id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
        archiving_status: status,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result?.message);
        setOpenDetailActivity(false);
        await fetchData();
      }
    } catch (error) {
      rollbar.error("AgendaCalendarEventCard - archiveActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateAndArchiveActivity = async (data) => {
    const updatedEvnt = {
      id: isGroupCard ? null : event.id,
      id_activity_group: isGroupCard ? event.id_activity_group : null, //for groups
      start_date: event.start_date,
      event_note: getValues("note"),
      data: createActivityObject(data, event.id_activity_type).details_data,
      feedback_parameter: loadParametersList,
    };
    let archiveStatus;
    if (event.feedbacks.map((elem) => elem.answer).length > 0) {
      archiveStatus = "completed_and_feedback_sent";
    } else {
      archiveStatus = "completed_and_feedback_not_sent";
    }
    try {
      await updateActivity(updatedEvnt);
    } catch (error) {
      rollbar.error(
        "AgendaCalendarEventCard - updateAndArchiveActivity",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
    await archiveActivity(archiveStatus);
  };

  const reopenActivity = () => {
    archiveActivity(null);
  };

  //Render input popup details
  const renderInputDetails = () => {
    if (isDisabled()) {
      const Detail = DISABLED_DETAILS[event.id_activity_type];
      return (
        <Grid direction="column" container style={{ marginBottom: "2%" }}>
          <Detail
            event={event}
            allowFeedback={allowFeedback}
            isGroupCard={isGroupCard}
            updateActivityFeedback={updateActivityFeedback}
            setValue={setValue}
            register={register}
            handleSubmit={handleSubmit}
          />
        </Grid>
      );
    } else {
      //Still editable
      const Detail = INPUT_DETAILS[event.id_activity_type];
      return (
        <Grid direction="column" container style={{ marginBottom: "2%" }}>
          <Detail
            watchTemplateSelect={null}
            selectedTemplate={null}
            templateData={event.data}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
            control={control}
            setValue={setValue}
            register={register}
            disabled={isDisabled()}
            handleSubmit={handleSubmit}
            updateActivityFeedback={updateActivityFeedback}
            event={event}
            allowFeedback={allowFeedback}
            isGroupCard={isGroupCard}
          />
        </Grid>
      );
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          padding: 15,
          background: theme.colors.primary.grey,
          minWidth: 800,
          width: "100%",
        }}
      >
        <Grid item xs={12} direction="row" container>
          <img
            src={event.media}
            style={{ width: 60, height: 60, marginRight: "8px" }}
            alt={""}
          />
          <Grid
            item
            direction="column"
            xs
            style={{
              display: "inline-block",
              verticalAlign: "top",
              width: "100%",
            }}
          >
            <SpText variant="h1PageSubtitleColored">
              {psTranslate(event.title)}
              {(event.id_activity_type === 3 ||
                event.id_activity_type === 4) && (
                <SpButton
                  buttonType={"accept"}
                  style={{ marginLeft: "5px", height: 20 }}
                  variant="h4ComponentLabel"
                  onClick={() =>
                    history.push(
                      `/mylab/${
                        event.id_activity_type === 3 ? `excercise` : `training`
                      }/add`
                    )
                  }
                  text={labels.agenda.card.prepare}
                />
              )}
            </SpText>
          </Grid>
          <Grid container xs={12} direction="row" style={{ marginTop: "10px" }}>
            {event.start_time && event.end_time && (
              <Grid item xs={6} style={{ marginTop: "5px" }}>
                <SpText variant="text">{labels.agenda.card.time}</SpText>
                <SpText variant="h4ComponentLabel">
                  {event.start_time.substring(0, 5) +
                    " - " +
                    event.end_time.substring(0, 5)}
                </SpText>
              </Grid>
            )}
            {event.patient ? (
              <Grid
                item
                xs={6}
                container
                style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
                direction="column"
              >
                <SpText variant="h4ComponentLabel">
                  {event.patient.givenName + " " + event.patient.familyName}
                </SpText>
                <SpButton
                  variant="h4ComponentLabel"
                  onClick={() =>
                    history.push(`/patients/edit/${event.patient.id}/plan`)
                  }
                  text={labels.agenda.card.goToActivityPlan}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {event.data && renderInputDetails()}
        <Grid
          item
          xs={12}
          container
          direction="column"
          style={{ width: "100%", marginTop: "1%" }}
        >
          {!(isDisabled() && !event?.event_note) && (
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.patient.patientPlan.activityDetailDialog.note + ": "}
              </SpText>
            </Grid>
          )}
          <Grid item xs={12}>
            {isDisabled() ? (
              <SpText variant="h4ComponentLabel">{event?.event_note}</SpText>
            ) : (
              <SpTextArea
                formControlStyle={{ width: "100% !important" }}
                textAlign="left"
                variant="text"
                defaultValue={event.event_note}
                inputRef={register}
                name={"note"}
              />
            )}
          </Grid>
        </Grid>
        {!isDisabled() ? (
          <Grid item xs={12} container direction="row">
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-start",
                marginTop: "2%",
              }}
              direction="row"
            >
              <SpButton
                buttonType="accept"
                style={{ flex: "auto" }}
                text={
                  labels.patient.patientPlan.activityDetailDialog
                    .updateActivityButton
                }
                onClick={handleSubmit(updateActivityContents)}
              />
              {archiveEnabled && (
                <SpButton
                  buttonType="accept"
                  style={{ flex: "auto" }}
                  text={
                    labels.patient.patientPlan.activityDetailDialog
                      .archiveActivityButton
                  }
                  onClick={handleSubmit(updateAndArchiveActivity)}
                />
              )}

              <SpButton
                buttonType="cancel"
                style={{ flex: "auto" }}
                text={
                  labels.patient.patientPlan.activityDetailDialog
                    .openDeleteButtonActivity
                }
                variant="h1PageTitle"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDeleteActivity(true);
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} direction="row">
            <Grid item xs={9}>
              <SpText variant="text" style={{ marginTop: 5 }}>
                {labels.patient.patientPlan.activityDetailDialog.archivedAct}
              </SpText>
            </Grid>
            <Grid item xs={3}>
              <SpButton
                buttonType="accept"
                style={{ flex: "auto" }}
                text={
                  labels.patient.patientPlan.activityDetailDialog.reopenButton
                }
                onClick={handleSubmit(reopenActivity)}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <SpDialog
        open={openDeleteActivity}
        setOpen={setOpenDeleteActivity}
        title={labels.calendar.eventDetailDialog.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.calendar.eventDetailDialog.deleteMessage}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                buttonType="cancel"
                text={labels.patient.patientPlan.activityDetailDialog.confirm}
                variant="h1PageTitle"
                onClick={(e) => deleteCalendarActivity(e)}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.patientPlan.activityDetailDialog.deny}
                variant="h1PageTitle"
                onClick={() => setOpenDeleteActivity(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(AgendaCalendarEventCard);
