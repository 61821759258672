/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import SpLoader from "../../../components/atoms/SpLoader";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import moment from "moment";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { green } from "@material-ui/core/colors";
import { labels } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";
import { rollbar } from "../../../utils/common.js";

import {
  getGroupPatients,
  getHeader,
  getPatientsAndGroupsSelection,
  getPatientInjury,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  selectPatientsInjuryRate,
  selectedGroupsInjuryRate,
  selectedGroupsDaysAbsence,
  selectPatientsDaysAbsence,
  selectPatientsInjuryBurden,
  selectedGroupsBurder,
  selectedGroupsInjury,
  selectPatientsInjury,
  injuryGroupsByGravity,
  injuryPatientsByGravity,
  injuryPatientsByReinjury,
  injuryGroupsByReinjury,
} from "./patientsReportInjury/MuscleLigamentInjuryFunction";

import {
  injuryLocalizationGroups,
  getAutocomplete,
} from "./patientsReportInjury/GeneralInjuryFunction";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const constPadding = { padding: "5px" };

const DEFAULT_RANGE = moment.rangeFromInterval("month", -1, moment());
const dateFormat = "YYYY-MM-DD";
const localizationDistributionLabel =
  labels.analytics.injuryReport.graphs.localizationDistribution;
const gravityLabel = labels.analytics.injuryReport.graphs.gravity;
const injuryRate = labels.analytics.injuryReport.graphs.injuryRate;
const reinjuryLabel = labels.analytics.injuryReport.graphs.reinjury;
const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const injuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;

const PatientsReportsMuscleLigamentInjury = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barChar, setBarChar] = useState([]);
  const [injuryGroup, setInjuryGroup] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [allRegions, setAllRegions] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [allAreasAvailable, setAllAreasAvailable] = useState([]);
  const [regionSelected, setRegionSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState([]);
  const [gravities, setGravities] = useState([]);
  const [gravitySelected, setGravitySelected] = useState([]);
  const [reinjuries, setReinjuries] = useState([]);
  const [reinjurySelected, setReinjurySelected] = useState([]);
  const [isPercentage, setIsPercentage] = useState(false);

  const { control, setValue, getValues } = useForm({
    shouldUnregister: false,
  });
  const { patId } = useParams();
  const [loading, setLoading] = useState(false);
  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
    "&:disabled": {
      color: "#31ccad",
    },
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await listGroupsByProfessional();
      const allRegionTemp = await getRegionAreaStructureLists({});
      setAllRegions(allRegionTemp?.regionList);
      setAllAreas(allRegionTemp?.areaList);
      setAllAreasAvailable(allRegionTemp?.areaList);

      const result = getGroupPatients(response, patId);
      setPatients(result.allPatients);
      setGroups(result.mapGroup);
      let tempInjuryGroup = await getPatientInjury(result.mapGroup, dateRange);
      setInjuryGroup(tempInjuryGroup);

      // calcolo del paziente attuale in base al suo id
      let tempPat = [
        result.allPatients.find(({ id }) => id === parseInt(patId)),
      ];
      // inizializzo il grafico con le statistiche del paziente
      selectionActionFunction(tempPat, "group", []);

      // set delle gravities
      setGravities(labels.analytics.injuryReport.gravityRange);
      setReinjuries(labels.analytics.injuryReport.reinjuryRange);
      setLoading(false);
    } catch (error) {
      rollbar.error("PatientsReportsMuscleLigamentInjury - useEffect", error);

      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  /**
   * Funzione che crea il grafico con i gruppi selezionati
   *
   * @param groups: lista di gruppi da visualizzare
   *
   */
  const injuryGroupSelectedFunction = (
    gruppi,
    newIsPercentage = isPercentage
  ) => {
    // compongo il grafico rispetto al tipo selezionato
    let res;
    setLoading(true);
    res = selectedGroupsInjury(
      gruppi,
      regionSelected,
      areaSelected,
      newIsPercentage
    );

    setBarChar(res.barChart);
    setDataChart(res.dataChart);
    setLoading(false);
  };

  /**
   * Funzione viene richiamata quando cambia la regione selezionata
   * @param newRegionSelected: nuova regione selezionata
   *
   */
  const selectRegionFunction = (newRegionSelected) => {
    // pulisco i gruppi selezionati
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(
        injuryGroup,
        newRegionSelected,
        areaSelected,
        isPercentage,
        props.idTissueType
      );
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        newRegionSelected,
        areaSelected,
        isPercentage,
        props.idTissueType
      );

      let result = selectPatientsInjury(
        selectedPatients,
        newRegionSelected,
        areaSelected,
        isPercentage,
        props.idTissueType
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result.dataChart;
      setDataChart(result.dataChart);
      setBarChar(result.barChart);
    }

    setRegionSelected(newRegionSelected);
    let tempArea = [];
    newRegionSelected.forEach((region) => {
      const tempAreas = allAreas.filter(
        (area) => area?.id_region == region?.id
      );
      tempArea = tempArea.concat(tempAreas);
    });
    setAllAreasAvailable(tempArea);
  };

  /**
   * Funzione viene richiamata quando cambia l'area selezionata
   * @param newAreaSelected: nuova area selezionata
   *
   */
  const selectAreaFuncion = (newAreaSelected) => {
    setAreaSelected(newAreaSelected);
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      setAllInjuryGroup(injuryGroup, regionSelected, newAreaSelected);
    } else {
      let resultInjuryGroups = selectedGroupsInjury(
        selectedGroups,
        regionSelected,
        newAreaSelected,
        isPercentage,
        props.idTissueType
      );
      let result = selectPatientsInjury(
        selectedPatients,
        regionSelected,
        newAreaSelected,
        isPercentage,
        props.idTissueType
      );
      result.dataChart =
        resultInjuryGroups.dataChart !== undefined
          ? result.dataChart.concat(resultInjuryGroups.dataChart)
          : result.dataChart;
      setDataChart(result.dataChart);
      setBarChar(result.barChart);
    }
  };

  const selectGravityFunction = (newGravitySelected) => {
    selectActionFunction(
      newGravitySelected,
      setGravitySelected,
      injuryGroupsByGravity,
      injuryPatientsByGravity
    );
  };

  const selectReinjuryFunction = (newReinjurySelected) => {
    selectActionFunction(
      newReinjurySelected,
      setReinjurySelected,
      injuryGroupsByReinjury,
      injuryPatientsByReinjury
    );
  };

  const selectActionFunction = (
    newSelected,
    setNewSelected,
    groupsFunction,
    patientsFunction
  ) => {
    setNewSelected(newSelected);
    let result;
    if (selectedGroups.length === 0 && selectedPatients.length === 0) {
      result = groupsFunction(
        groups,
        newSelected,
        false,
        isPercentage,
        props.idTissueType
      );
    } else {
      let resultInjuryGroups = groupsFunction(
        selectedGroups,
        newSelected,
        true,
        isPercentage,
        props.idTissueType
      );
      result = patientsFunction(
        selectedPatients,
        newSelected,
        isPercentage,
        props.idTissueType
      );
      result.dataChart = result.dataChart.concat(resultInjuryGroups.dataChart);
    }
    setDataChart(result.dataChart);
    setBarChar(result.barChart);
  };

  /**
   * Funzione che in base ai gruppi selezionati crea il grafico
   *
   * @param tempSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectGroupFunction = (
    tempSelectedGroups,
    newIsPercentage = isPercentage
  ) => {
    setSelectedGroups(tempSelectedGroups);
    if (tempSelectedGroups?.length === 0) {
      // creo il grafico con tutti i gruppi
      setAllInjuryGroup(
        injuryGroup,
        regionSelected,
        areaSelected,
        newIsPercentage
      );
    } else {
      // se vengono selezionati piu' gruppi
      injuryGroupSelectedFunction(tempSelectedGroups, newIsPercentage);
    }
  };

  /**
   * Funzione che mostra tutti gli infortuni divisi per gruppo
   *
   * @param tempInjuryGroup: lista di gruppi
   * @param regions: regioni selezionate
   * @param areas: aree selezionate
   *
   */
  const setAllInjuryGroup = (
    tempInjuryGroup,
    regions,
    areas,
    newIsPercentage = isPercentage
  ) => {
    let result = injuryLocalizationGroups(
      tempInjuryGroup,
      regions,
      areas,
      newIsPercentage
    );

    setDataChart(result.dataChart);
    setBarChar(result.barChart);
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectionActionFunction = async (
    patients,
    type,
    newSelectedGroups = selectedGroups,
    newIsPercentage = isPercentage,
    action = getValues("graficoSelezionato")
  ) => {
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );
    let result;
    let resultInjuryGroups;

    if (action === localizationDistributionLabel) {
      resultInjuryGroups = selectedGroupsInjury(
        newSelectedGroups,
        regionSelected,
        areaSelected,
        newIsPercentage,
        props.idTissueType
      );
      result = selectPatientsInjury(
        newPatientsSelected,
        regionSelected,
        areaSelected,
        newIsPercentage,
        props.idTissueType
      );
    }

    if (action === gravityLabel) {
      resultInjuryGroups = injuryGroupsByGravity(
        newSelectedGroups,
        gravitySelected,
        true,
        newIsPercentage,
        props.idTissueType
      );
      result = injuryPatientsByGravity(
        newPatientsSelected,
        gravitySelected,
        newIsPercentage,
        props.idTissueType
      );
    }

    if (action === reinjuryLabel) {
      resultInjuryGroups = injuryGroupsByReinjury(
        newSelectedGroups,
        reinjurySelected,
        true,
        newIsPercentage,
        props.idTissueType
      );
      result = injuryPatientsByReinjury(
        newPatientsSelected,
        reinjurySelected,
        newIsPercentage,
        props.idTissueType
      );
    }

    if (action === injuryRate) {
      resultInjuryGroups = await selectedGroupsInjuryRate(
        newSelectedGroups,
        dateRange,
        props.idTissueType
      );
      result = await selectPatientsInjuryRate(
        newPatientsSelected,
        groups,
        dateRange,
        props.idTissueType
      );
    }

    if (action === daysAbsenceLabel) {
      resultInjuryGroups = await selectedGroupsDaysAbsence(
        newSelectedGroups,
        props.idTissueType
      );
      result = await selectPatientsDaysAbsence(
        newPatientsSelected,
        props.idTissueType
      );
    }

    if (action === injuriesBurdenLabel) {
      resultInjuryGroups = await selectedGroupsBurder(
        newSelectedGroups,
        dateRange,
        props.idTissueType
      );
      result = await selectPatientsInjuryBurden(
        newPatientsSelected,
        groups,
        dateRange,
        props.idTissueType
      );
    }
    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    result.dataChart =
      resultInjuryGroups.dataChart !== undefined
        ? result.dataChart.concat(resultInjuryGroups.dataChart)
        : result.dataChart;
    setLoading(true);
    setDataChart(result.dataChart);
    setSelectedPatients(newPatientsSelected);
    setBarChar(result.barChart);
    setLoading(false);
    if (newPatientsSelected.length === 0 && newSelectedGroups.length === 0) {
      if (action === injury)
        setAllInjuryGroup(
          injuryGroup,
          regionSelected,
          areaSelected,
          newIsPercentage
        );
    }
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    let temp = newDateRange;
    if (temp.start < newDateRange.end) {
      let tempInjuryGroup = await getPatientInjury(groups, newDateRange);
      setInjuryGroup(tempInjuryGroup);
      setSelectedGroups([]);

      // calcolo del paziente attuale in base al suo id
      let tempPat = [patients.find(({ id }) => id === parseInt(patId))];
      selectionActionFunction(tempPat, "group", []);
      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  const changeGraph = (action) => {
    setSelectedGroups([]);
    setSelectedPatients([]);
    let tempPat = [patients.find(({ id }) => id === parseInt(patId))];
    selectionActionFunction(tempPat, "group", [], null, action);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={6}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      {/* selezione dei pazienti e dei gruppi  */}
      {getPatientsAndGroupsSelection(
        patients,
        selectedPatients,
        selectionActionFunction,
        groups,
        selectGroupFunction,
        selectedGroups,
        labels.analytics.injuryReport.avarageGroup
      )}
      {/* autocomplete per selezionare la regione */}
      {getValues("graficoSelezionato") === localizationDistributionLabel &&
        getAutocomplete(
          labels.analytics.injuryReport.selectRegion,
          regionSelected,
          allRegions,
          selectRegionFunction
        )}

      {/* autocomplete per selezionare l'area */}
      {regionSelected.length > 0 &&
        getValues("graficoSelezionato") === localizationDistributionLabel &&
        getAutocomplete(
          labels.analytics.injuryReport.selectArea,
          areaSelected,
          allAreasAvailable,
          selectAreaFuncion
        )}

      {/* autocomplete per selezionare la gravita' */}
      {getValues("graficoSelezionato") === gravityLabel &&
        getAutocomplete(
          gravityLabel,
          gravitySelected,
          gravities,
          selectGravityFunction
        )}

      {/* autocomplete per selezionare il reinfortunio */}
      {getValues("graficoSelezionato") === reinjuryLabel &&
        getAutocomplete(
          reinjuryLabel,
          reinjurySelected,
          reinjuries,
          selectReinjuryFunction
        )}
      {getValues("graficoSelezionato") === localizationDistributionLabel && (
        <Grid item xs={12} style={constPadding}>
          <SpCheckbox
            label={labels.analytics.injuryReport.percentage}
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={isPercentage}
            onChange={(_, value) => {
              setIsPercentage(value);
              if (selectedPatients.length === 0) {
                selectGroupFunction(selectedGroups, value);
              } else {
                selectionActionFunction([], "tutti", selectedGroups, value);
              }
            }}
            // disabled={!enabled}
          />
        </Grid>
      )}
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={9}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <TrainingReport dataChart={dataChart} barChar={barChar} />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.selectGraph}
            </SpText>
          </Grid>
          {/*  radio group per selezionare l'attivita' di infortunio */}
          <Controller
            as={
              <RadioGroup
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={localizationDistributionLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(localizationDistributionLabel);
                  }}
                  label={localizationDistributionLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={gravityLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(gravityLabel);
                  }}
                  label={gravityLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={reinjuryLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(reinjuryLabel);
                  }}
                  label={reinjuryLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={injuryRate}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(injuryRate);
                  }}
                  label={injuryRate}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={daysAbsenceLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(daysAbsenceLabel);
                  }}
                  label={daysAbsenceLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={injuriesBurdenLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    changeGraph(injuriesBurdenLabel);
                  }}
                  label={injuriesBurdenLabel}
                />
              </RadioGroup>
            }
            name={`graficoSelezionato`}
            control={control}
            defaultValue={localizationDistributionLabel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsMuscleLigamentInjury);
