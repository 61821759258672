import React, { useEffect, useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../styled";
import { labels, psTranslate } from "../../../pages/shared/translations";
import SpText from "../../../components/atoms/SpText";
import { Divider, Grid } from "@material-ui/core";
import { withSnackbar } from "../../atoms/SpSnackBar";
import {
  getAnswerStructureDysfunctions,
  getAssessmentQuestionnaires,
  getAssessmentsDetail,
  getAssessmentTemplatesByProfessional,
  sendAssessmentAnswer,
  sendAssessmentAnswerMonitoring,
  sendAssessmentAnswerPresentation,
} from "../../../models/actions/Assessment";
import { AddAssessment, DetailAssessment } from "./renderUtils";
import SpDialog from "../../atoms/SpDialog";
import SpCheckbox from "../../atoms/SpCheckbox";
import SpButton from "../../atoms/SpButton";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
  marginBottom: "3%",
});

const StyledBodyHalfColumn = styled("div")({
  flex: 1,
  height: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginRight: "10%",
});

const AddMonitoringAssessment = (props) => {
  //States
  const [assessmentsCategory, setAssessmentsCategories] = useState([]);
  const [assessmentsElements, setAssessmentsElements] = useState([]);
  const [selectedAssessmentElement, setSelectedElement] = useState();
  const [currentQuestionnairesList, setCurrentQuestionnairesList] = useState(
    []
  );
  const [assessmentsList, setAssessmentsList] = useState([]);
  const [assessmentsFilteredList, setAssessmentsFilteredList] = useState([]);
  const [assessmentListCurrentIndex, setAssessmentsListCurrentIndex] = useState(
    []
  );
  const [currentAssessment, setCurrentAssessment] = useState(null);
  const [assessmentTemplatesList, setAssessmentTemplatesList] = useState([]);
  const [selectedAssessmentTemplate, setSelectedAssessmentTemplate] =
    useState(null);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);
  const [openAddStructureDysfunction, setOpenAddStructureDysfunction] =
    useState(false);
  const [structureDysfunctionList, setStructureDysfunctionList] = useState([]);
  const [selectedStructureDysfunction, setSelectedStructureDysfunction] =
    useState(new Set());
  const [data, setData] = useState(null);

  //Hook form
  const { control, handleSubmit, getValues, setValue } = useForm({
    name: "category",
    shouldUnregister: false,
  });

  const { userId, patId } = useParams();
  const { setLoading } = props;
  //Effects
  const fetchData = async () => {
    try {
      // console.info(props);
      setLoading(true);
      if (!props.assessmentDetail) {
        //fetch assessments templates list
        let assessmentsTempListData =
          await getAssessmentTemplatesByProfessional();
        if (assessmentsTempListData) {
          if (!props.isRepeatAssessment) {
            assessmentsTempListData = assessmentsTempListData.filter(
              (x) => !x.deletedAt
            );
          }
          // console.info("assessmentsTempListData", assessmentsTempListData);
          setAssessmentTemplatesList([
            // {
            //     id: -1,
            //     name: labels.bundles.assessment.selectRegion,
            // },
            ...assessmentsTempListData,
          ]);
        }
        if (props.isRepeatFromTemplate) {
          setSelectedCategoriesIds(props.selectedCategoriesObj.categoryIds);
          setSelectedAssessmentTemplate(
            props.selectedCategoriesObj.templateAssessmentId
          );
        }
      }
      if (props.isRepeatAssessment) {
        setSelectedCategoriesIds(props.selectedCategoriesObj.categoryIds);
        !props.isRepeatFromTemplate &&
          setSelectedElement(props.selectedCategoriesObj.assessmentId);
      } else {
        if (!props.assessmentDetail) {
          const response = await getAssessmentsDetail();
          setAssessmentsCategories(response.categories);
          let firstElem = {
            id: -1,
            name: labels.bundles.assessment.selectTemplate,
          };
          setAssessmentsElements([...response.elements]);
        }
        //set the list to be scrolled with back and forward buttons
        if (props.assessmentsDisplayList)
          setAssessmentsList(props.assessmentsDisplayList);
        //set current index value

        if (props.assessmentDetail) {
          setCurrentAssessment(props.assessmentDetail);
          const foundInArray = props.assessmentsDisplayList?.find(
            (assessment) => assessment?.id === props.assessmentDetail?.id
          );
          if (foundInArray) {
            const indexOfCurrentElement =
              props.assessmentsDisplayList?.indexOf(foundInArray);
            if (indexOfCurrentElement > -1)
              setAssessmentsListCurrentIndex(indexOfCurrentElement);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    if (!currentAssessment) fetchData();
  }, []);

  //filter array of assessments to contain only one type of assessment
  useEffect(async () => {
    if (currentAssessment) {
      const filteredAssessmentArray = assessmentsList.filter(
        (elem) => elem.id_assessment == props.selectedAssessment?.id
      );
      if (filteredAssessmentArray) {
        const foundInArray = filteredAssessmentArray.find(
          (assessment) => assessment?.id == props.selectedRow?.id
        );
        // console.log("testing", filteredAssessmentArray, props.selectedRow, foundInArray);

        if (foundInArray) {
          const indexOfCurrentElement =
            filteredAssessmentArray?.indexOf(foundInArray);
          if (indexOfCurrentElement > -1)
            setAssessmentsListCurrentIndex(indexOfCurrentElement);
          // console.log("testing", indexOfCurrentElement, props.assessmentDetail?.id, filteredAssessmentArray);
        }

        setAssessmentsFilteredList(filteredAssessmentArray);
      }
    }
  }, [currentAssessment]);

  useEffect(async () => {
    if (
      props.isRepeatAssessment &&
      selectedAssessmentElement &&
      selectedCategoriesIds
    )
      await updateQuestionnairesList(selectedCategoriesIds);
  }, [selectedAssessmentElement, selectedCategoriesIds]);

  useEffect(async () => {
    if (
      props.isRepeatAssessment &&
      props.isRepeatFromTemplate &&
      selectedAssessmentTemplate &&
      selectedCategoriesIds
    )
      await updateQuestionnairesList(selectedCategoriesIds);
  }, [selectedAssessmentTemplate, selectedCategoriesIds]);

  const _exec = async (addSD, internalData) => {
    const _data = internalData ?? data;
    // console.info(_data);
    setOpenAddStructureDysfunction(false);
    // console.info(data);
    // if (addSD) {
    //     console.info([...selectedStructureDysfunction]);
    // }
    try {
      if (!props.monitoring) {
        if (props.presentation) {
          try {
            setLoading(true);
            const response = await sendAssessmentAnswerPresentation({
              id_patient: patId,
              userId: userId,
              id_presentation: props.presentation,
              id_assessment: selectedAssessmentElement,
              answers: _data.assessmentAnswers,
              id_assessment_template: _data.assessmentTemplate,
              addSD: addSD,
              selected_structure_dysfunctions: [
                ...selectedStructureDysfunction,
              ],
            });
            //if the record as been updated i notify it and update the layout
            if (response) {
              setLoading(false);
              props.snackbarShowMessage(response.message);
              props.closeAssessmentAdd(false);
            }
          } catch (error) {
            setLoading(false);
            props.snackbarShowErrorMessage(error);
          }
        } else {
          if (_data.presentationId) {
            try {
              setLoading(true);
              const response = await sendAssessmentAnswer({
                id_patient: patId,
                userId: userId,
                monitoringId: _data.presentationId,
                assessmentId: selectedAssessmentElement,
                answers: _data.assessmentAnswers,
                id_assessment_template: _data.assessmentTemplate,
                addSD: addSD,
                selected_structure_dysfunctions: [
                  ...selectedStructureDysfunction,
                ],
              });
              //if the record as been updated i notify it and update the layout
              if (response) {
                setLoading(false);
                props.snackbarShowMessage(response.message);
                props.closeAssessmentAdd(false);
              }
            } catch (error) {
              setLoading(false);
              props.snackbarShowErrorMessage(error);
            }
          } else {
            props.snackbarShowMessage(
              "Non è stata create la presentazione da associare"
            );
          }
        }
      } else {
        try {
          setLoading(true);

          const response = await sendAssessmentAnswerMonitoring({
            id_patient: patId,
            id_assessment: selectedAssessmentElement,
            answers: _data.assessmentAnswers,
            id_assessment_template: _data.assessmentTemplate,
            addSD: addSD,
            selected_structure_dysfunctions: [...selectedStructureDysfunction],
          });
          //if the record as been updated i notify it and update the layout
          if (response) {
            setLoading(false);
            props.snackbarShowMessage(response.message);
            props.closeAssessmentAdd(false);
          }
        } catch (error) {
          setLoading(false);
          props.snackbarShowErrorMessage(error);
        }
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveAnswers = async (data) => {
    // console.info(data);
    data.assessmentAnswers = data.assessmentAnswers?.filter(
      (answer) => answer.answer !== ""
    );
    await setData(data);
    //Check if there are some answers that provide new symbols in the 3d model
    try {
      setLoading(true);
      const response = await getAnswerStructureDysfunctions({
        assessment_answer_ids: data.assessmentAnswers
          ?.filter((x) => typeof x.answer === "object")
          ?.map((x) => x.answer.id),
      });
      if (response.length > 0) {
        //Show dialog to select structure_dysfunction
        setOpenAddStructureDysfunction(true);
        setStructureDysfunctionList(response);
      } else {
        await _exec(false, data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const handleUpdateCategoriesSelected = () => {
    props.setLoading(true);
    const newCategoriesIdsArray = getValues("category");
    let foundIds = [];
    getValues("category") &&
      getValues("category").map((newCategoryElem, idx) => {
        if (newCategoryElem)
          foundIds.push(newCategoriesIdsArray.indexOf(newCategoryElem, idx));
      });

    setSelectedCategoriesIds(foundIds);
    props.setLoading(false);
  };

  const updateQuestionnairesList = async (categoryIdsFiltered) => {
    try {
      props.setLoading(true);
      if (
        categoryIdsFiltered &&
        selectedAssessmentElement &&
        !selectedAssessmentTemplate
      ) {
        const response = await getAssessmentQuestionnaires({
          categories: categoryIdsFiltered,
          element: selectedAssessmentElement,
        });
        setCurrentQuestionnairesList(response);
        props.setLoading(false);
      } else {
        if (selectedAssessmentTemplate) {
          const foundElementAssTemp = assessmentTemplatesList.find(
            (elem) => elem?.id == selectedAssessmentTemplate
          );
          if (foundElementAssTemp)
            setCurrentQuestionnairesList(foundElementAssTemp.AssesmentElement);
          if (props.isRepeatFromTemplate) {
            setValue("assessmentTemplate", selectedAssessmentTemplate);
          }
        }
        props.setLoading(false);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      props.setLoading(false);
    }
  };

  const handleIndexIncrementChange = async () => {
    if (assessmentListCurrentIndex + 1 <= assessmentsFilteredList.length) {
      let newIndex = assessmentListCurrentIndex + 1;
      setAssessmentsListCurrentIndex(newIndex);
      const result = await props.getAssessmentDetail(
        assessmentsFilteredList[newIndex],
        assessmentsFilteredList[newIndex]?.id
      );
      setCurrentAssessment(result);
    }
  };

  const handleIndexDecreaseChange = async () => {
    if (assessmentListCurrentIndex - 1 >= 0) {
      let newIndex = assessmentListCurrentIndex - 1;
      setAssessmentsListCurrentIndex(newIndex);
      const result = await props.getAssessmentDetail(
        assessmentsFilteredList[newIndex],
        assessmentsFilteredList[newIndex]?.id
      );
      setCurrentAssessment(result);
    }
  };

  return (
    <>
      <div>
        <StyledBodyColumn>
          <StyledRow>
            {!props.noTitle && (
              <StyledBodyHalfColumn>
                <SpText variant={"h1PageTitle"}>
                  {labels.patient.presentation.add.stepper.step4.title.toUpperCase()}
                </SpText>
                <SpText
                  variant={
                    props.isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"
                  }
                >
                  {labels.patient.presentation.add.stepper.step4.subtitle}
                </SpText>
              </StyledBodyHalfColumn>
            )}
          </StyledRow>
          {!props.detail ? (
            <Grid item xs={12}>
              <AddAssessment
                {...{
                  handleUpdateCategoriesSelected,
                  updateQuestionnairesList,
                  handleSubmit,
                  setSelectedCategoriesIds,
                  assessmentsCategory,
                  saveAnswers,
                  props,
                  getValues,
                  setValue,
                  selectedAssessmentElement,
                  selectedAssessmentTemplate,
                  setCurrentQuestionnairesList,
                  control,
                  currentQuestionnairesList,
                  setSelectedAssessmentTemplate,
                  setSelectedElement,
                  assessmentsElements,
                  assessmentTemplatesList,
                  selectedCategoriesIds,
                }}
              />
            </Grid>
          ) : (
            <Grid container direction="column" style={{ paddingRight: "0px" }}>
              <Grid item xs={12}>
                <DetailAssessment
                  {...{
                    handleSubmit,
                    saveAnswers,
                    assessmentListCurrentIndex,
                    props,
                    assessmentsList,
                    assessmentsFilteredList,
                    currentAssessment,
                    handleIndexDecreaseChange,
                    handleIndexIncrementChange,
                  }}
                />
              </Grid>
            </Grid>
          )}
          <SpDialog
            open={openAddStructureDysfunction}
            setOpen={setOpenAddStructureDysfunction}
            title={labels.bundles.assessment.symbols.title}
          >
            <Grid container direction="column">
              <Grid item xs={12}>
                <SpText variant={"h1PageSubtitle"}>
                  {labels.bundles.assessment.symbols.description}
                </SpText>
              </Grid>
              <Divider
                style={{
                  backgroundColor: "#31ccad",
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                  width: "100%",
                }}
              />
              {structureDysfunctionList?.map((sdItem, idx) => (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  style={{ marginBottom: "10px", alignContent: "center" }}
                >
                  <Grid item xs={10} style={{ alignSelf: "center" }}>
                    <SpText variant={"h4ComponentLabel"}>
                      {psTranslate(
                        sdItem.structure_dysfunction?.dysfunction?.name
                      )}{" "}
                      -{" "}
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.name
                      )}{" "}
                      (
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.area?.name
                      )}{" "}
                      -{" "}
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.area?.region
                          ?.name
                      )}
                      )
                    </SpText>
                  </Grid>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpCheckbox
                        key={sdItem.id}
                        rightlabel={false}
                        variant="column"
                        onChange={async (e) => {
                          props.onChange(e.target.checked);
                          if (e.target.checked) {
                            selectedStructureDysfunction.add(sdItem);
                          } else {
                            selectedStructureDysfunction.delete(sdItem);
                          }
                          setSelectedStructureDysfunction(
                            selectedStructureDysfunction
                          );
                        }}
                        checked={props.value}
                      />
                    )}
                    defaultValue={""}
                    name={`structure_dysfunction[${idx}].id`}
                    control={control}
                  />
                </Grid>
              ))}
              <Grid item xs={12} container direction="row">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    marginTop: "2%",
                  }}
                  direction="row"
                >
                  <SpButton
                    buttonType="accept"
                    style={{ flex: "auto" }}
                    text={labels.general.confirm}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await _exec(true);
                    }}
                  />
                  <SpButton
                    buttonType="cancel"
                    style={{ flex: "auto" }}
                    text={labels.general.skip}
                    variant="h1PageTitle"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await _exec(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SpDialog>
        </StyledBodyColumn>
      </div>
    </>
  );
};

export default withSnackbar(AddMonitoringAssessment);
