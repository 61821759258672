import { Grid } from "@material-ui/core";
import React from "react";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";

const PATIENT_KEY = "patient";
const MAX_DECIMALS = 2;

const TableRow = ({ data, maxValues }) => {
  let fillPercentage = 100;
  if (maxValues) {
    fillPercentage = Math.floor((data.value / maxValues[data.field]) * 100);
    fillPercentage = isNaN(fillPercentage) ? 0 : fillPercentage;
  }
  //A simple antialiasing trick
  const antialisingIncrease = fillPercentage
    ? Math.min(2, 0.1 * (Object.keys(maxValues ?? {}).length + 1))
    : 0;

  //Create data row
  const value = data.value;
  return (
    <div
      style={{
        border: `1px solid ${theme.colors.primary.lightBlue}`,
        height: 32,
        minWidth: "100%",
      }}
    >
      <div
        style={{
          background: `linear-gradient(70deg, ${
            theme.colors.primary.lightBlue
          } ${fillPercentage}%, ${theme.colors.primary.black} ${
            fillPercentage + antialisingIncrease
          }%)`,
          height: 30,
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            color: theme.colors.primary.white,
            fontWeight: 800,
            fontSize: "1.2rem",
            display: "flex",
          }}
        >
          {(isNaN(value) ? value : Number(value?.toFixed(MAX_DECIMALS))) ?? 0}
        </span>
      </div>
    </div>
  );
};

const createColumnHeaders = (data) => {
  //Compute max values for each column
  const maxValues = {};
  Object.values(data).forEach((values) => {
    Object.entries(values).forEach(([dataKey, dataValue]) => {
      if (!maxValues[dataKey] || maxValues[dataKey] < dataValue)
        maxValues[dataKey] = dataValue;
    });
  });

  //Prepare headers
  const headers = [
    ...Object.values(data).reduce((accum, vals) => {
      Object.keys(vals).forEach((el) => {
        accum.add(el);
      });
      return accum;
    }, new Set()),
  ];
  headers.sort();

  const newData = [];
  newData.push({
    id: PATIENT_KEY,
    field: PATIENT_KEY,
    headerName: labels.groups.groupReports.firstTable.header.patient,
    editable: false,
    width: 200,
    renderCell: (params) => <TableRow data={params} />,
  });
  newData.push(
    ...headers.map((header) => ({
      id: header,
      field: header,
      headerName: header,
      editable: false,
      minWidth: 100,
      maxWidth: 250,
      flex: 1,
      renderCell: (params) => <TableRow data={params} maxValues={maxValues} />,
    }))
  );
  return newData;
};

const createRowsData = (data) => {
  //Return row data
  return Object.entries(data).map(([patientKey, values]) => ({
    id: patientKey,
    [PATIENT_KEY]: patientKey,
    ...values,
  }));
};

const StatisticsTable = ({ data }) => {
  return (
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <SpDataGrid
        headers={createColumnHeaders(data)}
        rows={createRowsData(data)}
      />
    </Grid>
  );
};

export default StatisticsTable;
