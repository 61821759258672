import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { getAllExercises } from "../../../models/actions/Excercise";
import { labels, psTranslate } from "../../shared/translations";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const A3_ExerciseDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
}) => {
  const [exercisesList, setExercisesList] = useState([]);

  useEffect(async () => {
    const results = await getAllExercises();
    setExercisesList(results);
  }, []);

  const RowExercise = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: theme.colors.primary.lightBlue,
          }}
        />
      )}
      <Grid item xs={12} container>
        <Grid item xs={2}>
          <SpText variant="h4ComponentLabelCenter">
            {psTranslate(
              exercisesList.find(
                (excercise) => excercise.id === row.id_excercise
              )?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.series}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.repetition_time}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.resting}
          </SpText>
        </Grid>

        <Grid item xs={1}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.load}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.accessories}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.execution}
          </SpText>
        </Grid>

        <Grid item xs={2}>
          <SpText
            variant="h4ComponentLabelCenter"
            formControlStyle={{ minWidth: 40 }}
          >
            {row.note}
          </SpText>
        </Grid>
      </Grid>
    </div>
  );

  //Columns that contains text (vs numerical columns)
  const textualRowExerciseCols = [0, 7, 6, 5];

  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant="h4ComponentLabel">{data?.excercises_motivation}</SpText>
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant="h4ComponentLabel">{data.name}</SpText>
        </Grid>
        {data?.excercises?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.excerciseSheet.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.excercises?.length > 0 && (
          <Grid
            item
            xs={12}
            container
            style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
          >
            <Grid item xs={12}>
              <SpList
                rows={data?.excercises}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.excerciseSheet.list.header.map(
                      (head, idx) => (
                        <Grid
                          key={head}
                          item
                          xs={textualRowExerciseCols.includes(idx) ? 2 : 1}
                        >
                          <SpText variant="listHeaderCenter">{head}</SpText>
                        </Grid>
                      )
                    )}
                  </Grid>
                }
              >
                <RowExercise
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}
        {allowFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
            />
          ))}
      </Grid>
    </>
  );
};

export default A3_ExerciseDisabledDetails;
