import { computeZScore } from "../../patients/patientsReports/ReportHelperFns";
import { labels } from "../../shared/translations";

/**
 * Average given values
 * @param {Array} values Values to average
 */
const averageFn = (values) => {
  const avg = values.reduce((accum, val) => accum + val, 0);
  return values.length === 0 ? 0 : avg / values.length;
};

/**
 * Return best values (max)
 * @param {Array} values Values to be checked
 */
const bestFn = (values) => {
  return values.length === 0 ? 0 : Math.max(...values);
};

/**
 * Return worst values (min)
 * @param {Array} values Values to be checked
 */
const worstFn = (values) => {
  return values.length === 0 ? 0 : Math.min(...values);
};

/**
 * Return most recent value (len - 1)
 * @param {Array} values Values to be checked (ordered by date ascending)
 */
const latestFn = (values) => {
  return values.length === 0 ? 0 : values[values.length - 1];
};

export const categoryFunctions = {
  latest: latestFn,
  best: bestFn,
  worst: worstFn,
  average: averageFn,
};

/**
 * Compute coefficient of variation on given data
 * Formula: CV = 100 * (stddev / average)
 * @param {Array} values Values to compute
 */
const computeCoefficientOfVariation = (values) => {
  const average = values.reduce((a, b) => a + b, 0) / values.length;
  const stddev = Math.sqrt(
    values.reduce((acc, val) => acc + Math.pow(val - average, 2), 0) /
      values.length
  );
  const coefficientOfVariation = 100 * (stddev / average);
  return isNaN(coefficientOfVariation) ? 0 : coefficientOfVariation;
};

export const extraFunctionKeys = ["zScore", "rank", "coefficientOfVariation"];

/**
 * Create data for second statistics table,
 * adding value categories (best, worst, average, latest)
 * and extras (zScore, rank and coefficientOfVariation)
 * as required by checked checkboxes
 * @param {any} newTableData Statistics second table source data
 * @param {Array} currSecondTableCategories Which categories are enabled
 * @param {Array} currSecondTableExtras Which extras are enabled
 */
export const adaptSecondTableData = (
  newTableData,
  currSecondTableCategories,
  currSecondTableExtras
) => {
  const rankKeys = new Set();

  //Iterate patients
  newTableData = Object.entries(newTableData).reduce(
    (accum, [patientKey, patData]) => {
      //Iterate elements
      accum[patientKey] = Object.entries(patData).reduce(
        (accum, [elemKey, elemData]) => {
          //Iterate categories
          currSecondTableCategories.forEach((tableCat) => {
            //Compute value
            const val = categoryFunctions[tableCat](elemData);
            const key = `${elemKey} - ${labels.groups.groupReports.secondTable.categories[tableCat]}`;
            accum[key] = val;

            rankKeys.add(key);

            //Compute z-score
            if (currSecondTableExtras.has("zScore"))
              accum[
                `${key} - ${labels.groups.groupReports.secondTable.extra.zScore}`
              ] = computeZScore(val, elemData);
          });

          //Compute coefficient of variation
          if (currSecondTableExtras.has("coefficientOfVariation"))
            accum[
              `${elemKey} - ${labels.groups.groupReports.secondTable.extra.coefficientOfVariation}`
            ] = computeCoefficientOfVariation(elemData);

          return accum;
        },
        {}
      );
      return accum;
    },
    {}
  );

  //Compute rank
  if (currSecondTableExtras.has("rank")) {
    rankKeys.forEach((rankKey) => {
      Object.entries(newTableData)
        .sort(
          ([_1, patData1], [_2, patData2]) =>
            (patData2[rankKey] ?? 0) - (patData1[rankKey] ?? 0)
        )
        .forEach(([patKey], idx) => {
          newTableData[patKey][
            `${rankKey} - ${labels.groups.groupReports.secondTable.extra.rank}`
          ] = idx + 1;
        });
    });
  }

  return newTableData;
};
