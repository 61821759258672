import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import SpDialog from "../../../components/atoms/SpDialog";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import {
  getAssessmentPresentationAnswers,
  getAssessmentPresentationList,
  getPresentationAssessmentToRepeat,
} from "../../../models/actions/Assessment";
import AddMonitoringAssessment from "../../../components/bundles/assessment/AddMonitoringAssessment";
import { isMobile } from "react-device-detect";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import NoData from "../shared/utils/NoData";
import { rollbar } from "../../../utils/common";

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const PatientPresentationDetailAssessment = (props) => {
  const [displayAssessments, setDisplayAssessments] = useState([]);
  const [openAssessmentChoice, setOpenAssessmentChoice] = useState(false);
  const [assessmentAdd, setAssessmentAdd] = useState(false);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCategoriesObj, setSelectedCategoriesObj] = useState(null);

  useEffect(() => {}, [props.go]);

  const { presentationId, patId } = useParams();
  const { endDate } = props;
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAssessmentPresentationList({
        id_presentation: presentationId,
        id_patient: patId,
      });
      response.reverse();
      setDisplayAssessments(response);
    } catch (error) {
      rollbar.error("PatientPresentationDetailAssessment - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!assessmentAdd) {
      fetchData();
      setAssessmentDetail(null);
      setSelectedCategoriesObj(null);
    }
  }, [assessmentAdd]);

  useEffect(() => {
    if (assessmentDetail) setAssessmentAdd(true);
  }, [assessmentDetail]);

  useEffect(() => {
    if (selectedCategoriesObj) setAssessmentAdd(true);
  }, [selectedCategoriesObj]);

  const repeatAssessment = async (presentationAssessment) => {
    try {
      const result = await getPresentationAssessmentToRepeat({
        id_presentation_assessment: presentationAssessment.id,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setSelectedCategoriesObj(result);
      }
    } catch (error) {
      rollbar.error(
        "PatientPresentationDetailAssessment - repeatAssessment",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const closeAssessmentAdd = () => setAssessmentAdd(false);

  const getAssessmentDetail = async (row, presentationAssessmentId) => {
    try {
      const result = await getAssessmentPresentationAnswers({
        id_assessment: row.assessment?.id,
        id_presentation: presentationId,
        id_presentation_assessment: presentationAssessmentId,
        id_patient: patId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setAssessmentDetail(result);
        setSelectedAssessment(
          row.assessment ?? { description: row.template_name }
        );
        setSelectedRow(row);
      }
    } catch (error) {
      rollbar.error(
        "PatientPresentationDetailAssessment - getAssessmentDetail",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const getAssessmentDetailFromScrolling = async (
    row,
    presentationAssessmentId
  ) => {
    try {
      const result = await getAssessmentPresentationAnswers({
        id_assessment: row.assessment?.id_assessment,
        id_presentation: presentationId,
        id_presentation_assessment: presentationAssessmentId,
        id_patient: patId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setAssessmentDetail(result);
        setSelectedAssessment(
          row.assessment ?? { description: row.template_name }
        );
        setSelectedRow(row);
        return result;
      }
    } catch (error) {
      rollbar.error(
        "PatientPresentationDetailAssessment - getAssessmentDetailFromScrolling",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const headCellsAssessment = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.name,
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.date,
      isAction: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const _onRowClick = (ass) => getAssessmentDetail(ass, ass.id);

  const AssessmentRow = ({ row, index }) => (
    <>
      <SpTableRow id={index} tabIndex={index}>
        <SpTableCell
          onClick={() => _onRowClick(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {row.assessment?.name
              ? psTranslate(row.assessment?.name)
              : row.template_name}
          </SpText>
        </SpTableCell>
        <SpTableCell
          onClick={() => _onRowClick(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {moment(row.createdAt).format("DD/MM/yyyy")}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpButton
            style={{ maxWidth: "40%" }}
            variant="column"
            onClick={() => repeatAssessment(row)}
            buttonType="accept"
            text={labels.patient.monitoring.repeatAssessment.toUpperCase()}
          />
        </SpTableCell>
      </SpTableRow>
    </>
  );

  return (
    <>
      <StyledBodyColumn>
        <Grid container>
          {!assessmentDetail ? (
            <Grid item xs={12}>
              <SpText style={{ margin: 0 }} variant="h1PageTitle">
                {labels.patient.presentation.detail.stepper.step5.title.toUpperCase()}
              </SpText>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <SpText
                style={{ margin: 0, marginLeft: 10 }}
                variant="h1PageTitle"
              >
                {selectedAssessment &&
                  selectedAssessment?.description?.toUpperCase()}
              </SpText>
            </Grid>
          )}
          {!assessmentDetail &&
            endDate &&
            (displayAssessments.length > 0 ? (
              <Grid item xs={12}>
                <SpText variant="h1PageSubtitle">
                  {
                    labels.patient.presentation.detail.stepper.step5
                      .closedPresentation
                  }
                </SpText>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <SpText variant="h1PageSubtitle">
                  {labels.patient.presentation.detail.stepper.step5.noData}
                </SpText>
              </Grid>
            ))}

          {(!endDate || assessmentDetail) && (
            <Grid item xs={4}>
              <SpButton
                style={{ width: "100%" }}
                variant="none"
                onClick={() => setAssessmentAdd(!assessmentAdd)}
                buttonType="accept"
                text={
                  assessmentAdd
                    ? labels.patient.monitoring.closeAddAssessment.toUpperCase()
                    : labels.patient.monitoring.addAssessment.toUpperCase()
                }
              />
            </Grid>
          )}
        </Grid>
        {assessmentAdd ? (
          <AddMonitoringAssessment
            selectedAssessment={selectedAssessment}
            selectedRow={selectedRow}
            presentation={presentationId}
            setLoading={props.setLoading}
            getAssessmentDetail={getAssessmentDetailFromScrolling}
            noTitle={true}
            detail={endDate || assessmentDetail}
            assessmentDetail={assessmentDetail}
            assessmentsDisplayList={displayAssessments}
            closeAssessmentAdd={closeAssessmentAdd}
            isRepeatAssessment={
              selectedCategoriesObj &&
              selectedCategoriesObj.categoryIds?.length > 0
            }
            selectedCategoriesObj={selectedCategoriesObj}
            isRepeatFromTemplate={
              selectedCategoriesObj &&
              selectedCategoriesObj.templateAssessmentId
            }
          />
        ) : displayAssessments.length > 0 ? (
          <SpTable
            headCells={headCellsAssessment}
            pagination={true}
            rows={displayAssessments}
            rowKey="id"
            padding={false}
            notCheckable={false}
            tableContainerMaxHeight={
              isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
            }
          >
            <AssessmentRow />
          </SpTable>
        ) : (
          !endDate && (
            <NoData label={"Non sono ancora stati inseriti assessment"} />
          )
        )}
      </StyledBodyColumn>
      <SpDialog
        open={openAssessmentChoice}
        setOpen={setOpenAssessmentChoice}
        title={"Scegli un template di assessment"}
      />
    </>
  );
};

export default withSnackbar(PatientPresentationDetailAssessment);
