import React from "react";
import "../App.css";
import SpText from "../components/atoms/SpText";
import { styled } from "../components/styled";
import { Grid } from "@material-ui/core";

const MyGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70vh",
});

const NotFound = (props) => {
  return (
    <MyGrid>
      <SpText variant={"h1PageTitle"}> 404 - Page Not Found </SpText>
    </MyGrid>
  );
};

export default NotFound;