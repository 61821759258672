import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";

import moment from "moment";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { green } from "@material-ui/core/colors";
import { labels, psTranslate } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import { getPatientSelected } from "./patientsReportInjury/ExposureFunction";

import {
  getPatientInjury,
  getGroupPatients,
  getHeader,
  getPatientsAndGroupsSelection,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import {
  selectedGroupsInjuryRate,
  selectPatientsInjuryRate,
  selectedGroupsDaysAbsence,
  selectPatientsDaysAbsence,
  selectedGroupsBurder,
  selectPatientsInjuryBurden,
} from "./patientsReportInjury/TrainingInjuryFunction";

const DEFAULT_RANGE = moment.rangeFromInterval("month", -1, moment());
const dateFormat = "YYYY-MM-DD";
const injuryRateLabel = labels.analytics.injuryReport.graphs.injuryRate;
const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const trainingInjuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;

const PatientsReportsStep7TrainingInjury = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barChar, setBarChar] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const { control, getValues } = useForm({
    shouldUnregister: false,
  });
  const { patId } = useParams();
  const [loading, setLoading] = useState(false);
  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
    "&:disabled": {
      color: "#31ccad",
    },
  };

  const GreenRadio = withStyles({
    root: {
      color: "white",
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
  useEffect(async () => {
    setLoading(true);
    try {
      const response = await listGroupsByProfessional();
      const result = getGroupPatients(response, patId);
      setPatients(result.allPatients);
      setGroups(result.mapGroup);
      await getPatientInjury(result.mapGroup, dateRange);
      selectExposureActivity(result.mapGroup, dateRange, result.allPatients);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups
  ) => {
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );
    let result;
    let resultInjuryGroups;

    const action = getValues("graficoSelezionato");
    if (action === injuryRateLabel) {
      resultInjuryGroups = selectedGroupsInjuryRate(newSelectedGroups);
      result = selectPatientsInjuryRate(
        newPatientsSelected,
        groups,
        props.type
      );
    }

    if (action === daysAbsenceLabel) {
      resultInjuryGroups = selectedGroupsDaysAbsence(
        newSelectedGroups,
        props,
        props.type
      );
      result = selectPatientsDaysAbsence(newPatientsSelected, props.type);
    }

    if (action === trainingInjuriesBurdenLabel) {
      resultInjuryGroups = selectedGroupsBurder(newSelectedGroups, props.type);
      result = selectPatientsInjuryBurden(
        newPatientsSelected,
        groups,
        props.type
      );
    }

    // concateno il risultato dei pazienti con quello dei gruppi selezionati
    result.dataChart =
      resultInjuryGroups.dataChart !== undefined
        ? result.dataChart.concat(resultInjuryGroups.dataChart)
        : result.dataChart;
    setLoading(true);
    setDataChart(result.dataChart);
    setSelectedPatients(newPatientsSelected);
    setBarChar(result.barChart);
    setLoading(false);
    if (newPatientsSelected.length === 0 && newSelectedGroups.length === 0) {
      selectExposureActivity(groups, dateRange);
    }
  };

  /**
   * Funzione che crea il grafico con i gruppi selezionati
   *
   * @param gruppi: lista di gruppi da visualizzare
   *
   */
  const injuryGroupSelectedFunction = (gruppi) => {
    const action = getValues("graficoSelezionato");
    // compongo il grafico rispetto al tipo selezionato
    let res;
    setLoading(true);
    if (action === injuryRateLabel) {
      res = selectedGroupsInjuryRate(gruppi, props.type);
    }
    if (action === daysAbsenceLabel) {
      res = selectedGroupsDaysAbsence(gruppi, props.type);
    }
    if (action === trainingInjuriesBurdenLabel) {
      res = selectedGroupsBurder(gruppi, props.type);
    }
    setBarChar(res.barChart);
    setDataChart(res.dataChart);
    setLoading(false);
  };

  /**
   * Funzione che in base ai gruppi selezionati crea il grafico
   *
   * @param tempSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectGroupFunction = (tempSelectedGroups) => {
    setSelectedGroups(tempSelectedGroups);
    if (tempSelectedGroups?.length === 0) {
      // creo il grafico con tutti i gruppi
      selectExposureActivity(groups, dateRange);
    } else {
      // se vengono selezionati piu' gruppi
      injuryGroupSelectedFunction(tempSelectedGroups);
    }
  };
  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @params newPatients: lista dei pazienti selezionati
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    newPatients = patients
  ) => {
    let temp = [];
    setSelectedGroups([]);
    setLoading(true);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
      });
      // let activity = getValues("graficoSelezionato");
      let item = {};
      item["id"] = tempGroup.id;
      item["name"] = tempGroup.name;
      item["patients"] = tempGroup.patients;

      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );

      if (props.type === "training") {
        item["action"] = allCalendarActivity.filter(
          (all) => all?.activity_datum?.activity_type?.key === "training"
        );
        temp.push(item);
      } else if (props.type === "match") {
        item["action"] = allCalendarActivity.filter(
          (all) => all?.activity_datum?.activity_type?.key === "race"
        );
        temp.push(item);
      }
    }
    setSelectedGroups([]);
    setSelectedPatients([]);
    setGroups(temp);

    // calcolo del paziente attuale in base al suo id
    let tempPat = [newPatients.find(({ id }) => id === parseInt(patId))];
    // inizializzo il grafico con le statistiche del paziente
    selectionActionFunction(tempPat, "group", []);

    setLoading(false);
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    let temp = newDateRange;
    if (temp.start < newDateRange.end) {
      const response = await listGroupsByProfessional();
      const result = getGroupPatients(response, patId);
      setPatients(result.allPatients);
      setGroups(result.mapGroup);
      await getPatientInjury(result.mapGroup, newDateRange);
      selectExposureActivity(result.mapGroup, newDateRange, result.allPatients);
      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <Grid container item xs={6}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>

      {/* selezione dei pazienti e dei gruppi  */}
      {getPatientsAndGroupsSelection(
        patients,
        selectedPatients,
        selectionActionFunction,
        groups,
        selectGroupFunction,
        selectedGroups,
        labels.analytics.injuryReport.avarageGroup
      )}
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={9}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <TrainingReport dataChart={dataChart} barChar={barChar} />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.selectGraph}
            </SpText>
          </Grid>
          {/*  radio group per selezionare l'attivita' */}
          <Controller
            as={
              <RadioGroup
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={injuryRateLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={injuryRateLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={daysAbsenceLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={daysAbsenceLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={trainingInjuriesBurdenLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={trainingInjuriesBurdenLabel}
                />
              </RadioGroup>
            }
            name={`graficoSelezionato`}
            control={control}
            defaultValue={injuryRateLabel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withSnackbar(PatientsReportsStep7TrainingInjury);
