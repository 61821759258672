import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import { getTrainingTemplateById } from "../../../models/actions/Activity";
import RowTraining from "../../patients/shared/activitiesData/RowTraining";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";

const TrainingInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  control,
  setValue,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  event,
  allowFeedback,
  isGroupCard,
}) => {
  const {
    fields: fieldsTraining,
    append: appendTraining,
    remove: removeTraining,
  } = useFieldArray({
    control,
    name: "training",
    keyName: "id",
    defaultValues: [],
  });

  const RenderTrainingMinutesCount = ({ control }) => {
    const watchMinutesTotalCountTrainings = useWatch({
      control,
      name: "training",
    });
    const currentArray = watchMinutesTotalCountTrainings;
    let minutesSum = 0;
    currentArray?.forEach((t) => {
      if (typeof t.time === "string") {
        const output = parseInt(t.time, 10);
        minutesSum = minutesSum + (Number.isNaN(output) ? 0 : output);
      } else {
        minutesSum = minutesSum + t.time;
      }
    });

    return (
      <SpText
        variant="h1PageSubtitleColored"
        style={{ display: "inline-block" }}
      >
        {minutesSum} {labels.agenda.inputDetails.totalMins}
      </SpText>
    );
  };

  useEffect(async () => {
    if (selectedTemplate) {
      const templateData = await getTrainingTemplateById({
        id_template: selectedTemplate,
      });
      if (templateData) {
        removeTraining();
        setValue("quantity_training", templateData.number);
        appendTraining(templateData.row_training_templates);
      }
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeTraining();
      setValue("training_motivation", templateData.training_motivation);
      setValue("quantity_training", templateData.training?.length ?? 0);
      appendTraining(templateData.training);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="training_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeTraining(fieldsTraining.length - 1);
              if (fieldsTraining.length > 0)
                setValue("quantity_training", fieldsTraining.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            minValue={1}
            name="quantity_training"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.training.inputs.quantity}
            disabled={disabled}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendTraining({ id: fieldsTraining.length });
              setValue("quantity_training", fieldsTraining.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>
      {loadParametersData && (
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
        />
      )}
      {fieldsTraining.length > 0 && (
        <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
          <Grid item xs={6}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.training.list.title}
            </SpText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <RenderTrainingMinutesCount control={control} />
          </Grid>
        </Grid>
      )}
      {fieldsTraining.length > 0 && (
        <Grid
          item
          xs={12}
          container
          style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
        >
          <Grid item xs={12}>
            <SpList
              rows={fieldsTraining}
              rowKey="id"
              checkable={false}
              deletable={false}
              HeaderRow={
                <Grid container style={{ justifyContent: "flex-start" }}>
                  {labels.mylab.tempAdd.training.list.header.map((head) => (
                    <Grid key={head} item xs={4}>
                      <SpText variant="listHeaderCenter">{head}</SpText>
                    </Grid>
                  ))}
                </Grid>
              }
            >
              <RowTraining
                headers={labels.mylab.tempAdd.training.list.header}
                control={control}
                register={register}
                disabled={disabled}
              />
            </SpList>
          </Grid>
        </Grid>
      )}
      {allowFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ))}
    </Grid>
  );
};

export default TrainingInputDetails;
