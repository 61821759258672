import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import { Grid } from "@material-ui/core";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  getAreasByRegion,
  getDysfunctionsCategoriesByDisorderType,
  getInterviewByArea,
  getInterviewByRegion,
  getPathologyByArea,
  getPathologyByRegion,
  getPathologyByStructure,
  getStructuresByArea,
} from "../../../models/actions/Pathologies";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import {
  createPresentation,
  getPresentationTypeList,
} from "../../../models/actions/Presentation";
import SpButton from "../../../components/atoms/SpButton";
import { getSortedAndTranslatedArray, rollbar } from "../../../utils/common";

const PatientsPresentationAddStep1 = (props) => {
  const [pathologies, setPathologies] = useState([]);
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [structures, setStructures] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const [structureSelected, setStructureSelected] = useState(null);
  const [regionSelected, setRegionSelected] = useState(null);
  const [presentationTypeList, setPresentationTypeList] = useState();
  const [dysfunctionTypeList, setDysfunctionTypeList] = useState();
  const [selectedType, setSelectedType] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [savedPresentation, setSavedPresentation] = useState(false);

  const { control, register, handleSubmit, getValues, setValue, watch, reset } =
    useForm({
      shouldUnregister: false,
    });

  const watchRegionSelect = watch("id_region");
  const watchAreaSelect = watch("id_area");
  const watchStructureSelect = watch("id_structure");
  const watchDysfunction = watch("dysfunction");
  const watchBodyAreas = watch(["id_region", "id_area", "id_structure"]);
  const watchTypeSelect = watch("type");
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const defaultValues = {
    type: null,
    dysfunction: null,
    severity: null,
    id_area: null,
    id_region: null,
    id_structure: null,
    id_pathology: null,
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(async () => {
    await fetchData();
  }, [refreshData]);

  useEffect(async () => {
    if (!regionSelected && !areaSelected) resetFunction();
  }, [regionSelected, areaSelected]);

  useEffect(() => {
    const foundRegion = regions.find((region) => region.key === regionSelected);
    setValue("id_region", foundRegion?.id);
  }, [regionSelected]);

  useEffect(() => {
    const foundArea = areas.find((area) => area.key === areaSelected);
    setValue("id_area", foundArea?.id);
  }, [areaSelected]);

  useEffect(async () => {
    setSavedPresentation(false);
    setInterviews(null);
    const data = getValues("id_region");
    if (data) {
      if (!getValues("id_structure") && !getValues("id_area")) {
        await fetchAreasByRegion(data);
        await fetchInterviewRegion(data);
        await fetchPathologiesByRegion(data);
      }
    }
  }, [watchRegionSelect]);

  useEffect(async () => {
    const data = getValues("id_area");
    setInterviews(null);
    //console.log("id_area", data);
    if (data) {
      //looking for the structure in the array
      if (!getValues("id_region")) {
        const foundArea = areas.find((elem) => elem.id == data);
        if (foundArea) setValue("id_region", foundArea?.id_region);
      }
      if (!getValues("id_structure")) {
        await fetchStructuresByArea(data);
        await fetchInterviewArea(data);
        await fetchPathologiesByArea(data);
      }
    }
  }, [watchAreaSelect]);

  useEffect(async () => {
    const data = getValues("id_structure");
    //console.log("structure", data);
    if (data) {
      //looking for the structure in the array
      if (!getValues("id_area")) {
        const foundStructure = structures.find((elem) => elem.id == data);

        if (foundStructure) setValue("id_area", foundStructure?.id_area);
      }
      await fetchPathologiesByStructure(data);
    }
  }, [watchStructureSelect]);

  useEffect(async () => {
    await fetchDysfunctionsCategoriesByDisorderTypeAndStructures();
  }, [watchTypeSelect]);

  useEffect(() => {
    setSelectedType(true);
  }, [watchDysfunction]);

  useEffect(() => {
    if (
      getValues("type") &&
      (getValues("id_region") ||
        getValues("id_area") ||
        getValues("id_structure"))
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [watchTypeSelect]);
  useEffect(() => {
    if (
      getValues("type") &&
      (getValues("id_region") ||
        getValues("id_area") ||
        getValues("id_structure"))
    ) {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [watchBodyAreas]);

  const resetFunction = () => {
    reset(defaultValues);
    setRefreshData(!refreshData);
    setRegionSelected(null);
    setAreaSelected(null);
    setStructureSelected(null);
  };

  const savePresentation = async (data, next) => {
    data.id_patient = patId;
    let newData = data;
    Object.keys(data).map((key) => {
      if (!data[key]) {
        newData[key] = null;
      }
    });

    try {
      newData["pathology_operation_type"] = labels.patient.presentation.pathology_operation_type;
      const response = await createPresentation(newData);
      props.snackbarShowMessage(response.message);
      history.push(
        `/patients/edit/${patId}/presentation/${response.id_presentation}`
      );
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - savePresentation", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getRegionAreaStructureLists();
      if (result) {
        setRegions(getSortedAndTranslatedArray(result.regionList));
        setAreas(getSortedAndTranslatedArray(result.areaList));
        setStructures(getSortedAndTranslatedArray(result.structureList));
      }
      const resultPresentationTypeList = await getPresentationTypeList();
      if (resultPresentationTypeList) {
        setPresentationTypeList(resultPresentationTypeList);
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInterviewRegion = async (id_region) => {
    try {
      if (!getValues("id_area")) {
        let currentInterviews;
        currentInterviews = await getInterviewByRegion({
          id_region: id_region,
        });
        if (currentInterviews.length > 1) {
          setInterviews(currentInterviews);
        } else {
          if (currentInterviews.length === 1) {
            setValue("id_interview", currentInterviews[0]?.id_interview);
          }
        }
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchInterviewRegion",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchInterviewArea = async (id_area) => {
    try {
      let currentInterviews;
      currentInterviews = await getInterviewByArea({ id_area: id_area });
      if (currentInterviews.length > 1) {
        setInterviews(currentInterviews);
      } else {
        if (currentInterviews.length === 1) {
          setValue("id_interview", currentInterviews[0]?.id_interview);
        }
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchInterviewArea", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchAreasByRegion = async (id_region) => {
    try {
      if (id_region) {
        const currentAreas = await getAreasByRegion({ id_region: id_region });
        setAreas(getSortedAndTranslatedArray(currentAreas));
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchAreasByRegion", error);
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchStructuresByArea = async (id_area) => {
    try {
      if (id_area) {
        const currentStructures = await getStructuresByArea({
          id_area: id_area,
        });
        setStructures(
          getSortedAndTranslatedArray(currentStructures?.structures)
        );
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchStructuresByArea",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchPathologiesByRegion = async (id_region) => {
    try {
      if (id_region) {
        const currentPathologies = await getPathologyByRegion({
          id_region: id_region,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByRegion",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchPathologiesByArea = async (id_area) => {
    try {
      if (id_area) {
        const currentPathologies = await getPathologyByArea({
          id_area: id_area,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByArea",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchPathologiesByStructure = async (id_structure) => {
    try {
      if (id_structure) {
        const currentPathologies = await getPathologyByStructure({
          id_structure: id_structure,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByStructure",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchDysfunctionsCategoriesByDisorderTypeAndStructures = async () => {
    try {
      const disorder_type = presentationTypeList?.find(
        (elem) => elem?.name == getValues("type")
      )?.id;
      if (disorder_type) {
        const currentDysfunctionCategories =
          await getDysfunctionsCategoriesByDisorderType({
            id_dysfunction_category: disorder_type,
          });
        setDysfunctionTypeList(currentDysfunctionCategories);
        setSelectedType(true);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchDysfunctionsCategoriesByDisorderTypeAndStructures",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const formClearFieldsAfter = (startField) => {
    const ids = [
      "",
      "id_region",
      "id_area",
      "id_interview",
      "id_structure",
      "id_pathology",
    ];
    const startId = ids.findIndex((elem) => elem === startField) + 1;
    if (startId !== 0)
      for (let i = startId; i < ids.length; i++) setValue(ids[i], null);
  };

  return (
    <>
      <form onSubmit={handleSubmit(savePresentation)}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="h1PageTitle">
              {labels.patient.presentation.add.title.toUpperCase()}
            </SpText>
          </Grid>

          <Grid item xs={12} container spacing={1}>
            <Grid item container style={{ height: "100%" }} xs={4}>
              <Grid
                item
                container
                direction="column"
                style={{ height: "100%" }}
                xs={12}
              >
                <SpTextInput
                  name="start_date"
                  type="date"
                  maxValue={"2100-12-31"}
                  defaultValue={moment(new Date()).format("yyyy-MM-DD")}
                  inputRef={register}
                  label={labels.patient.presentation.add.startDate}
                />
                <SpTextInput
                  name="estimated_end_date"
                  type="date"
                  maxValue={"2100-12-31"}
                  style={{ marginTop: "5px" }}
                  inputRef={register}
                  label={labels.patient.presentation.add.estimatedEndDate}
                />
                <Controller
                  style={{ marginTop: "3%", minWidth: "100%" }}
                  render={(props) => (
                    <SpSelect
                      label={labels.patient.presentation.add.presentationType}
                      value={props.value}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectAnswer
                      }
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        setSelectedType(false);
                        setValue("dysfunction", "");
                        setValue("severity", "");
                      }}
                    >
                      {presentationTypeList?.map((p) => (
                        <SpSelectMenuItem key={p.id} value={p.name}>
                          {psTranslate(p.name)}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`type`}
                  control={control}
                />
                <Controller
                  style={{ marginTop: "3%", minWidth: "100%" }}
                  render={(props) => (
                    <SpSelect
                      label={labels.patient.presentation.add.dysfunction}
                      value={props.value}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectAnswer
                      }
                      disabled={!getValues("type")}
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      {dysfunctionTypeList?.map((p) => (
                        <SpSelectMenuItem key={p.id} value={p.id}>
                          {psTranslate(p.name)}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`dysfunction`}
                  control={control}
                />
                <Controller
                  style={{ marginTop: "3%", minWidth: "100%" }}
                  render={(props) => (
                    <SpSelect
                      label={labels.patient.presentation.add.severity}
                      value={props.value}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectAnswer
                      }
                      disabled={!getValues("dysfunction")}
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      {Object.entries(labels.bodymap.severityLabels).map(
                        ([key, value]) => (
                          <SpSelectMenuItem key={key} value={parseInt(key)}>
                            {value}
                          </SpSelectMenuItem>
                        )
                      )}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`severity`}
                  control={control}
                />
                <SpText style={{ marginTop: "3%" }} variant="h4ComponentLabel">
                  {labels.patient.presentation.add.reasonOfConsultation}
                </SpText>
                <SpAutocomplete
                  formControlWidth={"100%"}
                  label={labels.patient.presentation.add.selectRegion}
                  value={regions.find((el) => el.key == regionSelected) ?? ""}
                  selectPlaceholder={
                    labels.patient.presentation.add.selectAnswer
                  }
                  onChange={(e, newValue) => {
                    setRegionSelected(newValue?.key);
                    setValue("id_region", newValue?.id);
                    formClearFieldsAfter("id_region");
                  }}
                  options={regions}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                />
                <SpAutocomplete
                  formControlWidth={"100%"}
                  label={labels.patient.presentation.add.selectArea}
                  value={areas.find((el) => el.key == areaSelected) ?? ""}
                  selectPlaceholder={
                    labels.patient.presentation.add.selectAnswer
                  }
                  onChange={(e, newValue) => {
                    setAreaSelected(newValue?.key);
                    setValue("id_area", newValue?.id);
                    formClearFieldsAfter("id_area");
                  }}
                  options={areas}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                />
                <SpAutocomplete
                  formControlWidth={"100%"}
                  label={labels.patient.presentation.add.selectStructure}
                  value={
                    structures.find((el) => el.id == structureSelected) ?? ""
                  }
                  selectPlaceholder={
                    labels.patient.presentation.add.selectAnswer
                  }
                  onChange={(e, newValue) => {
                    setStructureSelected(newValue?.id);
                    setValue("id_structure", newValue?.id);
                    formClearFieldsAfter("id_structure");
                  }}
                  options={structures}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) => option.id === value?.id}
                />
                <Controller
                  render={(props) => (
                    <SpAutocomplete
                      formControlWidth={"100%"}
                      label={labels.patient.presentation.add.selectPathology}
                      value={pathologies.find((el) => el?.id == props.value)}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectAnswer
                      }
                      onChange={(e, newValue) => props.onChange(newValue.id)}
                      options={pathologies}
                      getOptionLabel={(option) => psTranslate(option.name)}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                    />
                  )}
                  defaultValue={""}
                  name={`id_pathology`}
                  control={control}
                />

                {interviews && interviews.length > 1 && (
                  <Controller
                    render={(props) => (
                      <SpSelect
                        label={labels.patient.presentation.add.selectInterview}
                        disabled={!getValues("id_region")}
                        value={props.value}
                        selectPlaceholder={
                          labels.patient.presentation.add.selectAnswer
                        }
                        onChange={(e) => props.onChange(e.target.value)}
                      >
                        {interviews.map((p) => (
                          <SpSelectMenuItem
                            key={p.id_interview}
                            value={p.id_interview}
                          >
                            {psTranslate(p.name_interview)}
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    style={{ marginTop: "3%", minWidth: "100% !important" }}
                    defaultValue={""}
                    name={`id_interview`}
                    control={control}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={8} style={{ minHeight: 400 }}>
              <Sp3DModel
                modelBehaviour={"MARKER"}
                type="PATIENT"
                hideFilters={true}
                setSelectedRegion={setRegionSelected}
                setSelectedArea={setAreaSelected}
                selectedRegion={regionSelected}
                selectedArea={areaSelected}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={4}
          direction="column"
          style={{ width: "98%", minWidth: 0, marginTop: 10 }}
        >
          <SpButton
            buttonType="accept"
            style={{ width: "98%", minWidth: 0 }}
            text={labels.patient.presentation.add.save}
            disabled={disabledSave}
            variant="lightGreenFill"
            type="submit"
          />
          <SpButton
            buttonType="accept"
            style={{ width: "98%", minWidth: 0, marginTop: "10px" }}
            text={labels.general.reset}
            variant="lightGreenFill"
            onClick={resetFunction}
          />
        </Grid>
      </form>
    </>
  );
};

export default withSnackbar(PatientsPresentationAddStep1);