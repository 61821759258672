import {
  AbstractMesh,
  ActionManager,
  ExecuteCodeAction,
  Vector3,
} from "@babylonjs/core";
import MeshFadeInOutBehaviour from "../Extensions/MeshFadeInOutBehaviour";

export default class MeshGroup {
  constructor(scene, meshes, name) {
    this.name = name;
    this.children = [];

    this.meshes = meshes;
    this._onEnterCallback = null;
    this._onExitCallback = null;
    this._onClickCallback = null;

    this.center = new Vector3(0, 0, 0);

    for (let i = 0; i < meshes.length; i++) {
      let mesh = meshes[i];

      //Mesh optimizations
      mesh.freezeWorldMatrix();
      mesh.cullingStrategy =
        AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY;

      //Setup action manager
      if (!mesh.actionManager) mesh.actionManager = new ActionManager(scene);

      //Create opacity function
      mesh._hovered = false;
      mesh._opacity = 1;
      mesh._setOpacity = (val) => {
        mesh.visibility = val;
        mesh.edgesColor.a = val;
        mesh.setEnabled(val);
      };

      //Give default values to hover materials if not set
      if (!mesh._normalMaterial) mesh._normalMaterial = mesh.material;
      if (!mesh._hoverMaterial)
        mesh._hoverMaterial = mesh.material ? mesh.material.clone() : null;
      if (!mesh._alternateMaterial)
        mesh._alternateMaterial = mesh.material ? mesh.material.clone() : null;

      //Fade in/out behaviour
      if (!mesh._fadeBehaviour) {
        mesh._fadeBehaviour = new MeshFadeInOutBehaviour();
        mesh._fadeBehaviour.attach(
          () => mesh.visibility,
          (val) => mesh._setOpacity(val) //Will also set enable/disabled
        );
      }

      //Calculate center
      this.center.addInPlace(mesh.getBoundingInfo().boundingSphere.centerWorld);
    }
    this.center.scaleInPlace(1.0 / this.meshes.length);

    //Callbacks
    this.meshOnPointerEnter = new ExecuteCodeAction(
      ActionManager.OnPointerOverTrigger,
      (evnt) => {
        this.setHover(true);
        if (this._onEnterCallback) this._onEnterCallback(this);
      }
    );
    this.meshOnPointerExit = new ExecuteCodeAction(
      ActionManager.OnPointerOutTrigger,
      (evnt) => {
        this.setHover(false);
        if (this._onExitCallback) this._onExitCallback(this);
      }
    );
    this.meshOnPointerClick = new ExecuteCodeAction(
      ActionManager.OnPickTrigger,
      (evnt) => {
        this.meshOnPointerExit.func();
        if (this._onClickCallback) this._onClickCallback(this);
      }
    );

    //Hide at creation
    this.setOpacity(0);
  }

  //---------------------------------PUBLIC----------------------------------------
  updateCallbacks(onClick, onEnter, onExit) {
    if (onClick) this._onClickCallback = onClick;
    if (onEnter) this._onEnterCallback = onEnter;
    if (onExit) this._onExitCallback = onExit;
  }

  //Enable/disable mesh click & hovers
  setActive(isActive) {
    this.meshes.forEach((mesh) => {
      mesh.actionManager.unregisterAction(this.meshOnPointerEnter);
      mesh.actionManager.unregisterAction(this.meshOnPointerExit);
      mesh.actionManager.unregisterAction(this.meshOnPointerClick);
    });

    if (isActive) {
      this.meshes.forEach((mesh) => {
        mesh.actionManager.registerAction(this.meshOnPointerEnter);
        mesh.actionManager.registerAction(this.meshOnPointerExit);
        mesh.actionManager.registerAction(this.meshOnPointerClick);
      });
    }
  }

  //Set as selected group
  setHover(isHovering) {
    if (isHovering)
      this.meshes.forEach((mesh) => {
        mesh.material = mesh._hoverMaterial;
        mesh._hovered = true;
        mesh._setOpacity(0.5);
      });
    else
      this.meshes.forEach((mesh) => {
        mesh.material = mesh._alternateMaterialActive
          ? mesh._alternateMaterial
          : mesh._normalMaterial;
        mesh._hovered = false;
        mesh._setOpacity(mesh._opacity);
      });
  }

  //Change hover color, null to default
  setAlternateMaterialColor(color) {
    this.meshes.forEach(
      (mesh) =>
        (mesh._alternateMaterial.emissiveColor = color
          ? color
          : mesh._normalMaterial.emissiveColor)
    );
  }
  setAlternateMaterialActive(extraColor) {
    this.meshes.forEach((mesh) => {
      mesh._alternateMaterialActive = extraColor;

      mesh.material = mesh._alternateMaterialActive
        ? mesh._alternateMaterial
        : mesh._hovered
        ? mesh._hoverMaterial
        : mesh._normalMaterial;
      mesh._setOpacity(mesh._hovered ? 0.5 : mesh._opacity);
    });
  }

  //Fade this group of meshes
  fade(fadeIn, time = 500) {
    this.meshes.forEach((mesh) => {
      mesh._fadeBehaviour.fadeTo(fadeIn ? mesh._opacity : 0, time);
    });
  }

  //Set current opacity (don't apply during hovering)
  setOpacity(opacity) {
    this.meshes.forEach((mesh) => {
      mesh._opacity = opacity;
      if (!mesh._hovered) mesh._setOpacity(opacity);
    });
  }
}
