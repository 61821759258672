import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../../App.css";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { dateFormat } from "../../../utils/common";
import { nestedObjectAssign } from "../../patients/patientsReports/ReportHelperFns";
import { labels } from "../../shared/translations";
import StatisticsActivities from "./StatisticsActivities";
import StatisticsAssessments from "./StatisticsAssessments";
import StatisticsFeedbacks from "./StatisticsFeedbacks";
import {
  adaptSecondTableData,
  categoryFunctions,
  extraFunctionKeys,
} from "./StatisticsHelpers";
import StatisticsMeasurements from "./StatisticsMeasurements";
import StatisticsTable from "./StatisticsTable";

const moment = extendMoment(Moment);

const DEFAULT_RANGE = moment.rangeFromInterval("month", -6, moment());
const ASSESSMENT_ID = 1;
const constPadding = { padding: "5px" };

const ACTIVITIES_KEY = "ACTIVITIES";
const ASSESSMENTS_KEY = "ASSESSMENTS";
const FEEDBACKS_KEY = "FEEDBACKS";
const MEASUREMENTS_KEY = "MEASUREMENTS";

const SECOND_TABLE_DATA_CATEGORIES = Object.keys(categoryFunctions);

const Statistics = ({ isPrint }) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [currActivities, setCurrActivities] = useState([]);
  const [assessmentsIds, setAssessmentsIds] = useState([]);
  const [currSecondTableCategories, setCurrSecondTableCategories] = useState(
    new Set([SECOND_TABLE_DATA_CATEGORIES[0]])
  );
  const [currSecondTableExtras, setCurrSecondTableExtras] = useState(new Set());

  //These two states are used in setState callback (param)
  //only the variable itself is unused but the state is not
  // eslint-disable-next-line
  const [firstTableDataAll, setFirstTableDataAll] = useState({});
  // eslint-disable-next-line
  const [secondTableDataAll, setSecondTableDataAll] = useState({});

  const [firstTableData, setFirstTableData] = useState({});
  const [secondTableData, setSecondTableData] = useState({});

  const { patId } = useParams();

  //Hide rank in patients statistics
  const SECOND_TABLE_EXTRA_CATEGORIES = extraFunctionKeys.filter(
    (extraCat) => !(patId && extraCat === "rank")
  );

  const updateData = (newData, key, isFirstTable) => {
    const setTableDataAll = isFirstTable
      ? setFirstTableDataAll
      : setSecondTableDataAll;
    const setTableData = isFirstTable ? setFirstTableData : setSecondTableData;

    setTableDataAll((prevTableData) => {
      //Save current component data
      const newTableDataAll = { ...prevTableData };
      if (key) newTableDataAll[key] = newData;

      //Create table data by merging components
      let newTableData = Object.values(newTableDataAll).reduce(
        (accum, data) => {
          accum = nestedObjectAssign(accum, data);
          return accum;
        },
        {}
      );

      //Fix values for second table
      if (!isFirstTable) {
        newTableData = adaptSecondTableData(
          newTableData,
          currSecondTableCategories,
          currSecondTableExtras
        );
      }

      //Set rendered data
      setTableData(newTableData);

      return newTableDataAll;
    });
  };

  useEffect(() => {
    //Ensure cleanup data when deselecting assessments
    if (!currActivities.includes(ASSESSMENT_ID)) {
      updateData({}, ASSESSMENTS_KEY, true);
      updateData({}, MEASUREMENTS_KEY, false);
    }
  }, [currActivities]);

  useEffect(() => {
    updateData({}, null, false);
  }, [currSecondTableCategories, currSecondTableExtras]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}>
          {labels.groups.groupReports.secondTable.title}
        </SpText>
      </Grid>

      {/* Dates */}
      <Grid item lg={2} xs={4} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.start_date}
          value={dateRange.start.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            moment(evnt.target.value).isBefore(dateRange.end) &&
            setDateRange(moment.range(evnt.target.value, dateRange.end))
          }
        />
      </Grid>

      <Grid item lg={2} xs={4} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.end_date}
          value={dateRange.end.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            dateRange.start.isBefore(moment(evnt.target.value)) &&
            setDateRange(moment.range(dateRange.start, evnt.target.value))
          }
        />
      </Grid>

      {/* First table */}
      <Grid container item xs={12}>
        {/* Activities dropdown */}
        <Grid item xs={4} style={constPadding}>
          <StatisticsActivities
            dateRange={dateRange}
            currActivities={currActivities}
            setCurrActivities={setCurrActivities}
            setData={(data) => updateData(data, ACTIVITIES_KEY, true)}
          />
        </Grid>
        {/* Assessments dropdown */}
        {currActivities.includes(ASSESSMENT_ID) && (
          <Grid item xs={4} style={constPadding}>
            <StatisticsAssessments
              dateRange={dateRange}
              currActivities={currActivities}
              setData={(data) => updateData(data, ASSESSMENTS_KEY, true)}
              setAssessmentsIds={setAssessmentsIds}
            />
          </Grid>
        )}
        {/* Data Table */}
        <StatisticsTable data={firstTableData} />
      </Grid>

      {/* Second table */}
      <Grid container item xs={12}>
        {/* Category checkboxes */}
        <Grid container item xs={1}>
          {SECOND_TABLE_DATA_CATEGORIES.map((tableCateg) => (
            <Grid item xs={12} key={tableCateg} style={{ margin: "auto" }}>
              <SpCheckbox
                label={
                  labels.groups.groupReports.secondTable.categories[tableCateg]
                }
                rightlabel={true}
                formControlStyle={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                checked={currSecondTableCategories.has(tableCateg)}
                onChange={(_, value) => {
                  const newData = new Set(currSecondTableCategories);
                  if (value) newData.add(tableCateg);
                  else newData.delete(tableCateg);
                  setCurrSecondTableCategories(newData);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={11}>
          {/* Feedbacks dropdown */}
          <Grid item xs={12} style={constPadding}>
            <StatisticsFeedbacks
              dateRange={dateRange}
              currActivities={currActivities}
              setData={(data) => updateData(data, FEEDBACKS_KEY, false)}
            />
          </Grid>
          {/* Measurements dropdown */}
          {currActivities.includes(ASSESSMENT_ID) && (
            <Grid item xs={12} style={constPadding}>
              <StatisticsMeasurements
                dateRange={dateRange}
                currActivities={currActivities}
                setData={(data) => updateData(data, MEASUREMENTS_KEY, false)}
                assessmentsIds={assessmentsIds}
              />
            </Grid>
          )}
        </Grid>

        {/* Study checkboxes */}
        <Grid container item xs={12} style={constPadding}>
          {SECOND_TABLE_EXTRA_CATEGORIES.map((extraCateg) => (
            <Grid item xs={1} style={constPadding} key={extraCateg}>
              <SpCheckbox
                label={labels.groups.groupReports.secondTable.extra[extraCateg]}
                rightlabel={true}
                formControlStyle={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                checked={currSecondTableExtras.has(extraCateg)}
                onChange={(_, value) => {
                  const newData = new Set(currSecondTableExtras);
                  if (value) newData.add(extraCateg);
                  else newData.delete(extraCateg);
                  setCurrSecondTableExtras(newData);
                }}
              />
            </Grid>
          ))}
        </Grid>

        {/* Data table */}
        <StatisticsTable data={secondTableData} />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Statistics);
