import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import { styled } from "../styled";

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    top: "50%",
    width: 300,
    height: 8,
    margin: "0 auto",
    color: "primary",
    variant: "indeterminate",
    backgroundColor: "black",
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#31ccad",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#31ccad",
    },
  },
  text: {
    margin: "0 auto",
  },
}));

const FloatingContainer = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.85)",
  zIndex: 9999,
});

const SpLoader = () => {
  const classes = useStyles();

  return (
    <FloatingContainer>
      <LinearProgress className={classes.colorPrimary} />
    </FloatingContainer>
  );
};

export default SpLoader;
