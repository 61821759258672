import React, { useState } from "react";
import Header from "../header/Header";
import { Body, LayoutContainer, SupportContainer } from "./style";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpLoader from "../../../components/atoms/SpLoader";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import "./layout.css";
import supportIcon from "../../../assets/icon/supportIcon.png";

const Layout = (props, base) => {
  const [loading, setLoading] = useState(false);

  return (
    <LayoutContainer>
      <Header />
      <Body>
        {loading && <SpLoader />}
        {React.Children.map(props.children, (child, index) =>
          React.cloneElement(child, {
            setLoading: setLoading,
            componentName: props.componentName,
          })
        )}
        <SupportContainer>
          <a href="https://www.mypowerset.com/supporto" target="_blank">
            <SpIconButton style={{ alignSelf: "flex-end" }}>
              <img
                style={{ width: "100%" }}
                src={supportIcon}
                alt={"Support"}
              />
            </SpIconButton>
          </a>
        </SupportContainer>
      </Body>
    </LayoutContainer>
  );
};

export default withSnackbar(Layout);
