import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "./serviceWorker";
import { amplitudeUtils } from "./utils/common";

amplitudeUtils.initAmplitude();
const env = process.env.REACT_APP_ENV_NAME;

Rollbar.configure({
  // logLevel: "info",
  enabled: env === "dev" ? false : true,
  payload: { environment: env },
  // checkIgnore: () => true,
  checkIgnore: function (isUncaught, args, payload) {
    // Code here to determine whether or not to send the payload // to the Rollbar API // return true to ignore the payload } ... };
    // console.log(
    //   "INDEX.js Rollbar.configure.checkIgnore.isUncought: ",
    //   isUncaught
    // ),
    //   console.log("INDEX.js Rollbar.configure.checkIgnore.args: ", args),
    //   console.log("INDEX.js Rollbar.configure.checkIgnore.payload: ", payload);
    return true;
  },
});

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_API_IDENTIFIER}
    redirectUri={process.env.REACT_APP_AUTH0_RETURN_URL_LOGIN}
    scope="openid profile email"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
