import React from "react";
import { Bar, Label, LabelList } from "recharts";
import { barColors, barWithDimension } from "../../../../utils/common";
import moment from "moment";
import { labels, psTranslate } from "../../../shared/translations";
import { getCalendar } from "../../../../utils/common";
const training = labels.analytics.injuryReport.graphs.training;
const match = labels.analytics.injuryReport.graphs.partita;
const ratioTM = labels.analytics.injuryReport.graphs.ratioTM;

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="16" fill="#FFFFFF" fontWeight="Bold" />;
};

const createBarschartDataPoints = (labelKey, size, color) => {
  return (
    <Bar barSize={size} dataKey={labelKey} fill={color} stackId="a">
      <LabelList
        dataKey={labelKey}
        position="center"
        content={renderCustomizedLabel}
      />
    </Bar>
  );
};

const selectGroupTrainingMatch = (
  groupActivities,
  result,
  selectedActivity
) => {
  groupActivities[selectedActivity]?.forEach((activity) => {
    // se esiste la chiave, incremento il valore altrimento lo creo
    activity.patients.forEach((patient) => {
      // se il numero di pazienti selezionati e' 0 di default prendi tutti altrimenti solo quelli scelti
      if (result.hasOwnProperty(patient?.patientName)) {
        result[patient?.patientName] = result[patient?.patientName] + 1;
      } else {
        result[patient?.patientName] = 1;
      }
    });
  });
  return groupActivities;
};

/**
 * Funzione che restituisce i nuovi pazienti selezionati, perche' viene utilizzato un solo array
 * sia per i pazienti del gruppo che quelli che non ne fanno parte
 *
 * @param newPatientsSelected: nuovi pazienti selezionati
 * @param type: gruppo o altri per indicare da quale dropdown fa parte la sezione e calcolare correttamente il nuovo
 * gruppo selezionato quando vengono deselezionati tutti i pazienti
 * @param selectedPatients: vecchi pazienti selezionati
 */
export const getPatientSelected = (
  newPatientsSelected,
  type,
  selectedPatients
) => {
  let resultNewSelectedPatients = [];
  if (newPatientsSelected.length > 0) {
    if (selectedPatients.length > 0) {
      // se i nuovi selezionati fanno parte del gruppo, devo includere anche quelli che non ne fanno parte selezionati
      // in eventi precedenti
      if (newPatientsSelected[0].groupPatient) {
        resultNewSelectedPatients = selectedPatients.filter(
          ({ groupPatient }) => !groupPatient
        );
        resultNewSelectedPatients =
          resultNewSelectedPatients.concat(newPatientsSelected);
      } else {
        // stessa ocsa per i non appartenenti al gruppo
        resultNewSelectedPatients = selectedPatients.filter(
          ({ groupPatient }) => groupPatient
        );
        resultNewSelectedPatients =
          resultNewSelectedPatients.concat(newPatientsSelected);
      }
    } else {
      resultNewSelectedPatients = newPatientsSelected;
    }
  } else {
    // se vengono deselezionati tutti i pazienti del gruppo , il nuovo gruppo e' formato da
    // coloro che non ne fanno parte se prima erano stati selezionati e viceversa
    if (type === "gruppo") {
      resultNewSelectedPatients = selectedPatients.filter(
        ({ groupPatient }) => !groupPatient
      );
    } else if (type === "altri") {
      resultNewSelectedPatients = selectedPatients.filter(
        ({ groupPatient }) => groupPatient
      );
    } else {
      resultNewSelectedPatients = selectedPatients;
    }
  }

  // rimozione dei duplicati
  resultNewSelectedPatients = resultNewSelectedPatients.filter(
    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
  );
  return resultNewSelectedPatients;
};

/**
 * Funzione che restituisce tante bar quanti sono i gruppi selezionati e per ogni gruppo
 * somma il numero di training e li divide per la somma dei match
 *
 * @param result: oggetto utilizzato
 * @param groupActivities: lista di attivita' per ogni gruppo
 */
const selectGroupRT = (groupActivities, result) => {
  result[training] = {};
  result[match] = {};
  let tempAttivita = [training, match];
  tempAttivita.forEach((itemAct) => {
    groupActivities[itemAct].forEach(({ patients }) => {
      // se esiste la chiave, incremento il valore altrimento lo creo
      patients.forEach(({ patientName }) => {
        // se il numero di pazienti selezionati e' 0 di default prendi tutti altrimenti solo quelli scelti
        if (result[itemAct].hasOwnProperty(patientName)) {
          result[itemAct][patientName] = result[itemAct][patientName] + 1;
        } else {
          result[itemAct][patientName] = 1;
        }
      });
    });
  });

  // oggetto di appoggio per calcolare la media
  let temp = {};
  for (let key in result[training]) {
    temp[key] =
      result[match][key] > 0
        ? (result[training][key] / result[match][key]).toFixed(1)
        : result[training][key];
  }
  return temp;
};

/**
 * Funzione che restituisce tante bar quanti sono i gruppi selezionati e per ogni gruppo
 * somma il numero di training e li divide per il numero di pazienti
 *
 * @param groups: lista dei gruppi selezionati
 * @param groupActivities: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param nMonths: numero di mesi del range selezionato dall'utente
 */
export const selectGroup = (
  groups,
  groupActivities,
  selectedActivity,
  nMonths
) => {
  try {
    let activityGroup = groupActivities.find(
      ({ nameGruppo }) => nameGruppo === groups[0]?.name
    );
    let result = {};
    let resultArray = [];
    let nValue = 0;

    if (selectedActivity === training || selectedActivity === match) {
      activityGroup = selectGroupTrainingMatch(
        activityGroup,
        result,
        selectedActivity
      );
    }

    if (selectedActivity === ratioTM) {
      result = selectGroupRT(activityGroup, result);
    }
    let bars = [];
    // creo l'oggeto necessario per mappare i dati da mostrare nel grafico
    for (let key in result) {
      nValue += nMonths > 0 ? (result[key] / nMonths).toFixed(1) : result[key];
      let item = {};
      item["groupDate"] = key;
      item[selectedActivity] =
        nMonths > 0 ? (result[key] / nMonths).toFixed(1) : result[key];
      resultArray.push(item);
    }
    let item = {};
    // aggiungo una bar per il totale
    item["groupDate"] = labels.analytics.injuryReport.total;
    item[selectedActivity] =
      selectedActivity === training || selectedActivity === match
        ? activityGroup[selectedActivity].length
        : activityGroup[match].length > 0
        ? (
            activityGroup[training].length / activityGroup[match].length
          ).toFixed(1)
        : activityGroup[training].length;
    resultArray.push(item);
    bars.push(
      createBarschartDataPoints(
        selectedActivity,
        barWithDimension(resultArray.length),
        barColors.verde
      )
    );
    return {
      dataChart: resultArray,
      allPatients: resultArray,
      barChart: bars,
      patients: groups[0]?.patients,
    };
  } catch (error) {
    console.log(error);
  }
};

export const groupsDetails = (
  newSelectedGroups,
  trainingGroups,
  action,
  nMonth
) => {
  let groupsAvarage = [];

  newSelectedGroups.forEach((group) => {
    let res = selectGroup([group], trainingGroups, action, nMonth);
    groupsAvarage.push({
      [action]: res.dataChart.find(
        (result) => result.groupDate === labels.analytics.injuryReport.total
      )[action],
      groupDate: `${labels.analytics.injuryReport.total} ${group.name}`,
    });
  });

  return groupsAvarage;
};

/**
 * Funzione che restituisce tante bar quanti sono i gruppi selezionati e per ogni gruppo
 * somma il numero di training e li divide per la somma dei match
 *
 * @param groups: lista dei gruppi selezionati
 * @param activityGroups: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 */
export const selectGroups = (groups, activityGroups, selectedActivity) => {
  // il grafico deve paragonare i diversi gruppi quindi mostro le stats per ogni gruppo
  let resultArray = [];
  let bars = [];

  // per ogni utente del gruppo devo restituire la somma di ogni attivita' se e' training o match
  if (selectedActivity === training || selectedActivity === match) {
    groups.forEach(({ name }) => {
      let activityGroup = activityGroups.find(
        ({ nameGruppo }) => nameGruppo === name
      );
      resultArray.push({
        groupDate: activityGroup?.nameGruppo,
        [selectedActivity]: activityGroup[selectedActivity].length,
      });
    });
  }
  // se l'attivita' e' ratio devo dividere la somma dei training per la somma dei match
  if (selectedActivity === ratioTM) {
    groups.forEach(({ name }) => {
      let activityGroup = activityGroups.find(
        ({ nameGruppo }) => nameGruppo === name
      );
      let item = {};
      item["groupDate"] = activityGroup?.nameGruppo;
      item[selectedActivity] =
        activityGroup[match].length > 0
          ? (
              activityGroup[training].length / activityGroup[match].length
            ).toFixed(1)
          : activityGroup[training].length;
      resultArray.push(item);
    });
  }

  bars.push(
    createBarschartDataPoints(
      selectedActivity,
      barWithDimension(resultArray.length),
      barColors.verde
    )
  );
  return {
    dataChart: resultArray,
    barChart: bars,
    selectedPatients: [],
  };
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 * quando il numero dei pazienti e' 1 e l'attivita' scelta e' ratio TM
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param groupActivities: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param dateRange: intervallo di tempo selezionato
 */
const selectPatientsRT = (
  groupActivities,
  selectedPatients,
  dateRange,
  selectedActivity
) => {
  let tempDate = moment(groupActivities[0]?.start_date);

  // filtro le attivita' in base al nome e cognome del paziente per l'attivita' training
  let activityTraiuningGroup = groupActivities[training].filter(
    ({ patients }) =>
      patients.filter(
        ({ patientName }) =>
          patientName.includes(selectedPatients[0]?.familyName) &&
          patientName.includes(selectedPatients[0]?.givenName)
      ).length > 0
  );

  // filtro le attivita' in base al nome e cognome del paziente per l'attivita' match
  let activityMatchGroup = groupActivities[match].filter(
    ({ patients }) =>
      patients.filter(
        ({ patientName }) =>
          patientName.includes(selectedPatients[0].familyName) &&
          patientName.includes(selectedPatients[0].givenName)
      ).length > 0
  );

  groupActivities = activityTraiuningGroup.concat(activityMatchGroup);

  // group by week
  let selecetedCalendar = getCalendar(tempDate, dateRange);
  let resultArray = [];

  for (let day of selecetedCalendar) {
    let item = {};
    let start = moment(day.start);
    let end = moment(day.end);
    item["groupDate"] = `${start.format("DD-MM-YYYY")} - ${end.format(
      "DD-MM-YYYY"
    )}`;
    item[selectedActivity] = 0;
    item["training"] = 0;
    item["race"] = 0;
    for (let { start_date, activity_datum } of groupActivities) {
      if (
        moment(start_date) >= moment(day.start) &&
        moment(start_date) <= moment(day.end)
      ) {
        if (activity_datum?.activity_type?.key === "training")
          item["training"]++;
        if (activity_datum?.activity_type?.key === "race") item["race"]++;
      }
    }
    item[selectedActivity] =
      item["race"] > 0
        ? (item["training"] / item["race"]).toFixed(1)
        : item["training"];
    resultArray.push(item);
  }

  selectedActivity;

  return resultArray;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 * quando il numero dei pazienti e' 1 e l'attivita' scelta e' training o match
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param groupActivities: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param dateRange: intervallo di tempo selezionato
 */
const selectPatientsTrainingMatch = (
  groupActivities,
  selectedPatients,
  dateRange,
  selectedActivity
) => {
  // filtro le attivita' in base al nome e cognome del paziente selezionato
  groupActivities = groupActivities[selectedActivity].filter(
    ({ patients }) =>
      patients.filter(
        ({ patientName }) =>
          patientName.includes(selectedPatients[0]?.familyName) &&
          patientName.includes(selectedPatients[0]?.givenName)
      ).length > 0
  );
  let tempDate = moment(groupActivities[0]?.start_date);
  // recupero del calendario a partire dalla prima data
  let selectedCalendar = getCalendar(tempDate, dateRange);
  let resultArray = [];

  for (let day of selectedCalendar) {
    let item = {};
    let start = moment(day.start);
    let end = moment(day.end);
    item["groupDate"] = `${start.format("DD-MM-YYYY")} - ${end.format(
      "DD-MM-YYYY"
    )}`;
    item[selectedActivity] = 0;

    for (let { start_date } of groupActivities) {
      if (
        moment(start_date) >= moment(day.start) &&
        moment(start_date) <= moment(day.end)
      ) {
        item[selectedActivity]++;
      }
    }
    resultArray.push(item);
  }
  return resultArray;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 * quando il numero dei pazienti e' maggiore di
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param activityGroups: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param dateRange: intervallo di tempo selezionato
 * @param groups: lista di tutti i gruppi disponibili
 * @param nMonth: numero di mesi selezionati, necessario per la media.
 * Se nMonth = 0 media DISABILITA *
 */
const selectMultiplePatients = (
  groups,
  selectedPatients,
  activityGroups,
  selectedActivity,
  nMonths
) => {
  let result = {};
  for (let group of groups) {
    // richiamo la funzione che dato un gruppo mi restituisce il per ogni paziente
    // del gruppo il numero di attivita' svolte
    let oneGroupDataCharts = selectGroup(
      [group],
      activityGroups,
      selectedActivity
    );
    // sommo tutte le attivita' per ogni paziente perche un paziente puo' appartenere
    // a piu' gruppi
    oneGroupDataCharts?.dataChart?.forEach((groupDataChart) => {
      result[groupDataChart.groupDate] = result[groupDataChart.groupDate]
        ? result[groupDataChart.groupDate] + groupDataChart[selectedActivity]
        : groupDataChart[selectedActivity];
    });
  }
  let tempDataChartOut = [];
  // costruisco l'oggetto da restituire formattato in modo che il grafico possa essere
  // facilmente creato

  for (let key in result) {
    tempDataChartOut.push({
      groupDate: key,
      [selectedActivity]: nMonths > 0 ? result[key] / nMonths : result[key],
    });
  }
  let dataChartOut = [];
  // filtro il risultato di out in quanto non tutti i pazienti devono essere visualizzati
  selectedPatients.forEach(({ familyName, givenName }) => {
    const item = tempDataChartOut.find(
      ({ groupDate }) =>
        groupDate.includes(familyName) && groupDate.includes(givenName)
    );
    if (item !== undefined) dataChartOut.push(item);
  });
  return dataChartOut;
};

/**
 * Funzione che restituisce i dati da mandare al grafico rispetto ai pazienti selezionati
 *
 * @param selectedPatients: lista di pazienti selezionati
 * @param activityGroups: lista di attivita' per ogni gruppo
 * @param selectedActivity: attivita' selezionata
 * @param dateRange: intervallo di tempo selezionato
 * @param groups: lista di tutti i gruppi disponibili
 * @param nMonth: numero di mesi selezionati, necessario per la media.
 * Se nMonth = 0 media DISABILITA *
 */
export const selectPatients = (
  selectedPatients,
  activityGroups,
  selectedActivity,
  dateRange,
  groups,
  newSelectedGroups,
  nMonths
) => {
  // aggiungi tutti gli elementi selezionati nell'array allData
  let result = {
    dataChart: [],
    selectedPatients: selectedPatients,
  };

  if (selectedPatients.length === 1 && newSelectedGroups.length === 0) {
    //recuper tutti gli allenamenti dell'atleta
    let attivitaGruppo = activityGroups.find(
      ({ idGruppo }) =>
        idGruppo === selectedPatients[0]?.patient_group?.id_group
    );
    if (selectedActivity === training || selectedActivity === match) {
      result["dataChart"] = selectPatientsTrainingMatch(
        attivitaGruppo,
        selectedPatients,
        dateRange,
        selectedActivity
      );
    } else if (selectedActivity === ratioTM) {
      result["dataChart"] = selectPatientsRT(
        attivitaGruppo,
        selectedPatients,
        dateRange,
        selectedActivity
      );
    }
  } else {
    result["dataChart"] = selectMultiplePatients(
      groups,
      selectedPatients,
      activityGroups,
      selectedActivity,
      nMonths
    );
  }
  // creo il grafico
  result["barChart"] = createBarschartDataPoints(
    selectedActivity,
    barWithDimension(result["dataChart"]?.length),
    barColors.verde
  );
  return result;
};

/**
 * Funzione che calcola per ogni gruppo le attivita' fatte e restituisce il grafico con tutti
 * i gruppi
 *
 * @param groups: gruppi di pazienti con annesse le attivita'
 * @param selectedActivity: attivita' selezionata
 * @param nMonth: numero di mesi selezionati, necessario per la media.
 * Se nMonth = 0 media DISABILITA
 */
export const selectAllGroups = (groups, selectedActivity, nMonth) => {
  let resultArray = [];
  let bars = [];
  if (selectedActivity === training || selectedActivity === match) {
    groups.forEach((activity) => {
      // se nMonth > 0 calcolo numero attivita' / numero mesi per avere la media allenamenti-mese
      resultArray.push({
        groupDate: activity?.nameGruppo,
        [selectedActivity]:
          nMonth > 0
            ? (activity[selectedActivity].length / nMonth).toFixed(1)
            : activity[selectedActivity].length,
      });
    });
    bars.push(
      createBarschartDataPoints(
        selectedActivity,
        barWithDimension(resultArray.length),
        barColors.verde
      )
    );

    return {
      dataChart: resultArray,
      barChart: bars,
    };
  } else if (selectedActivity === ratioTM) {
    groups.forEach((group) => {
      let item = {};
      item["groupDate"] = group.nameGruppo;
      item[selectedActivity] =
        group[match].length > 0
          ? (group[training].length / group[match].length).toFixed(1)
          : group[training].length;
      item[selectedActivity] =
        nMonth > 0
          ? (item[selectedActivity] / nMonth).toFixed(1)
          : item[selectedActivity];
      resultArray.push(item);
    });
    bars.push(
      createBarschartDataPoints(
        selectedActivity,
        barWithDimension(resultArray.length),
        barColors.verde
      )
    );

    return {
      dataChart: resultArray,
      barChart: bars,
    };
  }
};
