import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 0 1%;
  //@media (min-width: 800px) {
  //  margin-bottom: 5px !important;
  //}
  //@media (min-width: 1700px) {
  //  margin-bottom: 5px !important;
  //}
  //
  //@media (max-width: 2300px) {
  //  margin-bottom: 5px !important;
  //}
  //
  //@media (min-width: 2300px) {
  //  margin-bottom: 5px !important;
  //}
  display: flex;
  flex: 1;
  //max-height: 150px;
  //min-height: 150px;
  flex-direction: column;
`;

export const HeaderContainerRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
`;

export const HeaderContainerRowMenu = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
`;
