import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const LogoImg = styled.img`
  max-width: 20%;
`;
