import { Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import { theme } from "../../../components/theme";
import { getFirstTableGroupsData } from "../../../models/actions/Groups";
import { labels, psTranslate } from "../../shared/translations";
import { rollbar } from "../../../utils/common";

const userDateFormat = "DD/MM/yyyy";

const SituationTable = ({ isPrint, fetchDataBe, ...props }) => {
  const [firstReports, setFirstReports] = useState([]);

  const { groupId } = useParams();

  //Table fixed headers
  const headCellsFirst = [
    {
      type: "string",
      field: "name_surname",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.patient,
      flex: 1,
      valueGetter: (params) => params.row.patient.familyName,
      renderCell: (params) => (
        <SpText variant={fetchDataBe ? "h4ComponentLabelPrint" : "tableText"}>
          {params.row.patient.givenName} {params.row.patient.familyName}
        </SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "string",
      field: "status",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.status,
      flex: 1,
      valueGetter: (params) => params.row.patStatus,
      renderCell: (params) => (
        <SpIconButton
          variant="lightBlue"
          style={{ marginLeft: 24, cursor: "default" }}
          small
        >
          <FiberManualRecordIcon
            style={{
              color:
                params.row.patStatus === 0
                  ? theme.colors.secondary.red
                  : theme.colors.secondary.green,
            }}
          />
        </SpIconButton>
      ),
      sortComparator: (v1, v2) => (v1 >= v2 ? 1 : 0),
    },
    {
      type: "string",
      field: "reason",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.reason,
      flex: 1,
      valueGetter: (params) => params.row.presentationReason,
      renderCell: (params) => (
        <SpText variant={fetchDataBe ? "h4ComponentLabelPrint" : "tableText"}>
          {psTranslate(params.row.presentationReason.trim())}
        </SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "date",
      field: "start_date",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.startDate,
      flex: 1,
      renderCell: (params) => (
        <SpText variant={fetchDataBe ? "h4ComponentLabelPrint" : "tableText"}>
          {moment(params.row.presStartDate).isValid()
            ? moment(params.row.presStartDate).format(userDateFormat)
            : params.row.presStartDate}
        </SpText>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
    {
      type: "date",
      field: "end_date",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.endDate,
      flex: 1,
      valueGetter: (params) =>
        moment(params.row.presEndDate).isValid()
          ? moment(params.row.presEndDate).format(userDateFormat)
          : params.row.presEndDate,
      renderCell: (params) => (
        <SpText variant={fetchDataBe ? "h4ComponentLabelPrint" : "tableText"}>
          {moment(params.row.presEndDate).isValid()
            ? moment(params.row.presEndDate).format(userDateFormat)
            : params.row.presEndDate}
        </SpText>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
    {
      type: "string",
      field: "pres_duration",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: labels.groups.groupReports.firstTable.header.presDuration,
      flex: 1,
      valueGetter: (params) =>
        params.row.presDuration
          ? ""
          : moment().diff(moment(params.row.presStartDate), "days"),
      renderCell: (params) => (
        <SpText variant={fetchDataBe ? "h4ComponentLabelPrint" : "tableText"}>
          {params.row.presDuration
            ? ""
            : moment().diff(moment(params.row.presStartDate), "days")}
        </SpText>
      ),
      sortComparator: () => 1,
    },
  ];

  useEffect(async () => {
    try {
      //Obtain table data
      const firstTableData = await getFirstTableGroupsData({
        id_group: groupId,
      });
      setFirstReports(firstTableData ?? []);
    } catch (error) {
      rollbar.error("SituationTable - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}>
        {labels.groups.groupReports.firstTable.title}
      </SpText>

      <SpDataGrid
        headers={headCellsFirst}
        rows={firstReports}
        emptyText={labels.groups.groupReports.no_patients}
      />
    </Grid>
  );
};

export default SituationTable;
