import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../App.css";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import {
  findPatients,
  linkPatientToProfessional,
} from "../../models/actions/Patients";
import { styled } from "../../components/styled";
import { ArrowBack } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { labels } from "../shared/translations";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { rollbar } from "../../utils/common";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});
const StyledRow = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "flex-start",
  justifyContent: "center",
  margin: "2%",
});
const StyledRowPatientFound = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "flex-start",
  justifyContent: "space-between",
  margin: "2%",
});
const StyledRowSearch = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "flex-end",
  justifyContent: "space-between",
  marginBottom: "5%",
});

const StyledColumn = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "5%",
});
const StyledWrapperColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  maxWidth: "40%",
  background: "transparent",
  border: "1px solid #31ccad",
});
const StyledWrapperPatientsList = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  background: "transparent",
  border: "1px solid #31ccad",
  padding: "3%",
});

const TitleWrapper = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  padding: "0 !important",
  margin: "0 !important",
  backgroundColor: "#31ccad",
});

const PatientsLink = (props) => {
  const [searchString, setSearchString] = useState("");
  const [patientsFound, setPatientFound] = useState();

  const history = useHistory();
  const { setLoading } = props;

  const searchPatients = () => {
    if (searchString) {
      try {
        findPatients({ pattern: searchString, is_linking: true }).then(
          (response) => setPatientFound(response.length > 0 ? response : null)
        );
      } catch (error) {
        rollbar.error("PatientsLink - searchPatients", error);
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  const linkPatientToProf = async (patientId) => {
    try {
      //passing patientId and professionalId
      setLoading(true);
      const response = await linkPatientToProfessional({
        id_patient: patientId,
      });
      if (response?.error) {
        throw response?.error;
      } else {
        history.push("/patients");
        props.snackbarShowMessage(response?.message);
      }
    } catch (error) {
      rollbar.error("PatientsLink - linkPatientToProf", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <TitleToolbarSection>
          <ArrowBack
            onClick={() => history.push("/patients")}
            style={{
              width: 30,
              color: "#31ccad",
              height: 50,
              cursor: "pointer",
            }}
          />
          <SpText variant="h1">{labels.nav.patients}</SpText>
        </TitleToolbarSection>
        <StyledRow>
          <StyledWrapperColumn>
            <TitleWrapper>
              <SpText variant="h1PageTitle">
                {labels.patient.patientLink.title}
              </SpText>
            </TitleWrapper>
            <StyledColumn>
              <StyledRowSearch>
                <SpTextInput
                  value={searchString}
                  label={labels.patient.patientLink.inputSearch.label}
                  onChange={(e) => setSearchString(e.currentTarget.value)}
                />
                <SpButton
                  style={{ marginLeft: "1%", marginRight: "0" }}
                  variant="none"
                  text={labels.patient.patientLink.inputSearch.buttons.search}
                  buttonType="accept"
                  onClick={() => searchPatients()}
                />
              </StyledRowSearch>
              <StyledWrapperPatientsList>
                {patientsFound ? (
                  patientsFound?.map((patientFound) => (
                    <StyledRowPatientFound>
                      <SpText variant="h4ComponentLabel">{`${patientFound.givenName} ${patientFound.familyName} - ${patientFound.email}`}</SpText>
                      <SpButton
                        text={
                          labels.patient.patientLink.patientsList.buttons.link
                        }
                        buttonType="accept"
                        onClick={() => linkPatientToProf(patientFound.id)}
                      />
                    </StyledRowPatientFound>
                  ))
                ) : (
                  <SpText variant={"column"}>
                    {labels.patient.patientLink.patientsList.placeholder}
                  </SpText>
                )}
              </StyledWrapperPatientsList>
            </StyledColumn>
          </StyledWrapperColumn>
        </StyledRow>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsLink);
