import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpRadio from "../../../components/atoms/SpRadio";
import { nanoid } from "nanoid";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import {
  findPatientByPk,
  getPatientRegistry,
  updatePatientRegistry,
} from "../../../models/actions/Patients";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { Divider, Grid, Radio } from "@material-ui/core";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const CustomRadioButton = styled(Radio)({
  border: "1px solid #31ccad",
});

const CustomAccordion = styled(Accordion)({
  textAlign: "center",
  maxWidth: "100%",
  marginTop: "1%",
  marginBottom: "1%",
  borderRadius: 0,
  color: "white",
  borderColor: "#31ccad",
  background: "transparent",
  border: "1px solid",
});

const StyledColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const PatientsRegistryInput = (props) => {
  const [currentPatientDetail, setCurrentPatientDetail] = useState();
  const [currentPatientRegistry, setCurrentPatientRegistry] = useState();
  const { setLoading } = props;

  const { patId } = useParams();
  const history = useHistory();
  let _inputIndexCounter = -1;

  const { control, register, handleSubmit, reset, getValues, setValue, watch } =
    useForm({
      shouldUnregister: false,
    });

  //Fetching patient data
  const fetchData = async () => {
    try {
      setLoading(true);
      _inputIndexCounter = -1;
      const responseDetail = await findPatientByPk({ userId: patId });
      const responseRegistry = await getPatientRegistry({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      setCurrentPatientRegistry(responseRegistry);
      reset({
        patientRegistry: responseRegistry,
        patientDetail: responseDetail,
      });
      setValue(
        "patientDetail.birth_date",
        moment(String(responseDetail.birth_date)).format("YYYY-MM-DD")
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateData = async (data) => {
    try {
      // const responsePat = await updatePatient({...data});
      const response = await updatePatientRegistry({
        userId: patId,
        answers: data.patientAnswers?.filter((x) => x)?.filter((x) => x.id),
        patientData: { ...data },
      });
      if (response) {
        await fetchData();
        props.snackbarShowMessage(response.message);
      } else {
        props.snackbarShowErrorMessage(response.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const submitData = () => {
    handleSubmit(updateData)();
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const renderPatientRegistry = () => {
    return currentPatientRegistry?.map((registry, idx) => {
      return (
        <CustomAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id={1}
          >
            <SpText variant="h4ComponentLabel">
              {psTranslate(registry.category)}
            </SpText>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"row"}>
              {renderQuestions(registry)}
            </Grid>
          </AccordionDetails>
        </CustomAccordion>
      );
    });
  };

  const renderQuestions = (category) => {
    return (
      <>
        {category.questions?.map((question) => {
          if (question.is_multiple) {
            return (
              <Grid xs={6} style={{ padding: "8px" }}>
                <SpText variant="h4">
                  {psTranslate(question.question).toUpperCase()}
                </SpText>
                {question.answers?.map((answer) => {
                  return (
                    <Grid direction="column">
                      <Controller
                        style={{ display: "none" }}
                        key={nanoid()}
                        name={`patientAnswers[${answer.id}].answer`}
                        defaultValue={answer.answer}
                        control={control}
                        render={(props) => <></>}
                      />
                      <Controller
                        key={nanoid()}
                        name={`patientAnswers[${answer.id}].id`}
                        defaultValue={answer.value ? answer.id : ""}
                        control={control}
                        render={(props) => (
                          <SpCheckbox
                            formControlStyle={formControlStyleCheck}
                            checked={props.value}
                            onChange={(e) => {
                              // console.log("Checked value", e.target.checked, questionAnswer.id);
                              if (e.target.checked) {
                                props.onChange(answer.id);
                              } else {
                                props.onChange(null);
                              }
                            }}
                            rightlabel
                            variant="column"
                            label={psTranslate(answer.answer)}
                          />
                        )}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
          } else {
            return (
              <Grid xs={6} style={{ padding: "8px" }}>
                <Controller
                  style={{ display: "none" }}
                  key={nanoid()}
                  name={`patientAnswers[${question.id}].answer`}
                  defaultValue={
                    question.answers?.find((a) => a.id === question.id_answer)
                      ?.answer
                  }
                  control={control}
                  render={(props) => <></>}
                />
                <Controller
                  key={nanoid()}
                  render={(props) => (
                    <SpSelect
                      allowDeselect={true}
                      label={psTranslate(question.question).toUpperCase()}
                      value={props.value}
                      formControlStyle={{ marginTop: "3%", width: "100%" }}
                      onChange={(e) => {
                        // console.info(e.target);
                        props.onChange(e.target.value);
                        setValue(
                          `patientAnswers[${question.id}].answer`,
                          question.answers?.find((a) => a.id === e.target.value)
                            .answer
                        );
                      }}
                    >
                      {question.answers?.map((answer) => (
                        <SpSelectMenuItem
                          key={answer.id}
                          value={answer.id}
                          name={answer.answer}
                        >
                          {psTranslate(answer.answer)}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={question.id_answer}
                  name={`patientAnswers[${question.id}].id`}
                  control={control}
                />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
  };

  return (
    <Grid direction="column" container spacing={2}>
      <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
        <SpButton
          buttonType={"accept"}
          onClick={() => submitData()}
          text={labels.patient.patientRegistry.submitButton}
        />
      </Grid>
      {currentPatientRegistry && (
        <Grid item container xs={12}>
          <form key={nanoid()} style={{ width: "100%" }}>
            <CustomAccordion style={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id={1}
              >
                <SpText variant="h4ComponentLabel">
                  {labels.patient.patientRegistry.registryData}
                </SpText>
              </AccordionSummary>
              <AccordionDetails>
                <StyledColumn>
                  <Grid xs={12} direction="row" container>
                    <Grid
                      xs={6}
                      direction="column"
                      container
                      style={{ padding: "8px" }}
                    >
                      <SpTextInput
                        variant="column"
                        inputRef={register}
                        label={labels.patient.patientRegistry.givenName.toUpperCase()}
                        name={"patientDetail.givenName"}
                      />
                      <SpTextInput
                        variant="column"
                        inputRef={register}
                        label={labels.patient.patientRegistry.familyName.toUpperCase()}
                        name={"patientDetail.familyName"}
                      />
                      <SpTextInput
                        variant="column"
                        inputRef={register}
                        type="date"
                        label={labels.patient.patientRegistry.birthDate.toUpperCase()}
                        maxValue={"2100-12-31"}
                        name={"patientDetail.birth_date"}
                      />
                      <SpTextInput
                        variant="column"
                        inputRef={register}
                        label={labels.patient.patientRegistry.job.toUpperCase()}
                        name="patientDetail.job"
                      />
                      <SpTextInput
                        variant="column"
                        name="patientDetail.email"
                        inputRef={register}
                        label={labels.patient.patientRegistry.email.toUpperCase()}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      direction="column"
                      container
                      style={{ padding: "8px" }}
                    >
                      <SpTextInput
                        variant="column"
                        name="patientDetail.family_doctor"
                        inputRef={register}
                        label={labels.patient.patientRegistry.doctor.toUpperCase()}
                      />
                      <SpTextInput
                        variant="column"
                        name="patientDetail.address"
                        inputRef={register}
                        label={labels.patient.patientRegistry.address.toUpperCase()}
                      />
                      <SpTextInput
                        variant="column"
                        name="patientDetail.phone"
                        inputRef={register}
                        label={labels.patient.patientRegistry.phone.toUpperCase()}
                      />
                      <SpTextInput
                        variant="column"
                        name="patientDetail.emergency_name"
                        inputRef={register}
                        label={labels.patient.patientRegistry.emergencyContactName.toUpperCase()}
                      />
                      <SpTextInput
                        variant="column"
                        name="patientDetail.emergency_telephone"
                        inputRef={register}
                        label={labels.patient.patientRegistry.emergencyContactPhone.toUpperCase()}
                      />
                    </Grid>
                    <Controller
                      as={
                        <SpRadio
                          style={{
                            flex: 1,
                            display: "flex",
                            paddingBottom: "2%",
                          }}
                          aria-label="gender"
                        >
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="M"
                            control={<CustomRadioButton />}
                            label={labels.patient.patientRegistry.sexMan.toUpperCase()}
                          />
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="F"
                            control={<CustomRadioButton />}
                            label={labels.patient.patientRegistry.sexWoman.toUpperCase()}
                          />
                        </SpRadio>
                      }
                      name={"patientDetail.gender"}
                      control={control}
                      defaultValue={"patientDetail.gender"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider
                      style={{
                        backgroundColor: "#31ccad",
                        marginTop: "1%",
                        height: 1,
                        marginBottom: "1%",
                      }}
                    />
                  </Grid>
                  <StyledRow>
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.patientRegistry.antropBase.toUpperCase()}
                    </SpText>
                  </StyledRow>
                  <Grid xs={12} container direction="row">
                    <Grid
                      xs={12}
                      container
                      direction="row"
                      style={{ padding: "8px" }}
                    >
                      <Grid
                        xs={6}
                        direction="column"
                        container
                        style={{ padding: "8px" }}
                      >
                        <SpTextInput
                          name="patientDetail.weight"
                          inputRef={register}
                          type="number"
                          minValue={0}
                          label={labels.patient.patientRegistry.weight.toUpperCase()}
                        />
                      </Grid>
                      <Grid
                        xs={6}
                        direction="column"
                        container
                        style={{ padding: "8px" }}
                      >
                        <SpTextInput
                          name="patientDetail.height"
                          inputRef={register}
                          type="number"
                          minValue={0}
                          label={labels.patient.patientRegistry.height.toUpperCase()}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      container
                      direction="row"
                      style={{ padding: "8px" }}
                    >
                      <Grid xs={12} container direction="row">
                        <Grid xs={6}>
                          <SpText variant="text">
                            {labels.patient.patientRegistry.dominantHand}
                          </SpText>
                          <Controller
                            as={
                              <SpRadio
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  paddingBottom: "2%",
                                }}
                                aria-label="gender"
                              >
                                <FormControlLabel
                                  style={formControlStyleCheck}
                                  value="L"
                                  control={<CustomRadioButton />}
                                  label={labels.patient.patientRegistry.left.toUpperCase()}
                                />
                                <FormControlLabel
                                  style={formControlStyleCheck}
                                  value="R"
                                  control={<CustomRadioButton />}
                                  label={labels.patient.patientRegistry.right.toUpperCase()}
                                />
                              </SpRadio>
                            }
                            name={"patientDetail.prevalent_hand"}
                            control={control}
                            defaultValue={"patientDetail.prevalent_hand"}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SpText variant="text">
                            {labels.patient.patientRegistry.dominantFoot}
                          </SpText>
                          <Controller
                            as={
                              <SpRadio
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  paddingBottom: "2%",
                                }}
                                aria-label="gender"
                              >
                                <FormControlLabel
                                  style={formControlStyleCheck}
                                  value="L"
                                  control={<CustomRadioButton />}
                                  label={labels.patient.patientRegistry.left.toUpperCase()}
                                />
                                <FormControlLabel
                                  style={formControlStyleCheck}
                                  value="R"
                                  control={<CustomRadioButton />}
                                  label={labels.patient.patientRegistry.right.toUpperCase()}
                                />
                              </SpRadio>
                            }
                            name={"patientDetail.prevalent_leg"}
                            control={control}
                            defaultValue={"patientDetail.prevalent_leg"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledColumn>
              </AccordionDetails>
            </CustomAccordion>
            {renderPatientRegistry()}
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default withSnackbar(PatientsRegistryInput);
