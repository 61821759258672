import Moment from "moment";
import { extendMoment } from "moment-range";
import { dateFormat } from "../../../../../../utils/common";
import {
  averageInRange,
  computeInRange,
  computeZScore,
  computeZScoreWithAverageAndStddev,
  getValuesInRange,
} from "../../../ReportHelperFns";

const moment = extendMoment(Moment);

export function linearFixedPeriod(
  defaultZero,
  dateRange,
  period,
  by,
  studyData,
  zScoreData
) {
  //Compute avg & stddev (using "by" steps)
  let dayAverages = averageInRange(
    period,
    by,
    (range) => range.reverseBy("day", { excludeStart: true }),
    (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null)
  );
  dayAverages = Object.values(dayAverages);
  const valAvg = dayAverages.reduce((a, b) => a + b, 0) / dayAverages.length;
  const valStddev = Math.sqrt(
    dayAverages.reduce((acc, val) => acc + Math.pow(val - valAvg, 2), 0) /
      dayAverages.length
  );

  //Compute Z-Score with all period reference
  return computeInRange(
    dateRange,
    by,
    (range) => range.reverseBy("day", { excludeStart: true }), //Always use full reference range
    (dayStr) => zScoreData[dayStr] ?? (defaultZero ? 0 : null),
    (values) => {
      const avg = values.reduce((a, b) => a + b) / values.length;
      return computeZScoreWithAverageAndStddev(avg, valAvg, valStddev);
    }
  );
}

export function radarFixedPeriod(
  defaultZero,
  dateRange,
  period,
  by,
  studyData,
  zScoreData
) {
  //Find first data in date range
  let endDate = [...dateRange.reverseBy("day")].find((day) => {
    const dayStr = day.format(dateFormat);
    return studyData[dayStr] || defaultZero;
  });
  endDate = endDate ?? dateRange.end;

  //Compute average of single "by"
  const currentRange = moment.rangeFromInterval(by, -1, endDate);
  const currentValues = getValuesInRange(zScoreData, currentRange, defaultZero);
  const valAvg =
    currentValues.reduce((a, b) => a + b, 0) / currentValues.length;

  //Obtain values for days interval
  const referenceValues = getValuesInRange(studyData, period, defaultZero);

  //Compute z score
  return computeZScore(valAvg, referenceValues);
}

export function linearReferencePeriod(
  defaultZero,
  dateRange,
  daysBefore,
  by,
  studyData,
  zScoreData
) {
  //Compute value averages
  const dayAverages = averageInRange(
    dateRange,
    by,
    (range) => range.reverseBy("day", { excludeStart: true }),
    (dayStr) => zScoreData[dayStr] ?? (defaultZero ? 0 : null)
  );

  //Compute Z-scores
  return computeInRange(
    dateRange,
    by,
    (range) =>
      moment
        .rangeFromInterval("day", -daysBefore, range.end)
        .reverseBy("day", { excludeStart: true }),
    (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
    (values, destDay) => computeZScore(dayAverages[destDay], values)
  );
}

export function radarReferencePeriod(
  defaultZero,
  dateRange,
  daysBefore,
  by,
  studyData,
  zScoreData
) {
  //Find first data in date range
  let endDate = [...dateRange.reverseBy("day")].find((day) => {
    const dayStr = day.format(dateFormat);
    return studyData[dayStr] || defaultZero;
  });
  endDate = endDate ?? dateRange.end;

  //Compute average of single "by"
  const currentRange = moment.rangeFromInterval(by, -1, endDate);
  const currentValues = getValuesInRange(zScoreData, currentRange, defaultZero);
  const valAvg =
    currentValues.reduce((a, b) => a + b, 0) / currentValues.length;

  //Obtain values for days interval
  const referenceRange = moment.rangeFromInterval(by, -daysBefore, endDate);
  const referenceValues = getValuesInRange(
    studyData,
    referenceRange,
    defaultZero
  );

  //Compute z score
  return computeZScore(valAvg, referenceValues);
}

export function linearValuesNumber(
  defaultZero,
  dateRange,
  valuesBefore,
  by,
  studyData,
  zScoreData
) {
  //Compute value averages
  const dayAverages = averageInRange(
    dateRange,
    by,
    (range) => range.reverseBy("day", { excludeStart: true }),
    (dayStr) => zScoreData[dayStr] ?? (defaultZero ? 0 : null)
  );

  //Compute Z-scores
  let count = 0;
  return computeInRange(
    dateRange,
    by,
    (range) => {
      count = 0;
      return moment
        .range(dateRange.start, range.end)
        .reverseBy("day", { excludeStart: true });
    },
    (dayStr) => {
      if (studyData[dayStr] && count < valuesBefore) {
        count++;
        return studyData[dayStr]; //Ignore default zero in this study
      } else return null;
    },
    (values, destDay) => computeZScore(dayAverages[destDay], values)
  );
}

export function radarValuesNumber(
  defaultZero,
  dateRange,
  valuesBefore,
  by,
  studyData,
  zScoreData
) {
  //Find first data in date range
  let endDate = [...dateRange.reverseBy("day")].find((day) => {
    const dayStr = day.format(dateFormat);
    return studyData[dayStr] || defaultZero;
  });
  endDate = endDate ?? dateRange.end;

  //Compute average of single "by"
  const currentRange = moment.rangeFromInterval(by, -1, endDate);
  const currentValues = getValuesInRange(zScoreData, currentRange, defaultZero);
  const valAvg =
    currentValues.reduce((a, b) => a + b, 0) / currentValues.length;

  //Obtain values for days interval
  const referenceRange = moment.range(dateRange.start, endDate);
  const referenceValues = getValuesInRange(
    studyData,
    referenceRange,
    false
  ).slice(0, valuesBefore); //Ignore default zero in this study

  //Compute z score
  return computeZScore(valAvg, referenceValues);
}

export function updateZScoreData(
  data,
  defaultZero,
  dateRange,
  period,
  by,
  isSubject,
  mainSubjectKey,
  periodFn
) {
  //Iterate over subjects
  return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
    //Can't z-score self (in subject z-score)
    if (!isSubject || subjectName != mainSubjectKey) {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          //Compute Z-scores
          accum[studyParamKey] = periodFn(
            defaultZero,
            dateRange,
            period,
            by,
            studyData,
            isSubject ? data[mainSubjectKey]?.[studyParamKey] ?? {} : studyData
          );
          return accum;
        },
        {}
      );
    }
    return accum;
  }, {});
}
