import React, { useEffect, useState } from "react";
import "../App.css";
import SpText from "../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import { getLicenceInformation } from "../models/actions/Professionals";
import { labels } from "./shared/translations";
import moment from "moment/moment";
import { styled } from "../components/styled";
import AccountButton from "../components/bundles/account/AccountButton";

const StyledRowMain = styled("div")({
  display: "flex",
  flexDirection: "row",
  flex: "1 1 50%",
  marginTop: "1%",
});

const StyledColumnRight = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  paddingLeft: "5%",
  paddingRight: "5%",
});

const StyledColumnLeft = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  paddingLeft: "5%",
  paddingRight: "5%",
});

const LicenceExpired = (props) => {
  const [licence, setLicence] = useState();

  const fetchData = async () => {
    try {
      const response = await getLicenceInformation({
        bypass_licence_check: true,
      });
      setLicence(response);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>
        {!props.value || props.value.toString().trim() === ""
          ? "---"
          : props.value}
      </SpText>
    </Grid>
  );

  const LicenseInfo = () => (
    <Grid item xs={12}>
      <StyledRowMain>
        <StyledColumnLeft>
          <LabelValue
            label={labels.account.licenseForm.parentUser.toUpperCase()}
            value={licence?.parent_user}
          />
          <LabelValue
            label={labels.account.licenseForm.licenses.toUpperCase()}
            value={licence?.licences}
          />
          <LabelValue
            label={labels.account.licenseForm.years.toUpperCase()}
            value={licence?.years}
          />
        </StyledColumnLeft>

        <StyledColumnRight>
          <LabelValue
            label={labels.account.licenseForm.activationDate.toUpperCase()}
            value={
              licence?.activation_date
                ? moment(licence?.activation_date).format("DD/MM/YYYY")
                : null
            }
          />
          <LabelValue
            label={labels.account.licenseForm.expiryDate.toUpperCase()}
            value={
              licence?.expiry_date
                ? moment(licence?.expiry_date).format("DD/MM/YYYY")
                : null
            }
          />
          <LabelValue
            label={labels.account.licenseForm.validLicense.toUpperCase()}
            value={
              licence?.valid_licence
                ? labels.general.yes.toUpperCase()
                : labels.general.no.toUpperCase()
            }
          />
          <LabelValue
            label={labels.account.licenseForm.trial.toUpperCase()}
            value={
              licence?.trial
                ? labels.general.yes.toUpperCase()
                : labels.general.no.toUpperCase()
            }
          />
        </StyledColumnRight>
      </StyledRowMain>
    </Grid>
  );

  const SectionTitle = ({ title }) => (
    <Grid item container xs={12}>
      <SpText style={{ flex: 1 }} variant="h1">
        {title}
      </SpText>
    </Grid>
  );

  return (
    <Grid
      style={{ paddingLeft: "1%", paddingTop: "2%" }}
      direction="column"
      container
      spacing={2}
    >
      <SectionTitle title={labels.account.license_title} />
      <LicenseInfo />
      <AccountButton professionalData={licence} />
    </Grid>
  );
};

export default LicenceExpired;