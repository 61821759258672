import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import StudySharedControls from "./SharedControls";
import StudyZScoreReferencePeriod from "./studyZScore/StudyZScoreReferencePeriod";
import StudyZScoreValuesNumber from "./studyZScore/StudyZScoreValuesNumber";
import StudyZScoreFixedPeriods from "./studyZScore/StudyZScoreFixedPeriods";
import { genericIsEnabled } from "../../ReportHelperFns";
import { labels } from "../../../../shared/translations";

const ZSCORE_STUDIES = [
  StudyZScoreReferencePeriod,
  StudyZScoreValuesNumber,
  StudyZScoreFixedPeriods,
  StudyZScoreReferencePeriod,
  StudyZScoreValuesNumber,
  StudyZScoreFixedPeriods,
];

const ZSCORE_TYPES = [
  "ZSCORE_REFERENCE",
  "ZSCORE_VALUES",
  "ZSCORE_FIXED",
  "ZSCORE_SUBJECT_REFERENCE",
  "ZSCORE_SUBJECT_VALUES",
  "ZSCORE_SUBJECT_FIXED",
];

const ZSCORE_KEYS = [
  "Reference Period",
  "Values number",
  "Fixed Period",
  "Subject reference Period",
  "Subject values number",
  "Subject fixed Period",
];

const StudyZScore = ({
  mainSubjectKey,
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <Grid container item xs={12}>
      <StudySharedControls
        title={labels.patient.graphReport.section.feedback.studies[study.key]}
        active={active}
        setActive={setActive}
        enabled={isEnabled()}
      ></StudySharedControls>
      <Grid container item xs={12}>
        {ZSCORE_STUDIES.map((ZScoreStudy, idx) => (
          <ZScoreStudy
            key={`${study.key}_${idx}`}
            mainSubjectKey={mainSubjectKey}
            study={study}
            graphDateView={graphDateView}
            graphType={graphType}
            currStudyParameters={currStudyParameters}
            dateRange={dateRange}
            data={data}
            defaultZero={defaultZero}
            onStudyDataChange={onStudyDataChange}
            onFetchDateDaysChange={onFetchDateDaysChange}
            zScoreActiveAndEnabled={active && isEnabled()}
            config={config?.[`${idx}`]}
            setConfig={(data) => setConfig({ [`${idx}`]: data })}
            requestSaveFlag={requestSaveFlag}
            zScoreType={ZSCORE_TYPES[idx]}
            zScoreSpecificKey={ZSCORE_KEYS[idx]}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default StudyZScore;
