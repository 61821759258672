import PatientsReportsMuscleLigamentInjury from "./PatientsReportsMuscleLigamentInjury";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import React from "react";

const PatientsReportsStep10LigamentInjury = (props) => {
  const idLigament = 2;
  return <PatientsReportsMuscleLigamentInjury idTissueType={idLigament} />;
};

export default withSnackbar(PatientsReportsStep10LigamentInjury);
