import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { dateFormat } from "../../../../../../utils/common";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../../shared/translations";
import { getMomentIdFromKey } from "../../../ReportHelperFns";
import StudySharedControls from "../SharedControls";
import {
  linearFixedPeriod,
  radarFixedPeriod,
  updateZScoreData,
} from "./ZScoreHelpers";

const moment = extendMoment(Moment);

const RADAR_KEY = "RADAR";
const SUBJECT_KEY = "ZSCORE_SUBJECT_FIXED";

const N_PERIODS = 1;

const StudyZScoreFixedPeriods = ({
  mainSubjectKey,
  study,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onStudyDataChange,
  onFetchDateDaysChange,
  zScoreActiveAndEnabled,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  zScoreType,
  zScoreSpecificKey,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [otherPeriods, setOtherPeriods] = useState(
    Array(N_PERIODS).fill(moment.rangeFromInterval("month", -1, moment()))
  );

  const updateFilterData = (by) => {
    return otherPeriods.reduce((accum, period, idx) => {
      const key = `${zScoreSpecificKey} ${idx + 1}`;

      //Obtain zscore for each period
      accum[key] = updateZScoreData(
        data,
        defaultZero,
        dateRange,
        period,
        by,
        zScoreType === SUBJECT_KEY,
        mainSubjectKey,
        graphType?.key === RADAR_KEY ? radarFixedPeriod : linearFixedPeriod
      );

      return accum;
    }, {});
  };

  const updatePeriods = (newPeriod, idx) => {
    //Set periods
    const newPeriods = [...otherPeriods];
    newPeriods[idx] = newPeriod;
    setOtherPeriods(newPeriods);

    //Request data
    let newRange = dateRange;
    newPeriods.forEach((period) => {
      if (period.start.isBefore(newRange.start))
        newRange = moment.range(period.start, newRange.end);
      if (period.end.isAfter(newRange.end))
        newRange = moment.range(newRange.start, period.end);
    });
    onFetchDateDaysChange(newRange);
  };

  //Effects
  useEffect(() => {
    let newData;
    //No zScoreSpecificKey here, will be provided by update data function
    if (active && zScoreActiveAndEnabled) {
      newData = {
        [study.key]: updateFilterData(getMomentIdFromKey(graphDateView?.key)),
      };
    } else {
      newData = { [study.key]: {} };
      //Zero only specific keys
      for (let idx = 0; idx < N_PERIODS; idx++) {
        const key = `${zScoreSpecificKey} ${idx + 1}`;
        newData[study.key][key] = {};
      }
    }

    onStudyDataChange(newData);
  }, [
    zScoreActiveAndEnabled,
    active,
    data,
    dateRange,
    graphDateView,
    otherPeriods,
    graphType,
  ]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);

      const newRanges = config.otherRanges.map((periodDiff) => {
        const nowStart = moment().startOf("day").add(periodDiff[0], "days");
        const nowEnd = moment().startOf("day").add(periodDiff[1], "days");
        return moment.range(nowStart, nowEnd);
      });
      //Ensure correct number
      for (let i = newRanges.length; i < N_PERIODS; i++)
        newRanges.push(moment.rangeFromInterval("month", -1, moment()));
      setOtherPeriods(newRanges);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      const now = moment().startOf("day");
      const ranges = otherPeriods.map((period) => [
        period.start.diff(now, "days"),
        period.end.diff(now, "days"),
      ]);
      //Save config
      setConfig({
        active: active,
        otherRanges: ranges,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  const constPadding = { padding: "5px" };
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[zScoreType]}
      active={active}
      setActive={setActive}
      enabled={zScoreActiveAndEnabled}
      hide={!zScoreActiveAndEnabled}
    >
      {otherPeriods.map((_, idx) => (
        <Grid item container xs={12} key={idx}>
          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters.start_date
              }
              value={otherPeriods[idx].start.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              onChange={(evnt) =>
                moment(evnt.target.value).isBefore(otherPeriods[idx].end) &&
                updatePeriods(
                  moment.range(evnt.target.value, otherPeriods[idx].end),
                  idx
                )
              }
            />
          </Grid>

          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              label={
                labels.patient.graphReport.section.feedback.filters.end_date
              }
              value={otherPeriods[idx].end.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              onChange={(evnt) =>
                otherPeriods[idx].start.isBefore(moment(evnt.target.value)) &&
                updatePeriods(
                  moment.range(otherPeriods[idx].start, evnt.target.value),
                  idx
                )
              }
            />
          </Grid>
        </Grid>
      ))}
    </StudySharedControls>
  );
};

export default StudyZScoreFixedPeriods;
