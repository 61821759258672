import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import "../../../App.css";
import { styled } from "../../../components/styled";
import SpText from "../../../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getFinalScore,
  getPromsSurveyAnswersByPresentation,
} from "../../../models/actions/Proms";
import { psTranslate } from "../../shared/translations";
import { useParams } from "react-router-dom";
import { rollbar } from "../../../utils/common";

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
  paddingTop: "2%",
});

const PatientsPresentationDetailProms = (props) => {
  const [promsSurvey, setPromsSurvey] = useState([]);
  const [promsObj, setPromsObj] = useState([]);
  const [promsAnswers, setPromsAnswers] = useState([]);
  const [finalScore, setFinalScore] = useState([]);

  const { patId } = useParams();
  const { proms } = props;
  const { setLoading } = props;

  const { reset, register, errors, control, getValues, handleSubmit } = useForm(
    {
      shouldUnregister: false,
    }
  );

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getPromsSurveyAnswersByPresentation({
        id_proms_presentation: proms,
        id_patient: patId,
      });

      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setPromsObj(result?.prom);
        setPromsSurvey(result?.prom.proms_surveys);
        setPromsAnswers(result?.proms_presentation_answers);
      }
    } catch (error) {
      rollbar.error("PatientsPresentationDetailProms - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (promsObj && promsSurvey && promsAnswers) {
      setFinalScore(getFinalScore(promsObj, promsAnswers));
    }
  }, [promsObj, promsSurvey, promsAnswers]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assessments",
    keyName: "id",
    shouldUnregister: false,
  });

  return (
    <StyledBodyColumn>
      <Grid xs={12} spacing={2}>
        <SpText variant="h1PageSubtitle">{promsObj?.name}</SpText>
      </Grid>
      {finalScore && (
        <Grid
          item
          xs={12}
          container
          style={{ border: "1px solid #31ccad", padding: "10px" }}
        >
          <SpText variant="h1PageSubtitle">{finalScore}</SpText>
        </Grid>
      )}
      <StyledBodyColumn style={{ paddingTop: "0px" }}>
        {promsSurvey?.map((survey, idx) => {
          const selectTitle = `${idx + 1}) ${psTranslate(survey.name)}`;
          return (
            <Grid container direction="column" spacing={1}>
              {survey.proms_questions?.map((question, questionIdx) => {
                let defaultValueAppo;
                for (let i = 0; i < promsAnswers.length; i++) {
                  for (let j = 0; j < question?.proms_answers?.length; j++) {
                    if (
                      question.proms_answers[j].id ==
                      promsAnswers[i].id_proms_answer
                    ) {
                      defaultValueAppo = promsAnswers[i].proms_answer;
                    }
                  }
                }
                if (defaultValueAppo) {
                  return (
                    <>
                      {questionIdx == 0 && (
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                          <SpText textAlign="start" variant="h4ComponentLabel">
                            {selectTitle}
                          </SpText>
                        </Grid>
                      )}
                      <Grid item xs={12} container direction="column">
                        <Grid item xs={12}>
                          <SpText variant="text">
                            {psTranslate(question?.question)}
                          </SpText>
                        </Grid>
                        <Grid item xs={12}>
                          <SpText variant="h4ComponentLabelRegular">
                            {psTranslate(defaultValueAppo.answer)}
                          </SpText>
                        </Grid>
                      </Grid>
                    </>
                  );
                }
              })}
            </Grid>
          );
        })}
      </StyledBodyColumn>
    </StyledBodyColumn>
  );
};

export default withSnackbar(PatientsPresentationDetailProms);
