import React from "react";
import {
  selectPatientsInjuryRate as selectPatientsTrainingInjuryRate,
  selectedGroupsInjuryRate as selectedGroupsTrainingInjuryRate,
} from "./TrainingInjuryFunction";

import { filterGroupByType } from "./SevereInjuryFunction";
import {
  selectedGroupsDaysAbsence as selectedGroupsDaysAbsenceTraining,
  selectPatientsDaysAbsence as selectPatientsDaysAbsenceTraining,
  selectPatientsInjuryBurden as selectPatientsInjuryBurdenTraining,
  selectedGroupsBurder as selectedGroupsBurderTraining,
} from "./TrainingInjuryFunction";

import {
  selectedGroupsInjury as selectedGroupsInjuryGeneral,
  selectPatientsInjury as selectPatientsInjuryGeneral,
  injuryGroupsByGravity as injuryGroupsByGravityGeneral,
  injuryPatientsByGravity as injuryPatientsByGravityGeneral,
  injuryPatientsByReinjury as injuryPatientsByReinjuryGeneral,
  injuryGroupsByReinjury as injuryGroupsByReinjuryGeneral,
} from "./GeneralInjuryFunction";
import { matchTrainingArrayUnion } from "./InjuryReportCommonFunction";
import { labels } from "../../../shared/translations";

const daysAbsenceLabel = labels.analytics.injuryReport.graphs.daysAbsence;
const trainingInjuriesBurdenLabel =
  labels.analytics.injuryReport.graphs.trainingInjuriesBurden;

export const filterByTissueType = (idTissueType, patients) => {
  let patientsFiltered = [];
  patients.forEach((patient) => {
    let injuriesFilteredByTissue = [];
    patient?.injuries.forEach((injury) => {
      if (
        injury?.pathology &&
        injury?.pathology?.id_tissue_type === idTissueType
      )
        injuriesFilteredByTissue.push(injury);
    });
    let patientFiltered = patient;
    patientFiltered.injuries = injuriesFilteredByTissue;
    patientsFiltered.push(patientFiltered);
  });
  return patientsFiltered;
};

export const injuryGroupsByGravity = (
  injuriesPatient,
  gravities,
  isAvarageEnabled,
  isPercentage,
  idTissueType
) => {
  injuriesPatient.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  return injuryGroupsByGravityGeneral(
    injuriesPatient,
    gravities,
    isAvarageEnabled,
    isPercentage
  );
};
export const injuryPatientsByGravity = (
  selectedInjuryPatient,
  gravities,
  isPercentage,
  idTissueType
) => {
  selectedInjuryPatient = filterByTissueType(
    idTissueType,
    selectedInjuryPatient
  );
  return injuryPatientsByGravityGeneral(
    selectedInjuryPatient,
    gravities,
    isPercentage
  );
};
export const injuryPatientsByReinjury = (
  selectedInjuryPatient,
  reinjuries,
  isPercentage,
  idTissueType
) => {
  selectedInjuryPatient = filterByTissueType(
    idTissueType,
    selectedInjuryPatient
  );

  return injuryPatientsByReinjuryGeneral(
    selectedInjuryPatient,
    reinjuries,
    isPercentage
  );
};

export const injuryGroupsByReinjury = (
  injuriesPatient,
  reinjuries,
  isAvarageEnabled,
  isPercentage,
  idTissueType
) => {
  injuriesPatient.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  return injuryGroupsByReinjuryGeneral(
    injuriesPatient,
    reinjuries,
    isAvarageEnabled,
    isPercentage
  );
};

export const selectPatientsInjury = (
  newPatientsSelected,
  regionSelected,
  areaSelected,
  newIsPercentage,
  idTissueType
) => {
  // filtro gli infortuni con id tissue type pari a quello di muscle
  newPatientsSelected = filterByTissueType(idTissueType, newPatientsSelected);
  return selectPatientsInjuryGeneral(
    newPatientsSelected,
    regionSelected,
    areaSelected,
    newIsPercentage
  );
};

export const selectedGroupsInjury = (
  newSelectedGroups,
  regionSelected,
  areaSelected,
  newIsPercentage,
  idTissueType
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });

  return selectedGroupsInjuryGeneral(
    newSelectedGroups,
    regionSelected,
    areaSelected,
    newIsPercentage
  );
};

export const selectPatientsInjuryRate = async (
  selectedPatients,
  groups,
  dateRange,
  idTissueType
) => {
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);
  // prendo i valori del injury ratio
  const groupFilteredByTraining = await filterGroupByType(
    groups,
    dateRange,
    "training"
  );
  const groupFilteredByMatch = await filterGroupByType(
    groups,
    dateRange,
    "race"
  );

  // prendo i valori del injury ratio
  const selectPatientsInjuryRateResultTraining =
    selectPatientsTrainingInjuryRate(
      selectedPatients,
      groupFilteredByTraining,
      "training"
    );

  const selectPatientsInjuryRateResultMatch = selectPatientsTrainingInjuryRate(
    selectedPatients,
    groupFilteredByMatch,
    "match"
  );

  const dataChart = matchTrainingArrayUnion(
    selectPatientsInjuryRateResultTraining,
    selectPatientsInjuryRateResultMatch
  );

  const result = {
    barChart: selectPatientsInjuryRateResultTraining.barChart,
    dataChart: dataChart,
  };
  return result;
};

export const selectedGroupsInjuryRate = async (
  newSelectedGroups,
  dateRange,
  idTissueType
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  // filtro in base agli allenamenti
  const groupFilteredByTraining = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "training"
  );

  const groupFilteredByMatch = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "race"
  );

  // prendo i valori del injury ratio
  const selectGroupsInjuryRateValueTraining = selectedGroupsTrainingInjuryRate(
    groupFilteredByTraining,
    "training"
  );

  const selectGroupsInjuryRateValueMatch = selectedGroupsTrainingInjuryRate(
    groupFilteredByMatch,
    "match"
  );

  const dataChart = matchTrainingArrayUnion(
    selectGroupsInjuryRateValueTraining,
    selectGroupsInjuryRateValueMatch
  );

  const result = {
    barChart: selectGroupsInjuryRateValueTraining.barChart
      ? selectGroupsInjuryRateValueTraining.barChart
      : {},
    dataChart: dataChart,
  };
  return result;
};

const trainingMatchAbsenceArrayUnion = (trainingArray, matchArray) => {
  let dataChart = [];
  if (trainingArray.dataChart) {
    trainingArray.dataChart.forEach((data) => {
      const matchPatient = matchArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      if (matchPatient !== undefined) {
        let result = {
          groupDate: data.groupDate,
        };
        result[daysAbsenceLabel] =
          data[daysAbsenceLabel] + matchPatient[daysAbsenceLabel];

        dataChart.push(result);
      } else {
        dataChart.push(data);
      }
    });

    // 2- unisco i dati di match con training
    matchArray.dataChart.forEach((data) => {
      const trainingPatient = trainingArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      // solo se non e' gia' stato fatto l'inverso posso considerare
      if (
        dataChart.find(({ groupDate }) => groupDate === data.groupDate) ===
        undefined
      ) {
        if (trainingPatient !== undefined) {
          let result = {
            groupDate: data.groupDate,
          };
          result[daysAbsenceLabel] =
            data[daysAbsenceLabel] + trainingPatient[daysAbsenceLabel];
          dataChart.push(result);
        } else {
          dataChart.push(data);
        }
      }
    });
  }
  return dataChart;
};

export const selectedGroupsDaysAbsence = (newSelectedGroups, idTissueType) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });

  const daysAbsenceTraining = selectedGroupsDaysAbsenceTraining(
    newSelectedGroups,
    "training"
  );

  const daysAbsenceMatch = selectedGroupsDaysAbsenceTraining(
    newSelectedGroups,
    "match"
  );

  const dataChart = trainingMatchAbsenceArrayUnion(
    daysAbsenceTraining,
    daysAbsenceMatch
  );

  const result = {
    barChart: daysAbsenceTraining.barChart ? daysAbsenceTraining.barChart : {},
    dataChart: dataChart,
  };
  return result;
};

export const selectPatientsDaysAbsence = (selectedPatients, idTissueType) => {
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);

  const daysAbsenceTraining = selectPatientsDaysAbsenceTraining(
    selectedPatients,
    "training"
  );

  const daysAbsenceMatch = selectPatientsDaysAbsenceTraining(
    selectedPatients,
    "match"
  );

  const dataChart = trainingMatchAbsenceArrayUnion(
    daysAbsenceTraining,
    daysAbsenceMatch
  );

  const result = {
    barChart: daysAbsenceTraining.barChart ? daysAbsenceTraining.barChart : {},
    dataChart: dataChart,
  };
  return result;
};

const burdenTrainingMatchUnion = (trainingArray, matchArray) => {
  let dataChart = [];

  // 1- unisco i dati di training con match
  if (trainingArray.dataChart) {
    trainingArray.dataChart.forEach((data) => {
      const matchPatient = matchArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      if (matchPatient !== undefined) {
        let result = {
          groupDate: data.groupDate,
          minutes: data.minutes,
          days: data.days + matchPatient.days,
        };
        result[trainingInjuriesBurdenLabel] =
          parseFloat(data[trainingInjuriesBurdenLabel]) +
          parseFloat(matchPatient[trainingInjuriesBurdenLabel]);
        dataChart.push(result);
      } else {
        dataChart.push(data);
      }
    });

    // 2- unisco i dati di match con training
    matchArray.dataChart.forEach((data) => {
      const trainingPatient = trainingArray.dataChart.find(
        ({ groupDate }) => groupDate === data.groupDate
      );
      // solo se non e' gia' stato fatto l'inverso posso considerare
      if (
        dataChart.find(({ groupDate }) => groupDate === data.groupDate) ===
        undefined
      ) {
        if (trainingPatient !== undefined) {
          let result = {
            groupDate: data.groupDate,
            minutes: data.minutes,
            days: data.days + trainingPatient.days,
          };
          result[trainingInjuriesBurdenLabel] =
            parseFloat(data[trainingInjuriesBurdenLabel]) +
            parseFloat(trainingPatient[trainingInjuriesBurdenLabel]);
          dataChart.push(result);
        } else {
          dataChart.push(data);
        }
      }
    });
  }
  return dataChart;
};

export const selectPatientsInjuryBurden = async (
  selectedPatients,
  groups,
  dateRange,
  idTissueType
) => {
  selectedPatients = filterByTissueType(idTissueType, selectedPatients);
  // prendo i valori del injury ratio
  const groupFilteredByTraining = await filterGroupByType(
    groups,
    dateRange,
    "training"
  );
  const groupFilteredByMatch = await filterGroupByType(
    groups,
    dateRange,
    "race"
  );

  const trainingBurder = selectPatientsInjuryBurdenTraining(
    selectedPatients,
    groupFilteredByTraining,
    "training"
  );

  const matchBurder = selectPatientsInjuryBurdenTraining(
    selectedPatients,
    groupFilteredByMatch,
    "match"
  );

  const dataChart = burdenTrainingMatchUnion(trainingBurder, matchBurder);

  const result = {
    barChart: trainingBurder.barChart ? trainingBurder.barChart : {},
    dataChart: dataChart,
  };
  return result;
};

export const selectedGroupsBurder = async (
  newSelectedGroups,
  dateRange,
  idTissueType
) => {
  newSelectedGroups.forEach((group) => {
    group.patients = filterByTissueType(idTissueType, group.patients);
  });
  // prendo i valori del injury ratio
  const groupFilteredByTraining = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "training"
  );

  const groupFilteredByMatch = await filterGroupByType(
    newSelectedGroups,
    dateRange,
    "race"
  );

  const trainingBurder = selectedGroupsBurderTraining(
    groupFilteredByTraining,
    "training"
  );

  const matchBurder = selectedGroupsBurderTraining(
    groupFilteredByMatch,
    "match"
  );

  const dataChart = burdenTrainingMatchUnion(trainingBurder, matchBurder);

  const result = {
    barChart: trainingBurder.barChart ? trainingBurder.barChart : {},
    dataChart: dataChart,
  };

  return result;
};
