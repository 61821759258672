import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import SpText from "../../components/atoms/SpText";
import CloseIcon from "@material-ui/icons/Close";
import SpIconButton from "./SpIconButton";
import { styled } from "../styled";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  padding: "4%",
  justifyContent: "space-between",
});

const useStyles = makeStyles({
  paper: {
    background: "#333333",
    border: "1px solid #31ccad",
  },
});

const SpDialog = ({
  children,
  open,
  setOpen,
  onCloseFunctions,
  title,
  variant,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    if (onCloseFunctions) {
      onCloseFunctions();
    }
  };

  return (
    <Dialog
      classes={classes}
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <StyledRow>
        <SpText variant="h1PageTitleDialog">{title}</SpText>
        <SpIconButton
          alignSelf="flex-end"
          color="#31ccad"
          variant={variant ? variant : "lightBlue"}
          onClick={handleClose}
          small
        >
          <CloseIcon />
        </SpIconButton>
      </StyledRow>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default SpDialog;
