import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "../App.css";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import SpText from "../components/atoms/SpText";
import SpTextInput from "../components/atoms/SpTextInput";
import SpButton from "../components/atoms/SpButton";
import SpSearch from "../components/atoms/SpSearch";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import { styled } from "../components/styled";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SpDialog from "../components/atoms/SpDialog";
import { labels, psTranslate } from "./shared/translations";

const Root = styled("div")({
  background: "transparent",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "2%",
});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const Agency = (props) => {
  const [displayRequests, setDisplayRequests] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [open, setOpen] = useState(false);

  const { control, register, handleSubmit, getValues, reset } = useForm({
    shouldUnregister: false,
  });

  const history = useHistory();

  const handleProfessionalClicked = (id) => {
    history.push("/patients/edit/${id}");
  };

  useEffect(() => {
    setDisplayRequests([]);
  }, []);

  // Columns
  const headCells = [
    {
      id: "professional",
      numeric: false,
      disablePadding: false,
      label: labels.agency.professionals.table.header.professional,
    },
    {
      id: "specialization",
      numeric: false,
      disablePadding: false,
      label: labels.agency.professionals.table.header.specialization,
    },
    {
      id: "coupon",
      numeric: false,
      disablePadding: false,
      label: labels.agency.professionals.table.header.coupon,
    },
    {
      id: "license",
      numeric: false,
      disablePadding: false,
      label: labels.agency.professionals.table.header.license,
    },
  ];

  const professionalDetailSetup = (patientId) => {
    //TODO: filter array of professional to find the one that need to be displayed
    let foundPatient = [].filter((elem) => elem.id === patientId);
    reset({
      professionalGivenName: foundPatient.givenName,
      professionalFamilyName: foundPatient.familyName,
      professionalBirthDate: foundPatient.familyName,
      professionalNation: foundPatient.familyName,
      professionalProvince: foundPatient.familyName,
      professionalCity: foundPatient.city,
      professionalAddress: foundPatient.address,
      professionalJob: foundPatient.job,
      professionalEmail: foundPatient.email,
    });
    setOpenDetail(true);
  };

  //rendering the profile editing form
  const PatientRequestRow = ({ row, rowKey, index, handleSelectedClick }) => {
    return (
      <>
        <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
          <SpTableCell>
            <SpIconAndTextButton
              isIconRight={true}
              text={`${row.name}`}
              onClick={() => professionalDetailSetup(row.id)}
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#31ccad",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.specialization}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.coupon}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.license}</SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <Root>
      <SpText variant="h1">{labels.agency.professionals.title}</SpText>
      <StyledRow>
        <SpButton
          buttonType={"accept"}
          onClick={() => setOpen(true)}
          text={labels.agency.professionals.toolbar.add}
        />
        <SpSearch />
      </StyledRow>
      <StyledRow>
        <SpTable
          pagination={true}
          headCells={headCells}
          rows={displayRequests}
          rowKey="id"
          padding={false}
          notCheckable={false}
          tableContainerMaxHeight={
            isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
          }
        >
          <PatientRequestRow />
        </SpTable>
      </StyledRow>
      <SpDialog
        open={open}
        setOpen={setOpen}
        title={labels.agency.professionals.addDialog.title}
      >
        <SpTextInput
          name="emailProfesional"
          inputRef={register}
          label={labels.agency.professionals.addDialog.email}
        />
      </SpDialog>
      <SpDialog
        open={openDetail}
        setOpen={setOpenDetail}
        title={labels.agency.professionals.detailDialog.title}
      >
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalGivenName"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.givenName}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalFamilyName"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.familyName}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalBirthDate"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.birthDate}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalEmail"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.email}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalNation"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.nation}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalProvince"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.province}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalCity"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.city}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalAddress"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.address}
        />
        <SpTextInput
          disabled
          style={{ padding: "2%" }}
          name="professionalJob"
          inputRef={register}
          label={labels.agency.professionals.detailDialog.job}
        />
      </SpDialog>
    </Root>
  );
};

export default Agency;
