import {
  ActionManager,
  DynamicTexture,
  Mesh,
  MeshBuilder,
  StandardMaterial,
  Texture,
  Vector3,
  Vector4,
} from "@babylonjs/core";
import MarkerTexture from "../Assets/Images/markers.png";
import markersConfig from "../Configs/markers";

export default class MarkerGraphicsPool {
  constructor() {
    this._scene = null;
    this._multiImg = null;
    this._id = 0;

    this._markerPrototypes = {};
    this._cachedMultiMarkerPrototypes = {};

    this._cachedMarkerInstances = {};
  }

  async load(assetsRootUrl, scene) {
    this._scene = scene;

    let markerTexture = new Texture(`${assetsRootUrl}${MarkerTexture}`, scene);
    markerTexture.hasAlpha = true;

    //FIX: [WORKER_FLIPPED_TEXTURE] bad hack to fix texture vertically flipped in workers
    if (typeof window === "undefined") markerTexture.vScale = -1;

    //Load markers prototypes
    let markers = markersConfig.textures;
    scene.blockMaterialDirtyMechanism = true;
    for (let i = 0; i < markers.length; i++) {
      let currMarkerData = markers[i];

      let mat = new StandardMaterial("marker_mat_" + i, scene);
      mat.diffuseTexture = markerTexture;
      mat.diffuseColor = new Vector3(0, 0, 0);
      mat.specularColor = new Vector3(0, 0, 0);
      mat.emissiveColor = new Vector3(1, 1, 1);
      mat.freeze();

      var f = new Vector4(
        currMarkerData.x + currMarkerData.sizeX,
        currMarkerData.y,
        currMarkerData.x,
        currMarkerData.y + currMarkerData.sizeY
      );
      var b = new Vector4(
        currMarkerData.x,
        currMarkerData.y,
        currMarkerData.x + currMarkerData.sizeX,
        currMarkerData.y + currMarkerData.sizeY
      );
      let plane = MeshBuilder.CreatePlane(
        "marker_plane_" + i,
        {
          width: markersConfig.planeSize,
          height: markersConfig.planeSize,
          sideOrientation: Mesh.DOUBLESIDE,
          frontUVs: f,
          backUVs: b,
        },
        scene
      );
      plane.material = mat;
      plane.setEnabled(false);

      this._markerPrototypes[`${currMarkerData.id}`] = plane;
    }
    scene.blockMaterialDirtyMechanism = false;

    let rawData = await fetch(`${assetsRootUrl}${MarkerTexture}`);
    let blob = await rawData.blob();
    this._multiImg = await createImageBitmap(blob);
  }

  _getMulti(prototypeId, markerType, n, id) {
    if (!this._cachedMultiMarkerPrototypes[prototypeId]) {
      //No cached prototype with given text, let's create one
      const dynamicConfig = markersConfig.dynamic;
      let model = this._markerPrototypes[markerType].clone(
        "marker_instance_" + id
      );
      model.material = model.material.clone();

      let dynamicTexture = new DynamicTexture(
        "marker_instance_dynamic_tex_" + id,
        { width: dynamicConfig.size, height: dynamicConfig.size },
        this._scene
      );
      dynamicTexture.hasAlpha = true;
      let ctx = dynamicTexture.getContext();

      //Draw img & text
      let img = this._multiImg;
      let x = dynamicConfig[markerType].x;
      let y = dynamicConfig[markerType].y;
      ctx.drawImage(
        img,
        x,
        y,
        dynamicConfig.size,
        dynamicConfig.size,
        0,
        0,
        ctx.canvas.width,
        ctx.canvas.height
      );
      ctx.font = "80px khand";
      ctx.fillStyle = "white";
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillText(
        String(n),
        ctx.canvas.width / 2,
        ctx.canvas.height / 2,
        ctx.canvas.width
      );
      dynamicTexture.update();

      model.material.diffuseTexture = dynamicTexture;
      model.material.freeze();
      model.setEnabled(false);
      this._cachedMultiMarkerPrototypes[prototypeId] = model;
    }

    return this._cachedMultiMarkerPrototypes[prototypeId].createInstance(
      "marker_instance_" + id
    ); //Recycle
  }

  getMeshForMarker(idIcon, nChildren) {
    //Create graphics object
    let markerMesh = null;
    let markerType = `${idIcon}`;
    if (nChildren > 1) {
      const multiMarkerType = `${markerType}_${nChildren}`;

      if (
        this._cachedMarkerInstances[multiMarkerType] &&
        this._cachedMarkerInstances[multiMarkerType].length > 0
      )
        //Recycle
        markerMesh = this._cachedMarkerInstances[multiMarkerType].pop();
      else
        markerMesh = this._getMulti(
          multiMarkerType,
          markerType,
          nChildren,
          this._id++
        );

      markerType = multiMarkerType;
    } else {
      //Single marker
      if (
        this._cachedMarkerInstances[markerType] &&
        this._cachedMarkerInstances[markerType].length > 0
      )
        //Recycle
        markerMesh = this._cachedMarkerInstances[markerType].pop();
      else
        markerMesh = this._markerPrototypes[markerType].createInstance(
          "marker_instance_" + this._id++
        );
    }

    //Prepare properties
    markerMesh.markerType = markerType;
    if (!markerMesh.actionManager)
      markerMesh.actionManager = new ActionManager(this._scene);

    markerMesh.release = () => {
      this.release(markerMesh);
    };

    //Done!
    return markerMesh;
  }

  release(markerMesh) {
    //Release to recycling-cache
    let markerType = markerMesh.markerType;
    if (!this._cachedMarkerInstances[markerType])
      this._cachedMarkerInstances[markerType] = [];
    this._cachedMarkerInstances[markerType].push(markerMesh);
  }

  clearCache() {
    //Dispose all remaining models/textures in cache
    Object.values(this._cachedMarkerInstances).forEach((elemArr) => {
      elemArr.forEach((elem) => {
        elem.dispose();
      });
    });
    this._cachedMarkerInstances = {};
  }
}
