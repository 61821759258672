import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { getStructuresSymbolDysfunction } from "../../../models/actions/Pathologies";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels } from "../../shared/translations";
import DysfunctionArea from "./patientsReportDysfunctions/DysfunctionsArea";
import DysfunctionDisorder from "./patientsReportDysfunctions/DysfunctionsDisorder";
import DysfunctionRegion from "./patientsReportDysfunctions/DysfunctionsRegion";

const moment = extendMoment(Moment);

const DYSFUNCTION_TYPES = {
  area: DysfunctionArea,
  region: DysfunctionRegion,
  disorder: DysfunctionDisorder,
};

const PatientsReportsStep3Dysfunctions = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [allData, setAllData] = useState([]);
  const [dysfunctionType, setDysfunctionType] = useState(
    Object.keys(DYSFUNCTION_TYPES)[0]
  );

  const [date, setDate] = useState(moment());

  const { patId } = useParams();

  const fetchData = async () => {
    try {
      const newAllData = await getStructuresSymbolDysfunction({
        id_patient: patId,
        date: date.format(dateFormat),
        direction: -1,
      });
      setAllData(
        newAllData.dysfunction_symbol_structures.filter((data) => data.severity)
      );
    } catch (error) {
      rollbar.error(
        "PatientsReportsStep3Dysfunctions - fetchDataInitial",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);
  useEffect(async () => {
    await fetchData();
  }, [date]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      const nowStart = moment().startOf("day").add(config.date, "days");
      setDysfunctionType(config.dysfunctionType);
      setDate(nowStart);
    }
  }, [config, configDone]);
  useEffect(() => {
    if (requestSaveFlag) {
      const now = moment().startOf("day");
      //Save config
      setConfig({
        dysfunctionType: dysfunctionType,
        date: date.diff(now, "days"),
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid
      container
      item
      xs={12}
      direction="column"
      wrap="nowrap"
      style={{ padding: "5px" }}
    >
      <Grid container item xs={12} style={{ marginBottom: "10px" }}>
        <Grid item xs={6}>
          <SpSelect
            displayLabel={true}
            label={
              labels.patient.graphReport.section.disorder.filters.type_select
            }
            formControlWidth={"99%"}
            labelPaddingTop={"0"}
            value={dysfunctionType}
            onChange={(evnt) => setDysfunctionType(evnt.target.value)}
          >
            {Object.keys(DYSFUNCTION_TYPES).map((key) => (
              <SpSelectMenuItem key={key} value={key}>
                {labels.patient.graphReport.section.disorder.types[key]}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            label={labels.patient.graphReport.section.disorder.filters.date}
            value={date.format(dateFormat)}
            style={{ width: "100%" }}
            type={"date"}
            onChange={(evnt) => setDate(moment(evnt.target.value))}
          />
        </Grid>
      </Grid>
      {(() => {
        const Type = DYSFUNCTION_TYPES[dysfunctionType];
        return (
          <Type
            key={dysfunctionType}
            allData={allData}
            config={config}
            setConfig={setConfig}
            requestSaveFlag={requestSaveFlag}
          />
        );
      })()}
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep3Dysfunctions);
