import { Divider, Grid } from "@material-ui/core";
import React from "react";
import { labels } from "../../../pages/shared/translations";
import SpButton from "../../atoms/SpButton";
import SpText from "../../atoms/SpText";
import FeedbackElement from "./FeedbackElement";

const Feedbacks = ({ event, setValue, click, register }) => (
  <Grid direction="column" container style={{ marginTop: "2%" }} spacing={2}>
    {event.patients.map((patAct) => {
      return (
        <Grid
          key={patAct.id}
          direction="column"
          container
          style={{ marginTop: "2%" }}
          spacing={2}
          xs={12}
        >
          <Grid item container direction="row" xs={12}>
            <Grid item xs={12}>
              <Divider
                style={{
                  backgroundColor: "#31ccad",
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SpText variant="h1PageSubtitle">{patAct.patientName}</SpText>
            </Grid>
          </Grid>

          <FeedbackElement
            feedbacks={patAct.patientFeedbacks}
            event={event}
            setValue={setValue}
            register={register}
          />
        </Grid>
      );
    })}
    {!(
      event.archiving_status === "completed_and_feedback_not_sent" ||
      event.archiving_status === "completed_and_feedback_sent"
    ) && (
      <Grid xs={12} item>
        <SpButton
          buttonType="accept"
          style={{ width: "100%" }}
          text={labels.bundles.calendarPlan.feedback.save}
          variant="h1PageTitle"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            click();
          }}
        />
      </Grid>
    )}
  </Grid>
);

export default Feedbacks;
