import React, { useEffect, useRef, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router";
import { SpList } from "../../../components/bundles/SpList";
import { ArrowBack } from "@material-ui/icons";
import { Box, Divider } from "@material-ui/core";
import {
  createTrainingTemplate,
  getTrainingTemplateById,
  updateTrainingTemplate,
} from "../../../models/actions/Activity";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { nanoid } from "nanoid";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

//rendering the profile editing form
const RowTraining = ({ row, index, headers, control, register, idElement }) => {
  return (
    <div
      key={row.id}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{ padding: "1px", width: "100%", backgroundColor: "#31ccad" }}
        />
      )}
      <Grid
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
      >
        <Grid item xs={4}>
          <SpTextInput
            disabled={idElement}
            name={`row_training_template[${index}].activity`}
            style={{ width: "80%" }}
            inputRef={register}
            value={row.activity}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            disabled={idElement}
            type="number"
            minValue={0}
            name={`row_training_template[${index}].time`}
            style={{ width: "80%" }}
            inputRef={register}
            value={row.time}
          />
        </Grid>

        <Grid item xs={4}>
          <SpTextInput
            disabled={idElement}
            name={`row_training_template[${index}].description`}
            inputRef={register}
            style={{ width: "80%" }}
            value={row.description}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const AddTraining = (props) => {
  const [trainingDetail, setTrainingDetail] = useState([]);
  const [trainingTableState, setTrainingTableState] = useState([]);
  const pastIndexTraining = useRef(0);

  const history = useHistory();

  const { register, control, watch, getValues, setValue, handleSubmit, reset } =
    useForm({
      shouldUnregister: false,
    });

  const { idElement } = useParams();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "row_training_template",
    keyName: "id",
  });

  const watchQuant = watch("quantity");

  useEffect(() => {
    if (!idElement) {
      remove();
      if (parseInt(getValues().quantity) > 20) {
        setValue("quantity", 20);
      } else if (parseInt(getValues().quantity) < 1) {
        setValue("quantity", 1);
      } else {
        for (let i = 0; i < control.getValues().quantity; i++) {
          append({ id: nanoid() }, false);
        }
      }
    }
  }, [watchQuant]);

  const fetchData = async () => {
    if (idElement) {
      try {
        const result = await getTrainingTemplateById({
          id_template: idElement,
        });
        result?.row_training_templates &&
          setValue("row_training_template", result?.row_training_templates);
        setValue("quantity", result.number);
        setValue("name", result.name);
        setTrainingDetail(result);
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTotalMinutes = () =>
    trainingDetail?.row_training_templates?.reduce(
      (prev, cur) => prev + (cur.time ? parseInt(cur.time) : 0),
      0
    );

  const editTemplate = async (data) => {
    try {
      const results = await updateTrainingTemplate({
        ...data,
        id_training_template: idElement,
      });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/training");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTrainingTemplate = async (data) => {
    if (getValues().name === "") {
      props.snackbarShowErrorMessage(labels.mylab.insertTemplateName);
      return;
    }
    try {
      const result = await createTrainingTemplate({ ...data });
      if (result.message) {
        props.snackbarShowMessage(result.message);
        reset();
        history.push("/mylab/training");
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const LabelValue = (props) =>
    props.value ? (
      <Grid direction="column" xs={6} style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    ) : null;

  const DetailTrainings = (props) => {
    return (
      <Grid container>
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: "#31ccad",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
        {props.rows.map((item) => (
          <Grid
            item
            xs={4}
            container
            style={{ border: "1px solid #31ccad", padding: "8px" }}
            direction="row"
          >
            <LabelValue
              label={labels.mylab.tempAdd.training.list.header[0]}
              value={item.activity}
            />
            <LabelValue
              label={labels.mylab.tempAdd.training.list.header[1]}
              value={item.time}
            />
            <LabelValue
              label={labels.mylab.tempAdd.training.list.header[2]}
              value={item.description}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const RenderTrainingMinutesCount = ({ control }) => {
    const watchMinutesTotalCountTrainings = useWatch({
      control,
      name: "row_training_template",
    });
    const currentArray = watchMinutesTotalCountTrainings;
    let minutesSum = 0;
    currentArray?.forEach((t) => {
      if (typeof t.time === "string") {
        const output = parseInt(t.time, 10);
        minutesSum = minutesSum + (Number.isNaN(output) ? 0 : output);
      } else {
        minutesSum = minutesSum + t.time;
      }
    });

    return (
      <SpText
        variant="h1PageSubtitleColored"
        style={{ display: "inline-block" }}
      >
        {minutesSum} {labels.agenda.inputDetails.totalMins}
      </SpText>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/training")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.training.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {fields.length > 0 && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                variant="none"
                style={{ marginLeft: "2%" }}
                text={
                  idElement
                    ? labels.mylab.tempAdd.training.actions.edit
                    : labels.mylab.tempAdd.training.actions.create
                }
                buttonType="accept"
                onClick={() =>
                  handleSubmit(
                    idElement ? editTemplate : saveTrainingTemplate
                  )()
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container style={{ justifyContent: "flex-start" }}>
          <Grid item xs={6}>
            <SpTextInput
              style={{ width: "95%" }}
              name={`name`}
              inputRef={register}
              label={labels.mylab.tempAdd.training.inputs.name}
            />
          </Grid>
          <Grid item xs={6}>
            {idElement ? (
              <Grid direction="row" container>
                <SpText
                  variant="h4ComponentLabelColored"
                  style={{ border: "1px solid #31ccad", padding: "8px" }}
                >
                  {labels.mylab.tempAdd.training.inputs.quantity}
                  <Box color={"#fff"}>{trainingDetail?.number}</Box>
                </SpText>
                <SpText
                  variant="h4ComponentLabelColored"
                  style={{ border: "1px solid #31ccad", padding: "8px" }}
                >
                  {labels.mylab.tempAdd.training.inputs.totalMinutes}
                  <Box color={"#fff"}>{getTotalMinutes()}</Box>
                </SpText>
              </Grid>
            ) : (
              <SpTextInput
                style={{ maxWidth: "40%" }}
                type="number"
                name="quantity"
                minValue={0}
                inputRef={register}
                label={labels.mylab.tempAdd.training.inputs.quantity}
                disabled={idElement}
              />
            )}
          </Grid>
        </Grid>
        {getValues("quantity") > 0 && (
          <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <SpText variant="h1PageSubtitle">
                {labels.mylab.tempAdd.training.list.title}
              </SpText>
            </Grid>
            {!idElement && (
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <RenderTrainingMinutesCount control={control} />
              </Grid>
            )}
          </Grid>
        )}
        {fields.length > 0 &&
          (idElement ? (
            <DetailTrainings rows={fields} />
          ) : (
            <Grid
              item
              xs={12}
              container
              style={{ border: "1px solid #31ccad" }}
            >
              <Grid item xs={12}>
                <form>
                  <SpList
                    rows={fields}
                    rowKey="id"
                    checkable={false}
                    deletable={false}
                    HeaderRow={
                      <Grid container style={{ justifyContent: "flex-start" }}>
                        {labels.mylab.tempAdd.training.list.header.map(
                          (head) => (
                            <Grid item xs={4}>
                              <SpText key={head} variant="listHeader">
                                {head}
                              </SpText>
                            </Grid>
                          )
                        )}
                      </Grid>
                    }
                  >
                    <RowTraining
                      headers={labels.mylab.tempAdd.training.list.header}
                      idElement={idElement}
                      control={control}
                      register={register}
                    />
                  </SpList>
                </form>
              </Grid>
            </Grid>
          ))}

        {fields.length > 0 && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            {!idElement && (
              <SpButton
                text={labels.mylab.tempAdd.training.actions.create}
                buttonType="accept"
                variant="h1"
                onClick={() => handleSubmit(saveTrainingTemplate)()}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddTraining);
