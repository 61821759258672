import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRouteUsernamePsw = ({ children, ...rest }) => {
  const accessToken = localStorage.getItem("accessToken");

  rest.location.state = { componentName: rest.componentName };
  return accessToken ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
};

export default PrivateRouteUsernamePsw;
