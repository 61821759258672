import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../App.css";
import SpButton from "../../../../components/atoms/SpButton";
import SpText from "../../../../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import {
  getPathologyMonitoringDetails,
  updatePathologyEndDate,
} from "../../../../models/actions/Pathologies";
import { labels, psTranslate } from "../../../shared/translations";

const PatientsMonitoringDetailPathology = (props) => {
  const [pathologyDetails, setPathologyDetails] = useState();
  const [monitoringPathologyDetails, setMonitoringPathologyDetails] =
    useState();
  const { patId } = useParams();

  const { pathology, setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getPathologyMonitoringDetails({
        id_pathology: pathology?.pathology?.id,
        id_monitoring_pathology: pathology?.id,
        id_patient: patId,
      });

      setLoading(false);
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setPathologyDetails(result?.pathology);
        setMonitoringPathologyDetails(result?.pathologyMonitoring);
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const endPathology = async () => {
    try {
      const result = await updatePathologyEndDate({
        id_monitoring_pathology: pathology?.id,
      });
      if (result.message) {
        props.snackbarShowMessage(result.message);
        props.closeAssessmentAdd();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <Grid container direction="column" spacing={1} style={{ marginTop: "3%" }}>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.pathologyName}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {psTranslate(pathologyDetails?.name)}
        </SpText>
      </Grid>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.tissueType}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {pathologyDetails?.tissue_type?.name
            ? psTranslate(pathologyDetails?.tissue_type?.name)
            : "-----"}
        </SpText>
      </Grid>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.pathologyType}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {pathologyDetails?.pathology_type?.name
            ? psTranslate(pathologyDetails?.pathology_type?.name)
            : "-----"}
        </SpText>
      </Grid>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.etiology}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {pathologyDetails?.etiology?.name
            ? psTranslate(pathologyDetails?.etiology?.name)
            : "-----"}
        </SpText>
      </Grid>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.medicalSystem}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {pathologyDetails?.medical_system_type?.name
            ? psTranslate(pathologyDetails?.medical_system_type?.name)
            : "-----"}
        </SpText>
      </Grid>
      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.note}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {monitoringPathologyDetails?.notes}
        </SpText>
      </Grid>
      <Grid item xs={12}>
        {!monitoringPathologyDetails?.end_date && (
          <SpButton
            text={labels.patient.monitoring.pathologiesStep.detail.setAsHealed}
            onClick={() => endPathology()}
            buttonType="accept"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsMonitoringDetailPathology);
