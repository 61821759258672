import React from "react";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";

const A1_AssessmentDisabledDetails = ({ event }) => {
  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant="h4ComponentLabel">{data?.assessment_motivation}</SpText>
    </>
  );
};

export default A1_AssessmentDisabledDetails;
