import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";

import moment from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  updatePresentation,
  getPresentationDetailById,
} from "../../../models/actions/Presentation";
import { getPatientRegistry } from "../../../models/actions/Patients";
import { Box, Divider, Grid } from "@material-ui/core";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "45%",
  flexDirection: "column",
});

const PatientsPresentationDetailSummary = (props) => {
  const [presentation, setPresentation] = useState();
  const [activityinjury, setActivityinjury] = useState();
  const [mechanism, setMechanism] = useState();
  const [reinjury, setReinjury] = useState();
  const [patientRegistry, setPatientRegistry] = useState();
  const [groups, setGroups] = useState([]);
  const [sports, setSports] = useState([]);

  const [listColumn, setListColumn] = useState([]);
  const [mapSelectInjuryCategory, setMapSelectInjuryCategory] = useState([]);
  const { patId, presentationId } = useParams();
  const { endDate } = props;

  const { control, register, handleSubmit, getValues, setValue, watch } =
    useForm({
      shouldUnregister: false,
    });

  const disableControl = presentation?.end_date;
  let actduringinjury;

  const getSelectmenu = (placeholderSelect, choise, name, nametext, result) => {
    const selectmenuact = (
      <Controller
        style={{ marginTop: "3%", minWidth: "100%", width: "100%" }}
        render={(props) => (
          <SpSelect
            value={props.value}
            selectPlaceholder={placeholderSelect}
            disabled={result?.presentation?.end_date}
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
          >
            {choise?.map((p) => (
              <SpSelectMenuItem key={p.key} value={p.key}>
                {p.value}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        )}
        defaultValue={""}
        name={name}
        control={control}
      />
    );

    actduringinjury = (
      <Grid
        container
        item
        direction="column"
        xs={3}
        style={{ marginBottom: "2%", width: "100%" }}
      >
        <SpText variant="text">{nametext}</SpText>

        <Grid container direction="column" xs={6}>
          {selectmenuact}
        </Grid>
      </Grid>
    );
    return actduringinjury;
  };

  const fetchData = async () => {
    try {
      // recupero la presentazione detail
      const result = await getPresentationDetailById({
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (result) {
        setPresentation(result?.presentation);
        // setto la estimated end date se presente
        if (result?.presentation?.start_date) {
          setValue(
            "start_date",
            moment(String(result?.presentation?.start_date)).format(
              "YYYY-MM-DD"
            )
          );
        }
        if (result?.presentation?.estimated_end_date) {
          setValue(
            "estimated_end_date",
            moment(String(result?.presentation?.estimated_end_date)).format(
              "YYYY-MM-DD"
            )
          );
        }
        setValue("note", result?.presentation?.note);
        setValue(
          "selectinjuryactivity",
          result?.presentation?.activity_during_injury
        );
        setValue("selectmechanism", result?.presentation?.mechanism);
        setValue("selectreinjury", result?.presentation?.reinjury);

        const listItems = result?.presentation?.patient?.groups?.map((d) => (
          <ul key={d.name}>{d.name}</ul>
        ));
        setGroups(listItems);
        // recupero injury detail
        let listCategory = [];
        let listColumn = [];
        const injuryDetail = result?.injuryDetail.reduce((group, product) => {
          const { column_name } = product;
          group[column_name] = group[column_name] ?? [];
          group[column_name].push(product);
          return group;
        }, {});

        for (const [key, value] of Object.entries(injuryDetail)) {
          injuryDetail[key] = value.reduce((group, product) => {
            const { category_name } = product;
            group[category_name] = group[category_name] ?? [];
            group[category_name].push(product);
            return group;
          }, {});
        }
        let index = 0;
        for (const [key, value] of Object.entries(injuryDetail)) {
          let element;

          for (const [keyX, valueX] of Object.entries(injuryDetail[key])) {
            index++;
            element = valueX[0];
            let mapSelect = mapSelectInjuryCategory;
            mapSelect.push({
              injury_category: element.category_name,
              index: index,
            });
            setMapSelectInjuryCategory(mapSelect);
            listCategory.push(
              <Controller
                style={{ marginTop: "3%", minWidth: "100%", width: "100%" }}
                render={(props) => (
                  <SpSelect
                    value={props.value}
                    selectPlaceholder={valueX[0]?.category_name}
                    disabled={result?.presentation?.end_date}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  >
                    {valueX?.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.item_name}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`select` + index}
                control={control}
              />
            );
          }

          listColumn.push(
            <Grid
              container
              item
              direction="column"
              xs={3}
              style={{ marginBottom: "2%", width: "100%" }}
            >
              <SpText variant="text">{element.column_name}</SpText>
              <SpText variant="h4ComponentLabel">
                {presentation?.patient?.givenName}
              </SpText>

              <Grid container direction="column" xs={6}>
                {listCategory}
              </Grid>
            </Grid>
          );
          listCategory = [];
        }
        setListColumn(listColumn);

        setActivityinjury(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.activity.name,
            labels.patient.presentation.add.stepper.step0.detail.activity
              .choise,
            `selectinjuryactivity`,
            labels.patient.presentation.add.stepper.step0.detail.activity.name,
            result
          )
        );

        setMechanism(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.mechanism.name,
            labels.patient.presentation.add.stepper.step0.detail.mechanism
              .choise,
            `selectmechanism`,
            labels.patient.presentation.add.stepper.step0.detail.mechanism.name,
            result
          )
        );

        setReinjury(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.reinjury.name,
            labels.patient.presentation.add.stepper.step0.detail.reinjury
              .choise,
            `selectreinjury`,
            labels.patient.presentation.add.stepper.step0.detail.reinjury.name,
            result
          )
        );
      }

      result?.detail_answer.forEach((answer) => {
        const itemCateg = mapSelectInjuryCategory?.filter(
          (item) =>
            item?.injury_category == answer?.injury_detail?.category_name
        );
        if (itemCateg?.length > 0)
          setValue("select" + itemCateg[0]?.index, answer?.id_injury_detail);
      });

      const patientR = await getPatientRegistry({ userId: patId });
      const listSport = patientR
        .find((question) => question.type == "sport")
        ?.questions.find((ques) => ques?.type == "sport-squadra")
        ?.answers.filter((res) => res?.value == true)
        .map((d) => <ul key={d.answer}>{d.answer}</ul>);
      setSports(listSport);

      setPatientRegistry(patientR);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  useEffect(() => fetchData(), []);

  const updatePresentationFunction = async (data) => {
    data.id_presentation = presentationId;
    try {
      let request = [];
      mapSelectInjuryCategory.forEach((item, index) => {
        request.push({
          id_injury_detail: getValues("select" + (index + 1)),
        });
      });
      let newData = {
        id_presentation: presentationId,
        estimated_end_date: data?.estimated_end_date,
        start_date: data?.start_date,
        note: getValues("note"),
        data: request,
        activity_during_injury: getValues("selectinjuryactivity"),
        mechanism: getValues("selectmechanism"),
        reinjury: getValues("selectreinjury"),
      };
      Object.keys(request).map((key) => {
        if (!data[key]) {
          newData[key] = null;
        }
      });
      const response = await updatePresentation(newData);
      props.snackbarShowMessage(response.message);
    } catch (error) {
      console.log(error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const LabelValue = (props) => {
    return (
      <Grid
        container
        direction="column"
        key={"grid_props_label" + props.label}
        style={{ marginBottom: "5px" }}
      >
        {props?.label && (
          <SpText
            variant="text"
            value={props.label}
            className={props?.className}
          >
            {props?.label}
          </SpText>
        )}
        {props?.value && (
          <SpText
            variant="h4ComponentLabel"
            value={props.label}
            className={props?.className}
          >
            {props.value}
          </SpText>
        )}
      </Grid>
    );
  };

  return (
    <>
      <StyledBodyColumn>
        <StyledRow>
          <StyledBodyHalfColumnToolbar>
            <SpText className="prova" variant="h1PageTitle">
              {labels.patient.presentation.add.stepper.step0.title.toUpperCase()}
            </SpText>
          </StyledBodyHalfColumnToolbar>
        </StyledRow>
        {!presentation?.end_date && (
          <StyledRow>
            {
              <Grid item xs={4}>
                <SpButton
                  style={{ width: "100%" }}
                  variant="none"
                  onClick={() => handleSubmit(updatePresentationFunction)()}
                  className="buttonClass"
                  buttonType="accept"
                  text={labels.patient.presentation.add.save}
                />
              </Grid>
            }
          </StyledRow>
        )}
        <form onSubmit={handleSubmit(updatePresentationFunction)}>
          <StyledRow style={{ marginBottom: "1%" }}>
            <Grid container spacing={2}>
              <Grid item xs={4} style={{ marginBottom: "1%" }}>
                <SpText variant="text">
                  {labels.patient.patientRegistry.givenName.toUpperCase()}
                </SpText>
                <SpText variant="h4ComponentLabel" className="patientName">
                  {presentation?.patient?.givenName}
                </SpText>
                <SpText variant="text">
                  {labels.patient.presentation.add.stepper.step0.groups.toUpperCase()}
                </SpText>
                <SpText variant="h4ComponentLabel" className="groups">
                  {groups}
                </SpText>
              </Grid>

              {sports && (
                <Grid
                  item
                  xs={4}
                  style={{ marginBottom: "1%" }}
                  className="sports"
                >
                  <SpText variant="text">
                    {labels.patient.presentation.add.stepper.step0.detail.sport.name.toUpperCase()}
                  </SpText>
                  <SpText variant="h4ComponentLabel">{sports}</SpText>
                </Grid>
              )}
            </Grid>
          </StyledRow>

          <StyledRow style={{ marginBottom: "1%" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                style={{ marginBottom: "1%" }}
                className="startDate"
              >
                <SpTextInput
                  name="start_date"
                  type="date"
                  id="inc"
                  maxValue={"2100-12-31"}
                  defaultValue={
                    getValues("start_date")
                      ? getValues("start_date")
                      : moment(new Date()).format("yyyy-MM-DD")
                  }
                  inputRef={register}
                  // disabled={disableControl}
                  label={
                    labels.patient.presentation.add.stepper.step0.startDate
                  }
                />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ marginBottom: "1%" }}
                className="estimatedEndDate"
              >
                <SpTextInput
                  name="estimated_end_date"
                  type="date"
                  maxValue={"2100-12-31"}
                  style={{ marginTop: "5px" }}
                  defaultValue={
                    getValues("estimated_end_date")
                      ? getValues("estimated_end_date")
                      : ""
                  }
                  inputRef={register}
                  // disabled={disableControl}
                  label={
                    labels.patient.presentation.add.stepper.step0
                      .endDateEstimated
                  }
                />
              </Grid>

              <Grid
                container
                item
                xs={4}
                direction="column"
                style={{ marginBottom: "1%" }}
              >
                <LabelValue
                  label={labels.patient.presentation.add.stepper.step0.endDate}
                  value={
                    presentation?.end_date
                      ? moment(presentation?.end_date).format("DD/MM/yyyy")
                      : labels.patient.presentation.add.stepper.step0
                          .endDateEstimatedLabel
                  }
                />
              </Grid>

              <Grid container item spacing={2}>
                {
                  <Grid item xs={4}>
                    <SpText variant="text">
                      {labels.patient.presentation.add.stepper.step0.region}
                    </SpText>
                    <SpText variant="h4ComponentLabel" className="region">
                      {presentation?.region?.name}
                    </SpText>
                  </Grid>
                }
                {
                  <Grid item xs={4}>
                    <SpText variant="text">
                      {labels.patient.presentation.add.stepper.step0.tissueType}
                    </SpText>
                    <SpText variant="h4ComponentLabel" className="tissueType">
                      {presentation?.pathology?.tissue_type?.name}
                    </SpText>
                  </Grid>
                }
                {
                  <Grid item xs={4}>
                    <SpText variant="text">
                      {
                        labels.patient.presentation.add.stepper.step0
                          .pathologyType
                      }
                    </SpText>
                    <SpText
                      variant="h4ComponentLabel"
                      className="pathologyType"
                    >
                      {presentation?.pathology?.pathology_type?.name}
                    </SpText>
                  </Grid>
                }
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={4}>
                  <SpText variant="text">
                    {
                      labels.patient.presentation.add.stepper.step0
                        .medicalDiagnose
                    }
                  </SpText>
                  <SpText variant="h4ComponentLabel" className="pathology">
                    {presentation?.pathology?.name}
                  </SpText>
                </Grid>
                {
                  <Grid item xs={4}>
                    <SpText variant="text">
                      {labels.patient.presentation.add.stepper.step0.osiicsCode}
                    </SpText>
                    <SpText variant="h4ComponentLabel" className="osiics">
                      {presentation?.pathology?.osiics_code}
                    </SpText>
                  </Grid>
                }
              </Grid>
            </Grid>
          </StyledRow>
          <StyledRow style={{ marginTop: "2%" }}>
            <Grid
              container
              item
              direction="row"
              xs={12}
              spacing={2}
              className="activity"
            >
              {activityinjury}
            </Grid>
            <Grid
              container
              item
              direction="row"
              xs={12}
              spacing={2}
              className="mechanism"
            >
              {mechanism}
            </Grid>

            <Grid
              container
              item
              direction="row"
              xs={12}
              spacing={2}
              className="reinjury"
            >
              {reinjury}
            </Grid>
          </StyledRow>
          <StyledRow style={{ marginTop: "2%" }}>
            <Grid
              container
              item
              direction="row"
              xs={12}
              spacing={2}
              className="injuryColumn"
            >
              {listColumn}
            </Grid>
          </StyledRow>
          <StyledRow style={{ marginTop: "2%" }}>
            <Grid container item direction="column" xs={12}>
              <SpTextInput
                variant={"row"}
                name={"note"}
                inputRef={register}
                // disabled={disableControl}
                id="note"
                label={
                  labels.patient.presentation.add.stepper.step0.noteDescription
                }
              />
            </Grid>
          </StyledRow>
        </form>
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetailSummary);
