let params = {
  skin: {
    normal: {
      base: {
        diffuseColor: "#00181f",
        emissiveColor: "#067174",
        specularColor: "#050505",
      },
      fresnel: {
        bias: 0.4,
        power: 4.07,
        leftColor: "#74d2d2",
        rightColor: "#089696",
      },
      blur: {
        blurSize: 0.85,
        blurColor: "#80fffd",
      },
      edge: {
        edgeDensity: 1.0,
        edgeWidth: 0.08,
        edgeColor: "#45d9d9FF",
      },
    },
    hover: {
      base: {
        emissiveColor: "#ff0000",
      },
    },
  },
  light: {
    diffuse: "#ffffff",
    intensity: 1.0,
  },
  markerAreaColors: [
    {
      color: "#00b704",
      step: 0.0,
    },
    {
      color: "#89d000",
      step: 0.25,
    },
    {
      color: "#dbe303",
      step: 0.5,
    },
    {
      color: "#e3a603",
      step: 0.75,
    },
    {
      color: "#e30303",
      step: 1.0,
    },
  ],
};

export default params;
