import React, { useEffect, useState } from "react";
import { labels } from "../../../../shared/translations";
import {
  computeInRange,
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const StudyComparationPerc = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const comparationPercAverageData = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          let avg = 0;
          accum[studyParamKey] = computeInRange(
            dateRange,
            by,
            (range) => range.by("day", { excludeEnd: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            (values) => {
              const prevAvg = avg;
              avg = values.reduce((a, b) => a + b, 0) / values.length;
              if (prevAvg != 0) return ((avg - prevAvg) / prevAvg) * 100;
              else return defaultZero ? 0 : null;
            },
            true,
            true //Forward compute
          );
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && isEnabled()) {
      onFetchDateDaysChange(-getNDaysFromKey(graphDateView?.key));
      newData = {
        [study.key]: {
          "": comparationPercAverageData(
            getMomentIdFromKey(graphDateView?.key)
          ),
        },
      };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { "": {} } };
    }
    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    />
  );
};

export default StudyComparationPerc;
