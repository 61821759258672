import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "../styled";
import { InputBase } from "@material-ui/core";
import { labels } from "../../pages/shared/translations";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    display: "flex",
    flexDirection: "reverse-row",
    justifyContent: "flex-start",
    position: "relative",
    maxHeight: "30px",
    borderRadius: 0,
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    width: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    color: "#31ccad",
    zIndex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  inputRoot: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#FFF",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#FFF",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const SearchContainer = styled(InputBase)({
  height: "100%",
  maxHeight: "30px",
  width: "100%",
  borderBottomStyle: "20",
  borderColor: "#31ccad",
  color: "#FFF",
  background: "transparent",
  border: "1px solid",
});

const SpSearch = ({ text, onChange, value, onKeyDown }) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <SearchContainer
        placeholder={labels.components.search.search}
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onKeyDown(e)}
        value={value}
        classes={{
          root: classes.inputRoot,
        }}
        inputProps={{ "aria-label": "search" }}
      />
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SpSearch;
