import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpText from "../SpText";
import { getDysfunctionsCategories } from "../../../models/actions/Pathologies";
import "../App.css";
import { SpAutocomplete } from "../SpAutocomplete";
import { withSnackbar } from "../SpSnackBar";
import { labels, psTranslate } from "../../../pages/shared/translations.js";

const Sp3DModelDysfunctionFilter = ({
  filterDysfunctionCategories,
  setFilterDysfunctionCategories,
  updateSymbolDate,
  ...props
}) => {
  const [dysfunctionCategories, setDysfunctionCategories] = useState([]);

  const updateFilter = (newValue) => {
    updateSymbolDate(true, true);
    setFilterDysfunctionCategories(newValue);
  };

  useEffect(async () => {
    try {
      const dysfunctionCategories = await getDysfunctionsCategories({});
      setDysfunctionCategories(dysfunctionCategories);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <Grid container direction="column" alignItems={"center"}>
      <Grid container item xs={12} direction="row" alignItems={"center"}>
        <SpAutocomplete
          multiple
          style={{ width: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={labels.bodymap.selectDysfunctionCategory}
          value={filterDysfunctionCategories}
          onChange={(_, newValue) => updateFilter(newValue)}
          displayLabel={false}
          renderTags={(tagValue) =>
            tagValue.map((option) => (
              <SpText style={{ marginLeft: "8px" }} variant="h4ComponentLabel">
                {psTranslate(option.name)}
              </SpText>
            ))
          }
          options={dysfunctionCategories}
          getOptionLabel={(option) => psTranslate(option.name)}
          getOptionSelected={(option, value) => option.id === value?.id}
        />
      </Grid>
    </Grid>
  );
};
export default withSnackbar(Sp3DModelDysfunctionFilter);
