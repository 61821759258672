import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import SpIconButton from "../../../components/atoms/SpIconButton";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { green, red } from "@material-ui/core/colors";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import SpButton from "../../../components/atoms/SpButton";
import {
  deleteMilestone,
  getMilestonesByPresentation,
  updateMilestone,
} from "../../../models/actions/Presentation";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { DeleteForever } from "@material-ui/icons";
import moment from "moment";
import { rollbar } from "../../../utils/common";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
});

const StyledColumn = styled("div")({
  flex: "1 1 100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "45%",
  flexDirection: "column",
});

const StyledGridContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "1%",
  marginTop: "3%",
  marginBottom: "1%",
  backgroundColor: "#333333",
  borderColor: "#31ccad",
  border: "1px solid",
  "&:disabled": {
    color: "#31ccad",
  },
});

const PatientsPresentationDetailMilestones = (props) => {
  useEffect(() => {}, []);
  const { presentationId, patId } = useParams();
  const { setLoading } = props;

  const { control, register, handleSubmit } = useForm({
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "milestones",
    keyName: "id",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getMilestonesByPresentation({
        id_presentation: presentationId,
        id_patient: patId,
      });
      const formattedResults = result.milestones?.map((milestone) => ({
        id: milestone.id,
        name: milestone.name,
        result: +milestone.result == 1 ? "true" : "false",
        date: milestone.date,
      }));
      remove();
      append(formattedResults);
    } catch (error) {
      rollbar.error("PatientsPresentationDetailMilestones - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const addMilestone = async () => {
    try {
      append({ id: "", name: "", result: "", date: "" });
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailMilestones - addMilestone",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const removeMilestone = async (milestoneId, idx) => {
    try {
      const result = await deleteMilestone({ id_milestone: milestoneId });
      if (result.message) {
        remove(idx);
        props.snackbarShowMessage(result.message);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailMilestones - removeMilestone",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateMilestones = async (data) => {
    try {
      const result = await updateMilestone({
        milestones: data.milestones,
        id_presentation: presentationId,
      });
      if (result.message) {
        props.snackbarShowMessage(result.message);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationDetailMilestones - updateMilestones",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => fetchData(), []);

  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
    "&:disabled": {
      color: "#31ccad",
    },
  };

  return (
    <>
      <StyledBodyColumn>
        <StyledRow>
          <StyledBodyHalfColumnToolbar>
            <SpText variant="h1PageTitle">
              {labels.patient.presentation.detail.stepper.step3.title.toUpperCase()}
            </SpText>
            {props?.endDate &&
              (fields.length > 0 ? (
                <Grid item xs={12}>
                  <SpText variant="h1PageSubtitle">
                    {
                      labels.patient.presentation.detail.stepper.step3
                        .closedPresentation
                    }
                  </SpText>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <SpText variant="h1PageSubtitle">
                    {labels.patient.presentation.detail.stepper.step3.noData}
                  </SpText>
                </Grid>
              ))}
          </StyledBodyHalfColumnToolbar>
        </StyledRow>
        {!props?.endDate && (
          <Grid item xs={4}>
            <SpButton
              style={{ width: "100%" }}
              variant="none"
              onClick={() => handleSubmit(updateMilestones)()}
              buttonType="accept"
              text={labels.patient.presentation.add.save}
            />
          </Grid>
        )}
        <form>
          <StyledColumn>
            {fields.map((milestone, obsIdx) => (
              <StyledGridContainer key={milestone.id}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <SpTextInput
                      disabled={props?.endDate}
                      label={"Milestone"}
                      name={`milestones[${obsIdx}].name`}
                      defaultValue={milestone.name}
                      inputRef={register}
                      formControlStyle={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SpTextInput
                      disabled={props?.endDate}
                      type="date"
                      maxValue={"2100-12-31"}
                      label={
                        labels.patient.presentation.detail.stepper.step3
                          .reachingDate
                      }
                      formControlStyle={{ width: "100%" }}
                      name={`milestones[${obsIdx}].date`}
                      defaultValue={moment(milestone?.date).format(
                        "YYYY-MM-DD"
                      )}
                      inputRef={register}
                    />
                  </Grid>
                  <Grid item xs={6} direction="row">
                    <Controller
                      as={
                        <RadioGroup
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="false"
                            disabled={props?.endDate}
                            control={<RedRadio />}
                            label={labels.patient.presentation.detail.stepper.step2.notSatisfied.toUpperCase()}
                          />
                          <FormControlLabel
                            style={formControlStyleCheck}
                            value="true"
                            disabled={props?.endDate}
                            control={<GreenRadio />}
                            label={labels.patient.presentation.detail.stepper.step2.satisfied.toUpperCase()}
                          />
                        </RadioGroup>
                      }
                      name={`milestones[${obsIdx}].result`}
                      control={control}
                      defaultValue={milestone.result}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {!props?.endDate && (
                      <SpIconButton
                        variant="lightBlueFill"
                        onClick={() => removeMilestone(milestone.id, obsIdx)}
                      >
                        <DeleteForever />
                      </SpIconButton>
                    )}
                  </Grid>
                </Grid>
              </StyledGridContainer>
            ))}
            {!props?.endDate && (
              <SpIconButton
                variant="lightBlueFill"
                onClick={() => addMilestone()}
              >
                <AddIcon />
              </SpIconButton>
            )}
          </StyledColumn>
        </form>
      </StyledBodyColumn>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetailMilestones);
