import { Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import { styled } from "../../components/styled";
import { theme } from "../../components/theme";
import { downloadScreenshot, isFeatureFlagEnabled } from "../../utils/common";
import printContext from "../../utils/printContext";
import PatientsReportsPDF from "../patients/patientsReports/PatientsReportsPDF";
import { labels } from "../shared/translations";
import Disponibility from "./disponibility/Disponibility";
import SituationTable from "./situation/SituationTable";
import Statistics from "./statistics/Statistics";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});
const reference = createRef(null);

const GroupReports = ({ ...props }) => {
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [fetchDataBe, setFetchDataBe] = useState(false);

  const { groupId } = useParams();
  const history = useHistory();

  useEffect(async () => {
    localStorage.removeItem("pdfScreenshot");

    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
  }, []);

  useEffect(() => {
    // quando fetchData � a true significa che le modifiche sono terminate
    // quindi posso procedere con settare a true isPrint in modo da far partire
    // il trigger per la stampa del PDF
    if (fetchDataBe) {
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div ref={reference}>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          {!isPrint && (
            <Grid item xs={12} container alignItems={"center"}>
              <Grid item xs={8}>
                <TitleToolbarSection>
                  <ArrowBack
                    onClick={() => history.push(`/groups/edit/${groupId}`)}
                    style={{
                      width: 30,
                      color: theme.colors.primary.lightBlue,
                      height: 50,
                      cursor: "pointer",
                    }}
                  />
                  <SpText variant="h1">{labels.groups.toolbar.back}</SpText>
                </TitleToolbarSection>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} container spacing={2} direction="column">
            <SituationTable isPrint={isPrint} fetchDataBe={fetchDataBe} />
            <Statistics isPrint={isPrint} />
            <Disponibility isPrint={isPrint} />
          </Grid>
        </Grid>
      </div>

      <Grid container style={{ paddingLeft: "1%", paddingTop: "1%" }}>
        <Grid item xs={10}>
          <SpButton
            className="selectGraph"
            text={labels.analytics.selectPDFGraph}
            onClick={() => {
              // attraverso fetchDataBe si attende che tutte le risorse grafiche siano aggiorate
              // prima di far partire l'evento della stampa PDF
              setFetchDataBe(true);
            }}
          />
          {pdfFeatureFlag && (
            <PatientsReportsPDF
              hide={true}
              reference={reference}
              label={"reference"}
              selectedPDF={selectedPDF}
              setSelectedPDF={setSelectedPDF}
              snackbarShowErrorMessage={props.snackbarShowErrorMessage}
            />
          )}{" "}
        </Grid>
        <Grid container xs={2} justifyContent="flex-end">
          {pdfFeatureFlag && selectedPDF.length > 0 && (
            <SpButton
              text={labels.analytics.injuryReport.createPdf}
              buttonType="accept"
              onClick={() => {
                downloadScreenshot(props, setSelectedPDF);
              }}
            />
          )}{" "}
        </Grid>
      </Grid>
    </printContext.Provider>
  );
};

export default withSnackbar(GroupReports);
