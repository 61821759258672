import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";

const RestInputDetails = ({
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  setValue,
  register,
  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  event,
  allowFeedback,
  isGroupCard,
}) => {
  useEffect(() => {
    if (templateData) {
      setValue("rest_motivation", templateData.rest_motivation);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <SpTextInput
        name="rest_motivation"
        inputRef={register}
        formControlStyle={{ width: "100%" }}
        label={
          labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
        }
        disabled={disabled}
      />
      {loadParametersData && (
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          internalLoadOnly={true}
        />
      )}
      {allowFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ))}
    </Grid>
  );
};

export default RestInputDetails;
