import React, { useEffect, useState } from "react";
import { dateFormat } from "../../../../../utils/common";
import { labels } from "../../../../shared/translations";
import { genericIsEnabled, getNDaysFromKey } from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const MIN_KEY = "Min";
const MAX_KEY = "Max";
const CONFIDENCE_INTERVAL = 1.96;

const StudyConfidenceInterval = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
}) => {
  const [active, setActive] = useState(false);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const confidenceData = () => {
    const dayStrStart = dateRange.start.format(dateFormat);
    const dayStrEnd = dateRange.end.format(dateFormat);
    const resultData = {
      [MIN_KEY]: {},
      [MAX_KEY]: {},
    };
    //Iterate over subjects
    Object.entries(data).forEach(([subjectName, subjectData]) => {
      resultData[MIN_KEY][subjectName] = {};
      resultData[MAX_KEY][subjectName] = {};

      //Iterate over study parameters
      Object.entries(subjectData).forEach(([studyParamKey, studyData]) => {
        resultData[MIN_KEY][subjectName][studyParamKey] = {};
        resultData[MAX_KEY][subjectName][studyParamKey] = {};
        const studyValues = Object.values(studyData);
        const average =
          studyValues.reduce((accum, val) => {
            accum += val;
            return accum;
          }, 0) / studyValues.length;
        const devStd = Math.sqrt(
          studyValues.reduce(
            (accum, val) => accum + Math.pow(val - average, 2),
            0
          ) / studyValues.length
        );
        const confidence =
          (CONFIDENCE_INTERVAL * devStd) / Math.sqrt(studyValues.length);
        const confidenceMin = average - confidence;
        const confidenceMax = average + confidence;
        resultData[MIN_KEY][subjectName][studyParamKey][dayStrStart] =
          confidenceMin;
        resultData[MIN_KEY][subjectName][studyParamKey][dayStrEnd] =
          confidenceMin;
        resultData[MAX_KEY][subjectName][studyParamKey][dayStrStart] =
          confidenceMax;
        resultData[MAX_KEY][subjectName][studyParamKey][dayStrEnd] =
          confidenceMax;
      });
    });
    return resultData;
  };

  //Effects
  useEffect(() => {
    onFetchDateDaysChange(getNDaysFromKey(graphDateView?.key));
    //Update filter data
    let newData;
    if (active && isEnabled())
      newData = {
        [study.key]: confidenceData(),
      };
    else
      newData = {
        [study.key]: { [MIN_KEY]: {}, [MAX_KEY]: {} },
      };

    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    />
  );
};

export default StudyConfidenceInterval;
