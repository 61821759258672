import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import SpTextInput from "../../components/atoms/SpTextInput";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { Divider, Grid, TableCell } from "@material-ui/core";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { isMobile } from "react-device-detect";
import {
  fetchPatAnswersToQuest,
  findPatientByPk,
  removeFeedbackById,
  saveControlQuestionnaires,
} from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { getAllPromsSurveys, getFinalScore } from "../../models/actions/Proms";
import SpDialog from "../../components/atoms/SpDialog";

const headCellsControlQuestions = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: labels.patient.controlQuestions.tableHeader.questionnaire,
    isAction: false,
  },
  {
    id: "visibility_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.controlQuestions.tableHeader.visibilityDate,
    isAction: false,
  },
  {
    id: "answer_date",
    numeric: false,
    disablePadding: false,
    label: labels.patient.controlQuestions.tableHeader.answerDate,
    isAction: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    isAction: true,
  },
];

const PatientsControlQuestion = (props) => {
  const [questionnaireList, setQuestionnaireList] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [promsQuestList, setPromsQuestList] = useState([]);
  const [promSelected, setPromSelected] = useState(null);
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [selectedRecordDetail, setSelectedRecordDetail] = useState(null);
  const [finalScore, setFinalScore] = useState(null);
  const [openDeleteFeedbackDialog, setOpenDeleteFeedbackDialog] =
    useState(false);
  const [feedbackToRemove, setFeedbackToRemove] = useState(false);

  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm({
      defaultValues: {
        proms: null,
        frequency: null,
        duration: null,
      },
      shouldUnregister: false,
    });

  const removeFeedback = async () => {
    try {
      const result = await removeFeedbackById({
        id_questionnaire: feedbackToRemove.id,
      });
      if (result.message) {
        setOpenDeleteFeedbackDialog(false);
        props.snackbarShowMessage(result.message);
        await fetchData();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveControlsQuestionnaires = async () => {
    try {
      if (!getValues().frequency || !getValues().duration) {
        props.snackbarShowErrorMessage(
          labels.patient.controlQuestions.insertAllData
        );
        return;
      }
      const saveResponse = await saveControlQuestionnaires({
        id_patient: patId,
        id_questionnaires: promSelected,
        frequency: getValues().frequency,
        duration: getValues().duration,
        monitoring_questions: promSelected.is_monit_question,
      });
      if (saveResponse?.message) {
        props.snackbarShowMessage(saveResponse.message);
        reset({
          proms: null,
          frequency: null,
          duration: null,
        });
        setPromSelected(null);
        await fetchQuestAnsData();
      } else {
        props.snackbarShowErrorMessage(saveResponse.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchQuestAnsData = async () => {
    try {
      const responseQuestionnaireList = await fetchPatAnswersToQuest({
        id_patient: patId,
      });
      setQuestionnaireList(responseQuestionnaireList);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      const resultProms = await getAllPromsSurveys();
      resultProms.unshift({
        id: -1,
        name: labels.patient.controlQuestions.defaultSelectValue,
      });
      setPromsQuestList([...resultProms]);
      await fetchQuestAnsData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const handleDetailSelect = (questionnaire) => {
    if (questionnaire.type === "proms") {
      const finalScore = getFinalScore(
        questionnaire,
        questionnaire.data?._fsAnswers
      );
      setFinalScore(finalScore);
    }
    setSelectedRecordDetail(questionnaire);
    setDialogDetailOpen(true);
  };

  const ControlQuestionsRow = ({ row, index }) => (
    <>
      <SpTableRow role="checkbox" tabIndex={index}>
        <SpTableCell>
          <SpText variant="tableText">{psTranslate(row?.title)}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{row?.visibility_date}</SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row?.answered ? row?.date : "---"}
          </SpText>
        </SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          {row.answered ? (
            <SpButton
              variant={"standardMax"}
              type="tableList"
              text={labels.patient.controlQuestions.responseTable.actions.view}
              onClick={() => handleDetailSelect(row)}
            />
          ) : (
            <SpButton
              variant={"standardOppositeMax"}
              type="tableList"
              text={labels.mylab.tempList.action.delete}
              onClick={() => {
                setFeedbackToRemove(row);
                setOpenDeleteFeedbackDialog(true);
              }}
            />
          )}
        </TableCell>
      </SpTableRow>
    </>
  );

  const LabelValue = (props) => (
    <Grid direction="column" style={{ marginBottom: "10px" }}>
      <SpText variant={"text"}>{props.label}</SpText>
      <SpText variant={"h4ComponentLabelRegular"}>{props.value}</SpText>
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item container xs={12} spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10} spacing={1} container direction="column">
            <form onSubmit={handleSubmit(saveControlsQuestionnaires)}>
              <Grid item xs={"auto"}>
                <SpText variant="h1PageTitle">
                  {labels.patient.controlQuestions.title.toUpperCase()}
                </SpText>
                <SpText variant="h1Subtitle">
                  {labels.patient.controlQuestions.subtitle +
                    " " +
                    currentPatient?.givenName +
                    " " +
                    currentPatient?.familyName}
                </SpText>
              </Grid>
              <Grid item xs={"auto"} container spacing={1} direction="column">
                <Grid item xs>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpSelect
                        label={
                          labels.patient.controlQuestions.selectProms.title
                        }
                        value={props.value}
                        formControlWidth={300}
                        selectPlaceholder={
                          labels.patient.controlQuestions.selectProms
                            .placeholder
                        }
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          setPromSelected(e.target.value);
                        }}
                      >
                        {promsQuestList.map((p) => (
                          <SpSelectMenuItem key={p.id} value={p.id}>
                            {p.name
                              ? psTranslate(p.name)
                              : labels.patient.controlQuestions.selectProms
                                  .placeholder}
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    defaultValue={""}
                    name={"proms"}
                    control={control}
                  />
                </Grid>
                {promSelected && (
                  <Grid item xs>
                    <SpTextInput
                      style={{ minWidth: 300 }}
                      name="frequency"
                      label={
                        labels.patient.controlQuestions.selectFrequency.title
                      }
                      placeholder={
                        labels.patient.controlQuestions.selectFrequency
                          .placeholder
                      }
                      inputRef={register}
                      type="number"
                      minValue={1}
                    />
                  </Grid>
                )}
                {promSelected && (
                  <Grid item xs>
                    <SpTextInput
                      style={{ minWidth: 300 }}
                      name="duration"
                      label={
                        labels.patient.controlQuestions.durationInDays.title
                      }
                      placeholder={
                        labels.patient.controlQuestions.durationInDays
                          .placeholder
                      }
                      inputRef={register}
                      type="number"
                      minValue={1}
                    />
                  </Grid>
                )}

                {promSelected && (
                  <Grid item xs>
                    <SpButton
                      style={{ minWidth: 300 }}
                      buttonType="accept"
                      type="submit"
                      variant="column"
                      text={labels.patient.controlQuestions.sendQuestionButton.toUpperCase()}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: "2%" }}>
                <SpText variant="h4ComponentLabel">
                  {labels.patient.controlQuestions.responseTable.title +
                    " " +
                    currentPatient?.givenName +
                    " " +
                    currentPatient?.familyName}
                </SpText>
                <SpTable
                  headCells={headCellsControlQuestions}
                  rows={questionnaireList}
                  pagination={true}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                  tableContainerMaxHeight={
                    isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                  }
                >
                  <ControlQuestionsRow />
                </SpTable>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <SpDialog
        open={dialogDetailOpen}
        setOpen={setDialogDetailOpen}
        title={labels.patient.controlQuestions.detailDialog.title}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <>
            <Grid item container xs={12} spacing={2}>
              <Grid xs={12}>
                <SpText variant="text">{`${labels.patient.controlQuestions.detailDialog.questTitle}`}</SpText>
              </Grid>
              <Grid xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(selectedRecordDetail?.title)}
                </SpText>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Divider
                style={{
                  padding: "1px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  backgroundColor: "#31ccad",
                  width: "100%",
                }}
              />
              {finalScore && (
                <Grid
                  item
                  xs={12}
                  container
                  style={{ border: "1px solid #31ccad", padding: "10px" }}
                >
                  <SpText variant="h1PageSubtitle">{finalScore}</SpText>
                </Grid>
              )}
              <Grid xs={12} direction="column" style={{ marginTop: "1%" }}>
                {selectedRecordDetail?.data?.questions?.map((question) => (
                  <LabelValue
                    label={psTranslate(question.question)}
                    value={question?.answers
                      ?.map((a) => psTranslate(a.answer))
                      ?.join(", ")}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        </Grid>
      </SpDialog>
      {/*Dialog delete feedback*/}
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteFeedbackDialog}
        setOpen={setOpenDeleteFeedbackDialog}
        title={labels.patient.controlQuestions.deleteDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.patient.controlQuestions.deleteDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.mylab.dialog.delete.yes}
                buttonType="accept"
                onClick={removeFeedback}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.mylab.dialog.delete.no}
                buttonType="accept"
                onClick={() => setOpenDeleteFeedbackDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsControlQuestion);
