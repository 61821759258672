import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Children, useEffect, useState, createRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import "../../components/atoms/App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextArea from "../../components/atoms/SpTextArea";
import {
  getActivityPlanTemplateById,
  getAllActivitiesTypes,
  getAllActivityPlanTemplates,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../../models/actions/Activity";
import {
  createCalendarActivityMonitoring,
  getAllCalendarActivityByMonitoring,
  updateCalendarActivityMonitoring,
} from "../../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../../models/actions/Excercise";
import { findPatientByPk } from "../../models/actions/Patients";
import { getPresentationsByPatProf } from "../../models/actions/Presentation";
import { getLanguage, labels, psTranslate } from "../shared/translations";
import AgendaCalendarEventCard from "../agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "../agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "../agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "../agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "../agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "../agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "../agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "../agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "../agenda/inputDetails/A8_RehabilitationInputDetails";
import ColoredEventCell from "../agenda/ColoredEventCell";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import {
  rollbar,
  momentDefinition,
  isDragAndDropActivityEnabled,
  isFeatureFlagEnabled,
  downloadScreenshot,
} from "../../utils/common";
import printContext from "../../utils/printContext";

import PatientsReportsPDF from "../patients/patientsReports/PatientsReportsPDF";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];
const DragAndDropCalendar = withDragAndDrop(Calendar);

const PatientsPlan = ({ setLoading, ...props }) => {
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [activityFormError, setActivityFormError] = useState(true);
  const [presentations, setPresentations] = useState([]);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [templateHelper, setTemplateHelper] = useState(false);
  const [activityPlanTemplatesList, setActivityPlanTemplatesList] = useState(
    []
  );
  const [isPrint, setIsPrint] = useState(false);
  const [fetchDataBe, setFetchDataBe] = useState(false);

  const [error, setError] = useState("");
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);

  const { patId } = useParams();
  const history = useHistory();

  moment.locale(getLanguage(), momentDefinition);
  const localizer = momentLocalizer(moment);

  const reference = {};
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [indexPDF, setIndexPDF] = useState(0);
  const [monthSelected, setMonthSelected] = useState(moment());

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const createReference = () => {
    reference[indexPDF] = createRef(null);
    return reference;
  };

  const fetchDataInitial = async () => {
    console.log("Fetch data initial");
    localStorage.removeItem("pdfScreenshot");

    try {
      const [activityTypeResponse, feedbackParametersList, templatesFound] =
        await Promise.all([
          getAllActivitiesTypes(),
          getAllFeedbackParameters(),
          getAllActivityPlanTemplates(),
        ]);
      setActivityTypeList(activityTypeResponse);
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      templatesFound && setActivityPlanTemplatesList(templatesFound);
    } catch (error) {
      rollbar.error("PatientsPlan - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const responseEvents = await getAllCalendarActivityByMonitoring({
          id_patient: patId,
        });
        Rollbar.configure({
          checkIgnore: () => false,
        });
        rollbar.info(
          " PatientsPlan: - fetchData",
          JSON.stringify({
            responseEvents,
          })
        );
        Rollbar.configure({
          checkIgnore: () => true,
        });
        const newEvents = updateEventsList(responseEvents);
        const responseDetail = await findPatientByPk({ userId: patId });
        setCurrentPatientDetail(responseDetail);
        const response = await getPresentationsByPatProf({ id_patient: patId });

        let newResponseArray = [
          { id: -1, name: labels.patient.patientPlan.defaultSelectValue },
        ];
        response.map((elem) => {
          if (elem.region) {
            newResponseArray.push({
              id: elem?.id,
              name:
                (elem.pathology ? elem.pathology?.name : elem.type) +
                " " +
                elem.region?.name.toLowerCase(),
            });
          }
        });
        setPresentations(newResponseArray);
        resolve(newEvents);
      } catch (error) {
        rollbar.error("PatientsPlan - fetchData", error);
        props.snackbarShowErrorMessage(error);
      }
      resolve(eventsList);
    });
  };

  const dateHeader = ({ date, label }) => {
    if (moment(monthSelected).format("MM") === moment(date).format("MM")) {
      return <h3 style={{ color: "lightGray" }}>{label}</h3>;
    } else {
      return <h3 style={{ color: "gray" }}>{label}</h3>;
    }
  };

  useEffect(async () => {
    if (fetchDataBe) {
      await fetchData();
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setLoadParametersList(
      event.feedbacks?.map((feedback) => feedback.feedback_parameter) ?? []
    );
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const updateEventsList = (eventsListData) => {
    const newEventsList = [];
    eventsListData.map((event, eventIdx) => {
      newEventsList.push({
        id: event?.id,
        title: event?.activity_datum?.activity_type?.name,
        description: "",
        start: new Date(event?.start_date),
        end: new Date(event?.start_date),
        start_time: event?.start_time,
        end_time: event?.end_time,
        type: event?.activity_datum?.name,
        event_note: event?.event_note,
        id_presentation: event?.id_presentation,
        presentation: event?.presentation,
        id_activity_type: event?.activity_datum?.activity_type?.id,
        media: event?.activity_datum?.activity_type?.key
          ? require(`../../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
          : null,
        color: event?.color,
        data: event?.activity_datum?.data,
        feedbacks: event?.activity_feedbacks[0]
          ?.activity_resp_parameter_feedbacks
          ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : [],
        archiving_status: event?.archiving_status,
        is_from_group_plan: event?.is_from_group_plan,
        id_activity_group: event?.id_activity_group,
      });
    });

    setEventsList(newEventsList);
    return newEventsList;
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "96%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
      setIndexPDF(indexPDF + 1);
      setMonthSelected(toolbar.date);
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
      setIndexPDF(indexPDF + 1);
      setMonthSelected(toolbar.date);
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
      setMonthSelected(toolbar.date);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>
        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent?.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    setValue("id_activity_type", draggedEvent?.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let tempIsDragAndDropActivityEnabled = await isFeatureFlagEnabled(
      "drag-drop-activity-patient"
    );
    if (tempIsDragAndDropActivityEnabled) {
      let allDay = event.allDay;
      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true;
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false;
      }
      const nextEvents = eventsList.map((existingEvent) => {
        return existingEvent?.id === event?.id
          ? { ...existingEvent, start, end, allDay }
          : existingEvent;
      });
      event.start_date = moment(start).format("YYYY-MM-DD");
      await updateActivity(event);
      setEventsList(nextEvents);
    }
  };

  const updateActivity = async (data) => {
    try {
      const updatedActivity = await updateCalendarActivityMonitoring({
        ...data,
        id_patient: patId,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity.message);
        resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity.error);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data.assessment_motivation,
        };
        break;
      case 2:
        data.details_data = {
          name: data.name,
          treatments: data.treatments,
          treatments_motivation: data.treatments_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 3:
        data.details_data = {
          name: data.name,
          excercises: data.excercises,
          excercises_motivation: data.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data.name,
          training: data.training,
          training_motivation: data.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data.rest_motivation };
        data.feedback_parameter = loadParametersList;
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data.race_motivation,
          race_minutes: data.race_minutes,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 8:
        data.details_data = {
          name: data.name,
          rehabilitations: data.rehabilitations,
          rehabilitation_motivation: data.rehabilitation_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      default:
        break;
    }
    return data;
  };

  const createActivity = async (data) => {
    try {
      const id_activity_template = getValues("id_activity_template");
      const id_activity_type = getValues("id_activity_type");
      let createdActivity;

      if (id_activity_template) {
        const responseDetail = await getActivityPlanTemplateById({
          id_template: id_activity_template,
        });
        let zeroDate = new Date("1900-01-01").getTime();
        let startDate = new Date(data.start_date).getTime();
        let endDate = new Date(data.end_date).getTime();
        await Promise.all(
          responseDetail.calendar_activities.map(async (activity) => {
            //Adding the progressive days to the current activity
            //start date progressive
            let activityStartDate = new Date(activity.start_date);
            activityStartDate.setTime(
              activityStartDate.getTime() + startDate - zeroDate
            );
            //end date progressive
            let activityEndDate = new Date(activity.end_date);
            activityEndDate.setTime(
              activityEndDate.getTime() + endDate - zeroDate
            );

            activity.start_date = activityStartDate;
            activity.end_date = activityEndDate;
            activity.start_time = "05:00"; //data.start_time;
            activity.end_time = "22:00"; //data.start_time;
            activity.fl_template = null;
            activity.id_activity_type =
              activity.activity_datum.id_activity_type;
            activity.details_data = activity.activity_datum?.data;
            Rollbar.configure({
              checkIgnore: () => false,
            });
            rollbar.info(
              " PatientsPlan: - createActivity",
              JSON.stringify({
                ...activity,
                id_patient: patId,
              })
            );
            Rollbar.configure({
              checkIgnore: () => true,
            });
            createdActivity = await createCalendarActivityMonitoring({
              ...activity,
              id_patient: patId,
            });
          })
        );
      } else {
        data.start_time = "05:00"; //data.start_time;
        data.end_time = "22:00"; //data.start_time;
        data.start_date = getValues("start_date");
        data = createActivityObject(data, id_activity_type);

        Rollbar.configure({
          checkIgnore: () => false,
        });
        rollbar.info(
          " PatientsPlan: - createActivity",
          JSON.stringify({
            ...data,
            id_patient: patId,
            is_from_agenda: 0,
          })
        );
        Rollbar.configure({
          checkIgnore: () => true,
        });

        createdActivity = await createCalendarActivityMonitoring({
          ...data,
          id_patient: patId,
          is_from_agenda: 0,
        });
      }

      //Check errors
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity.message);
        const newEvents = await resetFields();
        return [createdActivity.id, newEvents];
      } else {
        props.snackbarShowErrorMessage(createdActivity.error);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }
    return null;
  };

  const resetFields = () => {
    setValue("id_activity_type", null);
    setValue("id_template", null);
    setValue("id_activity_template", null);
    setValue("event_note", "");
    setValue("start_time", null);
    setValue("end_time", null);
    setValue("id_presentation", null);
    setValue("quantity_training", 0);
    setValue("quantity_treatment", 0);
    setValue("quantity_rehabilitation", 0);
    setValue("quantity_excercise", 0);
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setLoadParametersList([]);
    return fetchData();
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - fetchTemplateByActType", error);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataInitial();
    fetchData();
  }, []);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  const getDrandAndDropCalendar = () => {
    return (
      <DragAndDropCalendar
        selectable
        events={eventsList}
        scrollToTime={new Date()}
        defaultDate={new Date()}
        view={"month"}
        onEventDrop={handleEventDrop}
        popup={true}
        onDragStart={() => console.log("Drag started")}
        dragFromOutsideItem={displayDragItemInCell ? dragFromOutsideItem : null}
        onDropFromOutside={onDropFromOutside}
        handleDragStart={handleDragStart}
        onSelectEvent={handleEventDetailSelected}
        onSelectSlot={handleNewEventSelected}
        draggableAccessor={(event) =>
          !(
            event.archiving_status === "completed_and_feedback_not_sent" ||
            event.archiving_status === "completed_and_feedback_sent"
          )
        }
        step={60}
        eventPropGetter={(event, start, end, isSelected) => {
          const backgroundColor = event.color;
          const style = {
            backgroundColor: backgroundColor,
            borderRadius: "5px",
            paddingTop: "2px",
            paddingBottom: "2px",
            border: "0px",
            display: "block",
          };
          return {
            style: style,
          };
        }}
        messages={{
          showMore: (total) => (
            <a
              style={{ cursor: "pointer" }}
              onMouseOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {`+${total} mostra ancora`}
            </a>
          ),
        }}
        components={{
          toolbar: (toolbar) => CustomToolbar(toolbar),
          day: { header: MyCustomHeader },
          week: { header: MyCustomHeader },
          month: {
            header: MyCustomHeader,
            dateHeader: dateHeader,
          },
          event: ColoredEventCell,
          eventWrapper: AgendaEventWrapper,
        }}
        localizer={localizer}
      />
    );
  };

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid
              item
              xs={2}
              style={{ position: "sticky", top: 0, height: "100%" }}
            >
              <PatientsEditSidebar
                props={props}
                patId={patId}
                history={history}
                currentPatient={currentPatient}
              />
            </Grid>
            <SpCalendarDragAndDropItems
              activitiesAvailable={activityTypeList}
              handleDragStart={handleDragStart}
            />

            <Grid
              item
              xs={9}
              container
              direction={"column"}
              style={{ minHeight: "750px" }}
            >
              <Grid item xs={11} style={{ minWidth: "100%" }}>
                <div ref={createReference()}>{getDrandAndDropCalendar()}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ paddingLeft: "1%", paddingTop: "1%" }}>
          <Grid item xs={3}></Grid>
          <Grid item xs={7}>
            {pdfFeatureFlag && (
              <>
                <SpButton
                  className="selectGraph"
                  text={labels.analytics.selectPDFGraph}
                  onClick={() => {
                    setFetchDataBe(true);
                  }}
                />
                <PatientsReportsPDF
                  hide={true}
                  reference={createReference()}
                  label={indexPDF}
                  selectedPDF={selectedPDF}
                  setSelectedPDF={setSelectedPDF}
                  snackbarShowErrorMessage={props.snackbarShowErrorMessage}
                ></PatientsReportsPDF>
              </>
            )}
          </Grid>
          <Grid container xs={2} justifyContent="flex-end">
            {pdfFeatureFlag && selectedPDF.length > 0 && (
              <SpButton
                text={labels.analytics.injuryReport.createPdf}
                buttonType="accept"
                onClick={() => {
                  downloadScreenshot(props, setSelectedPDF);
                }}
              />
            )}{" "}
          </Grid>
        </Grid>

        <Grid
          style={{ paddingLeft: "1%" }}
          direction="row"
          container
          spacing={2}
        ></Grid>

        {/*Activity detail*/}
        {eventDetail && (
          <SpDialog
            variant="bigDialog"
            open={openDetailActivity}
            setOpen={setOpenDetailActivity}
            title={
              !eventDetail.is_from_group_plan
                ? labels.mylab.tempAdd.activityPlan.dialog.title
                : labels.mylab.tempAdd.activityPlan.dialog.groupActTitle
            }
          >
            <AgendaCalendarEventCard
              event={eventDetail}
              fetchData={fetchData}
              setOpenDetailActivity={setOpenDetailActivity}
              updateActivity={updateActivity}
              openDetailActivity={openDetailActivity}
              createActivityObject={createActivityObject}
              loadParametersData={loadParametersData}
              loadParametersList={loadParametersList}
              setLoadParametersList={setLoadParametersList}
            />
          </SpDialog>
        )}
        {/*Create activity*/}
        <SpDialog
          variant="bigDialog"
          open={openAddActivity}
          setOpen={setOpenAddActivity}
          onCloseFunctions={resetFields}
          title={labels.mylab.tempAdd.activityPlan.dialog.title}
        >
          <form onSubmit={handleSubmit(createActivity)}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Grid item style={{ maxWidth: "100%" }}>
                <Controller
                  render={(props) => (
                    <SpSelect
                      label={labels.patient.patientPlan.selectPresentation}
                      value={props.value}
                      formControlWidth={"100%"}
                      selectPlaceholder={
                        labels.patient.patientPlan.selectPresentation
                      }
                      onChange={(e) => {
                        props.onChange(e.target.value);
                      }}
                    >
                      {presentations.map((p) => (
                        <SpSelectMenuItem key={p?.id} value={p?.id}>
                          {p?.name}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`id_presentation`}
                  control={control}
                />
              </Grid>
              <Controller
                render={(props) => (
                  <SpSelect
                    label={labels.patient.patientPlan.chooseActivityPlan}
                    style={{ marginTop: "1%" }}
                    value={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      setTemplateHelper(!templateHelper);
                    }}
                  >
                    {activityPlanTemplatesList.map((p) => (
                      <SpSelectMenuItem key={p?.id} value={p?.id}>
                        {p?.template_name}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`id_activity_template`}
                control={control}
              />
              {!getValues("id_activity_template") && (
                <Controller
                  render={(props) => (
                    <SpSelect
                      label={
                        labels.patient.viewPlan.addActivity.inputForm
                          .chooseActivity
                      }
                      style={{ marginTop: "1%" }}
                      value={props.value ?? []}
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        setValue("quantity_training", 0);
                        setValue("quantity_treatment", 0);
                        setValue("quantity_rehabilitation", 0);
                        setValue("quantity_excercise", 0);
                      }}
                    >
                      {activityTypeList.map((p) => (
                        <SpSelectMenuItem key={p?.id} value={p?.id}>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require(`../../assets/activities/${p.key}${
                                props.value === p?.id ? "" : "_black"
                              }.png`)}
                              style={{ maxWidth: 25, maxHeight: 25 }}
                              alt={"img"}
                            />
                            <SpText
                              color={props?.value === p?.id ? "white" : "black"}
                              style={{
                                color: "black !important",
                                marginLeft: "5px",
                              }}
                            >
                              {psTranslate(p?.name)}
                            </SpText>
                          </div>
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`id_activity_type`}
                  control={control}
                />
              )}
              {templatesList.length > 0 && !getValues("id_activity_template") && (
                <Controller
                  render={(props) => (
                    <SpSelect
                      label={
                        labels.patient.viewPlan.addActivity.inputForm
                          .chooseTemplateActivity
                      }
                      style={{ marginTop: "1%" }}
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      {templatesList.map((p) => (
                        <SpSelectMenuItem key={p?.id} value={p?.id}>
                          <SpText
                            color={props.value === p?.id ? "white" : "black"}
                            style={{
                              color: "black !important",
                              marginLeft: "5px",
                            }}
                          >
                            {p?.name}
                          </SpText>
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`id_template`}
                  control={control}
                />
              )}
              {error && (
                <SpText variant="h4ComponentLabelError">{error}</SpText>
              )}
              <div style={{ marginTop: "1%", marginBottom: "1%" }}>
                {renderInputDetails()}
              </div>
              <SpTextArea name="event_note" label="Note" inputRef={register} />
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={6}>
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    text={labels.patient.viewPlan.addActivity.title}
                    disabled={
                      !getValues("id_activity_type") &&
                      !getValues("id_activity_template")
                    }
                    style={{ marginTop: "4%", width: "100%" }}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    text={labels.patient.viewPlan.addActivity.addAndCompile}
                    style={{ marginTop: "4%", width: "100%" }}
                    type="submit"
                    disabled={getValues("id_activity_template")}
                    onClick={async (e) => {
                      e.preventDefault();
                      setLoading(true);
                      const result = await createActivity(getValues());
                      if (result) {
                        const [idEvent, newEventList] = result;
                        const newEvent = newEventList.find(
                          (evnt) => evnt.id === idEvent
                        );
                        if (newEvent) handleEventDetailSelected(newEvent);
                      }
                      setLoading(false);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </SpDialog>
      </div>
    </printContext.Provider>
  );
};

export default withSnackbar(PatientsPlan);
