import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "../../App.css";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import { addPatientToProfessional } from "../../models/actions/Patients";
import { styled } from "../../components/styled";
import { ArrowBack } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { labels } from "../shared/translations";
import { withSnackbar } from "../../components/atoms/SpSnackBar";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const TitleWrapper = styled("div")({
  display: "flex",
  flex: 1,
  width: "40%",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "center",
  padding: "0 !important",
  margin: "0 !important",
  backgroundColor: "#31ccad",
});

const StyledColumn = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
});
const StyledWrapper = styled("div")({
  flex: 1,
  display: "flex",
  width: "40%",
  minHeight: "60%",
  alignSelf: "center",
  padding: "3%",
  background: "transparent",
  border: "1px solid #31ccad",
});

const PatientsAdd = (props) => {
  const { control, register, handleSubmit, reset } = useForm({
    shouldUnregister: false,
  });

  const history = useHistory();

  const submitRequest = async (param) => {
    try {
      const response = await addPatientToProfessional({ ...param });
      //console.log(response);
      if (response.error) {
        props.snackbarShowErrorMessage(response.error);
      } else {
        props.snackbarShowMessage(response.message);
        reset();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <TitleToolbarSection>
          <ArrowBack
            onClick={() => history.push("/patients")}
            style={{
              width: 30,
              color: "#31ccad",
              height: 50,
              cursor: "pointer",
            }}
          />
          <SpText variant="h1">{labels.nav.patients}</SpText>
        </TitleToolbarSection>

        <TitleWrapper>
          <SpText variant="h1PageTitle">
            {labels.patient.patientAdd.title}
          </SpText>
        </TitleWrapper>
        <StyledWrapper>
          <form
            onSubmit={handleSubmit(submitRequest)}
            style={{ width: "100%" }}
          >
            <StyledColumn>
              <SpTextInput
                variant={"column"}
                name={"familyName"}
                inputRef={register}
                style={{ width: "100%" }}
                label={labels.patient.patientAdd.givenName}
                value={control.givenName}
              />
              <SpTextInput
                variant={"column"}
                name={"givenName"}
                inputRef={register}
                label={labels.patient.patientAdd.familyName}
                value={control.surName}
              />
              <SpTextInput
                variant={"column"}
                name={"email"}
                inputRef={register}
                label={labels.patient.patientAdd.email}
                value={control.email}
              />
              <SpButton
                variant={"column"}
                type={"submit"}
                buttonType={"accept"}
                text={labels.patient.patientAdd.addPatient}
              />
            </StyledColumn>
          </form>
        </StyledWrapper>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsAdd);
