import React from "react";
import { labels } from "../../pages/shared/translations";
import { call } from "./utils";

export const sendPromsPresentationAnswer = async (data) =>
  await call({
    url: "/proms/send/presentation/answer",
    data: { data },
  });

export const sendPromsMonitoringAnswer = async (data) =>
  await call({
    url: "/proms/send/monitoring/answer",
    data: { data },
  });

export const getPromsAnswersByPresentation = async (data) =>
  await call({
    url: "/proms/list/by/presentation",
    data: { data },
  });

export const getPromsAnswersByMonitoring = async (data) =>
  await call({
    url: "/proms/list/by/monitoring",
    data: { data },
  });

export const getAllPromsSurveys = async (data) =>
  await call({ url: "/proms/get/surveys/data", data: { data } });

export const getPromsSurveyAnswersByPresentation = async (data) =>
  await call({
    url: "/proms/get/survey/answers/by/presentation",
    data: { data },
  });

export const getPromsSurveyAnswersByMonitoring = async (data) =>
  await call({
    url: "/proms/get/survey/answers/by/monitoring",
    data: { data },
  });

export const getAllPromsSurveyAnswers = async (data) =>
  await call({
    url: "/proms/get/all/survey/answers",
    data: { data },
  });

export const sendPromsToPatient = async (data) =>
  await call({ url: "/proms/send/to/patient", data: { data } });

export const getQuestionnairesAnswered = async (data) =>
  await call({
    url: "/proms/get/questionnaires/answered",
    data: { data },
  });

export const getQuestionnairesAnsweredByPresentation = async (data) =>
  await call({
    url: "/proms/get/questionnaires/answered/by/presentation",
    data: { data },
  });

export const getPromsIndexes = async (data) =>
  await call({
    url: "/proms/get/proms/indexes/by/type",
    data: { data },
  });

export const calculateFinalScore = (proms, promsAnswers) => {
  const _generateObj = ({ label = labels.patient.proms.finalScore, val }) => ({
    label: label,
    value: val ? val.toFixed(2) : 0,
  });

  const _calculatePercentage = (
    numerator,
    denominator,
    divider = 1,
    reverse = false
  ) => {
    const points = ((numerator / denominator) * 100) / divider;
    return reverse ? 100 - points : points;
  };

  const result = [];
  let value = 0;
  switch (proms.key?.toLowerCase()) {
    case "dash":
    case "quick_dash":
      //Total points: [(sum / N answers) - 1] X 25 /100
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value / promsAnswers?.length;
        value = value - 1;
        value = value * 25;
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "vas_u":
    case "vas_w":
    case "mmodified_tqr":
    case "rpe":
      //Total points: sum
      for (let i = 0; i < promsAnswers?.length; i++) {
        value += parseFloat(promsAnswers[i]?.score);
      }
      result.push(_generateObj({ val: value }));
      break;
    case "visa_p":
    case "visa_a":
      //Total points: sum / 100
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value /= 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "koos":
      //Total points: 100 - [(subscale average X 100) \ 4]
      let ss = labels.patient.proms.symptomsAndStiffness;
      let ssVal = 0,
        numSSVal = 0;
      let d = labels.patient.proms.pain;
      let dVal = 0,
        numDVal = 0;
      let aod = labels.patient.proms.dailyActivities;
      let aodVal = 0,
        numAodVal = 0;
      let s = labels.patient.proms.sport;
      let sVal = 0,
        numSVal = 0;
      let qol = labels.patient.proms.qualityOfLife;
      let qolVal = 0,
        numQolVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          const score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();
          if (valueToCheck) {
            if (valueToCheck.charAt(1) == "p") {
              //"sp1" || "sp2" || "sp3" || "sp4" || "sp5"
              numSVal++;
              sVal += score;
            } else {
              switch (valueToCheck.charAt(0)) {
                case "s": //"s1" || "s2" || "s3" || "s4" || "s5" || "s6" || "s7"
                  numSSVal++;
                  ssVal += score;
                  break;
                case "p": //"p1" || "p2" || "p3" || "p4" || "p5" || "p6" || "p7" || "p8" || "p9"
                  numDVal++;
                  dVal += score;
                  break;
                case "a": //"a1" || "a2" || "a3" || "a4" || "a5" || "a6" || "a7" || "a8" || "a9" || "a10" || "a11" || "a12" || "a13" || "a14" || "a15" || "a16" || "a17"
                  numAodVal++;
                  aodVal += score;
                  break;
                case "q": //"q1" || "q2" || "q3" || "q4"
                  numQolVal++;
                  qolVal += score;
                  break;
                default:
              }
            }
          }
        }

        result.push(
          _generateObj({
            label: ss,
            val: _calculatePercentage(ssVal, numSSVal, 4, true),
          })
        );
        result.push(
          _generateObj({
            label: d,
            val: _calculatePercentage(dVal, numDVal, 4, true),
          })
        );
        result.push(
          _generateObj({
            label: aod,
            val: _calculatePercentage(aodVal, numAodVal, 4, true),
          })
        );
        result.push(
          _generateObj({
            label: s,
            val: _calculatePercentage(sVal, numSVal, 4, true),
          })
        );
        result.push(
          _generateObj({
            label: qol,
            val: _calculatePercentage(qolVal, numQolVal, 4, true),
          })
        );
      }
      break;
    case "faam":
      //Points: (sum / subscale) * 100
      const daily = labels.patient.proms.dailyActivities;
      let dailyVal = 0;
      const sport = labels.patient.proms.sport;
      let sportVal = 0;
      if (promsAnswers) {
        const filteredAnswers = promsAnswers?.filter(
          (a) => a?.proms_answer?.score != "N/A"
        );
        for (let i = 0; i < filteredAnswers?.length; i++) {
          const score = parseFloat(filteredAnswers[i]?.proms_answer?.score);

          const valueToCheck =
            filteredAnswers[i]?.proms_answer?.proms_question?.pos.toLowerCase();

          if (valueToCheck) {
            if (parseInt(valueToCheck.substring(5)) > 21) {
              //"faam_22", "faam_23", etc...
              dailyVal += score;
            } else {
              //"faam_1", "faam_2", etc...
              sportVal += score;
            }
          }
        }
        result.push(
          _generateObj({
            label: daily,
            val: _calculatePercentage(dailyVal, 84 /*daily subscale*/),
          })
        );
        result.push(
          _generateObj({
            label: sport,
            val: _calculatePercentage(sportVal, 32 /*sport subscale*/),
          })
        );
      }
      break;
    case "hagos":
      //Symptoms:                               100 - (S1  - S7  X 100) \ 28
      //Pain:                                   100 - (D1  - D10 X 100) \ 40
      //Physical functionality and daily life:  100 - (F1  - F5  x 100) \ 20
      //Functional and sport activities:        100 - (SP1 - SP8 x 100) \ 32
      //Physical activities:                    100 - (AF1 - AF2 x 100) \  8
      //Quality of life:                        100 - (Q1  - Q5  x 100) \ 20
      const ss2 = labels.patient.proms.symptomsAndStiffness;
      let ss2Val = 0;
      const d2 = labels.patient.proms.pain;
      let d2Val = 0;
      const aod2 = labels.patient.proms.physicalFunctionalitiesAndDailyLife;
      let aod2Val = 0;
      const s2 = labels.patient.proms.functionalAndSportActivities;
      let s2Val = 0;
      const pa = labels.patient.proms.physicalActivities;
      let paVal = 0;
      const qol2 = labels.patient.proms.qualityOfLife;
      let qol2Val = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          const score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();

          if (valueToCheck) {
            if (valueToCheck.charAt(1) == "p") {
              //"sp1" || "sp2" || "sp3" || "sp4" || "sp5" || "sp6" || "sp7" || "sp8"
              s2Val += score;
            } else {
              switch (valueToCheck.charAt(0)) {
                case "s": //"s1" || "s2" || "s3" || "s4" || "s5" || "s6" || "s7"
                  ss2Val += score;
                  break;
                case "d": //"d1" || "d2" || "d3" || "d4" || "d5" || "d6" || "d7" || "d8" || "d9" || "d10"
                  d2Val += score;
                  break;
                case "f": //"f1" || "f2" || "f3" || "f4" || "f5"
                  aod2Val += score;
                  break;
                case "a": //"af1" || "af2"
                  paVal += score;
                  break;
                case "q": //"q1" || "q2" || "q3" || "q4" || "q5"
                  qol2Val += score;
                  break;
                default:
              }
            }
          }
        }
        result.push(
          _generateObj({
            label: ss2,
            val: _calculatePercentage(ss2Val, 1, 28, true),
          })
        );
        result.push(
          _generateObj({
            label: d2,
            val: _calculatePercentage(d2Val, 1, 40, true),
          })
        );
        result.push(
          _generateObj({
            label: aod2,
            val: _calculatePercentage(aod2Val, 1, 20, true),
          })
        );
        result.push(
          _generateObj({
            label: s2,
            val: _calculatePercentage(s2Val, 1, 32, true),
          })
        );
        result.push(
          _generateObj({
            label: pa,
            val: _calculatePercentage(paVal, 1, 8, true),
          })
        );
        result.push(
          _generateObj({
            label: qol2,
            val: _calculatePercentage(qol2Val, 1, 20, true),
          })
        );
      }
      break;
    case "ndi":
    case "odi":
      //Total points: ((sum * 2) / (50 all answered OR 45 all answered but one)) / 100
      if (promsAnswers) {
        const maxScore = promsAnswers?.length === 10 ? 50 : 45;
        for (let i = 0; i < promsAnswers?.length; i++) {
          value += parseFloat(promsAnswers[i]?.score);
        }
        value = value * 2;
        value = value / maxScore;
        value = value / 100;
        result.push(_generateObj({ val: value }));
      }
      break;
    case "prwe":
      //Pain points: pain sum
      //Functionality points: func sum / 2
      //Total points: pain + functionality
      const pain = labels.patient.proms.pain;
      let painVal = 0;
      const func = labels.patient.proms.functionality;
      let funcVal = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          const score = parseFloat(promsAnswers[i]?.score);

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();
          if (valueToCheck) {
            if (parseInt(valueToCheck.substring(5)) > 5) {
              //"prwe_6", "prwe_7", etc...
              painVal += score;
            } else {
              //"prwe_1", "prwe_2", etc...
              funcVal += score;
            }
          }
        }
        funcVal /= 2;
        result.push(_generateObj({ label: pain, val: painVal }));
        result.push(_generateObj({ label: func, val: funcVal }));
        result.push(_generateObj({ val: painVal + funcVal }));
      }
      break;
    case "wellness_score":
      //Single totals: sum of single scores
      //Total points: sum of all scores
      let sleepQuality = 0;
      let muscularPain = 0;
      let stressLevel = 0;
      let fatigueLevel = 0;
      if (promsAnswers) {
        for (let i = 0; i < promsAnswers?.length; i++) {
          const wscore = parseFloat(promsAnswers[i]?.score);
          value += wscore;

          const valueToCheck =
            promsAnswers[i]?.proms_question?.pos.toLowerCase();
          switch (valueToCheck) {
            case "wellness_1":
              sleepQuality += wscore;
              break;
            case "wellness_2":
              muscularPain += wscore;
              break;
            case "wellness_3":
              stressLevel += wscore;
              break;
            case "wellness_4":
              fatigueLevel += wscore;
              break;
            default:
          }
        }
      }
      result.push(_generateObj({ val: value }));
      result.push(
        _generateObj({
          label: labels.patient.proms.sleepQuality,
          val: sleepQuality,
        })
      );
      result.push(
        _generateObj({
          label: labels.patient.proms.muscularPain,
          val: muscularPain,
        })
      );
      result.push(
        _generateObj({
          label: labels.patient.proms.stressLevel,
          val: stressLevel,
        })
      );
      result.push(
        _generateObj({
          label: labels.patient.proms.fatigueLevel,
          val: fatigueLevel,
        })
      );
      break;
    default:
  }

  return result;
};

export const getFinalScore = (promsObj, promsAnswers) => {
  promsAnswers = promsAnswers.map((pa) => pa.proms_answer);
  const results = calculateFinalScore(promsObj, promsAnswers);

  return (
    <>
      {results.map((result, idx) => (
        <div key={result.label}>
          {result.label}
          {":"} {result.value}
        </div>
      ))}
    </>
  );
};
