import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import { SpStepper } from "../../components/bundles";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import PatientsMonitoringStep1 from "./patientsMonitoring/PatientsMonitoringStep1";
import PatientsMonitoringStep2 from "./patientsMonitoring/PatientsMonitoringStep2";
import PatientsMonitoringStep3 from "./patientsMonitoring/PatientsMonitoringStep3";
import PatientsMonitoringStepPathologies from "./patientsMonitoring/PatientsMonitoringStepPathologies";
import PatientsMonitoringStep4 from "./patientsMonitoring/PatientsMonitoringStep4";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";

import { Grid } from "@material-ui/core";

const PatientsMonitoring = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState(null);

  useEffect(() => {}, []);
  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { register, reset, errors, control, getValues } = useForm({
    defaultValues: {
      patientName: "",
      birthDate: "",
      phone: "",
      job: "",
    },
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justyfyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const titles = labels.patient.monitoring.steps.map((t) => (
    <StepTitle>{t}</StepTitle>
  ));

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10} container direction="row">
            <Grid item xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.monitoring.title.toUpperCase()}
              </SpText>
            </Grid>
            <Grid item xs={12}>
              <SpStepper
                titles={titles}
                stepperControls={StepperControls(errors)}
                containerWidth={"92%"}
              >
                <PatientsMonitoringStep1
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={setLoading}
                />
                <PatientsMonitoringStep2
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                <PatientsMonitoringStep3
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                <PatientsMonitoringStepPathologies
                  {...props}
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                />
                <PatientsMonitoringStep4
                  register={register}
                  control={control}
                  getValues={getValues}
                  patient={currentPatient}
                  setLoading={props.setLoading}
                />
              </SpStepper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsMonitoring);
