import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getMeasurementsParameters,
  getMeasurementsParametersAnswers,
  getPatientsNames,
  getStudies,
} from "../../../models/actions/Patients";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels } from "../../shared/translations";
import Report from "./patientsReportFeedback/Report";

const EXCLUDED_STUDIES = [
  "MONOTONY",
  "STRAIN",
  "ACWRRA",
  "ACWREWMA",
  "INDEXES",
];

const PATIENTS_LABEL =
  labels.patient.graphReport.section.assessment.filters.patients;

const PatientsReportsStep4Measurements = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [studies, setStudies] = useState([]);
  const [studyParameters, setStudyParameters] = useState([]);
  const [mainPatientKey, setMainPatientKey] = useState("");

  const [additionalStudySubjects, setAdditionalStudySubjects] = useState(null);

  const [fetchedData, setFetchedData] = useState([]);

  const { patId } = useParams();

  //Effects
  useEffect(async () => {
    try {
      //Fetch data
      const promises = [
        getStudies(),
        getMeasurementsParameters(),
        getPatientsNames(),
      ];
      let [studies, studyParams, patients] = await Promise.all(promises);

      //Housekeeping
      const mainPatientKey = patients.find((pat) => pat.id == patId).name ?? "";
      patients = patients.filter((pat) => pat.id != patId);

      //Set params
      studies = studies.filter(
        (study) => !EXCLUDED_STUDIES.includes(study.key)
      );
      setStudies(studies);
      setMainPatientKey(mainPatientKey);
      setStudyParameters(studyParams);
      setAdditionalStudySubjects({ [PATIENTS_LABEL]: patients });
    } catch (error) {
      rollbar.error(
        "PatientsReportsStep4Measurements - fetchDataInitial",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  //Requested new data fetch
  const onNewDataRequest = async (
    _,
    currStudyParameters,
    currAdditionalStudySubjects,
    fetchDateRange
  ) => {
    try {
      if (currStudyParameters.length > 0) {
        //Get single patients data
        const patientIds = new Set();
        patientIds.add(patId);
        currAdditionalStudySubjects[PATIENTS_LABEL]?.forEach((subj) =>
          patientIds.add(subj.id)
        );

        const newFetchedData = await getMeasurementsParametersAnswers({
          id_patients: [...patientIds],
          parameters: currStudyParameters.map((param) => param.id),
          startDate: fetchDateRange.start.format(dateFormat),
          endDate: fetchDateRange.end.format(dateFormat),
        });

        //Fetch data
        setFetchedData(newFetchedData);
      } else setFetchedData([]);
    } catch (error) {
      rollbar.error(
        "PatientsReportsStep4Measurements - onNewDataRequest",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid container item xs={12}>
        <Report
          mainSubjectKey={mainPatientKey}
          studies={studies}
          studyParameters={studyParameters}
          fetchedData={fetchedData}
          activities={null}
          additionalStudySubjects={additionalStudySubjects}
          combinedParams={null}
          defaultZero={false}
          onCombinedParamRequest={() => {}}
          onNewDataRequest={onNewDataRequest}
          config={config}
          setConfig={setConfig}
          requestSaveFlag={requestSaveFlag}
        />
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsReportsStep4Measurements);
