/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import { listGroupsByProfessional } from "../../../models/actions/Groups";
import SpLoader from "../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../models/actions/CalendarActivity";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import moment from "moment";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import {
  getGroupPatients,
  getHeader,
  getPatientsAndGroupsSelection,
} from "./patientsReportInjury/InjuryReportCommonFunction";

import { Controller, useForm } from "react-hook-form";
import { green } from "@material-ui/core/colors";
import { labels, psTranslate } from "../../shared/translations";
import TrainingReport from "./patientsReportInjury/InjuryReportGraph";
import {
  selectGroup,
  selectAllGroups,
  selectGroups,
  selectPatients,
  getPatientSelected,
  groupsDetails,
} from "./patientsReportInjury/ExposureFunction";

const GreenRadio = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const DEFAULT_RANGE = moment.rangeFromInterval("month", -1, moment());
const dateFormat = "YYYY-MM-DD";
const trainingLabel = labels.analytics.injuryReport.graphs.training;
const partitaLabel = labels.analytics.injuryReport.graphs.partita;
const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;
const constPadding = { padding: "5px" };

const PatientsReportsStep5Exposure = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barChar, setBarChar] = useState([]);
  const [dataChart, setDataChart] = useState({});
  const [allGroups, setAllGroups] = useState({});
  const { control, setValue, getValues } = useForm({
    shouldUnregister: false,
  });
  const { patId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isMonthAvarage, setMonthAvarage] = useState(false);
  const formControlStyleCheck = {
    minWidth: 60,
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    justifyContent: "flex-start",
    color: "#31ccad",
    "&:disabled": {
      color: "#31ccad",
    },
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const response = await listGroupsByProfessional();
      const result = getGroupPatients(response, patId);
      setPatients(result.allPatients);
      setGroups(result.mapGroup);
      // get allenamenti per ogni gruppo
      selectExposureActivity(result.mapGroup, dateRange, result.allPatients);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  /**
   * Funzione che restituisce il numero di mesi compreso nel range
   *
   */
  const getNumberOfMonthOnRange = () => {
    return isMonthAvarage
      ? Math.abs(
          moment(dateRange.end).diff(moment(dateRange.start), "months")
        ) + 1
      : 0;
  };

  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param isMonthAvarageValue: boolean utilizzato per settare o meno la funzione di calcolo di media per mese
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    tempPatients = patients
  ) => {
    let temp = [];
    setSelectedGroups([]);
    setLoading(true);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
      });
      let activity = getValues("graficoSelezionato");
      let item = {};
      item["idGruppo"] = tempGroup.id;
      item["nameGruppo"] = tempGroup.name;
      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );
      switch (activity) {
        case labels.analytics.injuryReport.graphs.training: {
          item[activity] = allCalendarActivity.filter(
            (all) => all?.activity_datum?.activity_type?.key === "training"
          );
          break;
        }
        case labels.analytics.injuryReport.graphs.partita: {
          item[activity] = allCalendarActivity.filter(
            (all) => all?.activity_datum?.activity_type?.key === "race"
          );
          break;
        }
        case labels.analytics.injuryReport.graphs.ratioTM: {
          item[labels.analytics.injuryReport.graphs.partita] =
            allCalendarActivity.filter(
              (all) => all?.activity_datum?.activity_type?.key === "race"
            );
          item[labels.analytics.injuryReport.graphs.training] =
            allCalendarActivity.filter(
              (all) => all?.activity_datum?.activity_type?.key === "training"
            );
          break;
        }
        default:
          break;
      }

      temp.push(item);
    }
    setSelectedGroups([]);
    setTrainingGroups(temp);
    setAllGroups(temp);
    setSelectedPatients([]);
    let tempPat = [tempPatients.find(({ id }) => id === parseInt(patId))];
    selectionActionFunction(tempPat, "gruppo", [], mapGroup, temp);
    setLoading(false);
  };

  /**
   * Funzione che crea il grafico con tutti i gruppi disponibili
   *
   * @param groups: lista di gruppi da visualizzare
   * @param monthAvarage: numero di mesi selezionati
   *
   */
  const selectAllGroupsFunction = (groups, monthAvarage) => {
    let result = selectAllGroups(
      groups,
      getValues("graficoSelezionato"),
      monthAvarage
    );
    setDataChart(result.dataChart);
    setBarChar(result.barChart);
    setLoading(false);
  };

  /**
   * Funzione che crea il grafico con i gruppi selezionati
   *
   * @param groups: lista di gruppi da visualizzare
   *
   */
  const injuryGroupSelectedFunction = (gruppi) => {
    const action = getValues("graficoSelezionato");
    // compongo il grafico rispetto al tipo selezionato
    setLoading(true);
    let res = selectGroup(
      gruppi,
      trainingGroups,
      action,
      getNumberOfMonthOnRange()
    );
    setBarChar(res.barChart);
    setDataChart(res.dataChart);
    setLoading(false);
  };

  /**
   * Funzione che in base ai gruppi selezionati crea il grafico
   *
   * @param tempSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectGroupFunction = (tempSelectedGroups) => {
    const action = getValues("graficoSelezionato");
    setSelectedGroups(tempSelectedGroups);
    if (tempSelectedGroups?.length === 0) {
      // creo il grafico con tutti i gruppi
      selectAllGroupsFunction(allGroups, getNumberOfMonthOnRange());
    } else if (tempSelectedGroups?.length === 1) {
      // se vengono selezionato un solo gruppo
      injuryGroupSelectedFunction(tempSelectedGroups);
    } else {
      // vengono selezionati piu' gruppi
      setLoading(true);
      let retValue = selectGroups(tempSelectedGroups, trainingGroups, action);
      setDataChart(retValue.dataChart);
      setBarChar(retValue.barChart);
      setSelectedPatients(retValue.selectedPatients);
      setLoading(false);
    }
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   *
   */
  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups,
    tempGroup = groups,
    tempTrainingGroups = trainingGroups
  ) => {
    const action = getValues("graficoSelezionato");
    setSelectedGroups(newSelectedGroups);
    // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
    let newPatientsSelected = getPatientSelected(
      patients,
      type,
      selectedPatients
    );

    let result;
    // per ogni gruppo calcolo la media delle attivita'
    let groupsAvarage = groupsDetails(
      newSelectedGroups,
      tempTrainingGroups,
      action,
      getNumberOfMonthOnRange()
    );

    // calcolo delle attivita' per ogni paziente selezionato
    result = selectPatients(
      newPatientsSelected,
      tempTrainingGroups,
      action,
      dateRange,
      tempGroup,
      newSelectedGroups,
      getNumberOfMonthOnRange()
    );
    // concateno il risultato dei gruppi con quello dei pazienti
    result.dataChart = result.dataChart.concat(groupsAvarage);
    setLoading(true);
    setDataChart(result.dataChart);
    setSelectedPatients(newPatientsSelected);
    setBarChar(result.barChart);
    setLoading(false);

    if (newPatientsSelected.length === 0) {
      if (newSelectedGroups.length > 0) {
        selectGroupFunction(newSelectedGroups);
      } else {
        selectAllGroupsFunction(allGroups, isMonthAvarage);
      }
    }
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    let temp = newDateRange;
    if (temp.start < newDateRange.end) {
      setSelectedGroups([]);
      selectExposureActivity(groups, newDateRange);
      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  /**
   * Funzione che viene richiamata quando viene abilitata o disabiltiata la media per mese
   * delle attivita'
   *
   * @param monthAvarageValue: boolean per abilitare o meno la media
   *
   */
  const monthAvarageFunction = (monthAvarageValue) => {
    setMonthAvarage(monthAvarageValue);
    setSelectedGroups([]);
    setSelectedPatients([]);
    selectExposureActivity(groups, dateRange);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid container item xs={6}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
        {/* checkbox per abilitare o meno la media per mese delle attivita' */}
        <Grid item xs={6} style={constPadding}>
          <SpCheckbox
            label={labels.analytics.injuryReport.monthAvarage}
            customFromLabelStyle={{ marginBottom: 0 }}
            rightlabel={true}
            formControlStyle={{
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            checked={isMonthAvarage}
            onChange={(_, value) => monthAvarageFunction(value)}
            // disabled={!enabled}
          />
        </Grid>
      </Grid>
      {/* selezione dei pazienti e dei gruppi  */}
      {getPatientsAndGroupsSelection(
        patients,
        selectedPatients,
        selectionActionFunction,
        groups,
        selectGroupFunction,
        selectedGroups,
        labels.analytics.injuryReport.totalGroup
      )}
      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={9}
          style={{ paddingTop: "25px", paddingRight: "25px" }}
        >
          {/* grafico finale */}
          <TrainingReport dataChart={dataChart} barChar={barChar} />
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.analytics.injuryReport.selectGraph}
            </SpText>
          </Grid>
          {/*  radio group per selezionare l'attivita' */}
          <Controller
            as={
              <RadioGroup
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={trainingLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={trainingLabel}
                />

                <FormControlLabel
                  style={formControlStyleCheck}
                  value={partitaLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={partitaLabel}
                />
                <FormControlLabel
                  style={formControlStyleCheck}
                  value={ratioTMLabel}
                  disabled={props?.endDate}
                  control={<GreenRadio />}
                  onChange={() => {
                    selectExposureActivity(groups, dateRange);
                  }}
                  label={ratioTMLabel}
                />
              </RadioGroup>
            }
            name={`graficoSelezionato`}
            control={control}
            defaultValue={trainingLabel}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
