import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dateFormat } from "../../../../utils/common";
import { labels, psTranslate } from "../../../shared/translations";
import { getMomentIdFromKey } from "../ReportHelperFns";

const RADAR_KEY = "RADAR";
const HISTOGRAM_KEY = "Histogram";

const BAR_KEY = "bar";

const axisFormat = "DD-MM";

const colorArray = [
  "#30a2ff",
  "#de32a1",
  "#075cfe",
  "#8B9556",
  "#ff5024",
  "#8941ff",
  "#31cccf",
  "#070cfe",
  "#813556",
  "#aa0014",
  "#894b11",
  "#31cc12",
  "#aabb54",
  "#1041ff",
  "#31cccf",
  "#1dffae",
  "#56ffae",
  "#56ffee",
  "#232377",
  "#78ff",
  "#552377",
];

const ReportGraph = ({
  dateRange,
  graphDateView,
  graphType,
  studies,
  currStudyParameters,
  defaultZero,
  data,
}) => {
  const [graphDataLinear, setGraphDataLinear] = useState([]);
  const [graphDataRadar, setGraphDataRadar] = useState([]);

  const [legendData, setLegendData] = useState([]); //[{ legend: legend, yAxisId: axisY }]
  const [yAxisLabels, setYAxisLabels] = useState([]); //["axisYname"]

  const adaptLinearGraph = () => {
    //Source format { study_key: { study_curve_name: { subject_name: { parameter_id: { "YYYY-MM-DD": datapoint, ... }, ... }, ... }, ... }, ... }
    //Recharts format [{ xAxis: "YYYY-MM-DD", study_curve_name_subject_name_parameter_merged: datapoint }}]

    //Flatten study_key, subject_name and parameter_id
    const legendData = [];
    const flattenedData = {};
    const measureUnits = new Set();
    //Iterate studies
    Object.entries(data).forEach(([studyKey, studyData]) => {
      const currStudy = studies.find((study) => study.key === studyKey);
      //Iterate studies curves
      Object.entries(studyData).forEach(([curveName, curveData]) => {
        //Iterate subjects
        Object.entries(curveData).forEach(([subjectName, subjectData]) => {
          //Iterate parameters
          Object.entries(subjectData).forEach(([key, val]) => {
            const currParam = currStudyParameters.find(
              (param) => param.id === parseInt(key)
            );
            const currName = currParam?.name ?? key;
            const units = currStudy.um ?? currParam?.um ?? "units";

            //Flatten data
            const currStudyName =
              labels.patient.graphReport.section.feedback.studies[
                currStudy.key
              ];
            const flattenedKey = `${subjectName} - ${psTranslate(
              currName
            )} (${units}) - ${currStudyName}${
              curveName ? ` (${curveName})` : ""
            }`;
            flattenedData[flattenedKey] = val;
            //Prepare legend
            legendData.push({
              legend: flattenedKey,
              yAxisId: units,
              type: curveName === HISTOGRAM_KEY ? BAR_KEY : null,
            });
            measureUnits.add(units);
          });
        });
      });
    });

    //Prevent more than 2 measure units
    if (measureUnits.size >= 3) return [];

    setLegendData(legendData);
    setYAxisLabels([...measureUnits].sort((a, b) => b.localeCompare(a)));

    //Convert flattened data into recharts format
    const graphData = [];
    for (const day of dateRange.reverseBy(
      getMomentIdFromKey(graphDateView?.key)
    )) {
      const dayKey = day.format(dateFormat);
      const obj = { xAxis: day.format(axisFormat) };
      Object.entries(flattenedData).forEach(([key, val]) => {
        if (val[dayKey] != null) obj[key] = val[dayKey];
      });
      graphData.push(obj);
    }
    return graphData.reverse();
  };

  const adaptRadarGraph = () => {
    //Source format { study_key: { study_curve_name: { subject_name: { parameter_id: datapoint, ... }, ... }, ... }, ... }
    //Recharts format [{ xAxis: "paramName", study_curve_name_subject_name_parameter_merged: datapoint }}]

    //Flatten study_key and subject_name
    const legendData = [];
    const flattenedData = {};
    //Iterate studies
    Object.entries(data).forEach(([studyKey, studyData]) => {
      const currStudy = studies.find((param) => param.key === studyKey);
      //Iterate studies curves
      Object.entries(studyData).forEach(([curveName, curveData]) => {
        //Iterate subjects
        Object.entries(curveData).forEach(([subjectName, subjectData]) => {
          const flattenedKey = `${subjectName} - ${psTranslate(
            currStudy.name
          )}${curveName ? ` (${curveName})` : ""}`;

          //Prepare legend
          legendData.push({
            legend: flattenedKey,
          });

          //Iterate parameters
          Object.entries(subjectData).forEach(([key, val]) => {
            const currParam = currStudyParameters.find(
              (param) => param.id == key
            );
            const currName = currParam?.name ?? key;

            //Flatten data
            if (!flattenedData[currName]) flattenedData[currName] = {};
            flattenedData[currName][flattenedKey] = val;
          });
        });
      });
    });
    setLegendData(legendData);

    //Convert flattened data into recharts format
    const graphData = Object.entries(flattenedData).map(([key, elem]) => ({
      xAxis: key,
      ...elem,
    }));
    return graphData;
  };

  useEffect(() => {
    if (graphType?.key === RADAR_KEY) {
      const graphData = adaptRadarGraph();
      setGraphDataLinear([]);
      setGraphDataRadar(graphData);
    } else {
      const graphData = adaptLinearGraph();
      setGraphDataRadar([]);
      setGraphDataLinear(graphData);
    }
  }, [data, graphType]);

  const CustomizedDot = ({ cx, cy, stroke, value, r, fill }) => {
    if (!value || (defaultZero && value === 0)) return null;
    else
      return (
        <circle
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={3}
          fill={fill}
        />
      );
  };

  return (
    <Grid item xs={12} style={{ paddingTop: "25px", paddingRight: "25px" }}>
      <ResponsiveContainer width="100%" height={500}>
        {graphType?.key === RADAR_KEY ? (
          <RadarChart data={graphDataRadar} key={"RADAR"}>
            <PolarGrid />
            <PolarAngleAxis dataKey="xAxis" stroke="white" />
            <PolarRadiusAxis angle={0} />
            <Tooltip
              contentStyle={{ backgroundColor: "#FFFFFFDF" }}
              wrapperStyle={{ zIndex: 99 }}
              formatter={(value, name) => [
                parseFloat(value).toFixed(2).replace("-", "\u2014"),
                name,
              ]}
            />
            {legendData.map(({ legend }, idx) => (
              <Radar
                key={legend}
                name={legend}
                dataKey={legend}
                stroke={colorArray[idx % colorArray.length]}
                fill={colorArray[idx % colorArray.length]}
                fillOpacity={0.6}
                isAnimationActive={false}
              />
            ))}
            <Legend height={100} />
          </RadarChart>
        ) : (
          <ComposedChart
            style={{ color: "white !important" }}
            data={graphDataLinear}
            key={"LINEAR"}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="xAxis" stroke="gray" />
            {yAxisLabels.length > 0 && (
              <YAxis
                yAxisId={yAxisLabels[0]}
                stroke="gray"
                width={50}
                label={{
                  value: yAxisLabels[0],
                  position: "insideLeft",
                  stroke: "gray",
                  angle: yAxisLabels[0].length <= 1 ? 0 : -90,
                }}
                type="number"
              />
            )}
            {yAxisLabels.length > 1 && (
              <YAxis
                yAxisId={yAxisLabels[1]}
                stroke="gray"
                width={50}
                label={{
                  value: yAxisLabels[1],
                  position: "insideRight",
                  stroke: "gray",
                  angle: yAxisLabels[1].length <= 1 ? 0 : 90,
                }}
                orientation="right"
                type="number"
              />
            )}
            <Tooltip
              contentStyle={{ backgroundColor: "#FFFFFFDF" }}
              wrapperStyle={{ zIndex: 99 }}
              formatter={(value, name) => [
                parseFloat(value).toFixed(2).replace("-", "\u2014"),
                name,
              ]}
            />
            <Legend height={100} />
            {legendData.map(({ legend, yAxisId, type }, idx) => {
              return type !== BAR_KEY ? (
                <Line
                  key={`${legend}_bar`}
                  type="monotone"
                  yAxisId={yAxisId}
                  strokeWidth={3}
                  dataKey={legend}
                  stroke={colorArray[idx % colorArray.length]}
                  activeDot={{ r: 8 }}
                  dot={<CustomizedDot />}
                  isAnimationActive={false}
                  connectNulls={true}
                />
              ) : (
                <Bar
                  key={`${legend}_line`}
                  type="monotone"
                  yAxisId={yAxisId}
                  dataKey={legend}
                  barSize={20}
                  fill={colorArray[idx % colorArray.length]}
                />
              );
            })}
          </ComposedChart>
        )}
      </ResponsiveContainer>
    </Grid>
  );
};

export default ReportGraph;
