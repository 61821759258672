import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";
import PatientsReportsConfigurable from "./patientsReports/PatientsReportsConfigurable";

const PatientsReports = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const history = useHistory();

  useEffect(async () => {
    const responseDetail = await findPatientByPk({ userId: patId });
    setCurrentPatientDetail(responseDetail);
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10}>
            <PatientsReportsConfigurable />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsReports);
