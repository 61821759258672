import {
  labels as defaultLabels,
  translations as defaultTranslations,
} from "./translations/it";

let currTranslations = defaultTranslations;
let currLabels = defaultLabels;

export const setLanguage = (langCode) => {
  localStorage.setItem("lang", langCode);

  try {
    ({
      labels: currLabels,
      translations: currTranslations,
    } = require(`./translations/${langCode}`));
  } catch (e) {
    //Catch non-existing language loads
    currTranslations = defaultTranslations;
    currLabels = defaultLabels;
  }
};

export const getLanguage = () =>
  localStorage.getItem("lang") ??
  (navigator.language ? navigator.language.slice(0, 2) : "it");

export const labels = new Proxy(
  {},
  {
    get: function (target, name) {
      if (currLabels[name]) return currLabels[name];
      else {
        console.warn(`No translation for label ${name} in current language`);
        return defaultLabels[name];
      }
    },
  }
);

export const psTranslate = (translationKey) => {
  if (currTranslations && translationKey) {
    if (currTranslations[translationKey]) {
      return currTranslations[translationKey];
    } else {
      console.warn(
        `No translation for key ${translationKey} in current language`
      );
    }
  } // else default language, no translation

  return translationKey ?? "";
};

setLanguage(getLanguage());
