import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SpLoadingButton from "../atoms/SpLoadingButton";
import axios from "axios";
import { labels } from "../../pages/shared/translations";

const setAxiosTokenInterceptor = async (accessToken, idToken) => {
  axios.interceptors.request.use(async (config) => {
    const requestConfig = config;
    if (accessToken && idToken && !requestConfig.url.includes('flagsmith')) {
      (requestConfig.headers.common.Authorization = `Bearer ${accessToken}`),
        (requestConfig.headers.common["Id-Token"] = idToken);
    }
    return requestConfig;
  });
};

const PrivateRouteAuth0 = ({ children, ...rest }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [userTokens, setUserTokens] = useState(null);

  const getLoadingButton = () => {
    return (
      <SpLoadingButton
        loading={isLoading}
        style={{ marginTop: "3%", marginRight: "0 !important" }}
        text={labels.login.buttons.login}
        buttonType={"accept"}
        variant="none"
        type="submit"
      />
    );
  };

  useEffect(async () => {
    if (!isLoading && isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      setUserTokens({ accessToken, idToken: idToken?.__raw });
    }
  }, [isLoading, isAuthenticated]);

  useEffect(async () => {
    if (userTokens) {
      await setAxiosTokenInterceptor(
        userTokens.accessToken,
        userTokens.idToken
      );
      setIsTokenSet(true);
    }
  }, [userTokens]);

  if (!isLoading) {
    if (isAuthenticated) {
      if (isTokenSet) {
        return <Route {...rest}>{children}</Route>;
      } else {
        return getLoadingButton();
      }
    } else {
      return <Redirect to={{ pathname: "/login-auth0" }} />;
    }
  } else {
    return getLoadingButton();
  }
};
export default PrivateRouteAuth0;
