import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SpText from "../atoms/SpText";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#333333",
    width: "15%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

//rendering the profile editing form
const ListRows = ({ row, selectTerm }) => {
  return (
    <SpText
      onClick={() => {
        selectTerm(row.id);
      }}
      variant="h5"
    >
      {row.word}
    </SpText>
  );
};

const SpDrawer = ({ rows, selectTerm }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {rows.map((row, index) => (
          <ListItem button key={index}>
            <ListRows row={row} selectTerm={selectTerm} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SpDrawer;
