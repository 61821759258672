import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../../shared/translations";
import { getMomentIdFromKey } from "../../../ReportHelperFns";
import StudySharedControls from "../SharedControls";
import {
  linearValuesNumber,
  radarValuesNumber,
  updateZScoreData,
} from "./ZScoreHelpers";

const RADAR_KEY = "RADAR";
const SUBJECT_KEY = "ZSCORE_SUBJECT_VALUES";

const MIN_STUDY_VALUES = 1;
const MAX_STUDY_VALUES = 999;

const DEFAULT_DAYS_BEFORE = 7;

const StudyZScoreValuesNumber = ({
  mainSubjectKey,
  study,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onStudyDataChange,
  onFetchDateDaysChange,
  zScoreActiveAndEnabled,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  zScoreType,
  zScoreSpecificKey,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [valuesBefore, setValuesBefore] = useState(DEFAULT_DAYS_BEFORE);

  const updateFilterData = (by) => {
    return updateZScoreData(
      data,
      defaultZero,
      dateRange,
      valuesBefore,
      by,
      zScoreType === SUBJECT_KEY,
      mainSubjectKey,
      graphType?.key === RADAR_KEY ? radarValuesNumber : linearValuesNumber
    );
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && zScoreActiveAndEnabled) {
      newData = {
        [study.key]: {
          [zScoreSpecificKey]: updateFilterData(
            getMomentIdFromKey(graphDateView?.key)
          ),
        },
      };
    } else {
      newData = { [study.key]: { [zScoreSpecificKey]: {} } };
    }

    onStudyDataChange(newData);
  }, [
    zScoreActiveAndEnabled,
    active,
    data,
    dateRange,
    graphDateView,
    valuesBefore,
    graphType,
  ]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
      setValuesBefore(config.valuesBefore);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        valuesBefore: valuesBefore,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[zScoreType]}
      active={active}
      setActive={setActive}
      enabled={zScoreActiveAndEnabled}
      hide={!zScoreActiveAndEnabled}
    >
      <SpTextInput
        label={
          labels.patient.graphReport.section.feedback.filters.previous_values
        }
        value={valuesBefore}
        onChange={(evnt) => {
          const value = evnt.target.value;
          if (value >= MIN_STUDY_VALUES && value <= MAX_STUDY_VALUES)
            setValuesBefore(value);
        }}
        type="number"
      />
    </StudySharedControls>
  );
};

export default StudyZScoreValuesNumber;
