import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { getThirdTableGroupsData } from "../../../models/actions/Groups";
import { dateFormat } from "../../../utils/common";
import { labels } from "../../shared/translations";
import DisponibilityTable from "./DisponibilityTable";

const moment = extendMoment(Moment);

const DEFAULT_RANGE = moment.rangeFromInterval("month", -1, moment());
const constPadding = { padding: "5px" };

const Disponibility = ({ isPrint }) => {
  const [notAvailableObj, setNotAvailableObj] = useState({});
  const [filterRange1, setFilterRange1] = useState(DEFAULT_RANGE);
  const [filterRange2, setFilterRange2] = useState(DEFAULT_RANGE);

  const { groupId } = useParams();

  useEffect(async () => {
    const thirdTableData = await getThirdTableGroupsData({
      id_group: groupId,
      filterStartDate1: filterRange1.start,
      filterEndDate1: filterRange1.end,
      filterStartDate2: filterRange2.start,
      filterEndDate2: filterRange2.end,
    });

    setNotAvailableObj(thirdTableData ?? []);
  }, [filterRange1, filterRange2]);

  return (
    <Grid
      xs={12}
      item
      container
      style={{ marginLeft: "1%", alignSelf: "center" }}
      direction={"column"}
      spacing={2}
    >
      <Grid item xs={12} container>
        <SpText
          variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}
          style={{ marginTop: "1%", marginBottom: "1%" }}
        >
          {labels.groups.groupReports.thirdTable.title}
        </SpText>
      </Grid>

      <Grid item xs={12} container>
        <Grid item lg={2} xs={4} style={constPadding}>
          <SpTextInput
            type="date"
            style={{ width: "100%" }}
            value={filterRange1.start.format(dateFormat)}
            onChange={(evnt) =>
              moment(evnt.target.value).isBefore(filterRange1.end) &&
              setFilterRange1(moment.range(evnt.target.value, filterRange1.end))
            }
            label={labels.groups.groupReports.thirdTable.startDateFirstPeriod}
          />
        </Grid>
        <Grid item lg={2} xs={4} style={constPadding}>
          <SpTextInput
            type="date"
            style={{ width: "100%" }}
            value={filterRange1.end.format(dateFormat)}
            onChange={(evnt) =>
              filterRange1.start.isBefore(moment(evnt.target.value)) &&
              setFilterRange1(
                moment.range(filterRange1.start, evnt.target.value)
              )
            }
            label={labels.groups.groupReports.thirdTable.endDateFirstPeriod}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        <Grid item lg={2} xs={4} style={constPadding}>
          <SpTextInput
            type="date"
            style={{ width: "100%" }}
            value={filterRange2.start.format(dateFormat)}
            onChange={(evnt) =>
              moment(evnt.target.value).isBefore(filterRange2.end) &&
              setFilterRange2(moment.range(evnt.target.value, filterRange2.end))
            }
            label={labels.groups.groupReports.thirdTable.startDateSecondPeriod}
          />
        </Grid>
        <Grid item lg={2} xs={4} style={constPadding}>
          <SpTextInput
            type="date"
            style={{ width: "100%" }}
            value={filterRange2.end.format(dateFormat)}
            onChange={(evnt) =>
              filterRange2.start.isBefore(moment(evnt.target.value)) &&
              setFilterRange2(
                moment.range(filterRange2.start, evnt.target.value)
              )
            }
            label={labels.groups.groupReports.thirdTable.endDateSecondPeriod}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        <DisponibilityTable data={notAvailableObj} isPrint={isPrint} />
      </Grid>
    </Grid>
  );
};

export default Disponibility;
