import React from "react";
import HumanWorkerProxy from "./Main/HumanWorkerProxy";

let worker = null;

export default class Human3D extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.managerCallbackRefs = [];

    this._onMessage = this._onMessage.bind(this);
    this._validateProps = this._validateProps.bind(this);

    this._canvasRef = React.createRef();
  }

  componentDidMount() {
    if (!worker) {
      //Create worker canvas out of React's control
      const workerCanvas = document.createElement("canvas");
      workerCanvas.id = "_human3d_canvas";
      workerCanvas.style.position = "fixed";
      workerCanvas.style.visibility = "hidden";
      document.body.appendChild(workerCanvas);

      //Prepare worker
      worker = new HumanWorkerProxy(workerCanvas);
      worker.sendMessage("init", {
        assetsRootUrl: this.props.assetsRootUrl ?? window.location.origin,
        props: (({ humanOptions, zoomedRegion, markers, behaviourType }) => ({
          humanOptions,
          zoomedRegion,
          markers,
          behaviourType,
        }))(this.props),
        callbacks: {
          informativeCallbacks: Object.keys(this.props.informativeCallbacks),
          markerCallbacks: Object.keys(this.props.markerCallbacks),
        },
      });
    }

    //Set this canvas as active input canvas
    worker.setViewCanvas(this._canvasRef.current, this._onMessage);

    //Ensure a props check
    this._validateProps(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    this._validateProps(nextProps);

    //Trigger data update
    worker.sendMessage(
      "update",
      (({ humanOptions, zoomedRegion, markers, behaviourType }) => ({
        humanOptions,
        zoomedRegion,
        markers,
        behaviourType,
      }))(nextProps)
    );
    return false; //Always return false, we never need react render update
  }

  componentWillUnmount() {
    worker.unsetViewCanvas();
  }

  render() {
    return (
      <div className="human3d-canvas">
        <canvas
          ref={this._canvasRef}
          style={{ width: "100%", height: "100%", outline: "none" }}
        ></canvas>
      </div>
    );
  }

  _validateProps(props) {
    if (
      !props.behaviourType ||
      (props.behaviourType !== "INFORMATIVE" &&
        props.behaviourType !== "MARKER")
    )
      throw new Error("Behaviour type must be one of INFORMATIVE or MARKER.");
    if (typeof props.zoomedRegion === "undefined")
      throw new Error("Zoomed region must be set to a region or null.");

    if (!props.informativeCallbacks || !props.markerCallbacks)
      throw new Error(
        "Informative & marker callbacks must be set in Human3D props."
      );

    if (!props.markers && !Array.isArray(props.markers))
      throw new Error(
        "Markers array must be an array and always be set (set to [] if not used)."
      );

    if (
      !props.informativeCallbacks.onEnter ||
      !props.informativeCallbacks.onExit ||
      !props.informativeCallbacks.onRegionClick ||
      !props.informativeCallbacks.onAreaClick
    )
      throw new Error(
        "All informative callbacks (onEnter, onExit, onRegionClick, onAreaClick) must be set in Human3D props."
      );

    if (
      !props.markerCallbacks.onEnter ||
      !props.markerCallbacks.onExit ||
      !props.markerCallbacks.onRegionMarkerClick ||
      !props.markerCallbacks.onRegionZoomClick ||
      !props.markerCallbacks.onAreaMarkerClick ||
      !props.markerCallbacks.onAreaZoomClick ||
      !props.markerCallbacks.onStructureMarkerClick
    )
      throw new Error(
        "All marker callbacks (onEnter, onExit, onRegionMarkerClick, onRegionZoomClick, onAreaMarkerClick, onAreaZoomClick, onStructureMarkerClick) must be set in Human3D props."
      );
  }

  _onMessage(names, data) {
    let fn;
    try {
      //Call corresponding props function
      fn = this.props[names[0]];
      for (let i = 1; i < names.length; i++) fn = fn[names[i]];
    } catch (err) {}

    if (fn) fn(data);
    else console.warn(`Required props method ${names.join(".")} not found.`);
  }
}
