import React from "react";
import Button from "@material-ui/core/Button";
import { styled } from "../styled";
import { CircularProgress, Fade } from "@material-ui/core";

const ButtonSubmit = styled(Button)((buttonType) => ({
  textAlign: "center",
  maxHeight: "30px",
  minWidth: "180px",
  borderRadius: 0,
  color: "white",
  borderColor: "#31ccad",
  backgroundColor: "#40173b",

  /*Angle shapes for the buttons*/
  border: "1px solid",
  "& .MuiButton-label": {
    fontFamily: "Montserrat, sans-serif !important",
    fontWeight: "bold",
  },
}));
const StyledCircularProgress = styled(CircularProgress)({
  width: 20,
  color: "#31ccad",
});

const variants = {
  column: {
    marginTop: 3,
  },
  row: {
    marginRight: "3%",
  },
  listButton: {
    minWidth: "100%",
  },
};

const SpLoadingButton = ({
  text,
  type,
  loading,
  onClick,
  buttonType,
  variant,
  disabled,
  style,
}) => {
  return !loading ? (
    <ButtonSubmit
      disabled={disabled}
      type={type}
      style={{
        ...style,
        ...variants[variant],
        marginRight: !variant && "3%",
        backgroundColor: buttonType == "accept" && "#31ccad",
      }}
      onClick={onClick}
      color="primary"
      disableElevation
    >
      {text}
    </ButtonSubmit>
  ) : (
    <Fade
      in={loading}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        transitionDelay: loading ? "800ms" : "0ms",
      }}
      unmountOnExit
    >
      <StyledCircularProgress />
    </Fade>
  );
};

export default SpLoadingButton;
