import amplitude from "amplitude-js";
import axios from "axios";
import { psTranslate } from "../pages/shared/translations";
import moment from "moment";
import ReactPDF, {
  Document,
  Page,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import React from "react";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Object used in moment to define that the month and the year have to start from monday
 * @type {{week: {dow: number, doy: number}}}
 */
export const momentDefinition = {
  week: {
    dow: 1,
    doy: 1,
  },
};

/**
 * @param array list of items to sort and translate
 * @param param optional param to search in the object
 * @param sorting ASC|DESC
 * @returns {*} array sorted and translated in the "param" object parameter
 */
export const getSortedAndTranslatedArray = (
  array,
  param = "name",
  sorting = "ASC"
) => {
  let res;
  //If the database content language is the same of the client, return just the sorted list
  //To check if the language of the content is the same as that of the client, we have to take one content of the array and see if it is equal to its translation
  let sameLanguage = false; //We save in this variable if the client has the same language of the database contents (fallback language = "IT")
  if (array.length > 0) {
    const item = array[0];
    sameLanguage = param
      ? item[param] === psTranslate(item[param])
      : item === psTranslate(item);
  }

  if (sameLanguage) {
    res = [
      ...array?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      ),
    ];
  } else {
    res = array
      ?.map((x) => {
        param ? (x[param] = psTranslate(x[param])) : (x = psTranslate(x));
        return x;
      })
      ?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      );
  }
  //The reverse() function can be replaced directly by the opposite compare() within the sort function
  return sorting === "DESC" ? res.reverse() : res;
};

export const isBusiness = () => {
  return window.location.hostname.includes("business");
};

export const barColors = {
  rosso: "#dd7876",
  verde: "#82ca9d",
  viola: "#8884d8",
  azzurro: "#76a8dd",
  arancione: "#ffcc00",
  marrone: "#663300",
};

export const barWithDimension = (nCols) => {
  const avarageWidth = 250; // media della grandezza del grafico
  const minCols = 6; // numero minimo di colonne per la grandezza impostata
  const maxCols = 10; // numero massimo di colonne per la grandezza impostata
  const max = avarageWidth / minCols; // massima dimensione calcolata per colonne
  const min = avarageWidth / maxCols; // minima dimensione calcolata per colonne
  let res = avarageWidth / nCols;
  if (res < max && res > min) return res;
  if (res < min) return min;
  if (res > max) return max;
};

const getWeekRange = (weeks, firstDay, endDay, calendar) => {
  for (let index = 0; index < weeks.length; index++) {
    let weeknumber = weeks[index];

    let firstWeekDay =
      index === 0
        ? moment(firstDay).week(weeknumber).day(0)
        : moment(firstDay).week(weeknumber).day(1);
    if (firstWeekDay.isBefore(firstDay)) {
      firstWeekDay = firstDay;
    }

    let lastWeekDay = moment(endDay).week(weeknumber).day(7);
    if (lastWeekDay.isAfter(endDay)) {
      lastWeekDay = endDay;
    }
    let weekRange = moment.range(firstWeekDay, lastWeekDay);
    calendar.push(weekRange);
  }
  return calendar;
};

export const getCalendar = (date, dateRange) => {
  let calendar = [];

  let year = date?.year();
  let month = date?.month();
  let startDate = moment([year, month]);
  let firstDay = dateRange?.start;
  let endDay = moment(dateRange?.start).endOf("month");

  let monthRange = moment?.range(firstDay, endDay);
  let weeks = [];
  for (let mday of monthRange?.by("days")) {
    if (weeks.indexOf(mday.week()) === -1) {
      weeks.push(mday.week());
    }
  }

  calendar = getWeekRange(weeks, firstDay, endDay, calendar);
  // se il range di date scelto e' in due mesi diversi popolo l'array delle
  // settimane per i diversi mesi
  if (dateRange.start.month() !== dateRange.end.month()) {
    month = dateRange.end.month();
    startDate = moment([year, month]);
    firstDay = moment(dateRange.end).startOf("month");
    endDay = dateRange.end;

    monthRange = moment.range(firstDay, endDay);
    weeks = [];
    for (let mday of monthRange.by("days")) {
      if (weeks.indexOf(mday.week()) === -1) {
        weeks.push(mday.week());
      }
    }
    calendar = getWeekRange(weeks, firstDay, endDay, calendar);
  }
  return calendar;
};

export const isFeatureFlagEnabled = async (nameFeature) => {
  const result = await axios.get("https://api.flagsmith.com/api/v1/flags/", {
    headers: { "X-Environment-Key": process.env.REACT_APP_FLAGSMITH },
  });
  const flag = result.data.filter((el) => el?.feature.name === nameFeature);
  return flag[0]?.enabled;
};

//ROLLBAR UTILS
export const setRollbarUser = (rollbarUserId, rollbarUserEmail) => {
  Rollbar.configure({
    // logLevel: "info",
    payload: {
      person: {
        id: rollbarUserId, // required
        username: rollbarUserEmail,
        email: rollbarUserEmail,
      },
    },
  });
};

export const rollbar = {
  info: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.info(component + " - " + msg)
      : Rollbar.info(component + " - " + msg);
  },
  debug: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.debug(component + " - " + msg)
      : Rollbar.debug(component + " - " + msg);
  },
  warn: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.warn(component + " - " + msg)
      : Rollbar.warn(component + " - " + msg);
  },
  error: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.error("No Rollbar: " + component + " - " + msg)
      : Rollbar.error(component + " - " + msg);
  },
  critical: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME == "dev"
      ? console.error(component + " - " + msg)
      : Rollbar.critical(component + " - " + msg);
  },
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const actionPDF = async (takeScreenShot, props) => {
  return new Promise(async (resolve, reject) => {
    const screenshot = await takeScreenShot(props.reference.current);
    let tempImagesScreenshot = await localStorage.getItem("pdfScreenshot");
    tempImagesScreenshot = JSON.parse(tempImagesScreenshot);
    if (!props.selectedPDF.includes(props.label)) {
      // il grafico non e' stato mai selezionato
      if (!tempImagesScreenshot) tempImagesScreenshot = [];
      tempImagesScreenshot.push({ value: screenshot, key: props.label });
      props.setSelectedPDF([...props.selectedPDF, props.label]);
    } else {
      // il grafico e' gia' stato inserito quindi va aggioranto
      tempImagesScreenshot = tempImagesScreenshot.filter(
        ({ key }) => key !== props.label
      );
      tempImagesScreenshot.push({ value: screenshot, key: props.label });
    }
    resolve(tempImagesScreenshot);
  });
};

// render component
const renderComponent = async (FeeAcceptance) =>
  new Promise(async (resolve, reject) => {
    const blob = await ReactPDF.pdf(
      <FeeAcceptance member_detail={"test"} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    if (url && url.length > 0) {
      resolve(url);
    }
  })
    .then((res) => res)
    .catch((error) => {
      snackbarShowErrorMessage(error);
      rollbar.error("common - pdfDownload", error);
    });

const generateDocument = (images) => {
  let pages = [];
  const styles = StyleSheet.create({
    page: { padding: 10 },
  });

  for (let i = 0; i <= images.length - 1; i += 2) {
    pages.push(
      <Page key={`pages ${i}`} size="A4" style={styles.page}>
        <Image source={images[i].value} />
        {images.length - 1 > i && <Image source={images[i + 1].value} />}
      </Page>
    );
  }
  const MyDocument = () => (
    <Document>
      {pages.map((page) => {
        return page;
      })}
    </Document>
  );
  return MyDocument;
};

// download the PDF
export const pdfDownload = async (images, snackbarShowErrorMessage) => {
  try {
    const MyDocument = generateDocument(images);
    const sampleTab = window.open();
    if (sampleTab) {
      try {
        let generatedUrl = await renderComponent(
          MyDocument,
          snackbarShowErrorMessage
        );
        if (generatedUrl) {
          const aTag = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = "FeeAcceptance.pdf";
          document.body.appendChild(aTag);
          aTag.click();
        }
      } catch (error) {
        snackbarShowErrorMessage(error);
        rollbar.error("common - pdfDownload", error);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const downloadScreenshot = async (props, setSelectedPDF) => {
  try {
    let tempImagesScreenshot = await localStorage.getItem("pdfScreenshot");
    let imageList = JSON.parse(tempImagesScreenshot);
    pdfDownload(imageList, props.snackbarShowErrorMessage);
    localStorage.removeItem("pdfScreenshot");
    setSelectedPDF([]);
  } catch (error) {
    props.snackbarShowErrorMessage(error);
    rollbar.error("PatientsReportsConfigurable - downloadScreenshot", error);
  }
};

//AMPLITUDE UTILS
export const amplitudeUtils = {
  initAmplitude: async () => {
    //decode del token e verifica se presente l'ID utente
    // id utente
    // email ok
    const userId = 0;
    //const envName = process.env.REACT_APP_ENV_NAME;
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API, userId);
  },

  getAmplitudeUserId: () => {
    return amplitude.getInstance().getUserId();
  },

  setAmplitudeUserId: (userId) => {
    return amplitude.getInstance().setUserId(userId);
  },
  setAmplitudeUserProperties: (userProperties) => {
    return amplitude.getInstance().setUserProperties(userProperties);
  },
  setAmplitudeComponent: (componentName) => {
    return amplitude.getInstance().setComponent(componentName);
  },
  sendAmplitudeLog: async (eventType, eventProperties) => {
    const accessToken = await localStorage.getItem("accessToken");
    amplitude.getInstance().logEvent(eventType, eventProperties);
  },
};

//DATE FORMAT used in date fields
export const dateFormat = "YYYY-MM-DD";