import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import PatientsRegistryInput from "./patientsRegistry/PatientsRegistryInput";
import PatientsRegistrySumup from "./patientsRegistry/PatientsRegistrySumup";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const PatientsRegistry = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  //Fetching patient data
  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const titles = labels.patient.patientRegistry.steps.map((t) => (
    <StepTitle>{t}</StepTitle>
  ));

  return (
    <div>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10}>
            <Grid item container xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.patientRegistry.title.toUpperCase()}
              </SpText>
            </Grid>
            <Grid item xs={12} container spacing={2} direction="row">
              <StyledGridContainer container spacing={1}>
                <StyledBodyColumn>
                  <SpStepper
                    titles={titles}
                    stepperControls={StepperControls(errors)}
                  >
                    <PatientsRegistrySumup
                      register={register}
                      control={control}
                      setLoading={setLoading}
                    />
                    <PatientsRegistryInput
                      register={register}
                      control={control}
                      setLoading={setLoading}
                    />
                  </SpStepper>
                </StyledBodyColumn>
              </StyledGridContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withSnackbar(PatientsRegistry);
