import HumanManager from "./HumanManager";
import LoadingScreen from "./LoadingScreen";

/**
 * This class will forward events to worker if supported, else will provide a worker-like interface
 *
 * CASE 1: UNSUPPORTED OFFSCREEN CANVAS
 * In case of unsupported OffscreenCanvas (firefox & safari at present) it just acts as a message bridge between Human3D.jsx and HumanManager, with direct callback/listeners links.
 *
 * CASE 2: SUPPORTED OFFSCREEN CANVAS
 * At present time, offscreen canvas does not manage input events directly, and babylonjs is not developed with the idea of receiving them neither,
 * so the canvas will not fire events and babylonjs will not register to them. This class, along with HumanWorkerCode, creates a worker with offscreen canvas.
 * In the main thread (HumanWorkerProxy) all canvas events are forwarded through messages to worker thread, and worker messages are received and converted back into main thread functions.
 * In the worker thread (HumanWorkerCode) babylonjs is forced to register its input events, then all messages are converted into canvas events and picked up by babylonjs as usual.
 *
 * */
export default class HumanWorkerProxy {
  constructor(canvas) {
    this.mainCanvas = canvas;
    this.controlCanvas = null;
    this.workerToMainCallback = null;
    this.worker = null;

    this.scrollTimer = null;
    this._scrollRef = () => this._scroll();

    this.loading = true;
    this.loadingScreen = new LoadingScreen();

    //Ensure supported features are there
    //Actually works only in chromium-based browsers, experimental in firefox and unsupported in safari (as usual)
    if (
      "OffscreenCanvas" in window &&
      "transferControlToOffscreen" in canvas &&
      "createImageBitmap" in window
    ) {
      //OffscreenCanvas is supported by this browser
      this.worker = new Worker(
        new URL(`./HumanWorkerCode.js`, import.meta.url)
      );
      this.worker.onmessage = (msg) => this.workerToMain(msg.data);

      // Ok to use offscreen canvas
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
      let offscreen = canvas.transferControlToOffscreen();

      //Register work canvas
      let coords = this.mainCanvas.getBoundingClientRect();
      this.sendMessage(
        "_construct",
        {
          canvas: offscreen,
          x: coords.x + window.scrollX,
          y: coords.y + window.scrollY,
        },
        [offscreen]
      );
    } else {
      //OffscreenCanvas NOT supported

      //Polyfill of worker bitmap creation (this is not compatible with web worker thread)
      if (!("createImageBitmap" in window)) {
        window.createImageBitmap = async function (blob) {
          return new Promise((resolve, reject) => {
            let img = document.createElement("img");
            img.addEventListener("load", function () {
              resolve(this);
            });
            img.src = URL.createObjectURL(blob);
          });
        };
      }

      //Directly forward functions to human manager
      this._manager = new HumanManager(this.mainCanvas, (name, data) =>
        this.workerToMain({ name: name, data: data })
      );
    }

    this._windowRegisterCallbacks();
  }

  setViewCanvas(canvas, workerToMainCallback) {
    if (this.controlCanvas)
      throw new Error(
        "Actually only one 3D model canvas at a time is supported!"
      );

    if (this.loading) this.loadingScreen.show(canvas);

    this.controlCanvas = canvas;
    this.workerToMainCallback = workerToMainCallback;

    //Add canvas as view
    if (this.worker) {
      this._workerRegisterCallbacks(canvas);
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
      let offscreen = canvas.transferControlToOffscreen();
      this.worker.postMessage({ name: "setViewCanvas", data: offscreen }, [
        offscreen,
      ]);
      this._resize();
    } else {
      this._manager["setViewCanvas"](canvas);
      this._manager.scene.detachControl();
      this._manager.engine.inputElement = canvas;
      this._manager.scene.attachControl();
    }

    //Add scroll event to all parents in chain
    let parent = this.controlCanvas.parentElement;
    while (parent) {
      parent.addEventListener("scroll", this._scrollRef);
      parent = parent.parentElement;
    }
  }
  unsetViewCanvas() {
    //Clear parent chain listeners
    let parent = this.controlCanvas.parentElement;
    while (parent) {
      parent.removeEventListener("scroll", this._scrollRef);
      parent = parent.parentElement;
    }

    //Remove canvas as view
    this.controlCanvas = null;
    if (this.worker) this.worker.postMessage({ name: "unsetViewCanvas" });
    else {
      this._manager["unsetViewCanvas"]();
      this._manager.scene.detachControl();
    }

    //Hide loading
    if (this.loading) {
      this.loadingScreen.hide();
      this.loading = false;
    }
  }

  //Main -> Worker
  sendMessage(name, data, ref) {
    if (this.worker) this.worker.postMessage({ name: name, data: data }, ref);
    else this._manager[name](data);
  }

  //Worker -> main
  workerToMain(data) {
    if (data.name === "_cursor") {
      this.controlCanvas.style.cursor = data.data;
    } else if (data.name === "_loadingEnd") {
      if (this.loading) {
        this.loadingScreen.hide();
        this._resize(); //Fix offset after loading
        this.loading = false;
      }
    } else this.workerToMainCallback(data.name, data.data);
  }

  terminate() {
    this._clearExternalCallbacks();
    clearTimeout(this.scrollTimer);
    this.sendMessage("dispose");
    this.worker = this._manager = null;
  }

  _resize() {
    if (this.controlCanvas) {
      let coords = this.controlCanvas.getBoundingClientRect();
      this.sendMessage("resize", {
        x: coords.x - window.scrollX,
        y: coords.y - window.scrollY,
        width: this.controlCanvas.clientWidth,
        height: this.controlCanvas.clientHeight,
      });
    }
  }
  _scroll() {
    clearTimeout(this.scrollTimer);
    this.scrollTimer = setTimeout(this._resizeRef, 250);
  }

  //Worker callbacks
  _workerRegisterCallbacks(canvas) {
    let commonEvents = [
      "blur",
      "focus",
      "webglcontextlost",
      "webglcontextrestored",
    ];
    commonEvents.forEach((type) => {
      canvas.addEventListener(type, (evnt) => {
        this.sendMessage("_canvasEvent", {
          type: type,
          isTrusted: evnt.isTrusted,
        });
      });
    });

    let mouseEvents = ["pointerout", "pointermove", "pointerdown"];
    mouseEvents.forEach((type) => {
      canvas.addEventListener(type, (evnt) => {
        this.sendMessage("_canvasEvent", {
          type: type,
          isTrusted: evnt.isTrusted,

          altKey: evnt.altKey,
          button: evnt.button,
          buttons: evnt.buttons,
          clientX: evnt.clientX,
          clientY: evnt.clientY,
          ctrlKey: evnt.ctrlKey,
          metaKey: evnt.metaKey,
          movementX: evnt.movementX,
          movementY: evnt.movementY,
          offsetX: evnt.offsetX,
          offsetY: evnt.offsetY,
          pageX: evnt.pageX,
          pageY: evnt.pageY,
          screenX: evnt.screenX,
          screenY: evnt.screenY,
          shiftKey: evnt.shiftKey,
          which: evnt.which,
        });
      });
    });

    let keyEvents = ["keydown", "keyup"];
    keyEvents.forEach((type) => {
      canvas.addEventListener(type, (evnt) => {
        this.sendMessage("_canvasEvent", {
          type: type,
          isTrusted: evnt.isTrusted,

          altKey: evnt.altKey,
          charCode: evnt.charCode,
          code: evnt.code,
          ctrlKey: evnt.ctrlKey,
          isComposing: evnt.isComposing,
          key: evnt.key,
          keyCode: evnt.keyCode,
          location: evnt.location,
          metaKey: evnt.metaKey,
          repeat: evnt.repeat,
          shiftKey: evnt.shiftKey,
          which: evnt.which,
        });
      });
    });
  }

  _windowRegisterCallbacks() {
    //Forward resize callbacks
    this._resizeRef = () => this._resize();
    window.addEventListener("resize", this._resizeRef);

    if (this.worker) {
      let pointerupEvent = "pointerup"; //This must be captured on window object
      this._onPointerUpRef = (evnt) => {
        //Save to var to be removed later from window
        this.sendMessage("_canvasEvent", {
          type: pointerupEvent,
          isTrusted: evnt.isTrusted,

          altKey: evnt.altKey,
          button: evnt.button,
          buttons: evnt.buttons,
          clientX: evnt.clientX,
          clientY: evnt.clientY,
          ctrlKey: evnt.ctrlKey,
          metaKey: evnt.metaKey,
          movementX: evnt.movementX,
          movementY: evnt.movementY,
          offsetX: evnt.offsetX,
          offsetY: evnt.offsetY,
          pageX: evnt.pageX,
          pageY: evnt.pageY,
          screenX: evnt.screenX,
          screenY: evnt.screenY,
          shiftKey: evnt.shiftKey,
          which: evnt.which,
        });
      };
      window.addEventListener(pointerupEvent, this._onPointerUpRef);
    }
  }

  _clearExternalCallbacks() {
    //Clear window listeners
    window.removeEventListener("resize", this._resizeRef);
    window.removeEventListener("pointerup", this._onPointerUpRef);
  }
}
