/* BUTTON IN THE NAVIGATION BAR */
import React from "react";
import Button from "@material-ui/core/Button";
import { styled } from "../styled";

const ButtonSubmit = styled(Button)((buttonType) => ({
  textAlign: "center",
  maxHeight: "30px",
  minWidth: "180px",
  borderRadius: 0,
  color: "white",
  borderColor: "#31ccad",
  backgroundColor: "#40173b",
  border: "1px solid",
  "& .MuiButton-label": {
    fontFamily: "Montserrat, sans-serif !important",
    fontWeight: "bold",
  },
}));
const variants = {
  none: {},
  column: {
    marginTop: 8,
  },
  row: {
    marginRight: "3%",
  },
  listButton: {
    minWidth: "100%",
  },
  standard: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #31ccad",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "50%",
    marginRight: 0,
  },
  standardMax: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #31ccad",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    marginRight: 0,
  },
  standardOpposite: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #40173b",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "50%",
    marginRight: 0,
  },
  standardOppositeMax: {
    backgroundColor: "transparent !important",
    borderBottom: "5px solid #40173b",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    width: "100%",
    marginRight: 0,
  },
};

const SpButton = ({
  text,
  type,
  onClick,
  buttonType,
  variant,
  disabled,
  className,
  style,
}) => {
  return (
    <ButtonSubmit
      className={className}
      disabled={disabled}
      type={type}
      style={{
        ...style,
        ...variants[variant],
        marginRight: !variant && "3%",
        backgroundColor:
          type === "tableList"
            ? "transparent"
            : buttonType == "accept" && "#31ccad",
      }}
      onClick={onClick}
      color="primary"
      disableElevation
      labelstyle={{
        fontFamily: "Montserrat, sans-serif !important",
      }}
    >
      {text}
    </ButtonSubmit>
  );
};

export default SpButton;
