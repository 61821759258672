import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import SpDialog from "../../components/atoms/SpDialog";
import SpText from "../../components/atoms/SpText";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpButton from "../../components/atoms/SpButton";
import SpSearch from "../../components/atoms/SpSearch";
import {
  SpTable,
  SpTableRow,
  SpTableCell,
} from "../../components/bundles/SpTable";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../../pages/shared/translations";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Root = styled("div")({
  background: "transparent",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  padding: "2%",
});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const requestRows = [
  {
    id: 1,
    coupon: "12431325",
    subscription: "Premium",
    expirationDate: "10/11/2020",
    usageStarted: "10/11/2020",
  },
  {
    id: 1,
    coupon: "12431325",
    subscription: "Premium",
    expirationDate: "10/11/2020",
    usageStarted: "10/11/2020",
  },
  {
    id: 1,
    coupon: "12431325",
    subscription: "Premium",
    expirationDate: "10/11/2020",
    usageStarted: "10/11/2020",
  },
  {
    id: 1,
    coupon: "12431325",
    subscription: "Premium",
    expirationDate: "10/11/2020",
    usageStarted: "10/11/2020",
  },
  {
    id: 1,
    coupon: "12431325",
    subscription: "Free",
    expirationDate: "10/11/2020",
    usageStarted: "10/11/2020",
  },
];

const professionals = [
  {
    id: 1,
    name: "Giovanni",
    surname: "Bianchi",
    specialization: "fisioterapista",
    coupon: "si",
    license: "10239809",
  },
  {
    id: 2,
    name: "Luigi",
    surname: "Verdi",
    specialization: "ortopedico",
    coupon: "si",
    license: "10239809",
  },
  {
    id: 3,
    name: "Mario",
    surname: "Principe",
    specialization: "podologo",
    coupon: "si",
    license: "10239809",
  },
  {
    id: 4,
    name: "Albano Antonio",
    surname: "Carrisi",
    specialization: "chinesiologo",
    coupon: "si",
    license: "10239809",
  },
  {
    id: 5,
    name: "Romina",
    surname: "Carrisi",
    specialization: "personal trainer",
    coupon: "si",
    license: "10239809",
  },
];

const licenseType = [
  { id: 1, type: "Premium" },
  { id: 2, type: "Medium" },
  { id: 3, type: "Standard" },
];

const AgencyLicenses = (props) => {
  const [openLicense, setOpenAddLicense] = useState(false);

  const { control, register, handleSubmit, getValues, reset } = useForm({
    shouldUnregister: false,
  });

  const history = useHistory();

  const handlePatientClicked = (id) => {
    history.push("/patients/edit/${id}");
  };

  const [displayRequests, setDisplayRequests] = useState([]);

  useEffect(() => {
    setDisplayRequests(requestRows);
  }, []);

  const classes = useStyles();

  const formControlStyle = { width: "calc(100% - 10px)" };

  // Columns
  const headCells = [
    {
      id: "coupon",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.table.header.coupon,
      isAction: false,
    },
    {
      id: "subscription",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.table.header.subscription,
      isAction: false,
    },
    {
      id: "expirationDate",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.table.header.expirationDate,
      isAction: false,
    },
    {
      id: "usageStarted",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.table.header.usageStarted,
      isAction: false,
    },
  ];

  //rendering the profile editing form
  const PatientRequestRow = ({ row, rowKey, index, handleSelectedClick }) => {
    return (
      <>
        <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
          <SpTableCell>
            <SpText variant="tableText">{row.coupon}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.subscription}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.expirationDate}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{row.usageStarted}</SpText>
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <Root>
      <SpText variant="h1">{labels.agency.licenses.title}</SpText>
      <StyledRow>
        <SpButton
          buttonType={"accept"}
          onClick={() => setOpenAddLicense(true)}
          text={labels.agency.licenses.toolbar.buy}
        ></SpButton>
        <SpSearch></SpSearch>
      </StyledRow>
      <StyledRow>
        <SpTable
          pagination={true}
          headCells={headCells}
          rows={displayRequests}
          rowKey="id"
          padding={false}
          notCheckable={false}
          tableContainerMaxHeight={
            isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
          }
        >
          <PatientRequestRow />
        </SpTable>
      </StyledRow>
      <SpDialog
        open={openLicense}
        setOpen={setOpenAddLicense}
        title={labels.agency.licenses.buyLicenseDialog.title}
      >
        <Grid container>
          <Grid item xs={12}>
            <SpTextInput
              type="number"
              name="professionalGivenName"
              inputRef={register}
              label={labels.agency.licenses.buyLicenseDialog.duration}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              key={1}
              render={(props) => (
                <SpSelect
                  label={labels.agency.licenses.buyLicenseDialog.type}
                  value={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}
                >
                  {licenseType.map((p) => (
                    <SpSelectMenuItem key={p.id} value={p.id}>
                      {p.type}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name="licenseType"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              key={2}
              render={(props) => (
                <SpSelect
                  label={labels.agency.licenses.buyLicenseDialog.professional}
                  value={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}
                >
                  {professionals.map((p) => (
                    <SpSelectMenuItem key={p.id} value={p.id}>
                      {p.name}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name="professionalName"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <SpButton
              style={{ marginTop: "2%" }}
              buttonType={"accept"}
              onClick={() => setOpenAddLicense(false)}
              text={labels.agency.licenses.toolbar.buy}
            ></SpButton>
          </Grid>
        </Grid>
      </SpDialog>
    </Root>
  );
};

export default AgencyLicenses;