import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@material-ui/core";
import { styled } from "../../../../components/styled";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import SpText from "../../../../components/atoms/SpText";
import { labels, psTranslate } from "../../../shared/translations";
import { SpAutocomplete } from "../../../../components/atoms/SpAutocomplete";
import SpTextArea from "../../../../components/atoms/SpTextArea";

const StyledRowList = styled(Grid)({});

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const RowExercise = ({
  row,
  index,
  register,
  control,
  setValue,
  exercisesList,
  filterOptions,
  disabled = false,
}) => {
  const [selectedExercise, setSelectedExercise] = useState(null);

  useEffect(() => {
    if (exercisesList && exercisesList.length > 0)
      setSelectedExercise(
        exercisesList.find((el) => el.id == row.id_excercise)
      );
  }, [exercisesList]);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "99%",
            backgroundColor: "#31ccad",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
      )}
      <StyledRowList item xs={12} container>
        <Grid xs={3} style={{ alignSelf: "center" }}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[0]}
            </SpText>
            <SpAutocomplete
              formControlWidth={"98%"}
              selectPlaceholder={labels.mylab.selectExercise}
              value={selectedExercise}
              onChange={(e, newValue) => {
                setValue(`excercises[${index}].id_excercise`, newValue.id);
                setSelectedExercise(newValue);
              }}
              options={exercisesList}
              getOptionLabel={(option) => psTranslate(option.name)}
              filterOptions={filterOptions}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid xs={3}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[1]}
            </SpText>
            <SpTextInput
              type="number"
              minValue={1}
              style={{ width: "98%" }}
              name={`excercises[${index}].series`}
              inputRef={register}
              defaultValue={row.series}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[2]}
            </SpText>
            <SpTextInput
              type="number"
              minValue={1}
              style={{ width: "98%" }}
              name={`excercises[${index}].repetition_time`}
              inputRef={register}
              defaultValue={row.repetition_time}
              disabled={disabled}
            />
          </Grid>
        </Grid>

        <Grid xs={3}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[3]}
            </SpText>
            <SpTextInput
              type="number"
              minValue={1}
              style={{ width: "98%" }}
              name={`excercises[${index}].resting`}
              inputRef={register}
              defaultValue={row.resting}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[4]}
            </SpText>
            <SpTextInput
              type="number"
              minValue={1}
              style={{ width: "98%" }}
              name={`excercises[${index}].load`}
              inputRef={register}
              defaultValue={row.load}
              disabled={disabled}
            />
          </Grid>
        </Grid>

        <Grid xs={3}>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[5]}
            </SpText>
            <SpTextInput
              name={`excercises[${index}].accessories`}
              style={{ width: "98%" }}
              inputRef={register}
              defaultValue={row.accessories}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs>
            <SpText variant="listHeader">
              {labels.mylab.tempAdd.excerciseSheet.list.header[6]}
            </SpText>
            <SpTextInput
              name={`excercises[${index}].execution`}
              style={{ width: "98%" }}
              inputRef={register}
              defaultValue={row.execution}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </StyledRowList>
      <StyledRow item xs={12} container>
        <Grid item xs>
          <SpText variant="listHeader">
            {labels.mylab.tempAdd.excerciseSheet.list.header[7]}
          </SpText>
          <SpTextArea
            name={`excercises[${index}].note`}
            style={{ width: "100%" }}
            inputRef={register}
            defaultValue={row.note}
            disabled={disabled}
          />
        </Grid>
      </StyledRow>
    </div>
  );
};

export default RowExercise;
