import React, { cloneElement, useState } from "react";
import { isMobile } from "react-device-detect";

import { styled } from "../styled";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { SpPagination } from "./SpPagination";
import { theme } from "../theme";
import "./tableHeader.css";
import SpText from "../atoms/SpText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  normal: {
    borderRight: "3px solid #000 !important",
    borderLeft: "0 !important",
    borderTop: "0 !important",
    borderBottom: "0 !important",
    color: "#fff !important",
    background: "#31ccad !important",
    paddingLeft: "15px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px",
  },
  action: {
    border: "0.5px solid #31ccad !important",
    color: "#31ccad !important",
    background: "transparent !important",
    paddingLeft: "15px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
  },
}));

export const SpTableRow = styled(({ isRowSelected, ...other }) => (
  <TableRow {...other} />
))({
  height: 50,
  width: "86%",
  background: "transparent",
  color: "#FFFFFF",
  "&.MuiTableRow-root.Mui-selected": {
    background: theme.colors.secondary.tableRow,
  },
});

function descendingComparator(a, b, orderBy) {
  const notNullBOrderBy = b[orderBy] ? b[orderBy] : "";
  const notNullAOrderBy = a[orderBy] ? a[orderBy] : "";

  if (notNullBOrderBy < notNullAOrderBy) {
    return -1;
  }
  if (notNullBOrderBy > notNullAOrderBy) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const SpTableCell = styled(TableCell)({
  borderBottom: "0.5px solid #31ccad !important",
});

const VisuallyHidden = styled("span")({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: 20,
  width: 1,
});

function EnhancedTableHead({
  onCheckAllClick,
  order,
  orderBy,
  numChecked,
  rowCount,
  sortable,
  onRequestSort,
  headCells,
  notCheckable,
}) {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={headCell.isAction ? classes.action : classes.normal}
            align={headCell.numeric ? "right" : "left"}
            padding={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <SpText
                  variant={
                    headCell.isAction
                      ? "h1PageSubtitleColored"
                      : "h1PageSubtitle"
                  }
                >
                  {headCell.label.toUpperCase()}
                </SpText>
                {orderBy === headCell.id ? (
                  <VisuallyHidden>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </VisuallyHidden>
                ) : null}
              </TableSortLabel>
            ) : (
              <SpText
                variant={
                  headCell.isAction ? "h1PageSubtitleColored" : "h1PageSubtitle"
                }
              >
                {headCell.label.toUpperCase()}
              </SpText>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomToolbar = styled(Toolbar)({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
});

const EnhancedTableToolbar = ({ title, numChecked }) => (
  <CustomToolbar>
    {numChecked > 0 ? (
      <Typography color="inherit" variant="subtitle1" component="div">
        {numChecked} selected
      </Typography>
    ) : (
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    )}
  </CustomToolbar>
);

export const SpTable = ({
  notCheckable,
  pagination,
  enhancedTable,
  headCells,
  rows,
  sortable,
  children: Row,
  tableContainerMaxHeight,
  style,
  selected,
  setSelected,
  onChecked,
  checkedItems,
  rowKey,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [checked, setChecked] = useState(checkedItems || []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const TableRoot = styled("div")({
    flex: 1,
  });

  const CustomTableContainer = styled(TableContainer)({
    maxHeight: tableContainerMaxHeight,
    marginTop: !enhancedTable && "1%",
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKey]);
      setChecked(newSelecteds);
      onChecked && onChecked(newSelecteds);
      return;
    }
    setChecked([]);
  };

  const handleSelectedClick = (event, row) => {
    if (selected && selected[rowKey] === row[rowKey]) {
      setSelected(null);
    } else {
      setSelected(row);
    }
    event.stopPropagation();
  };

  const handleCheck = (event, name) => {
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    onChecked && onChecked(newChecked);
    event.stopPropagation();
  };

  const isChecked = (name) => {
    return checked.indexOf(name) !== -1;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);

  return (
    <TableRoot>
      {enhancedTable && <EnhancedTableToolbar numChecked={checked.length} />}
      <CustomTableContainer>
        <Table
          stickyHeader
          style={style ? { ...style } : { minWidth: 750 }}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          <EnhancedTableHead
            numChecked={checked.length}
            order={order}
            sortable={sortable}
            orderBy={orderBy}
            onCheckAllClick={handleCheckAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            headCells={headCells}
            notCheckable={notCheckable}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const rowCloned = cloneElement(Row, {
                  row,
                  rowKey,
                  index,
                  isChecked,
                  handleCheck,
                  handleSelectedClick,
                });
                return (
                  <React.Fragment key={row[rowKey]}>
                    {rowCloned}
                    <TableRow
                      colSpan={headCells.length}
                      style={{
                        background: theme.colors.greyScale.backgroundGrey,
                        height: isMobile ? 3 : 7,
                      }}
                    />
                  </React.Fragment>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 50, //Add just one empty row
                }}
              >
                <TableCell
                  colSpan={
                    notCheckable ? headCells.length : headCells.length + 1
                  }
                  style={{ border: 0 }}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>

      {pagination ? (
        <SpPagination
          elementsCount={rows.length}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      ) : null}
    </TableRoot>
  );
};
