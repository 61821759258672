import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { getStructuresByArea } from "../../../models/actions/Pathologies";
import { labels, psTranslate } from "../../../pages/shared/translations";
import "../App.css";
import { withSnackbar } from "../SpSnackBar";
import SpText from "../SpText";

const useStyles = makeStyles(() => ({
  root: {
    "&::-webkit-scrollbar": {
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#31caad",
    },
  },
}));

const Sp3DModelBodymapDetails = (props) => {
  const [structuresSelected, setStructuresSelected] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const classes = useStyles();

  useEffect(async () => {
    try {
      const response = await getStructuresByArea({
        area_key: props.areaName.toLowerCase(),
      });

      setStructuresSelected(response?.structures);
      setAreaSelected(psTranslate(response?.name));
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item={12}>
            <SpText variant="text">{labels.bodymap.detailPage.title}</SpText>
          </Grid>
          <Grid item={12}>
            <SpText variant="h4ComponentLabelRegular">
              {areaSelected?.charAt(0).toUpperCase() +
                areaSelected?.toLowerCase().slice(1)}
            </SpText>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems={"flex-end"}
          spacing={1}
          className={classes.root}
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
            marginBottom: "10px",
          }}
        >
          <Grid item xs={12}>
            <SpText style={{ flex: 1 }} variant="text">
              {labels.bodymap.detailPage.structureList.title}
            </SpText>
          </Grid>
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabelRegular">
              {structuresSelected
                ?.map(
                  (structure) =>
                    psTranslate(structure?.name)
                      .toLowerCase()
                      .charAt(0)
                      .toUpperCase() +
                    psTranslate(structure?.name).toLowerCase().slice(1)
                )
                .join(", ")}
            </SpText>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(Sp3DModelBodymapDetails);
