import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  deleteGroup,
  exitGroup,
  listGroupsByProfessional,
} from "../models/actions/Groups";
import "../App.css";
import compose from "recompose/compose";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import SpSearch from "../components/atoms/SpSearch";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Grid, TableCell } from "@material-ui/core";
import { labels } from "./shared/translations";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpDialog from "../components/atoms/SpDialog";
import { rollbar } from "../utils/common";

const Groups = (props) => {
  const [searchString, setSearchString] = useState();
  const [displayGroups, setDisplayedGroups] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [groupToDeleteId, setGroupToDeleteId] = useState();
  const [openDeleteExitGroupDialog, setOpenDeleteExitGroupDialog] =
    useState(false);
  const [openDeleteExitGroupDialogTitle, setOpenDeleteExitGroupDialogTitle] =
    useState("");
  const [
    openDeleteExitGroupDialogContent,
    setOpenDeleteExitGroupDialogContent,
  ] = useState("");
  const [isDeleteGroup, setIsDeleteGroup] = useState(false); //It is false when the professional can quit the group; true when he can delete it

  const { setLoading } = props;
  const history = useHistory();

  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await listGroupsByProfessional();
      if (props.location?.state?.patId) {
        let filteredResponse = [];
        response?.map((elem) => {
          const elemFound = elem.group?.patients?.find(
            (pat) => pat.id === props.location.state?.patId
          );
          if (elemFound) {
            filteredResponse.push(elem);
          }
        });
        response = filteredResponse;
      }
      setDisplayedGroups([...response]);
      setGroupsList([...response]);
    } catch (error) {
      rollbar.error("Groups - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const filterGroups = (e) => {
    let newDisplayFilters = groupsList?.filter((row) =>
      row.group.name
        .trim()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().trim().toLowerCase())
    );
    setDisplayedGroups(newDisplayFilters);
    if (e.currentTarget.value === "") setDisplayedGroups(groupsList);
    setSearchString(e.currentTarget.value);
  };

  const preExecuteDeleteExitGroup = (group) => {
    //Check if is EXIT or DELETE function
    //Is DELETE function when the group does not have any professionals (excluded the owner) and any patients
    //Is EXIT function when the group has more than one professional
    //If the group has one professional and at least one patient, the professional cannot do anything

    //Set group id
    setGroupToDeleteId(group.id);

    //If group.professionals.length === 1, it means that the only professional present in the group is also the only one who can see it
    if (group.professionals?.length > 1) {
      //It means that the group cannot be deleted, but the professional can quit group (Warning: if no other professional in the group is an administrator, a random professional will become one)
      //Open the dialog
      setOpenDeleteExitGroupDialog(true);
      setOpenDeleteExitGroupDialogTitle(labels.groups.table.exitAction.title);
      setOpenDeleteExitGroupDialogContent(
        labels.groups.table.exitAction.content
      );
      setIsDeleteGroup(false);
    } else {
      if (group.patients?.length > 0) {
        //It means that the professional must delete all patients from the group to delete it
        props.snackbarShowErrorMessage(labels.groups.errors.deleteAction);
      } else {
        //It means that the group can be deleted
        // Open the dialog
        setOpenDeleteExitGroupDialog(true);
        setOpenDeleteExitGroupDialogTitle(
          labels.groups.table.deleteAction.title
        );
        setOpenDeleteExitGroupDialogContent(
          labels.groups.table.deleteAction.content
        );
        setIsDeleteGroup(true);
      }
    }
  };

  const deleteExitGroup = async () => {
    try {
      setOpenDeleteExitGroupDialog(false);
      setLoading(true);
      let response;
      if (isDeleteGroup) {
        //Delete group
        response = await deleteGroup({ id_group: groupToDeleteId });
      } else {
        //Quit group
        response = await exitGroup({ id_group: groupToDeleteId });
      }
      //Reset value
      setIsDeleteGroup(false);
      setOpenDeleteExitGroupDialogTitle("");
      setOpenDeleteExitGroupDialogContent("");

      if (response?.error) {
        return props.snackbarShowErrorMessage(response.error);
      }else{
        props.snackbarShowMessage(response.message);
      }

      //Refetch data
      fetchData();
    } catch (error) {
      rollbar.error("Groups - deleteExitGroup", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.group,
      isAction: false,
    },
    {
      id: "professionals",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.professional,
      isAction: false,
    },
    {
      id: "patients",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.patient,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.actions,
      isAction: true,
    },
    {
      id: "deleteExitAction",
      numeric: false,
      disablePadding: false,
      label: labels.groups.table.header.actions,
      isAction: true,
    },
  ];

  const GroupsRequestRow = ({ row, index }) => (
    <>
      <SpTableRow role="checkbox" id={row?.group?.id} tabIndex={index}>
        <SpTableCell>
          <SpIconAndTextButton
            isIconRight={true}
            textVariant="tableText"
            text={row.group?.name}
            onClick={() => {
              history.push(`/groups/edit/${row?.group?.id}`);
            }}
          >
            <ArrowForwardIosIcon
              style={{
                width: 10,
                color: "#31ccad",
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.group?.professionals?.length}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{row.group?.patients?.length}</SpText>
        </SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          <SpButton
            variant={"standard"}
            type="tableList"
            text={labels.groups.toolbar.reports}
            onClick={() => {
              history.push(`/groups/statistics/${row.group?.id}`);
            }}
          />
          <SpButton
            variant={"standardOpposite"}
            type="tableList"
            text={labels.groups.toolbar.activityPlan}
            onClick={() => {
              history.push(`/groups/activity/${row.group?.id}`);
            }}
          />
        </TableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          <SpButton
            variant={"standardMax"}
            type="tableList"
            text={labels.groups.toolbar.deleteExit}
            onClick={() => preExecuteDeleteExitGroup(row.group)}
          />
        </TableCell>
      </SpTableRow>
    </>
  );

  return (
    <Grid
      container
      style={{ paddingLeft: "1%" }}
      direction="column"
      spacing={2}
    >
      <Grid
        item
        container
        xs={12}
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <Grid item xs={4}>
          <SpText variant="h1">{labels.groups.title}</SpText>
        </Grid>
        <Grid container xs={8} direction="row" justify="flex-end">
          <SpButton
            buttonType={"accept"}
            onClick={() => history.push("/groups/add")}
            text={labels.groups.toolbar.add}
          />
          <SpSearch
            onChange={(e) => filterGroups(e)}
            value={searchString}
            style={{ marginLeft: "5px", marginRight: "0px" }}
          />
        </Grid>
      </Grid>
      {displayGroups && displayGroups?.length === 0 && (
        <Grid container item xs={12} style={{ marginTop: "1%" }}>
          <SpText variant="h1PageSubtitle">{labels.groups.noGroups}</SpText>
        </Grid>
      )}
      {displayGroups && displayGroups?.length > 0 && (
        <Grid item xs={12}>
          <SpTable
            pagination={true}
            headCells={headCells}
            rows={displayGroups}
            rowKey="id"
            padding={false}
            notCheckable={false}
          >
            <GroupsRequestRow />
          </SpTable>
        </Grid>
      )}
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteExitGroupDialog}
        setOpen={setOpenDeleteExitGroupDialog}
        title={openDeleteExitGroupDialogTitle}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">{openDeleteExitGroupDialogContent}</SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={deleteExitGroup}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => setOpenDeleteExitGroupDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </Grid>
  );
};
export default compose(withSnackbar, withRouter)(Groups);
