import React, { cloneElement, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { styled } from "../styled";
import { theme } from "../theme";
import SpIconButton from "./../atoms/SpIconButton";
import CloseIcon from "@material-ui/icons/Close";
import SpCheckbox from "./../atoms/SpCheckbox";

const StyledList = styled(List)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
});

export const SpListItem = styled(ListItem)(({ rowvariant }) => ({
  ...variants[rowvariant],
  marginTop: 5,
  marginBottom: 5,
}));

const StyledHeaderListItem = styled(ListItem)({
  marginRight: 5,
  background: "transparent",
});

const variants = {
  white: {
    background: "transparent",
  },
  grey: {
    background: theme.colors.greyScale.backgroundGrey,
  },
};

const ChildrenRows = ({
  children: Row,
  rows,
  rowKey,
  checkable,
  isChecked,
  handleCheck,
  onDeleted,
  childrenProps,
  variant,
  deletable,
}) => {
  return (
    <ListItemText>
      {rows.map((row, index) => {
        const clonedRow = cloneElement(Row, {
          row,
          index,
          ...childrenProps,
        });
        return (
          <SpListItem rowvariant={variant} key={row[rowKey]}>
            {checkable ? (
              <SpCheckbox
                tabIndex={-1}
                onClick={(event) => handleCheck(event, row[rowKey])}
                checked={isChecked(row[rowKey])}
              />
            ) : null}
            <div style={{ display: "flex", flex: 1 }}>{clonedRow}</div>
            {deletable && (
              <SpIconButton
                variant="redFill"
                onClick={(e) => onDeleted(e, row, index)}
              >
                <CloseIcon style={{ color: theme.colors.secondary.red }} />
              </SpIconButton>
            )}
          </SpListItem>
        );
      })}
    </ListItemText>
  );
};

const InnerHeaderRow = ({
  children,
  checkable,
  onCheckAllClick,
  numChecked,
  rowCount,
}) => (
  <StyledHeaderListItem>
    <ListItemText>
      <div style={{ display: "flex" }}>
        {checkable ? (
          <SpCheckbox
            variant="white"
            tabIndex={-1}
            indeterminate={numChecked > 0 && numChecked < rowCount}
            checked={rowCount > 0 && numChecked === rowCount}
            onChange={onCheckAllClick}
          />
        ) : null}
        {children}
      </div>
    </ListItemText>
  </StyledHeaderListItem>
);

export const SpList = ({
  HeaderRow,
  rows,
  rowKey,
  checkable,
  checkedItems,
  onChecked,
  children: Row,
  onDeleted,
  childrenProps,
  variant = "white",
  deletable = true,
}) => {
  const [checked, setChecked] = useState(checkedItems || []);

  const handleCheck = (event, name) => {
    const selectedIndex = checked.indexOf(name);
    let newChecked = [];

    if (selectedIndex === -1) {
      newChecked = newChecked.concat(checked, name);
    } else if (selectedIndex === 0) {
      newChecked = newChecked.concat(checked.slice(1));
    } else if (selectedIndex === checked.length - 1) {
      newChecked = newChecked.concat(checked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newChecked = newChecked.concat(
        checked.slice(0, selectedIndex),
        checked.slice(selectedIndex + 1)
      );
    }

    setChecked(newChecked);
    onChecked && onChecked(newChecked);
    event.stopPropagation();
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n[rowKey]);
      setChecked(newSelecteds);
      onChecked && onChecked(newSelecteds);
      return;
    }
    setChecked([]);
  };

  const isChecked = (name) => checked.indexOf(name) !== -1;

  return (
    <>
      {rows?.length !== 0 && (
        <StyledList>
          <InnerHeaderRow
            checkable={checkable}
            rowCount={rows?.length}
            numChecked={checked.length}
            onCheckAllClick={handleCheckAllClick}
          >
            {HeaderRow}
          </InnerHeaderRow>
          <ChildrenRows
            variant={variant}
            rows={rows}
            rowKey={rowKey}
            checkable={checkable}
            isChecked={isChecked}
            handleCheck={handleCheck}
            deletable={deletable}
            onDeleted={onDeleted}
            childrenProps={childrenProps}
          >
            {Row}
          </ChildrenRows>
        </StyledList>
      )}
    </>
  );
};
