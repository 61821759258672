const scalingFactor = 8;

export const theme = {
  spacing: (value) => value * scalingFactor,
  colors: {
    primary: {
      grey: "#333333",
      white: "#FFFFFF",
      black: "#000000",
      lightBlue: "#31ccad",
    },
    secondary: {
      tableRow: "#012e29",
      red: "#FE0C4E",
      green: "#29E129",
      violet: "#9336FD",
      blue: "#3A86FF",
    },
    greyScale: {
      white: "#FFFFFF",
      grey1: "#95989A",
      grey2: "#ECEAF1",
      grey3: "#84819A",
      backgroundGrey: "#F8F6FA",
      black: "#000000",
    },
    light: {
      yellow: "#FFF3B8",
      grey: "#EEEDF1",
      lightBlue: "#CFF4F4",
      red: "#FFCEDB",
      green: "#BEF6BE",
      violet: "#EEDEFF",
      blue: "#C9DEFF",
    },
    dark: {
      yellow: "#D9B918",
      grey: "#000000",
      lightBlue: "#14A6A7",
      red: "#C01C49",
      green: "#1FB81F",
      violet: "#6C15CF",
      blue: "#145BCB",
    },
    filters: {
      background: "#30CFD021",
    },
  },
};
