import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import { labels } from "../shared/translations";
import { styled } from "../../components/styled";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { getAssessmentTemplates } from "../../models/actions/Assessment";
import { getExerciseTemplates } from "../../models/actions/Excercise";
import {
  getAllActivityPlanTemplates,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllRiskFactorTemplates,
  getAllTrainingTemplates,
  removeTemplateById,
} from "../../models/actions/Activity";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { TableCell } from "@material-ui/core";
import SpDialog from "../../components/atoms/SpDialog";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const MyLabTemp = (props) => {
  const history = useHistory();
  const { sectionName } = useParams();
  const [header, setHeader] = useState({ title: "", subtitle: "" });
  const [displayItems, setDisplayItems] = useState([]);
  const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] =
    useState(false);
  const [templateToRemove, setTemplateToRemove] = useState(false);

  const fetchAssessmentData = async () => {
    try {
      const results = await getAssessmentTemplates({ is_handbook: 0 });
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchExerciseData = async () => {
    try {
      const results = await getExerciseTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchTrainingData = async () => {
    try {
      const results = await getAllTrainingTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchTreatmentData = async () => {
    try {
      const results = await getAllProtocolTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchRehabData = async () => {
    try {
      const results = await getAllRehabTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchHandbookData = async () => {
    try {
      const results = await getAssessmentTemplates({ is_handbook: 1 });
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchActivityPlanData = async () => {
    try {
      const results = await getAllActivityPlanTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchRiskFactorData = async () => {
    try {
      const results = await getAllRiskFactorTemplates();
      setDisplayItems(results);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await _fetch();
  }, []);

  const _fetch = async () => {
    switch (sectionName) {
      case "activity-plan":
        setHeader(labels.mylab.tempDetail.activityPlanList);
        await fetchActivityPlanData();
        break;
      case "excercise":
        setHeader(labels.mylab.tempDetail.excerciseSheet);
        await fetchExerciseData();
        break;
      case "assesment":
        setHeader(labels.mylab.tempDetail.assesment);
        await fetchAssessmentData();
        break;
      case "handbook":
        setHeader(labels.mylab.tempDetail.handbook);
        await fetchHandbookData();
        break;
      case "training":
        setHeader(labels.mylab.tempDetail.training);
        await fetchTrainingData();
        break;
      case "treatment":
        setHeader(labels.mylab.tempDetail.treatment);
        await fetchTreatmentData();
        break;
      case "rehab":
        setHeader(labels.mylab.tempDetail.rehab);
        await fetchRehabData();
        break;
      case "risk_factor":
        setHeader(labels.mylab.tempDetail.riskFactor);
        await fetchRiskFactorData();
        break;
      default:
        break;
    }
  };

  // Columns
  // Columns
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.name,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.tempDetail.table.actions,
      isAction: true,
    },
  ];

  const removeTemplate = async () => {
    try {
      const result = await removeTemplateById({
        id_template: templateToRemove.id,
        section: sectionName,
      });
      if (result.message) {
        setOpenDeleteTemplateDialog(false);
        props.snackbarShowMessage(result.message);
        await _fetch();
        // window.location.reload();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  //rendering the profile editing form
  const MyLabTableRow = ({ row, index }) => {
    return (
      <>
        <SpTableRow role="checkbox" id={row.id} tabIndex={index}>
          <SpTableCell>
            {sectionName !== "activity-plan" ? (
              <SpText variant="tableText">{row.name}</SpText>
            ) : (
              <SpText variant="tableText">{row.template_name}</SpText>
            )}
          </SpTableCell>
          <TableCell
            align={"right"}
            style={{
              padding: 0,
              verticalAlign: "bottom",
              borderBottom: 0,
            }}
          >
            <SpButton
              variant={"standard"}
              type="tableList"
              text={labels.mylab.tempList.action.view}
              onClick={() =>
                history.push(`/mylab/${sectionName}/add/${row.id}`)
              }
            />
            <SpButton
              variant={"standardOpposite"}
              type="tableList"
              text={labels.mylab.tempList.action.delete}
              onClick={() => {
                setTemplateToRemove(row);
                setOpenDeleteTemplateDialog(true);
              }}
            />
          </TableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>{header.subtitle}</span>
              </SpText>
              {/*<SpText variant='h1PageTitle'>{` $`}</SpText>*/}
            </TitleToolbarSection>
          </Grid>
          <Grid
            item
            xs={4}
            alignItems={"center"}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              style={{ marginLeft: "2%" }}
              buttonType="accept"
              text={labels.mylab.tempDetail.toolbar.add}
              onClick={() => history.push(`/mylab/${sectionName}/add`)}
              variant="h1"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "0.2em" }}>
          {displayItems && displayItems.length === 0 && (
            <SpText variant="h1PageTitle">{labels.mylab.no_template}</SpText>
          )}
          {displayItems && displayItems.length > 0 && (
            <SpTable
              pagination={true}
              headCells={headCells}
              rows={displayItems}
              rowKey="id"
              padding={false}
              notCheckable={false}
            >
              <MyLabTableRow />
            </SpTable>
          )}
        </Grid>
      </Grid>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteTemplateDialog}
        setOpen={setOpenDeleteTemplateDialog}
        title={labels.mylab.dialog.delete.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.mylab.dialog.delete.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.mylab.dialog.delete.yes}
                buttonType="accept"
                onClick={() => removeTemplate()}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.mylab.dialog.delete.no}
                buttonType="accept"
                onClick={() => setOpenDeleteTemplateDialog(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(MyLabTemp);
