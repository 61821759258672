import React from "react";
import { Redirect } from "react-router-dom";
import { styled } from "../components/styled";
import { theme } from "../components/theme";
import { labels } from "./shared/translations";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import SpLoadingButton from "../components/atoms/SpLoadingButton";
import LoginIcon from "../assets/icon/loginIcon.png";
import { useAuth0 } from "@auth0/auth0-react";

const StyledColumnLogin = styled(Grid)({
  alignSelf: "center",
  padding: "2%",
  marginTop: "10%",
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "40%",
  borderRadius: "10px",
  boxShadow: `1px 1px 11px 4px ${theme.colors.primary.lightBlue}`,
});

const LoginAuth0 = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (!isLoading) {
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <StyledColumnLogin
          container
          spacing={2}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={10} style={{ width: "100%" }}>
            <img style={{ width: "100%" }} src={LoginIcon} alt="logo" />
          </Grid>
          <Grid item xs={6} container direction="column">
            <SpLoadingButton
              onClick={() => loginWithRedirect()}
              style={{ marginTop: "3%", marginRight: "0 !important" }}
              text={labels.login.buttons.login}
              buttonType={"accept"}
              variant="none"
              type="submit"
            />
          </Grid>
        </StyledColumnLogin>
      );
    }
  } else {
    return (
      <SpLoadingButton
        loading={isLoading}
        style={{ marginTop: "3%", marginRight: "0 !important" }}
        text={labels.login.buttons.login}
        buttonType={"accept"}
        variant="none"
        type="submit"
      />
    );
  }
};

export default withSnackbar(LoginAuth0);