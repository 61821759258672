import Moment from "moment";
import { extendMoment } from "moment-range";
import { dateFormat } from "../../../utils/common";
const moment = extendMoment(Moment);

///Compute a function for a given time range
export const computeInRange = (
  dateRange,
  by,
  incrementFn,
  computeFn,
  stepFn,
  labelIsStart = false,
  forwardInterval = false
) => {
  //Proceed with compute
  let range = !forwardInterval
    ? moment.rangeFromInterval(by, -1, dateRange.end)
    : moment.rangeFromInterval(by, 1, dateRange.start);

  const averagedData = {};
  //Iterate by range
  while (range.overlaps(dateRange, { adjacent: true })) {
    const rangeStr = (labelIsStart ? range.start : range.end).format(
      dateFormat
    );

    //Iterate days averaging
    const rangeIncrement = incrementFn(range);
    const values = [...rangeIncrement].reduce((accum, day) => {
      const dayStr = day.format(dateFormat);
      const compute = computeFn(dayStr);
      if (compute != null) accum.push(compute);
      return accum;
    }, []);

    //Compute step
    if (values.length > 0) {
      const stepResult = stepFn(values, rangeStr);
      if (stepResult != null) averagedData[rangeStr] = stepResult;
    }

    //Shift up/down range
    range = !forwardInterval
      ? moment.rangeFromInterval(by, -1, range.start)
      : moment.rangeFromInterval(by, 1, range.end);
  }

  //Remove any data outside provided range
  let day = dateRange.end.clone();
  let dayStr = day.format(dateFormat);
  while (day.isSameOrAfter(dateRange.start) && computeFn(dayStr) == null) {
    delete averagedData[dayStr];
    day = day.add("days", -1);
    dayStr = day.format(dateFormat);
  }

  return averagedData;
};

///Average values in time range
export const averageInRange = (
  dateRange,
  by,
  incrementFn,
  computefn,
  labelIsStart = false,
  forwardInterval = false
) => {
  return computeInRange(
    dateRange,
    by,
    incrementFn,
    computefn,
    (values) => values.reduce((a, b) => a + b, 0) / values.length,
    labelIsStart,
    forwardInterval
  );
};

//Get all values in period (ordered as range end -> range start)
export const getValuesInRange = (studyData, range, defaultZero) => {
  const iterRange = range.reverseBy("day", { excludeStart: true });
  const values = [...iterRange].reduce((accum, day) => {
    const dayStr = day.format(dateFormat);
    const compute = studyData[dayStr] ?? (defaultZero ? 0 : null);
    if (compute != null) accum.push(compute);
    return accum;
  }, []);
  return values;
};

///Get moment.js key corresponding with given graphTimeView key
export const getMomentIdFromKey = (periodKey) => {
  if (periodKey && periodKey !== "DAILY")
    return periodKey.toLowerCase().substring(0, periodKey.length - 2);
  else return "day";
};
///Get number of days associated with given moment.js key
export const getNDaysFromKey = (periodkey) => {
  const momentKey = getMomentIdFromKey(periodkey);
  const intv = moment.rangeFromInterval(
    momentKey,
    1,
    moment.utc().startOf("day")
  );
  return intv.diff("days");
};

///Compute z score
export const computeZScore = (dayValue, values) => {
  const average = values.reduce((a, b) => a + b, 0) / values.length;
  const stddev = Math.sqrt(
    values.reduce((acc, val) => acc + Math.pow(val - average, 2), 0) /
      values.length
  );
  return computeZScoreWithAverageAndStddev(dayValue, average, stddev);
};
///Compute z score
export const computeZScoreWithAverageAndStddev = (
  dayValue,
  average,
  stddev
) => {
  if (
    dayValue != null &&
    average != null &&
    stddev &&
    !isNaN(dayValue) &&
    !isNaN(average) &&
    !isNaN(stddev)
  )
    return (dayValue - average) / stddev;
  else return 0;
};

///An enabled funcion in common with all studies
const RADAR_KEY = "RADAR";
export const genericIsEnabled = (
  currStudyParameters,
  study,
  measureUnits,
  graphType,
  graphDateView
) => {
  //Check at most 2 measure units (RADAR does not have this limitation)
  let measureEnabled = true;
  if (graphType?.key !== RADAR_KEY) {
    if (study.um == null) {
      measureEnabled = true;
    } else {
      const allUnits = new Set(
        measureUnits.concat(currStudyParameters.map((param) => param.um))
      );
      measureEnabled =
        (allUnits.has(study.um) && allUnits.size == 2) || allUnits.size < 2;
    }
  }

  return (
    0 < currStudyParameters.length &&
    measureEnabled &&
    study.charts.some((chart) => chart.id === graphType?.id) &&
    study.time_views.some((view) => view.id === graphDateView?.id)
  );
};

///Assign an object to another considering nested objects
const _nestedObjectAssign = (target, source) => {
  function isObject(obj) {
    return !Array.isArray(obj) && typeof obj === "object";
  }

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) target[key] = {};

        _nestedObjectAssign(target[key], source[key]);
      } else {
        //Overwrite arrays
        target[key] = source[key];
      }
    }
  }
};
export const nestedObjectAssign = (srcTarget, source) => {
  const target = { ...srcTarget };
  _nestedObjectAssign(target, source);
  return target;
};
