import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import RowRehabilitation from "../../patients/shared/activitiesData/RowRehabilitation";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";

const RehabilitationInputDetails = ({
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  control,
  setValue,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  event,
  allowFeedback,
  isGroupCard,
}) => {
  const {
    fields: fieldsRehabilitation,
    append: appendRehabilitation,
    remove: removeRehabilitation,
  } = useFieldArray({
    control,
    name: "rehabilitations",
    keyName: "id",
    defaultValues: [],
  });

  const RenderRehabMinutesCount = ({ control }) => {
    const watchMinutesTotalCountRehab = useWatch({
      control,
      name: "rehabilitations",
    });
    const currentArray = watchMinutesTotalCountRehab;
    let minutesSum = 0;
    currentArray?.forEach((t) => {
      if (typeof t.duration === "string") {
        const output = parseInt(t.duration, 10);
        minutesSum = minutesSum + (Number.isNaN(output) ? 0 : output);
      } else {
        minutesSum = minutesSum + t.duration;
      }
    });
    return (
      <SpText
        variant="h1PageSubtitleColored"
        style={{ display: "inline-block" }}
      >
        {minutesSum} {labels.agenda.inputDetails.totalMins}
      </SpText>
    );
  };

  useEffect(() => {
    if (templateData) {
      removeRehabilitation();
      setValue(
        "rehabilitation_motivation",
        templateData.rehabilitation_motivation
      );
      setValue("quantity_rehabilitation", templateData.rehabilitations.length);
      appendRehabilitation(templateData.rehabilitations);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="rehabilitation_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeRehabilitation(fieldsRehabilitation.length - 1);
              if (fieldsRehabilitation.length > 0)
                setValue(
                  "quantity_rehabilitation",
                  fieldsRehabilitation.length - 1
                );
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            minValue={1}
            name="quantity_rehabilitation"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.treatment.inputs.quantity}
            disabled={disabled}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendRehabilitation({ id: fieldsRehabilitation.length });
              setValue(
                "quantity_rehabilitation",
                fieldsRehabilitation.length + 1
              );
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>

      {loadParametersData && (
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
        />
      )}
      {fieldsRehabilitation.length > 0 && (
        <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
          <Grid item xs={6}>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.treatment.list.title}
            </SpText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <RenderRehabMinutesCount control={control} />
          </Grid>
        </Grid>
      )}
      {fieldsRehabilitation.length > 0 && (
        <Grid
          item
          xs={12}
          container
          style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
        >
          <Grid item xs={12}>
            <SpList
              rows={fieldsRehabilitation}
              rowKey="id"
              checkable={false}
              deletable={false}
              HeaderRow={
                <Grid container style={{ justifyContent: "flex-start" }}>
                  {labels.mylab.tempAdd.treatment.list.header.map((head) => (
                    <Grid key={head} item xs={4}>
                      <SpText variant="listHeaderCenter">{head}</SpText>
                    </Grid>
                  ))}
                </Grid>
              }
              disabled={disabled}
            >
              <RowRehabilitation
                headers={labels.mylab.tempAdd.treatment.list.header}
                control={control}
                register={register}
                disabled={disabled}
              />
            </SpList>
          </Grid>
        </Grid>
      )}
      {allowFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ))}
    </Grid>
  );
};

export default RehabilitationInputDetails;
