import { call } from "./utils";

export const getInterviewQuestionnairesById = async (data) =>
  await call({
    url: "/interview/get/questionnaires/by/id",
    data: { data },
  });

export const sendInterviewToPatient = async (data) =>
  await call({ url: "/interview/send/to/patient", data: { data } });

export const getQuestionnairesAnswered = async (data) =>
  await call({
    url: "/interview/get/questionnaires/answered",
    data: { data },
  });

export const savePresentationInterview = async (data) =>
  await call({
    url: "/interview/send/presentation/answer",
    data: { data },
  });
