import { call } from "./utils";

export const createExcerciseTemplate = async (data) =>
  await call({ url: "/exercise/template/create", data: { data } });

export const updateExerciseTemplate = async (data) =>
  await call({ url: "/exercise/template/update", data: { data } });

export const getAllExercises = async () =>
  await call({ method: "GET", url: "/exercise/list" });

export const getExerciseTemplates = async () =>
  await call({ url: "/exercise/templates/list" });

export const getExerciseTemplateDetail = async (data) =>
  await call({
    url: "/exercise/template/by/id",
    data: { data },
  });
