import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../App.css";
import SpIconAndTextButton from "../components/atoms/SpIconAndTextButton";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import SpSearch from "../components/atoms/SpSearch";
import SpIconButton from "../components/atoms/SpIconButton";
import { SpTable, SpTableCell, SpTableRow } from "../components/bundles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Grid, TableCell, Tooltip } from "@material-ui/core";
import { labels } from "./shared/translations";
import { getPatientsByProfessionalDetails } from "../models/actions/Professionals";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import moment from "moment";

const Patients = (props) => {
  const history = useHistory();
  const [patientList, setPatientsList] = useState();
  const [displayPatients, setDisplayPatients] = useState();
  const [searchString, setSearchString] = useState();
  const { setLoading } = props;

  const filterPatients = (e) => {
    setSearchString(e.currentTarget.value);
    let newDisplayFilters = displayPatients?.filter((patient) =>
      `${patient.givenName} ${patient.familyName}`
        .toString()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().toLowerCase())
    );
    setDisplayPatients(newDisplayFilters);
    if (e.currentTarget.value === "") setDisplayPatients(patientList);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getPatientsByProfessionalDetails();
      setPatientsList(response);
      setDisplayPatients(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  // Columns
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.patients,
      isAction: false,
    },
    {
      id: "age",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.age,
      isAction: false,
    },
    {
      id: "last_visit",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.last_visit,
      isAction: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.patient.table.header.actions,
      isAction: true,
    },
  ];

  const getAge = (patientAge) => {
    const currentDate = moment();
    const patAge = moment(patientAge, "YYYY");
    if (currentDate.diff(patAge, "years")) {
      return currentDate.diff(patAge, "years");
    } else {
      return ""; // calculates patient's age in years
    }
  };

  const PatientRequestRow = ({ row, index }) => (
    <>
      <SpTableRow
        role="checkbox"
        id={row.id}
        tabIndex={index}
        key={`patient-${index}`}
      >
        <SpTableCell>
          <SpIconAndTextButton
            isIconRight={true}
            text={`${row.givenName} ${row.familyName}`}
            textVariant={"tableText"}
            onClick={() => {
              history.push(`/patients/edit/${row.id}`);
            }}
          >
            <ArrowForwardIosIcon
              style={{
                width: 10,
                color: "#31ccad",
                height: 30,
                cursor: "pointer",
              }}
            />
          </SpIconAndTextButton>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">{getAge(row.birth_date)}</SpText>
        </SpTableCell>
        <SpTableCell>
          <Tooltip
            title={
              row.presentations?.length > 0
                ? labels.patient.list.table.patientRow.status.onPresentation
                : labels.patient.list.table.patientRow.status.healthy
            }
          >
            <SpIconButton variant="lightBlue" small="true">
              <FiberManualRecordIcon
                style={{
                  cursor: "default",
                  color: row.presentations?.length > 0 ? "red" : "green",
                }}
              />
            </SpIconButton>
          </Tooltip>
        </SpTableCell>
        <TableCell
          align={"right"}
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          <SpButton
            variant={"standard"}
            text={labels.patient.table.actions.groups}
            type="tableList"
            onClick={() => {
              history.push({ pathname: `/groups`, state: { patId: row.id } });
            }}
          />
          <SpButton
            variant={"standardOpposite"}
            type="tableList"
            text={labels.patient.table.actions.report}
            onClick={() => {
              history.push({ pathname: `patients/edit/${row.id}/report` });
            }}
          />
        </TableCell>
      </SpTableRow>
    </>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          container
          xs={12}
          direction="row"
          spacing={3}
          alignItems="center"
        >
          <Grid item xs={4}>
            <SpText variant="h1">
              {labels.patient.component.backButtonLista}
            </SpText>
          </Grid>
          <Grid
            item
            container
            xs={8}
            direction="row"
            spacing={2}
            style={{ justifyContent: "flex-end" }}
          >
            <SpButton
              buttonType={"accept"}
              onClick={() => {
                history.push("/patients/create");
              }}
              text={labels.patient.list.addPaz}
            />
            <SpButton
              buttonType={"accept"}
              onClick={() => {
                history.push("/patients/link");
              }}
              style={{ marginLeft: "5px" }}
              text={labels.patient.list.linkPaz}
            />
            <SpSearch
              onChange={(e) => {
                filterPatients(e);
              }}
              value={searchString}
              style={{
                marginLeft: "5px",
                marginRight: 0,
              }}
            />
          </Grid>
        </Grid>

        {displayPatients && displayPatients?.length === 0 && (
          <Grid container item xs={12} style={{ marginTop: "1%" }}>
            <SpText variant="h1PageSubtitle">
              {labels.patient.component.noPatients}
            </SpText>
          </Grid>
        )}
        {displayPatients && displayPatients?.length > 0 && (
          <Grid item xs={12}>
            <SpTable
              pagination={true}
              headCells={headCells}
              rows={displayPatients}
              rowKey="id"
              padding={false}
              notCheckable={false}
            >
              <PatientRequestRow />
            </SpTable>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(Patients);