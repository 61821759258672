import React, { useEffect, useState } from "react";
import SpText from "../../components/atoms/SpText";
import { labels, psTranslate } from "../../pages/shared/translations";
import { Grid } from "@material-ui/core";
import { getResourceFromServer } from "../../models/actions/Professionals";

const ExerciseDetailContainer = ({ exerciseDetail }) => {
  const [currentExerciseURL, setCurrentExerciseURL] = useState(null);

  const LabelValue = (props) =>
    props.value ? (
      <Grid direction="column" style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    ) : (
      <></>
    );

  const getCurrentFileUrl = async () => {
    try {
      if (exerciseDetail) {
        const resourceDetail = await getResourceFromServer({
          file: exerciseDetail?.file,
          path: "assets/",
        });
        if (resourceDetail) setCurrentExerciseURL(resourceDetail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => getCurrentFileUrl(), [exerciseDetail]);

  return (
    <Grid container xs={12} style={{ marginBottom: "10px", marginTop: "2%" }}>
      <Grid
        item
        xs={6}
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <LabelValue
          label={labels.mylab.tempAdd.excerciseSheet.dialog.content.name}
          value={psTranslate(exerciseDetail?.name)}
        />
        <LabelValue
          label={labels.mylab.tempAdd.excerciseSheet.dialog.content.tags}
          value={psTranslate(exerciseDetail?.tags)}
        />
      </Grid>
      {currentExerciseURL && (
        <Grid item xs={3}>
          <img
            alt={"Gif"}
            src={currentExerciseURL}
            style={{ width: "100%", height: "100%" }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ExerciseDetailContainer;
