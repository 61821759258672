import {
  Color3,
  Color4,
  FresnelParameters,
  HighlightLayer,
  SceneLoader,
  StandardMaterial,
  Vector3,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";

import Skin from "../Assets/Meshes/skin.glb";
import Muscles from "../Assets/Meshes/muscles.glb";
import Skeleton from "../Assets/Meshes/skeleton.glb";
import Cartilage from "../Assets/Meshes/cartilage.glb";
import Connective from "../Assets/Meshes/connective.glb";
import Nervous from "../Assets/Meshes/nervous.glb";

import Human from "./Human";
import MeshGroup from "./MeshGroup";

export default class HumanFactory {
  static async create(assetsRootUrl, scene, camera, params) {
    let human = new Human(camera);

    //Load meshes
    scene.blockMaterialDirtyMechanism = true;

    let promises = [];

    //Skin
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(assetsRootUrl, Skin, scene);
        HumanFactory._skinMaterialParameters(scene, meshes, params.skin);
        HumanFactory._loadSkin(scene, meshes, human);
        human.skin = new MeshGroup(scene, meshes, "skin");
      })()
    );

    //Muscles
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(
          assetsRootUrl,
          Muscles,
          scene
        );
        HumanFactory._setOtherMeshParams(meshes, 2);
        human.muscles = new MeshGroup(scene, meshes, "muscles");
      })()
    );

    //Skeleton
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(
          assetsRootUrl,
          Skeleton,
          scene
        );
        HumanFactory._setOtherMeshParams(meshes, 3);
        human.skeleton = new MeshGroup(scene, meshes, "skeleton");
      })()
    );

    //Nervous
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(
          assetsRootUrl,
          Nervous,
          scene
        );
        HumanFactory._setOtherMeshParams(meshes, 4);
        human.nervous = new MeshGroup(scene, meshes, "nervous");
      })()
    );

    //Connective
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(
          assetsRootUrl,
          Connective,
          scene
        );
        HumanFactory._setOtherMeshParams(meshes, 5);
        human.connective = new MeshGroup(scene, meshes, "connective");
      })()
    );

    //Cartilage
    promises.push(
      (async () => {
        let meshes = await HumanFactory._loadModels(
          assetsRootUrl,
          Cartilage,
          scene
        );
        HumanFactory._setOtherMeshParams(meshes, 6);
        human.cartilage = new MeshGroup(scene, meshes, "cartilage");
      })()
    );

    await Promise.all(promises);

    scene.blockMaterialDirtyMechanism = false;

    //Return human
    return human;
  }

  static async _loadModels(assetsRootUrl, modelFile, scene) {
    const url = `${assetsRootUrl}${modelFile}`;
    const filePath = url.substring(0, url.lastIndexOf("/") + 1);
    const fileName = url.split("/").pop();
    let importResult = await SceneLoader.ImportMeshAsync(
      null,
      filePath,
      fileName,
      scene
    );
    let newMeshes = importResult.meshes;
    if (newMeshes.length <= 1)
      throw new Error(`Failed to fetch 3D model file ${modelFile}`);

    //[HACK] For scene optimizer to work, we must prevent __root__ mesh checking with this hacky code
    for (let i = 0; i < newMeshes.length; i++) {
      if (newMeshes[i].name === "__root__") {
        //Different checkCollisions values will prevent problematic merge
        //collisions are not used by us so this won't be a problem
        newMeshes[i].checkCollisions = modelFile;
        break;
      }
    }

    return newMeshes;
  }

  static _loadSkin(scene, meshes, human) {
    //Load regions & areas
    let meshRegionsDict = {};
    let meshAreasDict = {};
    for (let i = 0; i < meshes.length; i++) {
      let mesh = meshes[i];
      let name = mesh.material ? mesh.material.name : "";

      //Manually handle eyes meshes
      if (name === "eyeR") name = "ps_testa_orbitaledx";
      else if (name === "eyeL") name = "ps_testa_orbitalesx";

      //Only managed markers
      let sections = name.split("_");
      if (sections[0] !== "ps") continue;

      //Process level 1
      if (!meshRegionsDict[sections[1]]) meshRegionsDict[sections[1]] = [];
      meshRegionsDict[sections[1]].push(mesh);

      //Process level 2
      if (sections.length > 1) {
        name = sections[1] + "_" + sections[2];
        if (!meshAreasDict[name]) meshAreasDict[name] = [];
        meshAreasDict[name].push(mesh);
      }
    }

    //Convert to mesh regions
    human.skinRegions = Object.keys(meshRegionsDict).map(
      (key) => new MeshGroup(scene, meshRegionsDict[key], key)
    );
    human.skinAreas = Object.keys(meshAreasDict).map(
      (key) => new MeshGroup(scene, meshAreasDict[key], key)
    );

    //Fill children
    for (let i = 0; i < human.skinRegions.length; i++) {
      for (let j = 0; j < human.skinAreas.length; j++) {
        let regionName = human.skinRegions[i].name;
        let areaName = human.skinAreas[j].name;
        if (areaName.startsWith(regionName)) {
          human.skinRegions[i].children.push(human.skinAreas[j]);
        }
      }
    }
  }

  //-------------------------Material parameters-------------------------------
  static _skinMaterialParameters(scene, meshes, params) {
    let normalParams = params.normal;

    let hl = new HighlightLayer("hl", scene);
    hl.innerGlow = false;
    hl.outerGlow = true;
    hl.blurHorizontalSize = normalParams.blur.blurSize;
    hl.blurVerticalSize = normalParams.blur.blurSize;

    for (var i = 0; i < meshes.length; i++) {
      if (!meshes[i].material) continue;

      meshes[i].isPickable = true;

      //Normal material
      let mat = new StandardMaterial();
      mat.name = meshes[i].material.name;
      mat.zOffset = 1;

      mat.diffuseColor = Color3.FromHexString(normalParams.base.diffuseColor);
      mat.emissiveColor = Color3.FromHexString(normalParams.base.emissiveColor);
      mat.specularColor = Color3.FromHexString(normalParams.base.specularColor);

      mat.emissiveFresnelParameters = new FresnelParameters();
      mat.emissiveFresnelParameters.bias = normalParams.fresnel.bias;
      mat.emissiveFresnelParameters.power = normalParams.fresnel.power;
      mat.emissiveFresnelParameters.leftColor = Color3.FromHexString(
        normalParams.fresnel.leftColor
      );
      mat.emissiveFresnelParameters.rightColor = Color3.FromHexString(
        normalParams.fresnel.rightColor
      );

      meshes[i].enableEdgesRendering(normalParams.edge.edgeDensity);
      meshes[i].edgesWidth = normalParams.edge.edgeWidth;
      meshes[i].edgesColor = Color4.FromHexString(normalParams.edge.edgeColor);

      hl.addMesh(meshes[i], Color3.FromHexString(normalParams.blur.blurColor));

      mat.freeze();
      meshes[i]._normalMaterial = mat;

      //Hover material
      let hoverParams = params.hover;

      let hoverMat = new StandardMaterial();
      hoverMat.name = meshes[i].material.name + "_hover";
      hoverMat.zOffset = 1;
      hoverMat.diffuseColor = Color3.Black();
      hoverMat.emissiveColor = Color3.FromHexString(
        hoverParams.base.emissiveColor
      );
      hoverMat.specularColor = Color3.Black();

      hoverMat.freeze();
      meshes[i]._hoverMaterial = hoverMat;

      //Extra material
      let alternateMat = new StandardMaterial();
      alternateMat.name = meshes[i].material.name + "_extra";
      alternateMat.zOffset = 1;
      alternateMat.diffuseColor = Color3.Black();
      alternateMat.emissiveColor = Color3.FromHexString(
        hoverParams.base.emissiveColor
      );
      alternateMat.specularColor = Color3.Black();

      //alternateMat.freeze();
      meshes[i]._alternateMaterial = alternateMat;

      //Set normal
      meshes[i].material = meshes[i]._normalMaterial;
    }
  }

  static _setOtherMeshParams(meshes, zOffset) {
    for (var i = 0; i < meshes.length; i++) {
      meshes[i].isPickable = false;

      let pbrMaterial = meshes[i].material;
      if (!pbrMaterial) continue;

      let mat = new StandardMaterial();
      mat.diffuseColor = pbrMaterial.albedoColor;
      mat.diffuseTexture = pbrMaterial.albedoTexture;
      mat.specularColor = new Vector3(0.02, 0.02, 0.02);
      mat.zOffset = zOffset;
      mat.freeze();

      meshes[i].material = mat;
    }
  }
}
