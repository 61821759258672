/* BUTTON IN THE NAVIGATION BAR */
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import { styled } from "../styled";

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    display: "flex",
    alignItems: "center",
    padding: 0,
    maxHeight: 30,
    width: 30,
  },
  icon: {
    borderRadius: 0,
    borderColor: "#31ccad",
    backgroundColor: "#000",
    border: "1px solid",
    display: "flex",
    flex: 1,
    alignItems: "center",
    maxHeight: 30,
    width: 30,
    minHeight: 30,
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "#000",
      borderColor: "#1a6d5c",
    },
  },
  checkedIcon: {
    backgroundColor: "#31ccad",
  },
});

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 6,
  paddingBottom: 0,
  color: "#31ccad",
  fontWeight: "bold",
  fontSize: 14,
  "&.Mui-disabled": {
    color: "#1a6d5c",
  },
});

const SpCheckboxContainer = styled(FormLabel)(({ rightlabel }) => ({
  display: "flex",
  flexDirection: rightlabel ? "row" : "column",

  flex: 1,
  alignItems: "flex-end",
  justifyContent: "flex-end",
}));

const StyledCheckbox = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <DoneIcon className={clsx(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

const SpCheckbox = ({
  label,
  labelTop,
  rightlabel,
  displayLabel = true,
  formControlStyle,
  checked,
  onChange,
  customFromLabelStyle,
  disabled = false,
}) => {
  return (
    <SpCheckboxContainer
      style={formControlStyle}
      rightlabel={rightlabel.toString()}
    >
      {labelTop ? (
        <CustomFormLabel style={customFromLabelStyle} disabled={disabled}>
          {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      {displayLabel && !rightlabel ? (
        <CustomFormLabel style={customFromLabelStyle} disabled={disabled}>
          {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
      <StyledCheckbox
        checked={checked}
        onChange={onChange}
        style={{ paddingRight: rightlabel ? 4 : 0 }}
        disabled={disabled}
      />
      {displayLabel && rightlabel ? (
        <CustomFormLabel style={customFromLabelStyle} disabled={disabled}>
          {label}
        </CustomFormLabel>
      ) : (
        <></>
      )}
    </SpCheckboxContainer>
  );
};

export default SpCheckbox;
