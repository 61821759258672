import {
  Color3,
  Color4,
  Engine,
  Scene,
  SceneOptimizer,
  SceneOptimizerOptions,
  PointLight,
  Vector3,
  PostProcessesOptimization,
  TextureOptimization,
  MergeMeshesOptimization,
} from "@babylonjs/core";

import HumanCamera from "../Human/HumanCamera";
import HumanFactory from "../Human/HumanFactory";

import MarkerGraphicsPool from "../Marker/MarkerGraphicsPool";

import InformativeBehaviour from "../Behaviours/InformativeBehaviour";
import MarkerBehaviour from "../Behaviours/MarkerBehaviour";

import Params from "../Configs/parameters";

export default class HumanManager {
  constructor(canvas, messageCallback) {
    this.mainCanvas = canvas;
    this.camera = null;
    this.human = null;
    this.paused = false;

    this.messageCallback = messageCallback;

    this.informativeBehaviour = null;
    this.markerBehaviour = null;
    this.behaviourType = null;

    this.zoomedRegion = null;

    //Easiest way to compare props: JSON.stringify them
    this.stringifiedHumanOptions = null;
    this.stringifiedMarkers = null;
    this.stringifiedMarkerAreas = null;

    //Early function call failsafe mechanism
    this.ready = false;
    this.initProps = null;
  }

  async init(data) {
    try {
      const canvas = this.mainCanvas;

      this.engine = new Engine(canvas, true, {
        preserveDrawingBuffer: true,
        stencil: true,
      });
      this.engine.disableManifestCheck = true;

      if (!this.engine)
        throw new Error("3D rendering engine failed to initialise!");

      //Store props for update after init
      this.initProps = data.props;

      //Prepare scene obj
      const scene = new Scene(this.engine);
      this.scene = scene;
      scene.clearColor = new Color4(0, 0, 0, 1);
      scene.useRightHandedSystem = true;
      scene.collisionsEnabled = false;
      scene.particlesEnabled = false;
      scene.disablePhysicsEngine();
      scene.disableSubSurfaceForPrePass();

      //Create camera
      const camera = new HumanCamera(canvas, scene);
      this.camera = camera;
      scene.attachControl();

      //Disable camera rotation on any click
      canvas.addEventListener("pointerdown", (evnt) =>
        this._onPointerDown(evnt)
      );
      canvas.addEventListener("pointerup", (evnt) => this._onPointerUp(evnt));

      //Load human and markers
      this.human = await HumanFactory.create(
        data.assetsRootUrl,
        scene,
        camera,
        Params
      );
      const markerGraphicsPool = new MarkerGraphicsPool();
      await markerGraphicsPool.load(data.assetsRootUrl, scene);

      //Adding a light and move it with the camera
      const light = new PointLight(
        "OmniLight",
        new Vector3(20, 20, 100),
        scene
      );
      light.diffuse = Color3.FromHexString(Params.light.diffuse);
      light.intensity = Params.light.intensity;
      scene.registerBeforeRender(() => {
        light.position = camera.getPosition();
      });

      //Setup callbacks objs
      const informativeCallbackNames = data.callbacks.informativeCallbacks;
      const markerCallbackNames = data.callbacks.markerCallbacks;
      let infoCallbackObj = {};
      for (let i = 0; i < informativeCallbackNames.length; i++)
        infoCallbackObj[informativeCallbackNames[i]] = (data) => {
          this.messageCallback(
            ["informativeCallbacks", informativeCallbackNames[i]],
            data
          );
        };
      let markerCallbackObj = {};
      for (let i = 0; i < markerCallbackNames.length; i++)
        markerCallbackObj[markerCallbackNames[i]] = (data) => {
          this.messageCallback(
            ["markerCallbacks", markerCallbackNames[i]],
            data
          );
        };

      //Setup behaviours
      this.informativeBehaviour = new InformativeBehaviour(
        this.human,
        camera,
        infoCallbackObj
      );
      this.markerBehaviour = new MarkerBehaviour(
        scene,
        this.human,
        camera,
        markerGraphicsPool,
        Params.markerAreaColors,
        markerCallbackObj
      );

      //Add scene optimizer
      await SceneOptimizer.OptimizeAsync(scene, this._createOptimizerOptions());

      //Init behaviour data
      this.ready = true;
      this.update(this.initProps);
      delete this.initProps;

      //Start engine render
      this.engine.runRenderLoop(() => {
        if (!this.paused) scene.render();
      });

      //Signal loading done
      this.messageCallback("_loadingEnd", data);
    } catch (err) {
      if (this.mainCanvas)
        //Show error only if dispose() not called
        console.error(err);
    }
  }

  update(nextProps) {
    //If not ready override init props
    if (!this.ready) {
      this.initProps = nextProps;
      return;
    }

    //Check options changed
    const nextHumanOptions = JSON.stringify(nextProps.humanOptions);
    if (nextHumanOptions !== this.stringifiedHumanOptions) {
      this.human.updateOptions(nextProps.humanOptions);
      this.stringifiedHumanOptions = nextHumanOptions;
    }

    //Check behaviour changed
    if (nextProps.behaviourType !== this.behaviourType) {
      this.behaviourType = nextProps.behaviourType;

      if (nextProps.behaviourType === "INFORMATIVE") {
        this.markerBehaviour.setActive(false);
        this.informativeBehaviour.setActive(true);
      } else if (nextProps.behaviourType === "MARKER") {
        this.informativeBehaviour.setActive(false);
        this.markerBehaviour.setActive(true);
      } else
        console.warn(`No behaviour of type ${nextProps.behaviourType} exists.`);

      this.zoomedRegion = ""; //Force a zoomedRegion update by setting invalid region
    }

    //Check zoom changed
    if (nextProps.zoomedRegion !== this.zoomedRegion) {
      this.zoomedRegion = nextProps.zoomedRegion;

      if (this.behaviourType === "INFORMATIVE")
        this.informativeBehaviour.zoomTo(this.zoomedRegion);
      else this.markerBehaviour.zoomTo(this.zoomedRegion);
    }

    //Update markers
    if (this.behaviourType === "MARKER") {
      const nextMarkers = JSON.stringify(nextProps.markers);
      if (nextMarkers !== this.stringifiedMarkers) {
        this.markerBehaviour.updateMarkers(nextProps.markers);
        this.stringifiedMarkers = nextMarkers;
      }
    }
  }

  resize(data) {
    this.mainCanvas.x = this.controlCanvas.x = data.x;
    this.mainCanvas.y = this.controlCanvas.y = data.y;
    this.mainCanvas.width = this.controlCanvas.width = data.width;
    this.mainCanvas.height = this.controlCanvas.height = data.height;
  }

  dispose() {
    this.engine.stopRenderLoop();
    this.scene.dispose();
    this.engine.dispose();
    this.engine =
      this.scene =
      this.human =
      this.mainCanvas =
      this.informativeBehaviour =
      this.markerBehaviour =
        null;
  }

  setViewCanvas(canvas) {
    this.controlCanvas = canvas;
    //Disable camera rotation on control canvas clicks
    canvas.addEventListener("pointerdown", (evnt) => this._onPointerDown(evnt));
    canvas.addEventListener("pointerup", (evnt) => this._onPointerUp(evnt));
    //Restart view
    this.paused = false;
    this.camera.enableRotation(true);
    //Swap canvas controls
    this.engine.registerView(canvas);
  }
  unsetViewCanvas() {
    this.paused = true;
    this.engine.unRegisterView(this.controlCanvas);
    this.camera.zoomTo(null, true); //Reset zooming
    this.controlCanvas = null;
  }

  _onPointerDown() {
    this.camera.enableRotation(false);
    if (this.behaviourType === "MARKER") {
      const result = this.markerBehaviour.onDownClick();
      if (typeof window !== "undefined" && result)
        //Bad firefox-only fix
        this.scene.detachControl();
    }
  }
  _onPointerUp() {
    if (this.behaviourType === "MARKER") {
      const result = this.markerBehaviour.onUpClick();
      if (typeof window !== "undefined" && result)
        //Bad firefox-only fix
        this.scene.attachControl();
    }
  }

  _createOptimizerOptions() {
    const result = new SceneOptimizerOptions(30, 2000); //Target FPS, interval

    let priority = 0;
    result.optimizations.push(new MergeMeshesOptimization(priority));

    // Next priority
    priority++;
    result.optimizations.push(new PostProcessesOptimization(priority));

    // Next priority
    priority++;
    result.optimizations.push(new TextureOptimization(priority, 512));

    // Next priority (causes bad behaviours on views)
    //priority++;
    //result.optimizations.push(new HardwareScalingOptimization(priority, 1));

    return result;
  }
}
