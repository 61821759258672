import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { LinkContainer, MenuNav } from "./style";
import "./nav.css";
import { labels } from "../translations";

const Menu = (props) => {
  const getNavLinkClass = (path) =>
    path === "/"
      ? props.location.pathname === path
      : props.location.pathname.includes(path) ||
        props.location.pathname.includes(path + "/");

  return (
    <>
      <MenuNav>
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/") && "#31ccad" }}
        >
          <NavLink exact to={`/`} activeClassName="active-page">
            {labels.nav.dashboard}
          </NavLink>
        </LinkContainer>
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/patients") && "#31ccad" }}
        >
          <NavLink to={`/patients`} activeClassName="active-page">
            {labels.nav.patients}
          </NavLink>
        </LinkContainer>
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/groups") && "#31ccad" }}
        >
          <NavLink to={`/groups`} activeClassName="active-page">
            {labels.nav.groups}
          </NavLink>
        </LinkContainer>
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/agenda") && "#31ccad" }}
        >
          <NavLink to={`/agenda`} activeClassName="active-page">
            {labels.nav.agenda}
          </NavLink>
        </LinkContainer>
        {/*<LinkContainer style={{ backgroundColor: getNavLinkClass("/library") && "#31ccad" }}>*/}
        {/*	<NavLink to={`/library`} activeClassName='active-page'>*/}
        {/*		LIBRERIA MEDICA*/}
        {/*	</NavLink>*/}
        {/*</LinkContainer>*/}
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/reports") && "#31ccad" }}
        >
          <NavLink to={`/reports`} activeClassName="active-page">
            {labels.nav.stats}
          </NavLink>
        </LinkContainer>
        <LinkContainer
          style={{ backgroundColor: getNavLinkClass("/mylab") && "#31ccad" }}
        >
          <NavLink to={`/mylab`} activeClassName="active-page">
            {labels.nav.mylab}
          </NavLink>
        </LinkContainer>
      </MenuNav>
    </>
  );
};

Menu.propTypes = {};

export default withRouter(Menu);
