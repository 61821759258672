import React, { useState } from "react";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import { useLocation } from "react-router-dom";
import SpDialog from "../../../components/atoms/SpDialog";
import { Grid } from "@material-ui/core";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { deletePatient } from "../../../models/actions/Patients";
import { ArrowBack } from "@material-ui/icons";

const SidebarNavColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  marginBottom: "4%",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #31ccad",
});

const PatientsEditSidebar = ({ patId, history, props, currentPatient }) => {
  const [openDeletePatient, setOpenDeletePatient] = useState();

  const location = useLocation();

  const removePatient = async () => {
    try {
      const response = await deletePatient({ id_patient: parseInt(patId) });
      setOpenDeletePatient(false);
      if (response?.error) {
        props.snackbarShowErrorMessage(response?.error);
      } else {
        props.snackbarShowMessage(response?.message);
        history.push(`/patients`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <SidebarNavColumn>
        <Grid item xs={12}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/patients")}
              style={{
                width: 15,
                color: "#31ccad",
                height: 15,
                cursor: "pointer",
              }}
            />
            <SpText
              variant="h4ComponentLabelColored"
              style={{ marginRight: "5px" }}
            >{`${labels.patient.component.backButtonLista} / `}</SpText>
            <SpText variant="h4ComponentLabel">{`${
              currentPatient?.givenName ? currentPatient?.givenName : ""
            } ${
              currentPatient?.familyName ? currentPatient?.familyName : ""
            }`}</SpText>
          </TitleToolbarSection>
        </Grid>
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}` && `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}`)}
          text={labels.patient.sideBarPatEdit.dettPaz.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/registry` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/registry`)}
          text={labels.patient.sideBarPatEdit.anagPat.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/past-history` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/past-history`)}
          text={labels.patient.sideBarPatEdit.pastHistory.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/monitoring` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/monitoring`)}
          text={labels.patient.sideBarPatEdit.monit.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            !location.pathname.includes(
              `/patients/edit/${patId}/presentation`
            ) && `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/presentations`)}
          text={labels.patient.sideBarPatEdit.pres.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/plan` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/plan`)}
          text={labels.patient.sideBarPatEdit.viewPlan.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/statistics` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/statistics`)}
          text={labels.patient.sideBarPatEdit.statistics.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/report` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/report`)}
          text={labels.patient.sideBarPatEdit.graphReport.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/control-quest` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/control-quest`)}
          text={labels.patient.sideBarPatEdit.controlQuest.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/resource` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/resource`)}
          text={labels.patient.sideBarPatEdit.ris.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={
            location.pathname !== `/patients/edit/${patId}/transfer` &&
            `sidebarItem`
          }
          onClick={() => history.push(`/patients/edit/${patId}/transfer`)}
          text={labels.patient.sideBarPatEdit.transfPat.toUpperCase()}
        />
        <SpIconAndTextButton
          textVariant={`sidebarItem`}
          onClick={() => setOpenDeletePatient(true)}
          text={labels.patient.sideBarPatEdit.deletePatient.toUpperCase()}
        />
      </SidebarNavColumn>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        title={labels.patient.sideBarPatEdit.deletePatientDialogTitle}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.patient.sideBarPatEdit.deletePatientDialogContent}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={removePatient}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => setOpenDeletePatient(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default PatientsEditSidebar;
