import React, { useEffect, useState } from "react";
import "../App.css";
import SpText from "../components/atoms/SpText";
import SpSearch from "../components/atoms/SpSearch";
import SpDrawer from "../components/bundles/SpDrawer";
import { styled } from "../components/styled";
import { Grid } from "@material-ui/core";
import { labels, psTranslate } from "./shared/translations";
import { getLibrary } from "../models/actions/Professionals";

const StyledRow = styled("div")({
  flex: 1,
  width: "100%",
  height: "100%",
  display: "flex",
  alignSelf: "flex-start",
  justifyContent: "flex-start",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const StyledRowTitle = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "2%",
  marginBottom: "2%",
});

const StyledColumn = styled("div")({
  flex: 1,
  height: "80%",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "2%",
  paddingRight: "2%",
});

const Library = (props) => {
  const [selectedTerm, setSelectedTerm] = useState();
  const [libraryData, setLibrary] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getLibrary();
      if (response) {
        setLibrary(response);
        setSelectedTerm(response.length > 0 ? response[0] : null);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const selectTerm = (id) => {
    const filteredData = libraryData.filter((elem) => elem.id === id);
    setSelectedTerm(filteredData[0]);
  };

  const LibraryBody = () => (
    <StyledColumn>
      <SpText variant="h2PageSubtitleColored">
        {psTranslate(selectedTerm?.word)}
      </SpText>
      <SpText variant="h4ComponentLabelRegular">
        {psTranslate(selectedTerm?.description)}
      </SpText>
    </StyledColumn>
  );

  const NoData = () => (
    <Grid item xs={12}>
      <Grid
        direction="column"
        container
        xs={12}
        style={{ marginTop: "3%", padding: "1%" }}
      >
        <SpText variant="h1PageTitle" style={{ alignSelf: "center" }}>
          {labels.library.no_data}
        </SpText>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid
        style={{ paddingLeft: libraryData.length > 0 ? "1%" : 0 }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <StyledRowTitle>
            <SpText variant="h1">{labels.library.title}</SpText>
            <SpSearch />
          </StyledRowTitle>
          {libraryData.length > 0 ? (
            <StyledRow>
              <SpDrawer rows={libraryData} selectTerm={selectTerm} />
              <LibraryBody />
            </StyledRow>
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Library;