export default class Human {
  constructor() {
    this.skin = null;
    this.muscles = null;
    this.skeleton = null;
    this.nervous = null;
    this.connective = null;
    this.cartilage = null;

    this.skinRegions = [];
    this.skinAreas = [];
  }

  updateOptions(humanOptions) {
    if (humanOptions.skinOpacity != null)
      this.skin.setOpacity(parseFloat(humanOptions.skinOpacity));
    if (humanOptions.musclesToggle != null)
      this.muscles.setOpacity(humanOptions.musclesToggle ? 1 : 0);
    if (humanOptions.skeletonToggle != null)
      this.skeleton.setOpacity(humanOptions.skeletonToggle ? 1 : 0);
    if (humanOptions.nervousToggle != null)
      this.nervous.setOpacity(humanOptions.nervousToggle ? 1 : 0);
    if (humanOptions.connectiveToggle != null)
      this.connective.setOpacity(humanOptions.connectiveToggle ? 1 : 0);
    if (humanOptions.cartilageToggle != null)
      this.cartilage.setOpacity(humanOptions.cartilageToggle ? 1 : 0);
  }

  dispose() {
    this.skinRegions = [];
    this.skinAreas = [];

    this.skin.dispose();
    this.muscles.dispose();
    this.skeleton.dispose();
    this.nervous.dispose();
    this.connective.dispose();
    this.cartilage.dispose();
  }
}
