import { Grid } from "@material-ui/core";
import React from "react";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";
import { theme } from "../../../components/theme";

const DisponibilityTable = ({ data, isPrint }) => {
  return (
    <Grid
      xs={12}
      item
      container
      style={{ marginLeft: "1%", alignSelf: "center" }}
    >
      <Grid
        item
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
        }}
        direction="column"
      >
        <SpText variant="h4regular">
          {labels.groups.groupReports.thirdTable.unavalDaysFirstPeriod}
        </SpText>
        <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}>
          {data?.notAvailableDaysFirstPeriod ?? 0}
        </SpText>
      </Grid>
      <Grid
        item
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
        }}
        direction="column"
      >
        <SpText variant="h4regular">
          {labels.groups.groupReports.thirdTable.unavalDaysSecondPeriod}
        </SpText>
        <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}>
          {data?.notAvailableDaysSecondPeriod ?? 0}
        </SpText>
      </Grid>
      <Grid
        item
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          padding: "8px",
        }}
        direction="column"
      >
        <SpText variant="h4regular">
          {labels.groups.groupReports.thirdTable.diffUnavalDays}
        </SpText>
        <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}>
          {data?.notAvailableDaysFirstPeriod &&
          data?.notAvailableDaysSecondPeriod
            ? data?.notAvailableDaysFirstPeriod -
              data?.notAvailableDaysSecondPeriod
            : 0}
        </SpText>
      </Grid>
    </Grid>
  );
};

export default DisponibilityTable;
