import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { theme } from "../theme";
import { styled } from "../styled";
import "./App.css";

const variants = {
  lightBlueFill: {
    background: "#31ccad",
    color: "white",
    borderRadius: "25px !important",
    margin: "8px auto !important",
    "&:hover": {
      color: theme.colors.dark.blue,
      background: theme.colors.light.blue,
    },
    "&:disabled": {
      color: theme.colors.primary.lightBlue,
      background: theme.colors.light.blue + " !important",
      opacity: 0.4,
    },
  },
  lightBlue: {
    background: "transparent",
    color: "#31ccad",
    borderRadius: "25px !important",
    margin: "auto 0px",
    "&:hover": {
      color: theme.colors.dark.blue,
      background: theme.colors.light.blue,
    },
    "&:disabled": {
      color: theme.colors.primary.lightBlue,
      background: theme.colors.light.blue + " !important",
      opacity: 0.4,
    },
  },
  activityAddRemoveButton: {
    background: "transparent",
    color: "#31ccad",
    borderRadius: "25px !important",
    margin: "auto 0px",
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    height: 10,
    width: 10,
    "&:hover": {
      color: theme.colors.dark.blue,
      background: theme.colors.light.blue,
    },
    "&:disabled": {
      color: theme.colors.primary.lightBlue,
      background: theme.colors.light.blue + " !important",
      opacity: 0.4,
    },
  },
  redFlag: {
    background: "#31ccad",
    color: "#FFF",
    borderRadius: "25px !important",
    margin: "auto 0px",
    padding: "15px",
  },
};
const CustomIconButton = styled(IconButton)(
  ({ variant = "lightBlueFill", small = false, width, style }) => ({
    width: width ? width : small ? "20px !important" : "40px !important",
    height: small ? "20px !important" : "40px !important",
    color: "#31ccad",
    fontSize: "0.875rem",
    borderRadius: "4px !important",
    ...(variant && variants[variant]),
    ...style,
  })
);

//NOTE Receiving the Icon as props as it has been before imported from MaterialUI
const SpIconButton = React.forwardRef(
  ({ width, additionalText = "", style, children, ...rest }, ref) => (
    <CustomIconButton
      ref={ref}
      {...rest}
      disableRipple
      style={style}
      disableFocusRipple
      width={width}
    >
      {children} {additionalText.toUpperCase()}
    </CustomIconButton>
  )
);

export default SpIconButton;
