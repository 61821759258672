import styled from "styled-components";

export const MenuContainer = styled.div`
  flex: 1;
  min-height: 15%;
`;

export const MenuNav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const LinkContainer = styled.div`
  a {
    text-decoration: none;
    color: var(--gray);

    &.active-page {
      font-weight: 700;
      color: white;
    }
  }

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #012e29;
  height: 1.8rem;
  width: 18%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  color: #31ccad;
  line-height: 100%;
  border: 10px solid;
  border-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' width='11' height='11'><g fill='rgb(49,204,173)'><rect width='1' height='5'/><rect y='6' width='1' height='5'/><rect x='10' y='6' width='1' height='5'/><rect x='10' width='1' height='5'/><rect width='5' height='1'/><rect y='10' width='5' height='1'/><rect x='6' y='10' width='5' height='1'/><rect x='6' width='5' height='1'/></g></svg>")
    5;
`;
