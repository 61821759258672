import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { labels, psTranslate } from "../../../pages/shared/translations";
import "../App.css";
import SpButton from "../SpButton";
import SpDialog from "../SpDialog";
import SpText from "../SpText";
import { withSnackbar } from "../SpSnackBar";
import { getRedFlagsByPatientId } from "../../../models/actions/Patients";
import { getRedFlagsByPresentationId } from "../../../models/actions/Presentation";
import { rollbar } from "../../../utils/common";

const Sp3DModelRedFlags = ({
  riskFactorEnabled,
  setRiskFactorEnabled,
  ...props
}) => {
  const [dialogRedFlagOpen, setDialogRedFlagOpen] = useState(false);
  const [redFlagList, setRedFlags] = useState([]);

  const { patId, presentationId } = useParams();

  useEffect(async () => {
    try {
      const redFlagsResult = presentationId
        ? await getRedFlagsByPresentationId({
            id_presentation: presentationId,
            id_patient: patId,
          })
        : await getRedFlagsByPatientId({ id_patient: patId });
      setRedFlags(redFlagsResult);
    } catch (error) {
      rollbar.error("Sp3DModelRedFlags - main useEffect", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  return (
    <Grid container item xs={12} alignItems={"center"}>
      <Grid container item xs={12} direction="row" alignItems={"center"}>
        {redFlagList && redFlagList.length > 0 && (
          <Grid item xs={6}>
            <SpButton
              variant="none"
              style={{ width: "100%" }}
              text={"Red Flags"}
              onClick={() => setDialogRedFlagOpen(true)}
            />
          </Grid>
        )}
        <Grid
          item
          xs={redFlagList && redFlagList.length > 0 ? 6 : 12}
          style={{
            paddingLeft: redFlagList && redFlagList.length > 0 ? "3px" : 0,
          }}
        >
          <SpButton
            variant="none"
            style={{ width: "100%", paddingLeft: "5px" }}
            text={
              riskFactorEnabled
                ? labels.patient.addSymbols.filter.riskFactorEnabled
                : labels.patient.addSymbols.filter.riskFactorDisabled
            }
            onClick={() => setRiskFactorEnabled(!riskFactorEnabled)}
            buttonType={riskFactorEnabled ? null : "accept"}
          />
        </Grid>
      </Grid>

      <SpDialog
        open={dialogRedFlagOpen}
        setOpen={setDialogRedFlagOpen}
        title={labels.patient.presentation.add.stepper.step2.redFlagsTitle}
        style={{ marginRight: "60%" }}
      >
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          {redFlagList?.map((redFlag) => (
            <Grid
              item
              container
              xs={12}
              spacing={2}
              style={{ marginBottom: "10px" }}
            >
              <Grid item xs={12}>
                <SpText variant="text">{psTranslate(redFlag.question)}</SpText>
              </Grid>
              <Grid item xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {psTranslate(redFlag.answer)}
                </SpText>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </SpDialog>
    </Grid>
  );
};
export default withSnackbar(Sp3DModelRedFlags);
