import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import {
  getActivityFeedbackParameters,
  getAllActivitiesTypes,
} from "../../../models/actions/Activity";
import { getActivityFeedbacksInRange } from "../../../models/actions/CalendarActivity";
import {
  getActivityResponseParameterFeedbacks,
  getActivityResponseParameterFeedbacksGroups,
  getCombinedParametersFor,
  getGroupsSameGroupOf,
  getPatientsNames,
  getPatientsNamesSameGroupOf,
  getStudies,
} from "../../../models/actions/Patients";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels } from "../../shared/translations";
import {
  addProxyParameters,
  fixForProxyParams,
  fixForProxyParamsResults,
  fixProxyParamsListAvailable,
} from "./FeedbackDataHelper";
import Report from "./patientsReportFeedback/Report";

const EXCLUDED_STUDIES = ["SYMMETRY", "ASYMMETRY", "SYMMETRYASS", "INDEXES"];

const UNLINKED_PATIENTS_LABEL =
  labels.patient.graphReport.section.feedback.filters.unlinked_patients;
const LINKED_PATIENTS_LABEL =
  labels.patient.graphReport.section.feedback.filters.linked_patients;
const GROUPS_LABEL =
  labels.patient.graphReport.section.feedback.filters.patients_group;

const PatientsReportsStep1Feedback = ({
  config,
  setConfig,
  requestSaveFlag,
  ...props
}) => {
  const [studies, setStudies] = useState([]);
  const [activities, setActivities] = useState([]);
  const [studyParameters, setStudyParameters] = useState([]);
  const [activeStudyParameters, setActiveStudyParameters] = useState([]);
  const [mainPatientKey, setMainPatientKey] = useState("");

  const [additionalStudySubjects, setAdditionalStudySubjects] = useState([]);
  const [combinedParams, setCombinedParams] = useState([]);

  const [fetchedData, setFetchedData] = useState([]);

  const { patId } = useParams();

  //Effects
  useEffect(async () => {
    try {
      //Fetch data
      const promises = [
        getStudies(),
        getActivityFeedbackParameters(),
        getPatientsNames(),
        getPatientsNamesSameGroupOf({ id_patient: patId }),
        getGroupsSameGroupOf({ id_patient: patId }),
        getAllActivitiesTypes(),
      ];
      let [
        studies,
        studyParams,
        unlinkedPatients,
        linkedPatients,
        groups,
        activs,
      ] = await Promise.all(promises);
      studyParams = addProxyParameters(studyParams);
      //Housekeeping
      const mainPatientKey =
        unlinkedPatients.find((pat) => pat.id == patId).name ?? "";
      unlinkedPatients = unlinkedPatients.filter((pat) => pat.id != patId);
      linkedPatients.forEach((pat) => {
        pat.groupByKey = pat.group.name;
      });
      //Set params
      studies = studies.filter(
        (study) => !EXCLUDED_STUDIES.includes(study.key)
      );
      setStudies(studies);
      setMainPatientKey(mainPatientKey);
      setActivities(activs);
      setStudyParameters(studyParams);
      setActiveStudyParameters([]);
      setAdditionalStudySubjects({
        [UNLINKED_PATIENTS_LABEL]: unlinkedPatients,
        [LINKED_PATIENTS_LABEL]: linkedPatients,
        [GROUPS_LABEL]: groups,
      });
    } catch (error) {
      rollbar.error("PatientsReportsStep1Feedback - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  //Requested new data fetch
  const onNewDataRequest = async (
    currActivities,
    currStudyParameters,
    currAdditionalStudySubjects,
    fetchDateRange
  ) => {
    try {
      //Filter available study params
      const feedbackIds = await getActivityFeedbacksInRange({
        id_activity_types: currActivities.map((act) => act.id),
        startDate: fetchDateRange.start.format(dateFormat),
        endDate: fetchDateRange.end.format(dateFormat),
        id_patient: patId,
      });
      const newActiveParams = studyParameters.filter((param) =>
        feedbackIds.includes(param.id)
      );
      fixProxyParamsListAvailable(newActiveParams);
      if (
        newActiveParams.length !== activeStudyParameters.length ||
        newActiveParams.some(
          (newParam) =>
            !activeStudyParameters.find(
              (activeParam) => activeParam.id === newParam.id
            )
        )
      )
        setActiveStudyParameters(newActiveParams);

      //Fetch new data
      if (currStudyParameters.length > 0) {
        const currStudyParameterIdsClean = currStudyParameters.map(
          (param) => param.id
        );
        const currStudyParameterIds = fixForProxyParams(
          currStudyParameterIdsClean
        );

        //Get single patients data
        const patientIds = new Set();
        patientIds.add(patId);
        currAdditionalStudySubjects[UNLINKED_PATIENTS_LABEL]?.forEach((subj) =>
          patientIds.add(subj.id)
        );
        currAdditionalStudySubjects[LINKED_PATIENTS_LABEL]?.forEach((subj) =>
          patientIds.add(subj.id)
        );

        const newFetchedDataPromise = getActivityResponseParameterFeedbacks({
          id_activity_types: currActivities.map((act) => act.id),
          id_patients: [...patientIds],
          parameters: currStudyParameterIds,
          startDate: fetchDateRange.start.format(dateFormat),
          endDate: fetchDateRange.end.format(dateFormat),
        });

        //Get groups data
        const groups = currAdditionalStudySubjects[GROUPS_LABEL]?.map(
          (group) => group.id
        );
        let newGroupDataPromise;
        if (groups && groups.length > 0)
          newGroupDataPromise = getActivityResponseParameterFeedbacksGroups({
            id_activity_types: currActivities.map((act) => act.id),
            id_groups: groups,
            parameters: currStudyParameterIds,
            startDate: fetchDateRange.start.format(dateFormat),
            endDate: fetchDateRange.end.format(dateFormat),
          });
        else newGroupDataPromise = Promise.resolve({});

        //Fetch data
        const [newFetchedData, newGroupData] = await Promise.all([
          newFetchedDataPromise,
          newGroupDataPromise,
        ]);
        let results = { ...newFetchedData, ...newGroupData };
        results = fixForProxyParamsResults(results, currStudyParameterIdsClean);
        setFetchedData(results);
      } else setFetchedData([]);
    } catch (error) {
      rollbar.error("PatientsReportsStep1Feedback - onNewDataRequest", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  //Requested available combined parameters with given ones
  const onCombinedParamRequest = async (currStudyParameters) => {
    try {
      //Query compatible parameters
      let combinedParameters = await getCombinedParametersFor({
        parameters: currStudyParameters.map((elem) => elem.id),
      });
      combinedParameters = new Set(combinedParameters);

      //Add currently selected parameters
      currStudyParameters.forEach((param) => combinedParameters.add(param.id));

      //Update params
      setCombinedParams([...combinedParameters]);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid container item xs={12}>
        <Report
          mainSubjectKey={mainPatientKey}
          studies={studies}
          studyParameters={activeStudyParameters}
          fetchedData={fetchedData}
          activities={activities}
          additionalStudySubjects={additionalStudySubjects}
          combinedParams={combinedParams}
          onCombinedParamRequest={onCombinedParamRequest}
          onNewDataRequest={onNewDataRequest}
          defaultZero={true}
          config={config}
          setConfig={setConfig}
          requestSaveFlag={requestSaveFlag}
        />
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsReportsStep1Feedback);
