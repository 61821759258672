import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import {
  findPatientByPk,
  sendCompilationRequestToPatient,
} from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import PastHistorySumup from "./patientPastHistorySteps/PastHistorySumup";
import PastHistoryInputStep from "./patientPastHistorySteps/PastHistoryInputStep";
import PathologiesInputStep from "./patientPastHistorySteps/PathologiesInputStep";
import SpButton from "../../components/atoms/SpButton";

const StyledGridContainer = styled(Grid)({
  marginTop: "2%",
});

const StyledBodyColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "90%",
  paddingTop: "3%",
});

const PatientsPastHistory = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const sendCompilationRequest = async () => {
    try {
      const compilationRequestSent = await sendCompilationRequestToPatient({
        id_patient: patId,
      });
      if (compilationRequestSent) {
        props.snackbarShowMessage(compilationRequestSent.message);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  const titles = labels.patient.pastHistory.steps.map((t) => (
    <StepTitle>{t}</StepTitle>
  ));

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          spacing={2}
          direction="row"
          style={{ paddingRight: 0 }}
        >
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10} style={{ paddingRight: 0 }}>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <SpText variant="h1PageTitle">
                  {labels.patient.pastHistory.title.toUpperCase()}
                </SpText>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ alignSelf: "center", textAlign: "end" }}
              >
                <SpButton
                  variant="none"
                  text={labels.patient.pastHistory.lastEightWeeks}
                  buttonType="accept"
                  onClick={sendCompilationRequest}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2} direction="row">
              <StyledGridContainer container spacing={1}>
                <StyledBodyColumn>
                  <SpStepper
                    titles={titles}
                    stepperControls={StepperControls(errors)}
                  >
                    <PastHistorySumup register={register} control={control} />
                    <PastHistoryInputStep
                      register={register}
                      control={control}
                    />
                    <PathologiesInputStep
                      register={register}
                      control={control}
                      setLoading={setLoading}
                    />
                  </SpStepper>
                </StyledBodyColumn>
              </StyledGridContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsPastHistory);
