import { Grid } from "@material-ui/core";
import React from "react";
import { labels } from "../../../pages/shared/translations";
import SpButton from "../../atoms/SpButton";
import SpText from "../../atoms/SpText";
import FeedbackElement from "./FeedbackElement";

const Feedback = ({ event, setValue, click, register }) =>
  event.feedbacks &&
  event.feedbacks?.length > 0 && (
    <Grid
      direction="column"
      container
      xs={12}
      style={{ marginTop: "2%", marginBottom: "2%" }}
    >
      <Grid
        item
        container
        direction="row"
        xs={12}
        style={{ marginBottom: "2%" }}
      >
        <Grid item xs={12}>
          <SpText variant="h1PageSubtitle">Feedback</SpText>
          {!(
            event.archiving_status === "completed_and_feedback_not_sent" ||
            event.archiving_status === "completed_and_feedback_sent"
          ) && (
            <SpText variant="text">
              {labels.bundles.calendarPlan.feedback.hint}
            </SpText>
          )}
        </Grid>
      </Grid>
      <FeedbackElement
        feedbacks={event.feedbacks}
        event={event}
        setValue={setValue}
        register={register}
      />
      {!(
        event.archiving_status === "completed_and_feedback_not_sent" ||
        event.archiving_status === "completed_and_feedback_sent"
      ) && (
        <Grid xs={12} item>
          <SpButton
            buttonType="accept"
            style={{ width: "100%", marginTop: "2%" }}
            text={labels.bundles.calendarPlan.feedback.save}
            variant="h1PageTitle"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              click();
            }}
          />
        </Grid>
      )}
    </Grid>
  );

export default Feedback;
