import React, { useEffect, useState } from "react";
import { getPromsIndexes } from "../../../../../models/actions/Proms";
import { dateFormat } from "../../../../../utils/common";
import { labels } from "../../../../shared/translations";
import {
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const StudyMcidIndexes = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  mcidList,
}) => {
  const [promsIndexes, setPromsIndexes] = useState([]);
  const [active, setActive] = useState(true);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const mcidData = (by) => {
    const data = {
      "": {},
    };

    [dateRange.start, dateRange.end].forEach((day) => {
      const dayStr = day.format(dateFormat);
      promsIndexes.forEach((promIndex) => {
        const mcids = promIndex.value.split("-");
        let i = 1;
        mcids.forEach((mcid) => {
          const key = `${promIndex.name} ${i}`;
          if (!data[""][key]) data[""][key] = {};
          data[""][key][dayStr] = parseFloat(mcid);
          i++;
        });
      });
    });

    return data;
  };

  useEffect(async () => {
    if (mcidList) {
      //Show mcids
      const indexes = await getPromsIndexes({
        id_proms: mcidList,
        type: "mcid",
      });
      setPromsIndexes(indexes ?? []);
    }
  }, [mcidList]);

  //Effects
  useEffect(() => {
    //In case of week view, average over next 7 days
    onFetchDateDaysChange(getNDaysFromKey(graphDateView?.key));
    //Update filter data
    let newData;
    if (active && isEnabled())
      newData = {
        [study.key]: {
          "": mcidData(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    else newData = { [study.key]: { "": {} } };

    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView, promsIndexes]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    />
  );
};

export default StudyMcidIndexes;
