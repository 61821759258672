import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getAllActivitiesTypes } from "../../../models/actions/Activity";
import { getSecondTableGroupsData } from "../../../models/actions/Groups";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsActivities = ({
  isGroup,
  dateRange,
  currActivities,
  setCurrActivities,
  setData,
  ...props
}) => {
  const [activities, setActivities] = useState([]);

  const { groupId, patId } = useParams();

  useEffect(async () => {
    try {
      //Fetch data
      const activs = await getAllActivitiesTypes();
      setActivities(activs);
    } catch (error) {
      rollbar.error("StatisticsActivities - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  useEffect(async () => {
    try {
      //Fetch activity data
      const secondTableData = await getSecondTableGroupsData({
        id_group: groupId,
        id_patients: patId ? [patId] : null,
        activitiesSelected: currActivities,
        feedbacksSelected: [],
        filterActStartDate: dateRange.start.format(dateFormat),
        filterActEndDate: dateRange.end.format(dateFormat),
      });
      const newData = secondTableData.reduce((accum, patientData) => {
        const patientKey = `${patientData.patient.patientData.givenName} ${patientData.patient.patientData.familyName}`;
        accum[patientKey] = patientData.activitiesData.reduce((accum, act) => {
          accum[
            `${labels.groups.groupReports.secondTable.sessions} ${act.activityName}`
          ] = act.numberOfSession.count;
          return accum;
        }, {});
        return accum;
      }, {});
      setData(newData);
    } catch (error) {
      rollbar.error("StatisticsActivities - fetchDataActivities", error);
      props.snackbarShowErrorMessage(error);
    }
  }, [currActivities, dateRange]);

  return (
    <SpSelect
      label={labels.groups.groupReports.secondTable.chooseActivity}
      selectPlaceholder={labels.groups.groupReports.secondTable.selectValue}
      multiple
      formControlStyle={{ minWidth: "100%" }}
      value={currActivities}
      onChange={(evnt) => setCurrActivities(evnt.target.value)}
    >
      {activities.map((lp) => (
        <SpSelectMenuItem key={lp.id} value={lp.id}>
          {psTranslate(lp.name)}
        </SpSelectMenuItem>
      ))}
    </SpSelect>
  );
};

export default withSnackbar(StatisticsActivities);
