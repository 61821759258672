import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import SpDialog from "./SpDialog";
import {
  getRiskFactorSymbols,
  getStructuresSymbolDysfunction,
  getStructuresSymbolDysfunctionByPresentation,
} from "../../models/actions/Pathologies";
import { labels } from "../../pages/shared/translations";
import "./App.css";
import Sp3DModelBodymapDetails from "./Sp3DModel/Sp3DModelBodymapDetails";
import Sp3DModelDateSelection from "./Sp3DModel/Sp3DModelDateSelection";
import Sp3DModelDysfunctionFilter from "./Sp3DModel/Sp3DModelDysfunctionFilter";
import Sp3DModelModel from "./Sp3DModel/Sp3DModelModel";
import Sp3DModelRedFlags from "./Sp3DModel/Sp3DModelRedFlags";
import Sp3DModelSymbolDetails from "./Sp3DModel/Sp3DModelSymbolDetails";
import SpButton from "./SpButton";
import { withSnackbar } from "./SpSnackBar";
import { rollbar } from "../../utils/common";

const commonDateFormat = "YYYY-MM-DD";
const zeroDate = moment.utc("1900-01-01", commonDateFormat);

const Sp3DModel = ({
  modelBehaviour, //INFORMATIVE or MARKER
  type, //PATIENT, PRESENTATION or MONITORING
  selectedRegion,
  setSelectedRegion,
  selectedArea,
  setSelectedArea,
  hideFilters = false, //True to hide model filters
  refreshMarkers = false, //Force a marker refresh from db by changing this value
  ...props
}) => {
  const [nowDate, setNowDate] = useState();
  const [filterDate, setFilterDate] = useState(moment().startOf("day"));

  const [riskFactorEnabled, setRiskFactorEnabled] = useState(false);
  const [filterDysfunctionCategories, setFilterDysfunctionCategories] =
    useState([]);

  const [symbolsDysfunction, setSymbolsDysfunction] = useState([]);
  const [riskFactors, setRiskFactors] = useState([]);

  const [selectedSymbol, setSelectedSymbol] = useState();
  const [symbols, setSymbols] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [dialogSymbolOpen, setDialogSymbolOpen] = useState(false);
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [selectedAreaDialog, setSelectedAreaDialog] = useState(null);

  const { patId, presentationId } = useParams();

  const fetchData = async () => {
    const symbols = await updateSymbolsFromFilterDate(filterDate);
    const _array = symbols.filter((x) => !x.riskFactor);
    const newNowDate = _array[_array.length - 1]?.date;
    setNowDate(newNowDate);
  };

  const fetchSymbolsStructureDysfunction = async (data) => {
    try {
      if (type === "PATIENT")
        return await getStructuresSymbolDysfunction({
          id_patient: patId,
          ...data,
        });
      else if (type === "PRESENTATION")
        return await getStructuresSymbolDysfunctionByPresentation({
          id_presentation: presentationId,
          ...data,
        });
      else {
        rollbar.error(
          "Sp3DModel - fetchSymbolsStructureDysfunction",
          "INVALID sp3dmodel type"
        );
      }
    } catch (error) {
      rollbar.error("Sp3DModel - fetchSymbolsStructureDysfunction", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const updateSymbolsFromFilterDate = async (date, direction = 0) => {
    //Obtain symbols, fetching date (dir 1 date upwards, date -1 downwards, 0 exact)
    const patientDysfunctionResult = await fetchSymbolsStructureDysfunction({
      date: date.format(commonDateFormat),
      direction: direction,
    });

    setSymbolsDysfunction(
      patientDysfunctionResult?.dysfunction_symbol_structures
    );
    let symbols = patientDysfunctionResult?.dysfunction_symbol_structures?.map(
      (elem) => {
        return {
          id: elem.id,
          icon: elem.structure_dysfunction?.dysfunction?.dysfunction_category
            ?.id,
          area: elem.structure_dysfunction?.structure?.area,
          weight:
            elem.structure_dysfunction?.dysfunction?.dysfunction_category
              ?.weight,
          severity: elem.severity,
          date: moment(elem?.date || nowDate),
          category_id:
            elem.structure_dysfunction?.dysfunction?.dysfunction_category?.id,
          riskFactor: false,
        };
      }
    );

    if (!symbols) symbols = [];

    //Set symbols
    symbols = symbols.sort((a, b) => a?.date.valueOf() - b?.date.valueOf());
    setSymbols(symbols);

    //Set real filter date
    if (symbols.length > 0) setFilterDate(symbols[symbols.length - 1].date);
    else setFilterDate(zeroDate);

    if (type === "PATIENT") {
      //Handle risk factors
      let riskFactorList = await getRiskFactorSymbols({ id_patient: patId });

      setRiskFactors(riskFactorList);
      riskFactorList?.forEach((elem) => {
        symbols.push({
          id: elem.id,
          icon: 7, //Risk factors fixed icon
          area: elem.structure_dysfunction?.structure?.area,
          weight:
            elem.structure_dysfunction?.dysfunction?.dysfunction_category
              ?.weight,
          severity: 2, //Risk factors fixed severity
          date: moment(nowDate),
          category_id:
            elem.structure_dysfunction.dysfunction.dysfunction_category.id,
          riskFactor: true,
        });
      });
    }

    return symbols;
  };

  const updateMarkers = () => {
    let markers = [];

    if (riskFactorEnabled) {
      symbols.forEach((elem) => {
        if (elem.riskFactor && elem.severity)
          markers.push({
            id: elem.id,
            id_icon: elem.icon,
            name: elem.area.key,
            weight: elem.weight,
            severity: elem.severity,
            riskFactor: elem.riskFactor,
          });
      });
    } else {
      if (filterDysfunctionCategories.length === 0) {
        //Date filter mode
        symbols.forEach((elem) => {
          if (!elem.riskFactor && elem.severity)
            markers.push({
              id: elem.id,
              id_icon: elem.icon,
              name: elem.area.key,
              weight: elem.weight,
              severity: elem.severity,
              riskFactor: elem.riskFactor,
            });
        });
      } else {
        //Dysfunction category filter mode
        symbols.forEach((dysfunctionElem) => {
          const isInFilter = filterDysfunctionCategories.find(
            (cat) => cat.id === dysfunctionElem.category_id
          );
          if (
            isInFilter &&
            !dysfunctionElem.riskFactor &&
            dysfunctionElem.severity
          ) {
            const { id, area, icon, weight, severity, riskFactor } =
              dysfunctionElem;
            markers.push({
              id: id,
              id_icon: icon,
              name: area.key,
              weight: weight,
              severity: severity,
              riskFactor: riskFactor,
            });
          }
        });
      }
    }

    setMarkers(markers);
  };

  const updateSymbolDate = (advanceDirectionRight, advanceToEnd) => {
    if (!advanceToEnd) {
      if (advanceDirectionRight) updateSymbolsFromFilterDate(filterDate, 1);
      else updateSymbolsFromFilterDate(filterDate, -1);
    } else {
      if (advanceDirectionRight) updateSymbolsFromFilterDate(nowDate, 0);
      else updateSymbolsFromFilterDate(zeroDate, 0);
    }
  };

  const navigateToAreaDetail = (areaName) => {
    setSelectedAreaDialog(areaName);
    setDialogDetailOpen(true);
  };

  useEffect(() => {
    if (modelBehaviour === "MARKER") fetchData();
  }, []);

  useEffect(() => {
    if (nowDate && modelBehaviour === "MARKER")
      updateSymbolsFromFilterDate(moment().startOf("day"));
  }, [refreshMarkers]);

  useEffect(() => {
    if (modelBehaviour === "MARKER") updateMarkers();
  }, [symbols, filterDysfunctionCategories, riskFactorEnabled]);

  return (
    <>
      {modelBehaviour === "MARKER" && !hideFilters && (
        <Sp3DModelDateSelection
          enabled={filterDysfunctionCategories.length == 0}
          symbols={symbols}
          filterDate={filterDate}
          nowDate={nowDate}
          updateSymbolDate={updateSymbolDate}
        />
      )}

      <Sp3DModelModel
        markers={markers}
        symbolsDysfunction={symbolsDysfunction}
        riskFactors={riskFactors}
        setSelectedSymbol={setSelectedSymbol}
        setSymbolDialogOpen={setDialogSymbolOpen}
        modelBehaviour={modelBehaviour}
        setSelectedRegion={setSelectedRegion}
        setSelectedArea={setSelectedArea}
        selectedRegion={selectedRegion}
        selectedArea={selectedArea}
        navigateToAreaDetail={navigateToAreaDetail}
      />

      {modelBehaviour === "MARKER" && !hideFilters && (
        <Grid
          container
          direction="column"
          style={{ marginTop: "10px" }}
          alignItems={"center"}
        >
          <Sp3DModelDysfunctionFilter
            filterDysfunctionCategories={filterDysfunctionCategories}
            setFilterDysfunctionCategories={setFilterDysfunctionCategories}
            updateSymbolDate={updateSymbolDate}
          />
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems={"center"}
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={8}>
              <Sp3DModelRedFlags
                riskFactorEnabled={riskFactorEnabled}
                setRiskFactorEnabled={setRiskFactorEnabled}
              />
            </Grid>
            <Grid item xs={4} style={{ paddingLeft: "3px" }}>
              <SpButton
                variant="none"
                style={{ width: "100%" }}
                text={"Reset"}
                buttonType={"accept"}
                onClick={() => setFilterDysfunctionCategories([])}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <SpDialog
        open={dialogSymbolOpen}
        setOpen={setDialogSymbolOpen}
        title={
          !riskFactorEnabled
            ? labels.bodymap.dialogDetail.title
            : labels.bodymap.dialogDetail.titleRiskFactor
        }
        style={{ marginRight: "60%" }}
      >
        {selectedSymbol && (
          <Sp3DModelSymbolDetails
            editable={filterDate.isSame(nowDate)}
            nowDate={nowDate}
            selectedSymbol={selectedSymbol}
            updateSymbolDate={updateSymbolDate}
            setDialogOpen={setDialogSymbolOpen}
          />
        )}
      </SpDialog>
      <SpDialog
        open={dialogDetailOpen}
        setOpen={setDialogDetailOpen}
        title={labels.bodymap.detailPage.title}
        style={{ marginRight: "60%" }}
      >
        <Sp3DModelBodymapDetails areaName={selectedAreaDialog} />
      </SpDialog>
    </>
  );
};
export default withRouter(withSnackbar(Sp3DModel));
