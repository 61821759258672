import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SpText from "../SpText";
import { labels, psTranslate } from "../../../pages/shared/translations";
import "../App.css";
import SpButton from "../SpButton";
import { SpSelect, SpSelectMenuItem } from "../SpSelect";
import { withSnackbar } from "../SpSnackBar";
import { updateStructureSymbolById } from "../../../models/actions/Pathologies";

const commonDateFormat = "YYYY-MM-DD";

const Sp3DModelSymbolDetails = ({
  editable,
  nowDate,
  selectedSymbol,
  updateSymbolDate,
  setDialogOpen,
  ...props
}) => {
  const [editSeverity, setEditSeverity] = useState();

  const updateSymbol = async (severity) => {
    try {
      setDialogOpen(false);
      //Update symbol history
      const results = await updateStructureSymbolById({
        id_symbol: selectedSymbol.id,
        date: nowDate.format(commonDateFormat),
        severity: severity,
      });
      //Refresh symbols
      updateSymbolDate(true, true);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    setEditSeverity(selectedSymbol.severity);
  }, [selectedSymbol]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <SpText variant="text">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.disorder.name
            )}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.name
            )}
          </SpText>
        </Grid>
        {selectedSymbol.structure_dysfunction?.dysfunction?.dysfunction_category
          ?.id_disorder == 1 && (
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.bodymap.dialogDetail.dysfunctionLabel}
            </SpText>
            <SpText variant="h4ComponentLabelRegular">
              {psTranslate(
                selectedSymbol.structure_dysfunction?.dysfunction?.name
              )}
            </SpText>
          </Grid>
        )}

        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.inputDateLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {moment(String(selectedSymbol.createdAt)).format("DD/MM/yyyy")}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.structureLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(selectedSymbol.structure_dysfunction?.structure?.name)}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.areaLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.structure?.area?.name
            )}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.regionLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.structure?.area?.region
                ?.name
            )}
          </SpText>
        </Grid>
        {editSeverity && (
          <Grid container item xs={12}>
            <Grid xs={12}>
              <SpText variant="text">
                {labels.bodymap.dialogDetail.severityLabel}
              </SpText>
            </Grid>
            {editable ? (
              <Grid container xs={12}>
                <Grid xs={12}>
                  <SpSelect
                    value={editSeverity}
                    displayLabel={false}
                    onChange={(e) => setEditSeverity(e.target.value)}
                  >
                    {Object.entries(labels.bodymap.severityLabels).map(
                      ([key, value]) => (
                        <SpSelectMenuItem key={key} value={parseInt(key)}>
                          {value}
                        </SpSelectMenuItem>
                      )
                    )}
                  </SpSelect>
                  <SpButton
                    style={{ marginLeft: "10px" }}
                    buttonType={"accept"}
                    text={labels.bodymap.updateButton}
                    onClick={() => updateSymbol(editSeverity)}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid xs={12}>
                <SpText variant="h4ComponentLabelRegular">
                  {labels.bodymap.severityLabels[selectedSymbol.severity]}
                </SpText>
              </Grid>
            )}
          </Grid>
        )}
        {editSeverity && editable && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <SpButton
              buttonType={"accept"}
              text={labels.bodymap.deleteButton}
              onClick={() => updateSymbol(0)}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default withSnackbar(Sp3DModelSymbolDetails);
