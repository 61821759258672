export default class InformativeBehaviour {
  constructor(human, camera, callbacks) {
    this.human = human;
    this.camera = camera;

    this.activeNodes = [];

    this.onRegionClickCallback = callbacks.onRegionClick;
    this.onAreaClickCallback = callbacks.onAreaClick;
    this.onEnterCallback = callbacks.onEnter;
    this.onExitCallback = callbacks.onExit;
  }

  //---------------------------------PUBLIC----------------------------------------
  setActive(isActive) {
    if (isActive) {
      //Update hover callbacks
      this.human.skinRegions.forEach((meshGroup) => {
        meshGroup.updateCallbacks(
          null,
          (group) => this.onEnterCallback(group.name),
          (group) => this.onExitCallback(group.name)
        );
      });
      this.human.skinAreas.forEach((meshGroup) => {
        meshGroup.updateCallbacks(
          null,
          (group) => this.onEnterCallback(group.name),
          (group) => this.onExitCallback(group.name)
        );
        meshGroup.setAlternateMaterialActive(false); //Back to default color
      });
      //Prepare zoom & update click callbacks
      this._exitLayers();
    } else {
      //Deactivate all callbacks & clicks
      this.activeNodes = [];
      this.human.skinRegions.forEach((region) => {
        region.setActive(false);
        region.setHover(false);
      });
      this.human.skinAreas.forEach((area) => {
        area.setActive(false);
        area.setHover(false);
      });
    }
  }

  zoomTo(regionName) {
    if (!regionName) {
      this._exitLayers();
      this.camera.zoomTo(null);
    } else this._zoomToRegion(regionName);
  }

  //---------------------------------PRIVATE----------------------------------------
  _exitLayers() {
    //Deactivate other systems
    this.human.skinAreas.forEach((meshRegion) => {
      meshRegion.setHover(false);
      meshRegion.setActive(false);
    });

    //Activate skinRegions
    this.activeNodes = this.human.skinRegions;
    this.activeNodes.forEach((meshGroup) => {
      meshGroup.updateCallbacks((clickedGroup) => {
        if (this.onRegionClickCallback)
          this.onRegionClickCallback(clickedGroup.name);
      });
      meshGroup.setActive(true);
    });
  }

  _zoomToRegion(regionName) {
    let clickedGroup = this.human.skinRegions.find(
      (region) => region.name === regionName
    );

    if (clickedGroup) {
      //Deactivate skinRegions
      this.human.skinRegions.forEach((meshRegion) => {
        meshRegion.setActive(false);
      });

      //Activate selected zone in layer 1
      this.activeNodes = clickedGroup.children;
      this.activeNodes.forEach((node) => {
        node.updateCallbacks((clickedRegion) => this._areaClick(clickedRegion));
        node.setActive(true);
      });
      this.camera.zoomTo(clickedGroup.center);
    } else {
      console.warn(`Requested zoom region ${regionName} does not exist.`);
    }
  }

  _areaClick(clickedGroup) {
    //User callback
    if (this.onAreaClickCallback) this.onAreaClickCallback(clickedGroup.name);
  }
}
