import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../styled";
import { labels, psTranslate } from "../../../pages/shared/translations";
import SpText from "../../../components/atoms/SpText";
import { Checkbox, Chip, Divider, Grid } from "@material-ui/core";
import { withSnackbar } from "../../atoms/SpSnackBar";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import {
  getAssessmentsTemplateCategories,
  getAssessmentTemplateDetail,
  getAssessmentTemplateElementsByCategoryIds,
} from "../../../models/actions/Assessment";
import { SpAutocomplete } from "../../atoms/SpAutocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { getSortedAndTranslatedArray } from "../../../utils/common";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledRowListHeader = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const AddAssessmentTemplate = ({
  setTemplateDetailData,
  assessmentElements,
  setAssessmentElements,
  setName,
}) => {
  const [assessmentsCategory, setAssessmentsCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentQuestionnairesList, setCurrentQuestionnairesList] = useState(
    []
  );
  const [assessmentData, setAssessmentData] = useState([]);

  const { idElement } = useParams();

  //Effects
  const fetchData = async () => {
    try {
      const response = await getAssessmentsTemplateCategories();
      setAssessmentsCategories(response);
      setSelectedCategories(response?.map((_) => false));

      //fetch template detail and setting the form to be displayed
      if (idElement) {
        const responseAssessmentCategory = await getAssessmentTemplateDetail({
          id_template: idElement,
        });
        if (responseAssessmentCategory) {
          setTemplateDetailData(responseAssessmentCategory);
          let newAssessmentData = [];
          responseAssessmentCategory.AssesmentElement.map((ass) => {
            const foundElem = newAssessmentData.find(
              (elem) =>
                elem?.id ==
                ass.assessment_assessment_category.assessment_category.id
            );
            if (!foundElem) {
              newAssessmentData.push({
                id: ass.assessment_assessment_category.assessment_category.id,
                name: ass.assessment_assessment_category.assessment_category
                  .name,
                assessment_elements: [],
              });
              newAssessmentData[
                newAssessmentData.length - 1
              ]?.assessment_elements.push(ass.name);
            } else {
              newAssessmentData[
                newAssessmentData.length - 1
              ].assessment_elements.push(ass.name);
            }
          });
          setAssessmentData(newAssessmentData);
        }
        setName(responseAssessmentCategory.name);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => await fetchData(), []);

  const updateQuestionnairesList = async (categoryIdsFiltered) => {
    try {
      if (categoryIdsFiltered) {
        const response = await getAssessmentTemplateElementsByCategoryIds({
          id_categories: categoryIdsFiltered,
        });
        setCurrentQuestionnairesList(response);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <StyledBodyColumn>
      {idElement ? (
        <Grid container xs={12}>
          {/*Detail assessment*/}
          {assessmentData?.map((item, idx) => (
            <Grid
              key={`assessment_data_${idx}`}
              container
              xs={4}
              style={{ border: "1px solid #31ccad", padding: "8px" }}
              direction="column"
            >
              <Grid item container direction="row" spacing={1} xs={12}>
                <Grid item xs={12}>
                  <SpText variant="text">{psTranslate(item?.name)}</SpText>
                </Grid>
                {getSortedAndTranslatedArray(
                  item?.assessment_elements,
                  null
                )?.map((currentAssessmentTemplateElement, idy) => (
                  <Grid
                    item
                    xs={12}
                    key={`current_assessment_template_element${idy}`}
                  >
                    <SpText variant="h4ComponentLabel">
                      {currentAssessmentTemplateElement}
                    </SpText>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container direction="row" xs={12} style={{ marginTop: "1%" }}>
          {/*Add assessment*/}
          <Grid container item direction="column" xs={12} spacing={3}>
            <Grid item xs={12}>
              <SpText variant="h1PageSubtitle">
                {
                  labels.patient.presentation.add.stepper.step4
                    .assessmentDetailRequest.categoryTitle
                }
              </SpText>
            </Grid>
          </Grid>
          <StyledRow>
            <StyledRowListHeader container spacing={1}>
              {assessmentsCategory.map((category, idx) => (
                <Grid
                  key={`category_${category.id}`}
                  item
                  container
                  xs={6}
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <SpCheckbox
                      rightlabel
                      variant="column"
                      onChange={(e) => {
                        let newSelectedCategories = [...selectedCategories];
                        newSelectedCategories[idx] = e.target.checked;
                        setSelectedCategories(newSelectedCategories);

                        let foundIds = [];
                        assessmentsCategory.map((newCategoryElem, idx) => {
                          if (newSelectedCategories[idx])
                            foundIds.push(newCategoryElem.id);
                        });
                        updateQuestionnairesList(foundIds);
                      }}
                      checked={selectedCategories[idx]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{ paddingLeft: "5px", alignSelf: "center" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate(category.name)}
                    </SpText>
                  </Grid>
                </Grid>
              ))}
            </StyledRowListHeader>
          </StyledRow>
          {/*Assessment element autocomplete*/}
          <Grid container direction="row" xs={12} style={{ marginTop: "1%" }}>
            {currentQuestionnairesList?.map((item) => (
              <Grid
                direction="column"
                container
                xs={6}
                style={{ padding: "5px" }}
                key={`current_questionnaire_list_${item.id}`}
              >
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    backgroundColor: "#31ccad",
                  }}
                />
                <SpText variant={"h4ComponentLabelColored"}>
                  {psTranslate(item?.category)?.toUpperCase()}
                </SpText>
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    backgroundColor: "#31ccad",
                  }}
                />
                <Grid item>
                  <SpAutocomplete
                    multiple
                    style={{ width: "100%", height: "100%", maxHeight: "100%" }}
                    formControlWidth={"100%"}
                    selectPlaceholder={labels.bundles.assessment.selectElements}
                    options={getSortedAndTranslatedArray(item.elements)}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(opt, value) => opt.id === value.id}
                    value={assessmentElements[item.id]}
                    onChange={(_, newValue) => {
                      let obj = { ...assessmentElements };
                      obj[item.id] = newValue;
                      setAssessmentElements(obj);
                    }}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        key={`option_${props.id}`}
                        style={{ color: option.selected ? "white" : "black" }}
                      >
                        <Checkbox
                          name={props.id}
                          icon={<CheckBoxOutlineBlankIcon small />}
                          checkedIcon={<CheckBoxIcon small />}
                          style={{
                            marginRight: 5,
                            color: option.selected ? "white" : "black",
                          }}
                          checked={option.selected}
                        />
                        {psTranslate(props.name)}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.name}
                          style={{ backgroundColor: "#31ccad", color: "white" }}
                          label={psTranslate(option.name)}
                          size="medium"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </StyledBodyColumn>
  );
};

export default withSnackbar(AddAssessmentTemplate);
