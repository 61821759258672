import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../../../components/atoms/SpTextInput";
import { labels } from "../../../../shared/translations";
import StudySharedControls from "./SharedControls";
import { computeInRange, genericIsEnabled } from "../../ReportHelperFns";

const moment = extendMoment(Moment);

const MIN_STUDY_DAYS = 1;
const MAX_STUDY_DAYS = 999;

const StudyACWREWMA = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [daysBeforeAcute, setDaysBeforeAcute] = useState(7);
  const [daysBeforeCronic, setDaysBeforeCronic] = useState(28);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const acrewmaOverPrevDays = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          //Acute EWMA
          let a = 2 / (daysBeforeAcute + 1);
          const acuteValues = computeInRange(
            dateRange,
            by,
            (range) =>
              moment
                .rangeFromInterval("day", -daysBeforeAcute, range.end)
                .reverseBy("day", { excludeStart: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            (values) =>
              values
                .reverse()
                .reduce((ewma, val) => val * a + ewma * (1 - a), 0)
          );
          //Cronic EWMA
          a = 2 / (daysBeforeCronic + 1);
          const cronicValues = computeInRange(
            dateRange,
            by,
            (range) =>
              moment
                .rangeFromInterval("day", -daysBeforeCronic, range.end)
                .reverseBy("day", { excludeStart: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            (values) =>
              values
                .reverse()
                .reduce((ewma, val) => val * a + ewma * (1 - a), 0)
          );

          //EWMA result
          accum[studyParamKey] = Object.entries(cronicValues).reduce(
            (accum, [key, cronicValue]) => {
              if (acuteValues[key] != null && cronicValue != null)
                accum[key] =
                  cronicValue != 0 ? acuteValues[key] / cronicValue : 0;
              return accum;
            },
            {}
          );

          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    let newData;
    if (active && isEnabled()) {
      onFetchDateDaysChange(-Math.max(daysBeforeAcute, daysBeforeCronic));
      newData = { [study.key]: { "": acrewmaOverPrevDays("day") } };
    } else {
      onFetchDateDaysChange(0);
      newData = { [study.key]: { "": {} } };
    }
    onStudyDataChange(newData);
  }, [
    active,
    data,
    dateRange,
    graphType,
    graphDateView,
    daysBeforeAcute,
    daysBeforeCronic,
  ]);

  //---- Config Section
  useEffect(() => {
    //Load config (only when all loading is done)
    if (config) {
      setActive(config.active);
      setDaysBeforeAcute(config.daysBeforeAcute);
      setDaysBeforeCronic(config.daysBeforeCronic);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        daysBeforeAcute: daysBeforeAcute,
        daysBeforeCronic: daysBeforeCronic,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    >
      <Grid container item xs={12} alignItems={"center"}>
        <Grid item xs={6}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.acute_load
            }
            value={daysBeforeAcute}
            onChange={(evnt) => {
              const value = evnt.target.value;
              if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
                setDaysBeforeAcute(value);
            }}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            label={
              labels.patient.graphReport.section.feedback.filters.chronic_load
            }
            value={daysBeforeCronic}
            onChange={(evnt) => {
              const value = evnt.target.value;
              if (value >= MIN_STUDY_DAYS && value <= MAX_STUDY_DAYS)
                setDaysBeforeCronic(value);
            }}
            type="number"
          />
        </Grid>
      </Grid>
    </StudySharedControls>
  );
};

export default StudyACWREWMA;
