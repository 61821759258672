import React, { useEffect, useState } from "react";
import "../../../../App.css";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import { Controller, useForm } from "react-hook-form";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Legend,
} from "recharts";

const TrainingReport = (props) => {
  const { control, setValue } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {}, []);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={props.dataChart}>
        <XAxis stroke="grey" dataKey="groupDate" />
        <YAxis allowDecimals={false} stroke="grey" />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#f5f5f5" />
        {props.barChar}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default withSnackbar(TrainingReport);
