import { Grid } from "@material-ui/core";
import React from "react";
import SpText from "../../components/atoms/SpText";
import { labels } from "../shared/translations";

const ColoredEventCell = ({ event }) => {
  const data = event?.data;
  const isArchived =
    event?.archiving_status === "completed_and_feedback_not_sent" ||
    event?.archiving_status === "completed_and_feedback_sent";

  //Motivation ordered by id
  const motivations = [
    data?.assessment_motivation,
    data?.treatments_motivation,
    data?.excercises_motivation,
    data?.training_motivation,
    data?.rest_motivation,
    data?.race_motivation,
    data?.rehabilitation_motivation,
  ];

  return (
    <div style={{ display: "flex", flex: 1 }}>
      <div
        className="rbc-event-label"
        style={{ display: "flex", flexDirection: "column", maxHeight: 50 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={event?.media} style={{ width: 30, height: 30 }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            {motivations.map((motivation, idx) => {
              const idActivityType = idx + 1;
              return (
                <Grid key={idActivityType} direction="column">
                  <SpText variant="h6BoldWhite" color={"white"}>
                    {event.id_activity_type === idActivityType && !motivation
                      ? event?.title
                      : motivation}
                  </SpText>
                  {event.id_activity_type === idActivityType && isArchived ? (
                    <SpText variant="textWhiteArchived">
                      {labels.groups.archived}
                    </SpText>
                  ) : (
                    ""
                  )}
                </Grid>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColoredEventCell;
