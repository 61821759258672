import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import moment from "moment";
import React from "react";
import { labels } from "../../../pages/shared/translations";
import "../App.css";
import SpIconButton from "../SpIconButton.js";
import SpText from "../SpText";

const commonDateFormat = "YYYY-MM-DD";
const zeroDate = moment.utc("1900-01-01", commonDateFormat);

const Sp3DModelDateSelection = ({
  enabled,
  symbols,
  filterDate,
  nowDate,
  updateSymbolDate,
  ...props
}) => (
  <>
    <Grid
      container
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        border: "1px solid #31caad",
        marginBottom: "10px",
      }}
    >
      <Grid item xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title={labels.bodymap.addSymbol.pastDateLabel}>
          <SpIconButton
            buttonType={"accept"}
            disabled={
              !nowDate ||
              filterDate.isSame(zeroDate) ||
              symbols.length === 0 ||
              !enabled
            }
            onClick={() => updateSymbolDate(false, true)}
          >
            <FastRewindIcon />
          </SpIconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={1} style={{ display: "flex", justifyContent: "flex-end" }}>
        <SpIconButton
          buttonType={"accept"}
          disabled={
            !nowDate ||
            filterDate.isSame(zeroDate) ||
            symbols.length === 0 ||
            !enabled
          }
          onClick={() => updateSymbolDate(false, false)}
        >
          <NavigateBeforeIcon />
        </SpIconButton>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <SpText variant={"h1PageSubtitle"}>
          {filterDate.isSame(zeroDate)
            ? labels.bodymap.addSymbol.pastDateLabel
            : symbols.length !== 0
            ? filterDate.format(commonDateFormat)
            : labels.bodymap.noSymbolToFilter}
        </SpText>
      </Grid>
      <Grid
        item
        xs={1}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <SpIconButton
          buttonType={"accept"}
          disabled={!nowDate || filterDate.isSame(nowDate) || !enabled}
          onClick={() => updateSymbolDate(true, false)}
        >
          <NavigateNextIcon />
        </SpIconButton>
      </Grid>
      <Grid
        item
        xs={3}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <SpIconButton
          buttonType={"accept"}
          disabled={!nowDate || filterDate.isSame(nowDate) || !enabled}
          onClick={() => updateSymbolDate(true, true)}
        >
          <FastForwardIcon />
        </SpIconButton>
      </Grid>
    </Grid>
  </>
);
export default Sp3DModelDateSelection;
