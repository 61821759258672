import { call } from "./utils";

export const getActivityFeedbacksInRange = async (data) =>
  await call({
    url: "/calendar/activity/feedbacks",
    data: { data },
  });

export const getAllCalendarActivityByMonitoring = async (data) =>
  await call({
    url: "/calendar/activity/by/monitoring",
    data: { data },
  });

export const getAllCalendarActivityByMonitGroup = async (data) =>
  await call({
    url: "/calendar/activity/by/monitoring/group",
    data: { data },
  });

export const getAllAgendaActivityByProfessional = async () =>
  await call({ url: "/calendar/activity/by/professional" });

export const createCalendarActivityMonitoring = async (data) =>
  await call({
    url: "/calendar/activity/create/by/monitoring",
    data: { data },
  });

export const updateCalendarActivityMonitoring = async (data) =>
  await call({
    url: "/calendar/activity/update/by/monitoring",
    data: { data },
  });

export const updateCalendarActivityMonitGroup = async (data) =>
  await call({
    url: "/calendar/activity/update/by/monitoring/group",
    data: { data },
  });

export const deleteCalendarActivityById = async (data) =>
  await call({
    url: "/calendar/activity/delete/by/id",
    data: { data },
  });

export const archiveActivityById = async (data) =>
  await call({
    url: "/calendar/activity/archive/by/id",
    data: { data },
  });

export const createCalendarActivityOfGroup = async (data) =>
  await call({
    url: "/calendar/activity/create/by/group",
    data: { data },
  });

export const createCalendarActivityTemplate = async (data) =>
  await call({
    url: "/calendar/activity/create/template",
    data: { data },
  });

export const getAllCalendarActivityTempById = async (data) =>
  await call({
    url: "/calendar/activity/get/template/by/id",
    data: { data },
  });

export const updateCalendarActivityTemplate = async (data) =>
  await call({
    url: "/calendar/activity/update/template",
    data: { data },
  });

export const updateActivityPlanTemplate = async (data) =>
  await call({
    url: "/calendar/activity/plan/update/template",
    data: { data },
  });
