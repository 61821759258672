import React from "react";
import { Grid } from "@material-ui/core";
import SpText from "../../../../components/atoms/SpText";

const NoData = ({ label }) => (
  <Grid item xs={12}>
    <Grid direction="column" container xs={12}>
      <SpText variant="h1PageTitle">{label}</SpText>
    </Grid>
  </Grid>
);

export default NoData;
