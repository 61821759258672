/**
 * A behavior that when attached to a mesh will allow the mesh to fade in and out
 */
export default class MeshFadeInOutBehavior {
  /**
   * Time in milliseconds to delay before fading in (Default: 0)
   */
  delay = 0;
  /**
   * Time in milliseconds for the mesh to fade in (Default: 300)
   */
  fadeTime = 300;

  _millisecondsPerFrame = 1000 / 60;

  _currOpacity = 0;
  _targetOpacity = 1;
  _opacityStep = 0;
  _timeElapsed = 0;

  _runningJob = null;
  _getVisibility = null;
  _setVisibility = null;

  /**
   *  The name of the behavior
   */
  name() {
    return "FadeInOut";
  }

  /**
   *  Initializes the behavior
   */
  init() {}

  /**
   * Attaches the fade behavior on the passed in mesh
   */
  attach(getVisibilityFn, setVisibilityFn) {
    this._getVisibility = getVisibilityFn;
    this._setVisibility = setVisibilityFn;
  }
  /**
   *  Detaches the behavior from the mesh
   */
  detach() {
    this._getVisibility = null;
    this._setVisibility = null;
  }

  /**
   * Triggers the mesh to begin fading in or out
   * @param value if the object should fade in or out (true to fade in)
   */
  fadeTo(value, time, delay = 0) {
    //Abort previously pending jobs
    if (this._runningJob) {
      clearTimeout(this._runningJob);
      this._runningJob = null;
    }

    //Prepare new job
    this._currOpacity = this._getVisibility();
    this._targetOpacity = parseFloat(value);
    if (Math.abs(this._targetOpacity - this._currOpacity) < Number.EPSILON)
      return;

    this.fadeTime = parseFloat(time);
    this._opacityStep =
      (this._targetOpacity - this._currOpacity) /
      (this.fadeTime / this._millisecondsPerFrame);
    this._timeElapsed = 0;

    //Start new job
    if (delay > 0) this._runningJob = setTimeout(this._update, delay);
    //Start next frame (to allow intra-frame opposite fade)
    else
      this._runningJob = setTimeout(this._update, this._millisecondsPerFrame);
  }

  _update = () => {
    this._timeElapsed += this._millisecondsPerFrame;

    if (this._timeElapsed < this.fadeTime) {
      //Update visibility
      this._currOpacity += this._opacityStep;
      this._setVisibility(this._currOpacity);

      //Keep going
      this._runningJob = setTimeout(this._update, this._millisecondsPerFrame);
    } else {
      //Done!
      this._setVisibility(this._targetOpacity);
      this._runningJob = null;
    }
  };
}
