import React from "react";

import { styled } from "../styled";
import Select from "@material-ui/core/Select";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SpText from "./SpText";
import { theme } from "../theme";
import { labels } from "../../pages/shared/translations";

export const SpSelectMenuItem = styled(MenuItem)({
  display: "flex ",
  flexDirection: "column ",
  padding: "5px 10px ",
  alignItems: "flex-start ",
  backgroundColor: "white ",
  textAlign: "center",
  paddingLeft: 3,
  marginTop: 1,
  marginBottom: 1,
  "&.Mui-selected": {
    backgroundColor: "#31ccad !important",
    color: "white !important",
    fontWeight: 600,
  },
});
export const SpSelectMenuItemEmpty = styled(MenuItem)({
  display: "flex ",
  flexDirection: "column ",
  padding: "5px 10px ",
  alignItems: "flex-start ",
  backgroundColor: "white ",
  textAlign: "center",
  paddingLeft: 3,
  marginTop: 1,
  marginBottom: 1,
  "&.Mui-selected": {
    backgroundColor: "white !important",
    color: "white !important",
    fontWeight: 0,
  },
});

const CustomFormControl = styled(({ formControlWidth, m, ...other }) => (
  <FormControl {...other} />
))({
  margin: (props) => theme.spacing(props.m),
  minWidth: 120,
  maxWidth: "100%",
  flex: 1,
  width: (props) => props.formControlWidth,
});

const CustomFormLabel = styled(FormLabel)({
  marginBottom: 5,
  paddingBottom: 0,
  color: "#31ccad",
  fontWeight: "bold",
  fontSize: 14,
  paddingTop: 8,
  textDecoration: "none",
});

const CustomSelect = styled(Select)(({ selectvariant }) => ({
  ...selectvariant,
  minWidth: "120px",
  height: 40,
  marginTop: "0px !important",
  // marginBottom: 8,
  paddingLeft: 10,
  paddingRight: 10,
  maxHeight: "30px",
  borderColor: "#31ccad",
  color: "white",
  border: "1px solid",
  backgroundColor: "#333333 !important",
  textAlign: "left",
  "& .MuiSvgIcon-root": {
    color: "white",
  },
}));

const formControlLabelPlacementTop = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const formLabelLabelPlacementTop = {
  marginBottom: 0,
  paddingRight: 8,
};

const variants = {
  darkGrey: {
    background: "#eceaf1",
  },
  lightGreen: {
    background: "transparent",
  },
  white: {
    background: "white",
  },
  noMargin: {
    margin: "0px !important",
    padding: "0px  !important",
    background: "transparent",
  },
  defaultItem: {
    fontStyle: "italic",
    fontSize: "0.875rem",
    color: "#B9B8BCFF",
  },
};

export const SpSelect = ({
  label,
  labelPaddingTop,
  labelMarginBottom,
  classes,
  children,
  displayLabel = true,
  selectVariant = "lightGreen",
  formControlWidth,
  formControlStyle,
  labelPlacementLeft,
  selectPlaceholder = "",
  multiple = false,
  allowDeselect = false,
  m = 1,
  ...rest
}) => {
  const controlStyle = {
    ...formControlStyle,
    ...(labelPlacementLeft && formControlLabelPlacementTop),
  };

  const labelStyle = labelPlacementLeft
    ? formLabelLabelPlacementTop
    : {
        color: "#31ccad",
        paddingTop: labelPaddingTop ? labelPaddingTop : null,
        marginBottom: labelMarginBottom ? labelMarginBottom : null,
      };

  const notEmpty = rest.value && rest.value?.length !== 0;

  if (allowDeselect) {
    if (notEmpty) {
      children = [
        <SpSelectMenuItem key={-10} value={""}>
          <span style={variants.defaultItem}>
            {labels.general.no_selection}
          </span>
        </SpSelectMenuItem>,
        ...children,
      ];
    }
    if (children?.length === 0) {
      //If empty, set empty menu item
      children?.push(
        <SpSelectMenuItemEmpty key={-10} value={""}>
          <span style={variants.defaultItem}>{labels.general.no_data}</span>
        </SpSelectMenuItemEmpty>
      );
    }
  }

  return (
    <CustomFormControl formControlWidth={formControlWidth} style={controlStyle}>
      {displayLabel ? (
        <CustomFormLabel style={labelStyle}>{label}</CustomFormLabel>
      ) : (
        <></>
      )}

      <CustomSelect
        classes={classes && classes}
        multiple={multiple}
        displayEmpty={!notEmpty}
        renderValue={
          !notEmpty
            ? (value) => (
                <SpText
                  style={{
                    opacity: value && value?.length !== 0 ? "inherit" : "0.5",
                  }}
                >
                  {selectPlaceholder}
                </SpText>
              )
            : null
        }
        disableUnderline
        variant="standard"
        selectvariant={variants[selectVariant]}
        {...rest}
      >
        {children}
      </CustomSelect>
    </CustomFormControl>
  );
};
