import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../../components/atoms/SpSelect";
import { labels } from "../../../../shared/translations";
import {
  averageInRange,
  genericIsEnabled,
  getMomentIdFromKey,
  getNDaysFromKey,
} from "../../ReportHelperFns";
import StudySharedControls from "./SharedControls";

const moment = extendMoment(Moment);

const LINE_KEY = "Line";
const HISTOGRAM_KEY = "Histogram";

const StudyTemporalEvolution = ({
  study,
  studiesMeasureUnits,
  graphDateView,
  graphType,
  currStudyParameters,
  dateRange,
  data,
  onFetchDateDaysChange,
  onStudyDataChange,
  config,
  setConfig,
  requestSaveFlag,
  defaultZero,
  ...props
}) => {
  const [active, setActive] = useState(true);
  const [selectedLineType, setSelectedLineType] = useState(LINE_KEY);

  const isEnabled = () => {
    return genericIsEnabled(
      currStudyParameters,
      study,
      studiesMeasureUnits,
      graphType,
      graphDateView
    );
  };

  const averageData = (by) => {
    //Iterate over subjects
    return Object.entries(data).reduce((accum, [subjectName, subjectData]) => {
      //Iterate over study parameters
      accum[subjectName] = Object.entries(subjectData).reduce(
        (accum, [studyParamKey, studyData]) => {
          //Compute avg (temporal is studies ahead)
          const range = moment
            .rangeFromInterval(by, 1, dateRange.end)
            .add(dateRange, { adjacent: true });
          accum[studyParamKey] = averageInRange(
            range,
            by,
            (range) => range.by("day", { excludeEnd: true }),
            (dayStr) => studyData[dayStr] ?? (defaultZero ? 0 : null),
            true,
            true
          );
          return accum;
        },
        {}
      );
      return accum;
    }, {});
  };

  //Effects
  useEffect(() => {
    //In case of week view, average over next 7 days
    onFetchDateDaysChange(getNDaysFromKey(graphDateView?.key));
    //Update filter data
    let newData;
    if (active && isEnabled())
      newData = {
        [study.key]: {
          [LINE_KEY]: averageData(getMomentIdFromKey(graphDateView?.key)),
        },
      };
    else newData = { [study.key]: { [LINE_KEY]: {} } };

    //Correct for histogram
    if (selectedLineType == HISTOGRAM_KEY) {
      newData[study.key][HISTOGRAM_KEY] = newData[study.key][LINE_KEY];
      newData[study.key][LINE_KEY] = {};
    } else newData[study.key][HISTOGRAM_KEY] = {};

    //Set new data
    onStudyDataChange(newData);
  }, [active, data, dateRange, graphType, graphDateView, selectedLineType]);

  //---- Config Section
  const [configDone, setConfigDone] = useState(false);
  useEffect(() => {
    setConfigDone(false);
  }, [config]);
  useEffect(() => {
    //Load config (only when all loading is done)
    if (!configDone && config) {
      setActive(config.active);
      setSelectedLineType(config.selectedLineType ?? LINE_KEY);
      setConfigDone(true);
    }
  }, [config]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      setConfig({
        active: active,
        selectedLineType: selectedLineType,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  //Render
  return (
    <StudySharedControls
      title={labels.patient.graphReport.section.feedback.studies[study.key]}
      active={active}
      setActive={setActive}
      enabled={isEnabled()}
    >
      <SpSelect
        label={labels.patient.graphReport.section.feedback.filters.graph_types}
        formControlWidth={"100%"}
        labelPaddingTop={"0"}
        value={selectedLineType}
        onChange={(evnt) => setSelectedLineType(evnt.target.value)}
      >
        <SpSelectMenuItem key={LINE_KEY} value={LINE_KEY}>
          {labels.patient.graphReport.section.feedback.lines[LINE_KEY]}
        </SpSelectMenuItem>
        <SpSelectMenuItem key={HISTOGRAM_KEY} value={HISTOGRAM_KEY}>
          {labels.patient.graphReport.section.feedback.lines[HISTOGRAM_KEY]}
        </SpSelectMenuItem>
      </SpSelect>
    </StudySharedControls>
  );
};

export default StudyTemporalEvolution;
