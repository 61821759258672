import { call } from "./utils";

export const getPatientsByProfessional = async () =>
  await call({ url: "/professional/patients/by/professional" });

export const getPatientsByProfessionalDetails = async () =>
  await call({ url: "/professional/patients/by/professional/details" });

export const getProfessionalById = async () =>
  await call({ url: "/professional/by/id" });

export const getLibrary = async () => await call({ url: "/library" });

export const getLicenceInformation = async (data) =>
  await call({
    url: "/professional/licence/information",
    data: { data },
  });

export const getProfessionalByEmail = async (data) =>
  await call({ url: "/professional/by/email", data: { data } });

export const getProfessionalPatientByEmail = async (data) =>
  await call({
    url: "/professional/patients/by/email",
    data: { data },
  });

export const getPatientToProfessionalRequest = async () =>
  await call({ url: "/professional/patient/request" });

export const transferPatientToProfessional = async (data) =>
  await call({
    url: "/professional/transfer/patient",
    data: { data },
  });

export const addResourceToPatientProfessional = async (data) =>
  await call({
    url: "/professional/add/resources",
    data: { data },
  });

export const addResourceToServer = async (data) =>
  await call({
    url: "/professional/add/resources/to/server",
    data: data, //Intentionally without { } to allow file upload
  });

export const getResourceFromServer = async (data) =>
  await call({
    url: "/professional/get/resources/from/server",
    data: { data },
  });

export const removeResourceToPatientProfessional = async (data) =>
  await call({
    url: "/professional/remove/resources",
    data: { data },
  });

export const getAllResourcesToPatientProfessional = async (data) =>
  await call({
    url: "/professional/list/resources",
    data: { data },
  });

export const getProfessionalStats = async (data) =>
  await call({ url: "/professional/get/stats", data: { data } });
