import { GraphicEqSharp } from "@material-ui/icons";
import React from "react";

export const labels = {
  serverMessages: {
    groupDelete: {
      error: "Non sono riuscito ad eliminare il gruppo",
      success: "Gruppo eliminato con successo",
    },
    groupExit: {
      error: "C'è stato qualche problema nell'uscire dal gruppo",
      success: "Uscita dal gruppo avvenuta correttamente",
    },
  },
  general: {
    loading_data: "Caricamento dati...",
    no_selection: "Nessun elemento",
    yes: "Si",
    no: "No",
    no_data: "Nessun dato presente",
    confirm: "Conferma",
    skip: "Salta",
    update: "Aggiorna",
    saveAndClose: "Salva e chiudi",
    reset: "Reset filtro",
    back: "Indietro",
    trialText: "Il tuo account è in fase di Trial",
    uploadLimitSize: "Limite upload: %sMB",
  },
  analytics: {
    selectPDFGraph: "Seleziona per il PDF",
    updatePDFGraph: "Aggiorna PDF",
    disturbedAreas: "Aree perturbate",
    disorders: "Disordini",
    filterByRegion: "Filtra per regione",
    filterByDisorder: "Filtra per disordine",
    disturbedRegions: "Regioni perturbate",
    remove: "Rimuovi",
    add: "Aggiungi",
    save: "Salva",
    account: {
      activity: "Attività",
      sessionNumber: "Numero di sessioni svolte",
      startDate: "Data di inizio",
      endDate: "Data di fine",
      helperText1: "Dati aggiornati dal",
      helperText2: "al",
      updateData: "Aggiorna dati",
      reset: "Reset",
      avgPresSatisfaction: "Soddisfazione media presentazioni",
      patNumber: "Numero pazienti",
      groupNumber: "Numero gruppi",
      actNumber: "Numero attività totali",
      summaryPresentation: "Riepilogo presentazioni",
      presTypeFreq: "Tipologia più frequente",
      presLonger: "Presentazione più lunga",
      areaAffected: "Area più colpita",
      structureAffected: "Struttura più colpita",
      summaryActivity: "Riepilogo attività",
      activityFreq: "Attività più frequente",
      days: "giorni",
    },
    injuryReport: {
      selectGroup: "Seleziona il gruppo",
      selectPatiets: "Seleziona i pazienti",
      selectGraph: "Seleziona il grafico",
      selectRegion: "Seleziona le regioni",
      selectArea: "Seleziona le aree",
      monthAvarage: "Media mensile",
      percentage: "Calcolo percentuale",
      patientsGroup: "Pazienti del gruppo",
      patientsOtherGrous: "Pazienti di altri gruppi",
      avarageGroup: "Media dei gruppi",
      totalGroup: "Totale dei gruppi",
      total: "Totale",
      selectTissueType: "Seleziona il tipo di tessuto",
      selectGravity: "Seleziona la gravità",
      selectReinjury: "Seleziona il reinfortunio",
      proportionReinjuryLabel: "Il grafico mostra dati in percentuale",
      createPdf: "Scarical il PDF",
      graphs: {
        training: "Allenamento",
        partita: "Partita",
        ratioTM: "Rapporto allenamenti/gare",
        localizationDistribution: "Distribuzione per localizzazione",
        tissueType: "Distribuzione per tipo tessuto",
        gravity: "Distribuzione per gravità",
        reinjury: "Distribuzione per reinfortunio",
        altro: "Altro",
        daysAbsence: "Giorni d'assenza",
        trainingInjuriesBurden: "Burden dei giorni d'assenza",
        severeInjuryPattern: "Pattern di infortuni gravi",
        severeInjuryRate: "Rapporto infortuni severi",
        injuryRate: "Rapporto infortuni",
        reinjuryProportion: "Proporzione di re-infortunio",
      },
      gravityRange: [
        { name: "Minima (1-3 giorni)", id: 1, key: "minimal", min: 1, max: 3 },
        { name: "Lieve (4-7 giorni)", id: 2, key: "mild", min: 4, max: 7 },
        {
          name: "Moderata (8-28 giorni)",
          id: 3,
          key: "moderate",
          min: 8,
          max: 28,
        },
        {
          name: "Severa (>28 giorni)",
          id: 4,
          key: "severe",
          min: 29,
          max: 1000,
        },
      ],
      reinjuryRange: [
        { id: 1, name: `no` },
        { id: 2, name: `sì` },
      ],
    },
  },
  nav: {
    dashboard: "DASHBOARD",
    patients: "PAZIENTI",
    groups: "GRUPPI",
    agenda: "AGENDA",
    support: "SUPPORTO",
    library: "LIBRERIA MEDICA",
    account: "ACCOUNT",
    stats: "STATISTICHE",
    mylab: "MY LAB",
  },
  components: {
    picker: {
      selectDate: "Seleziona data",
    },
    search: {
      search: "Cerca…",
    },
  },
  shared: {
    addSymbol: {
      selectRegion: "Seleziona la regione",
      selectArea: "Seleziona l'area",
      selectStructure: "Seleziona la struttura",
      selectDisorder: "Seleziona il disordine",
      selectDysfunctionCategory: "Seleziona la disfunzione",
      selectDysfunction: "Seleziona il tipo di disordine",
      selectSeverity: "Seleziona gravità",
    },
  },
  agenda: {
    selectPatient: "Seleziona un paziente",
    inputDetails: {
      totalMins: "minuti totali",
    },
    card: {
      subject: "Oggetto",
      prepare: "Prepara scheda",
      time: "Orario",
      goToActivityPlan: "Vai al piano attività",
    },
  },
  bundles: {
    assessment: {
      selectElements: "Seleziona gli elementi...",
      selectRegion: "Seleziona regione",
      selectTemplate: "Seleziona template",
      symbols: {
        title: "Aggiungi simboli su mappa",
        description:
          "Seleziona tutte le disfunzioni che vuoi che vengano inserite automaticamente sulla mappa e clicca su <u>Conferma</u>. Altrimenti clicca su <u>Salta</u> per inserire soltanto l'assessment",
      },
      renderUtils: {
        additionalNotes: "Note aggiuntive",
        buttons: {
          prev: "Precedente",
          next: "Successivo",
        },
      },
    },
    calendarPlan: {
      feedback: {
        hint: "Compila i parametri sottostanti con le rilevazioni",
        save: "Salva feedback",
      },
    },
  },
  bodymap: {
    noSymbolToFilter: "Non ci sono simboli da filtrare",
    selectDysfunctionCategory: "Seleziona la disfunzione/sintomatologia",
    resetButton: "Reset zoom",
    deleteButton: "Elimina",
    updateButton: "Aggiorna",
    musclesLabel: "Muscoli",
    skeletonLabel: "Scheletro",
    connectiveLabel: "Tessuto connettivo",
    cartilageLabel: "Cartilagine",
    nervousLabel: "Sistema nervoso",
    skinOpacity: "Bodymap",
    legend: "Relative perturbation index",
    legendValues: {
      low: "Less",
      high: "More",
    },
    detailPage: {
      title: "Dettaglio Area",
      subtitle: `Qui trovi l'area selezionata e le strutture ad essa collegate`,
      structureList: {
        title: "Strutture",
      },
      dysfunctionsList: {
        title: "Disfunzioni",
      },
    },
    dialogDetail: {
      title: "Dettaglio disordine",
      titleRiskFactor: "Dettaglio fattore di rischio",
      structureLabel: "Struttura",
      regionLabel: "Regione",
      areaLabel: "Area",
      disorderLabel: "Disordine",
      dysfunctionCategoryLabel: "Categoria disordine",
      dysfunctionLabel: "Disfunzione specifica",
      severityLabel: "Gravità",
      inputDateLabel: "Inserimento",
    },
    severityLabels: {
      1: "Lieve",
      2: "Medio",
      3: "Forte",
    },
    addSymbol: {
      presentationLabel: "Presentazione",
      monitoringLabel: "Monitoraggio",
      dateLabel: "Data inserimento simbolo",
      pastDateLabel: "Evento passato",
    },
    modelBehavior: {
      informative: "INFORMATIVE",
      marker: "MARKER",
    },
  },
  login: {
    title: "Login",
    email: "Email",
    password: "Password",
    error: "Nome utente o password errati",
    emailRequired: `Inserire una mail`,
    passwordRequired: `Inserire una password`,
    passwordLost: "Hai dimenticato la password?",
    companyAccess: "Accesso come azienda",
    buttons: {
      login: "Login",
      registration: "Registrazione",
    },
    passwordLostDialog: {
      title: "Recupera password",
      subtitle: "Inserisci la mail alla quale inviare il link di recupero",
      label: "Email",
      send: "Invia",
    },
  },
  dashboard: {
    requestAccepted: "Richiesta accettata!",
    requestRefused: "Richiesta rifiutata!",
    title: "La tua Dashboard",
    patientsNumber: "Tuoi assistiti",
    dailyAppointment: {
      title: "Appuntamenti di oggi",
      noAppointments: "Non ci sono appuntamenti",
      goToAgenda: "agenda",
      table: {
        header: { object: "Oggetto", patient: "Paziente", time: "Orario" },
      },
    },
    patientRequests: {
      title: "richieste di collegamento pazienti",
      goToPatients: "i tuoi pazienti",
      table: {
        header: { name: "Nome" },
        requestRow: { accept: "accetta", refuse: "rifiuta" },
      },
    },
    operations: {
      title: "notifiche",
      descriptionTitle: "Descrizione",
      actions: "",
      noOperations: "Non ci sono notifiche",
      table: {
        header: { title: "Titolo", description: "Descrizione" },
        operationsRow: { accept: "accetta", refuse: "rifiuta" },
      },
    },
  },
  account: {
    title: "Informazioni personali",
    license_title: "Informazioni abbonamento",
    accountForm: {
      givenName: "Nome",
      familyName: "Cognome",
      email: "Email",
      password: "Password",
      fiscalCode: "Codice Fiscale",
      birthDate: "Anno di nascita",
      piva: "Partita IVA",
      telephone: "Cellulare",
      address: "Indirizzo",
      city: "Città",
      cap: "Cap",
      province: "Provincia",
      nation: "Nazione",
      jobs: "Professione",
    },
    licenseForm: {
      parentUser: "Azienda",
      licenses: "N° licenze",
      years: "Durata abbonamento",
      expiryDate: "Scadenza abbonamento",
      activationDate: "Inizio abbonamento",
      validLicense: "Licenza valida",
      trial: "Trial",
    },
    toolbar: {
      addPaz: "Aggiungi paziente",
      linkPaz: "Collega paziente",
      backToDash: "Torna alla sashboard",
      report: "Statistiche",
      logout: "Log-out",
      faq: "FAQ",
      modify: "Modifica",
      go_to_account: "Vai all'account",
      myLab: "My lab",
      addGroup: "Aggiungi gruppo",
    },

    accountReports: {
      title: "Statistiche",
      inputs: {
        fromDate: "Da",
        toDate: "A",
        nunmberOfTreatment: "Numero di trattamenti",
      },
      table: {
        title: "Localizzazione trattamenti",
        header: {
          region: "Regione colpita",
          presentationNumber: "Numero di presentazioni",
          result: "Esito",
          date: "Data trattamento",
        },
      },
    },
  },
  groups: {
    roles: { admin: "Amministratore", reader: "Lettore" },
    title: "Gruppi",
    noGroups: "Nessun gruppo associato!",
    archived: "Archiviata",
    plan: {
      prev: "Precedente",
      today: "Oggi",
      next: "Successivo",
      selectAll: "Seleziona tutti",
      deselectAll: "Deseleziona tutti",
      chooseActivity: "Scegli l'attività da aggiungere",
    },
    errors: {
      selectAtLeastAPatient: "Seleziona almeno un paziente!",
      deleteAction:
        "Per poter eliminare un gruppo, devi eliminare prima tutti i pazienti e i professionisti all'interno di quest'ultimo",
    },
    toolbar: {
      add: "Aggiungi gruppo",
      reports: "Report gruppo",
      back: "Gruppi",
      backToDetail: "Dettaglio gruppo",
      statistics: "Statistiche",
      activityPlan: "Piano attività",
      deleteExit: "Cancella/Esci",
    },
    table: {
      header: {
        group: "Nome",
        professional: "Professionisti",
        patient: "Pazienti",
        actions: "",
      },
      actions: {
        view: "Visualizza",
      },
      deleteAction: {
        title: "Cancella il gruppo",
        content:
          "Sei sicuro di voler cancellare il gruppo? L'operazione è irreversibile",
      },
      exitAction: {
        title: "Esci dal gruppo",
        content:
          "Sei sicuro di voler uscire dal gruppo? Non potrai più vedere i dati dei pazienti presenti al suo interno. L'operazione è irreversibile",
      },
    },
    groupAdd: {
      title: "Nome gruppo",
      subtitle: "Inserisci le informazioni per aggiungere un gruppo",
      name: "Nome del gruppo",
      description: "Descrizione del gruppo",
      addPatientTable: {
        title: "Pazienti nel gruppo",
        addPatientButton: "Aggiungi pazienti",
        serachButton: "Cerca",
        dialog: {
          title: "Scegli paziente",
          addProfessionalButton: "Aggiungi",
          usersFoundPlaceholder: "Non ci sono pazienti",
        },
        header: {
          name: "Nome",
          actions: "",
        },
        add: "Aggiungi",
      },
      addProfessionalTable: {
        title: "Professionisti nel gruppo",
        addProfessionalButton: "Aggiungi professionista",
        serachButton: "Cerca",
        dialog: {
          title: "Scegli professionista",
          addProfessionalButton: "Aggiungi",
          usersFoundPlaceholder: "Nessun professionista trovato",
          searchText: "Inserisci email del professionista",
        },
        header: {
          name: "Nome",
          role: "Ruolo",
          actions: "",
        },
        add: "Aggiungi",
        roleSelect: "Seleziona ruolo",
        searchButton: "Inserisci email del professionista",
      },
      actions: {
        save: "Salva",
      },
    },
    groupDetail: {
      title: "Dettaglio gruppo",
      subtitle: "Visualizza le informazioni per aggiungere un gruppo",
      name: "Nome del gruppo",
      in_presentation: "In presentazione",
      searchPatient: "Cerca un paziente",
      searchProfessional: "Cerca un professionista",
      assignRole: "Assegna ruolo",
      chooseRole: "Scegli un ruolo",
      in_good_health: "In salute",
      errors: {
        professionalAlreadyExists: "Professionista già presente",
        patientAlreadyExists: "Paziente già presente",
        selectRole: "Devi assegnare un ruolo al professionista",
      },
      description: "Descrizione del gruppo",
      deletePatientDialog: {
        title: "Eliminazione paziente",
        subtitle:
          "Sei sicuro di voler eliminare il paziente e tutti i suoi collegamenti con i professionisti? Cliccando su NO, eliminerai solo il paziente dal gruppo. Cliccando su SI' eliminerai anche le relazioni associate",
        yes: "Sì",
        no: "No",
      },
      deleteProfessionalDialog: {
        title: "Eliminazione professionista",
        subtitle:
          "Sei sicuro di voler eliminare il professionista e tutti i suoi collegamenti con i pazienti?  Cliccando su NO, eliminerai solo il professionista dal gruppo. Cliccando su SI' eliminerai anche le relazioni associate",
        yes: "Sì",
        no: "No",
      },
      addPatientTable: {
        title: "Pazienti nel gruppo",
        addPatientButton: "Aggiungi paziente",
        serachButton: "Cerca",
        header: {
          name: "Nome",
          actions: "",
          status: "Stato",
        },
        add: "Aggiungi",
        searchButton: "Inserisci email del paziente",
      },
      addProfessionalTable: {
        title: "Professionisti nel gruppo",
        searchButton: "Aggiungi professionista",
        serachButton: "Cerca",
        header: {
          name: "Nome",
          role: "Ruolo",
          actions: "",
        },
        add: "Aggiungi",
        roleSelect: "Seleziona ruolo",
        searchText: "Inserisci email del professionista",
      },
      actions: {
        save: "Salva",
      },
    },
    groupReports: {
      no_patients: "Non ci sono pazienti all'interno del gruppo",
      firstTable: {
        title: "Situazione pazienti",
        header: {
          patient: "Paziente",
          status: "Stato",
          reason: "Motivo presentazione",
          startDate: "Data inizio presentazione",
          endDate: "Data fine presentazione",
          presDuration: "Durata presentazione (giorni)",
        },
      },
      secondTable: {
        chooseActivity: "Scegli le attività da filtrare",
        chooseParameter: "Scegli i parametri da filtrare",
        chooseAssessment: "Scegli le valutazioni da filtrare",
        chooseMeasurements: "Scegli le misurazioni da filtrare",
        selectValue: "Seleziona un valore...",
        title: "Sezione attività/feedback",
        searchInputStart: "Data inizio filtro",
        searchInputEnd: "Data fine filtro",
        searchReset: "Resetta filtro",
        sessions: "Sessioni ",
        extra: {
          rank: "Rank",
          zScore: "Z-Score",
          coefficientOfVariation: "Coefficiente di variazione",
        },
        categories: {
          latest: "Ultimo",
          best: "Migliore",
          worst: "Peggiore",
          average: "Medio",
        },
      },
      thirdTable: {
        title: "Giorni indisponibilità",
        startDateFirstPeriod: "Inserisci data inzio primo periodo",
        endDateFirstPeriod: "Inserisci data fine primo periodo",
        startDateSecondPeriod: "Inserisci data inzio secondo periodo",
        endDateSecondPeriod: "Inserisci data fine secondo periodo",
        filter: "Filtra",
        unavalDaysFirstPeriod: "Giorni indisponibilità primo periodo",
        unavalDaysSecondPeriod: "Giorni indisponibilità secondo periodo",
        diffUnavalDays: "Differenza giorni indisponibilità",
      },
    },
    groupActivityPlan: {
      addActivity: {
        patientsSelect: {
          label: "Seleziona pazienti da quelli appartenenti al gruppo",
          placeholder: "Seleziona dei pazienti",
        },
        searchInputEnd: "Data fine filtro",
        searchReset: "Resetta filtro",
      },
    },
  },
  registration: {
    title: "Registrazione",
    emailRequired: `Inserire una mail`,
    passwordRequired: `Inserire una password`,
    passwordConfirm: `Le password non corrispondono`,
    form: {
      givenName: "Nome",
      familyName: "Cognome",
      birthDate: "Data di nascita",
      sex: "Sesso",
      email: "Email",
      username: "Nome utente",
      password: "Password",
      confirmPassword: "Conferma password",
    },
    actions: {
      registration: "Registrati",
      backToLogin: "Login",
    },
  },
  patient: {
    proms: {
      errors: {
        no_answers: "Non sono ancora state date risposte al questionario",
      },
      symptomsAndStiffness: "Sintomi e stiffness",
      pain: "Dolore",
      dailyActivities: "Attività quotidiane",
      sport: "Sport",
      qualityOfLife: "Qualità della vita",
      physicalFunctionalitiesAndDailyLife:
        "Funzionalità fisica e vita quotidiana",
      functionalAndSportActivities: "Attività funzionali e sportive",
      physicalActivities: "Attività fisiche",
      functionality: "Funzione",
      finalScore: "Overall score",
      sleepQuality: "Qualità del sonno",
      muscularPain: "Dolenzia muscolare",
      stressLevel: "Livello di stress",
      fatigueLevel: "Livello di fatica",
    },
    list: {
      addPaz: "Aggiungi paziente",
      linkPaz: "Collega paziente",
      table: {
        patientRow: {
          status: { onPresentation: "In presentazione", healthy: "In salute" },
        },
      },
    },
    status: { onPresentation: "In presentazione", healthy: "In salute" },
    component: {
      backButtonLista: "Pazienti",
      noPatients: "Non ci sono pazienti associati!",
    },
    sideBarPatEdit: {
      anagPat: "Anagrafica",
      pastHistory: "Storia",
      monit: "Monitoraggio",
      pres: "Presentazioni",
      viewPlan: "Piano attività",
      statistics: "Statistiche",
      graphReport: "Analytics",
      addSymbol: "Bodymap",
      controlQuest: "Feedback",
      ris: "Risorse",
      transfPat: "Trasferisci",
      dettPaz: "Status",
      deletePatient: "Elimina",
      deletePatientDialogTitle: "Elimina paziente",
      deletePatientDialogContent:
        "Sei sicuro di voler eliminare il paziente? L'operazione è irreversibile!",
    },
    patientEditInput: {
      name: "Nome paziente",
      birthDate: "Data di nascita",
      phone: "Telefono",
      job: "Professione",
      from: "Da",
      to: "A",
      dysfunction: "Filtra per disfunzioni",
      modelFiltering: "Ricerca modello 3D",
      region: "Filtra per regioni",
      area: "Filtra per area",
      pathology: "Filtra per patologie",
    },
    patientAdd: {
      givenName: "Nome",
      familyName: "Cognome",
      email: "Email",
      coupon: "Codice APP",
      title: "Aggiungi un paziente",
      addPatient: "Aggiungi paziente",
    },
    patientLink: {
      title: "Collegati ad un paziente",
      inputSearch: {
        label: "Inserisci la mail esatta del paziente",
        label2: "Inserisci la mail o il nome del paziente",
        buttons: {
          search: "Cerca",
        },
      },
      patientsList: {
        placeholder: "Non ci sono pazienti",
        buttons: {
          link: "Collegati",
        },
      },
      cancelButton: "Annulla",
    },
    addSymbols: {
      symbolManaging: {
        title: "Gestione simboli",
        add: "Aggiungi",
      },
      model3DFilter: {
        title: "Bodymap",
        add: "Reset zoom",
      },
      filter: {
        riskFactorEnabled: "Nascondi Risk factors",
        riskFactorDisabled: "Mostra Risk factors",
      },
      isAddPresentation: "Vuoi creare una presentazione?",
      pathologySelect: "Seleziona la patologia",
    },
    table: {
      header: {
        patients: "Pazienti",
        age: "Età",
        last_visit: "Stato",
        actions: "",
      },
      actions: {
        groups: "Gruppi",
        report: "Report",
      },
    },
    patientRegistry: {
      steps: ["Sintesi", "Anagrafica"],
      submitButton: "Salva dati",
      printButton: "Stampa",
      title: "Scheda anagrafica paziente",
      nameContact: "Nome e contatti",
      registryData: "Dati anagrafici",
      antropBase: "Antropometria di base",
      workActivity: "Attività lavorativa",
      seatPos:
        "Il tuo lavoro richiede il mantenimento prolungato della posizione seduta?",
      standPos:
        "Il tuo lavoro richiede il mantenimento prolungato della posizione in piedi? ",
      lift: "Il tuo lavoro richiede il continuo sollevamento di oggeti?",
      mental: "Il tuo lavoro è molto strassante fisicamente o mentalmente?",
      relation:
        "Pensi che il tuo problema attuale sia realazionato con il tuo lavoro?",
      sportAct: "Attività sportiva o ricreativa",
      givenName: "Nome",
      familyName: "Cognome",
      birthDate: "Data di nascita",
      job: "Professione",
      sex: "Sesso",
      sexMan: "Uomo",
      sexWoman: "Donna",
      sexBinary: "Non binario",
      yes: "Sì",
      no: "No",
      right: "Destra",
      right_m: "Destro",
      left_m: "Sinistro",
      left: "Sinistra",
      doctor: "Medico curante",
      address: "Indirizzo",
      phone: "Telefono",
      emergencyContactName: "Nome contatto di emergenza",
      emergencyContactPhone: "Telefono contatto di emergenza",
      email: "Email",
      weight: "Peso (Kg)",
      height: "Altezza (cm)",
      bmi: "bmi",
      dominantHand: "Mano dominante",
      dominantFoot: "Piede dominante",
      workingActivity: "Nome",
      activityType: "Tipo di attività sportiva praticata",
      fitnessLevel: "Attuale livello di fitness",
      sportPracticeLevel: "Livello di pratica sportiva",
      functionalRequest:
        "Richiesta funzionale o livello di fitness che vorresti raggiungere",
    },
    pastHistory: {
      steps: ["Sintesi", "Storia", "Patologie"],
      lastEightWeeks: "RICHIEDI COMPILAZIONE ULTIME 8 SETTIMANE",
      stepper: {
        sumup: {
          additionalInfoTitle: "Informazioni aggiuntive",
        },
        pathologies: {
          title: "Patologie",
          pathologyRemoved: "Patologia rimossa con successo!",
          compileAllFields:
            "Devi compilare tutti i campi obbligatori (Inserisci almeno le patologie)",
          save: "Salva",
          pathology: "Patologia",
          operationType: "Tipo operazione",
          pathologyDate: "Data patologia",
          currentStatus: "Stato corrente",
          rehabDuration: "Durata riabilitazione (giorni)",
          addPathology: "Aggiungi patologia",
          structure: "Struttura",
          dysfunction: "Disfunzione",
          dysfunction_category: "Categoria disfunzione",
          pathologyDelete: {
            content: "Sei sicuro di voler eliminare questa patologia?",
            title: "Elimina patologia",
          },
        },
      },
      title: "Storia remota paziente",
      subtitle:
        "Per favore indica se tu o uno dei tuoi familiari più vicini (genitori,fratelli,figli) avete mai sofferto di una delle seguenti condizioni:",
      allergie: "Allergie",
      angina: "Angina o dolore toracico",
      ansia: "Ansia o attacchi di panico",
      artrite: "Artrite reumatoide",
      asma: "Asma",
      cirrosi: "Cirrosi o condizioni relative al fegato",
      colesterolo: "Colesterolo altro",
      osteoporosi: "Osteoporosi",
      revSystemsTit: "Revisione dei sistemi e storia remota chirurgica",
      revSystemsSubt:
        "Per favore indica se nella tua vita hai mai avuto problemi di (considera che alcune voci potrebbero essere ripetute) : ",
      useAccessories: "Per favore indica se utilizzi : ",
      osteoPorosys: "Osteoporosi",
      endoCrinology: "Endocrinologici",
      lifeStyle: "Stile di vita",
      desc: "Descrizione",
      approxDate: "Data approssimativa",
      intType: "Tipo di intervento",
      status: "Stato attuale",
      handicap: "Handicap o menomazioni",
      streetAccident: "Incidenti stradali o traumi maggiori",
      actualHealtVal: "Come valuteresti il tuo stato di salute?",
      dietQuality: "Come valuteresti la qualità della tua dieta",
      sleepQuality: "Come valuteresti la qualità del tuo sonno",
      pharma: "Stai prendendo farmaci attualmente",
      supp: "Stai prendendo supplementi nutrizionali attualmente",
      diet: "Stai seguendo un particolare regime dietetico",
      lossWeight: "Hai mai avuto perdite di peso inspiegabili nell'ultimo mese",
      tobacco: "Fai uso ti tabacco (quante sigarette al giorno)",
      alcool: "Fai uso di alcool (Quante porzioni al giorno)",
      coffee: "Consumi caffeina (quanti caffè al giorno)",
      plantars: "Plantari",
      dentBite: "Apparecchio dentale o bite dentale",
      acousticApp: "Apparecchio acustico",
      glasses: "Occhiali o lenti a contatto",
      prothesys: "Altri tipi di protesi",
      additionalInfoTitle: "Storia attuale personale (ultime 6 settimane)",
      additionalInfoSubtitle:
        "Per favore, indica se soffri attualmente, o hai sofferto recentemente di uno dei seguenti problemi:",
      yes: "Sì",
      no: "No",
      patientPathologiesTitle: "Patologie del paziente",
      patientPathologiesSubtitle: "Lista delle patologie passate del paziente",

      patientAddPathologiesTitle: "Aggiungi nuove patologie",
      patientAddPathologiesSubtitle:
        "Per ogni patologia indica la data e clicca su salva",

      whereMuscPast: "Dove",
      eventMuscPast: "Evento",
      intTypeMuscPast: "Tipo di intervento",
      dateMuscPast: "Data",
      rehabDuration: "Tempo di recupero(giorni)",
      actualStatusMuscPast: "Stato attuale",
      submitButton: "Salva dati",
      pathologyDate: "Date (approx.)",
      pathologyName: "Tipologia Patologia",
      pathologyOperationType: "Tipologia Operazione",
    },
    monitoring: {
      noPathologies: "Non sono ancora state inserite malattie",
      addProms: "Inserisci proms",
      addAssessment: "Inserisci",
      closeAddAssessment: "Chiudi",
      steps: ["Bodymap", "Assessment", "PROMs", "Malattie", "Tabella"],
      repeatAssessment: "Ripeti assessment",
      addMonitoring: "Inserisci monitoraggio",
      table: {
        in_good_health: "In salute",
        in_presentation: "In presentazione",
        in_illness: "In malattia",
        prevTrimester: "PRECEDENTE TRIMESTRE",
        nextTrimester: "SUCCESSIVO TRIMESTRE",
        startDate: "Data di inizio",
        endDate: "Data di fine",
        reset: "Resetta filtro",
        patientSituation: "Situazione del paziente",
        status: "Stato",
        statusTable: "Tabella stato",
        dayInPresentation: "Giorni in presentazione",
        dayInGoodHealth: "Giorni in salute",
        dayInIllness: "Giorni in malattia",
        activityMonitoringTable: "Tabella monitoraggio attività",
        activity: "Attività",
        sessionNumber: "Numero di sessioni",
        minutesNumber: "Numero in minuti",
      },
      assessments: {
        noAssessments: "Non sono ancora stati inseriti assessment",
        chooseAssessmentTemplate: "Scegli un template di assessment",
        header: {
          name: "Nome",
          date: "Data",
        },
        inputs: {
          selectTemplate: "Seleziona il template assessment",
          selectRegion: "Seleziona la regione dell'assessment",
        },
      },
      title: "Monitoraggio",
      actualPatientStatusTitle: "Stato attuale paziente",
      addPromsLayout: {
        title: "Aggiungi PROMS",
        subtitle:
          "Compila i PROMs del paziente o demanda a lui questo compito. Il paziente potrà compilarla attraverso la sua app dedicata.",
        buttonBack: "Chiudi inserimento",
        sendButton: "Invia",
      },
      addPathology: {
        tissueType: "Selezione tipo di tessuto",
        etiology: "Seleziona eziologia",
        medicalSystemType: "Seleziona sistema medicale",
        pathologyType: "Seleziona tipo di patologia",
        pathology: "Seleziona malattia",
      },
      pathologiesStep: {
        title: "Malattie",
        table: {
          toDate: "Al",
          fromDate: "Dal",
        },
        selectMedicalSystem: "Seleziona sistema medicale",
        selectPathology: "Seleziona patologia",
        saveAndClose: "Salva e chiudi",
        reset: "Resetta filtro",
        note: "Note",
        detail: {
          pathologyName: "Nome patologia",
          tissueType: "Tipo tessuto",
          pathologyType: "Tipo patologia",
          etiology: "Eziologia",
          medicalSystem: "Sistema medicale",
          note: "Note",
          setAsHealed: "Imposta come guarito",
        },
      },
      monitoringTable: {
        activities: [
          "Stato",
          "Trattamenti",
          "Assessment",
          /* "Esercizio terapeutico", */ "Allenamento",
          "Gara",
          "Riposo",
        ],
      },
      proms: {
        noProms: "Non sono ancora stati inseriti PROMs",
        errors: {
          genericError1:
            "Devi rispondere almeno a 27 domande delle prime 30 obbligatorie",
          genericError2: "Devi rispondere alla domanda",
          genericError3: "Devi rispondere almeno a 1 domanda",
          genericError4: "Devi rispondere almeno al 10% delle domande",
        },
        saveAndClose: "Salva e chiudi",
        finalPoint: "Punteggio finale:",
        symptomAndStiffness: "Sintomi e stiffness: ",
        pain: "Dolore: ",
        dailyActivities: "Attività quotidiane: ",
        sport: "Sport: ",
        qualityOfLife: "Qualità della vita: ",
        physicalFunctionAndDailyLife: "Funzionalità fisica e vita quotidiana: ",
        sportAndFunctionActivities: "Attività funzionali e sportive: ",
        physicalActivities: "Attività fisiche: ",
        functionality: "Funzione: ",
      },
      model3DTitle: "Bodymap",
      model3DSubtitle: "Seleziona intervallo di date",
      intervalDateFrom: "Da",
      intervalDateTo: "A",
      intervalDateFilterBy: "Filtra per",
      assesmentTitle: "Assessment",
      promsTitle: "PROMs",
      graphTitle: "Report e grafici",
      tableRegion: "Regione",
      tableDate: "Data",
    },
    patientPlan: {
      addProms: "Inserisci proms",
      addAssessment: "Inserisci assessment",
      closeAddAssessment: "Chiudi",
      defaultSelectValue: "Monitoraggio",
      selectPresentation: "Seleziona una presentazione",
      chooseActivityPlan: 'Scegli il "Piano attività" da aggiungere',
      repeatAssessment: "Ripeti assessment",
      addMonitoring: "Inserisci monitoraggio",
      title: "Monitoraggio",
      activityDetailDialog: {
        openDeleteButtonAppointment: "Elimina appuntamento",
        openDeleteButtonActivity: "Elimina attività",
        archiveActivityButton: "Aggiorna e archivia",
        updateActivityButton: "Aggiorna attività",
        note: "Note",
        appointment: "Altro",
        excercise: "Esercizio",
        training: "Allenamento",
        treatment: "Trattamento",
        presentation: "Presentazione",
        patient: "Paziente",
        deleteButton: "Elimina attività",
        confirm: "Sì",
        deny: "No",
        deleteMessage: "Vuoi davvero eliminare l'attività?",
        feedbackParameterInput:
          "Inserisci il feedback per il parametro di carico",
        archivedAct:
          "Non puoi modificare l'attività perché archiviata, appartenente ad un gruppo oppure sei nella tabella di monitoraggio",
        reopenButton: "Riapri attività",
      },
    },
    presentation: {
      steps: [
        "Dettaglio",
        "Bodymap",
        "Intervista",
        "Assessment",
        "PROMs",
        "SINS",
        "Milestones",
        "Exit-criteria",
      ],
      closed: "Chiusa",
      no_presentations: "Nessuna presentazione inserita",
      progress: "In corso",
      open: "Aperta",
      feedbackArray: [
        "Molto insoddisfatto",
        "Insoddisfatto",
        "Né soddisfatto, né insoddisfatto",
        "Soddisfatto",
        "Molto soddisfatto",
      ],
      title: "Presentazioni",
      pathology_operation_type: "Intervento chirurgico",
      newPresentation: "Nuova presentazione",
      tableReason: "Motivo",
      tableStart: "Inizio",
      tableEnd: "Fine",
      tableEstimatedEnd: "Fine stimata",
      tableSatisfaction: "Soddisfazione",
      tableStatus: "Stato",
      intervalDateTo: "A",
      add: {
        title: "Nuova presentazione",
        presentationType: "Seleziona il disordine della presentazione",
        dysfunction: "Seleziona la disfunzione della presentazione",
        severity: "Seleziona la gravità del disordine",
        reasonOfConsultation:
          "Con area, regione e patologie costituirai la motivazione di consultazione",
        selectRegion: `Seleziona la regione tramite il menù a tendina`,
        selectArea: `Seleziona l'area tramite il menù a tendina`,
        selectPathology: `Seleziona la patologia tramite il menù a tendina`,
        selectStructure: `Seleziona la struttura tramite il menù a tendina`,
        selectInterview: `Selezionare l'intervista`,
        selectAnswer: `Scegli una risposta`,
        startDate: `Inserisci la data se si tratta di una presentazione precedente ad oggi`,
        estimatedEndDate: `Data stimata di fine presentazione`,
        continue: `Continua e Salva`,
        save: `Salva`,
        stepper: {
          actions: {
            next: "Continua",
            previous: "Indietro",
            saveAndExit: "Salva ed esci",

            createPresentation: "Continua e crea la presentazione",
          },
          step0: {
            title: `Dettagli presentazione`,
            groups: `Gruppi`,
            startDate: `Inserisci la data se si tratta di una presentazione precedente ad oggi`,
            endDateEstimated: `Data stima di fine presentazione`,
            endDateEstimatedLabel: `Ancora in corso`,
            endDate: `Data di fine presentazione`,
            region: `Regione corporea`,
            tissueType: `Tipo Tesuto`,
            pathologyType: `Tipo di patologia`,
            medicalDiagnose: `Diagnosi`,
            osiicsCode: `Codice OSIICS`,
            note: `Note`,
            noteDescription: `Inserisci delle note aggiuntive`,
            detail: {
              activity: {
                name: `L'infortunio si è verificato in:`,
                choise: [
                  { key: 1, value: `allentamento` },
                  { key: 2, value: `partita` },
                ],
              },
              mechanism: {
                name: `Meccanismo`,
                choise: [
                  { key: 1, value: `overuse` },
                  { key: 2, value: `trauma diretto` },
                  { key: 3, value: `trauma indiretto` },
                  { key: 4, value: `altro meccanismo` },
                ],
              },
              reinjury: {
                name: `Reinfortunio`,
                choise: [
                  { key: 1, value: `no` },
                  { key: 2, value: `sì` },
                ],
              },
              sport: {
                name: `Sport`,
              },
            },
          },
          step2: {
            title: "Intervista",
            no_interview: "Nessuna intervista per questa regione",
            subtitle: `Compila l'intervista del paziente o demanda a lui questo compito. Il paziente potrà compilarla attraverso la sua app dedicata.`,
            regionLabel: `Regione: `,
            skipInterview: `Salta intervista`,
            patientDelegTitle: `Delega al paziente`,
            patientDelegSubtitle: `Invia la richiesta di compilazione al paziente.`,
            patientAlreadyDelegSubtitle: `Richiesta di compilazione inviata al paziente`,
            patientDelegSend: `Invia`,
            medicalDiagnose: `Diagnosi medica`,
            medicalDiagnoseSubitle: `Hai una diagnosi medica per il tuo dolore?`,
            localization: `Localizzazione`,
            localizationSubitle: `Puoi localizzare esattamente il tuo dolore?`,
            localizationSubtitle2: `Il dolore si irradia in altre regioni o è presente in altre regioni del corpo?`,
            redFlagsTitle: `Red Flags`,
            redFlagsSubtitle: `Hai avuto episodi di nausea e vomito ultimamente?`,
            redFlagsSubtitle2: `Hai manifestato disturbi della coordinazione muscolare , disturbi della sensibilità corporea,disturbi visivi, alterato stato mentale ultimamente?`,
            redFlagsSubtitle3: `Hai avuto dolore alla mandibola (claudatico mandibolare) durante i pasti ultimamente?`,
            painStart: `Esordio`,
            painStartSubtitle: `Puoi trovare una causa chiara di esordio del tuo dolore ovvero cosa pensi lo abbia scaturito?`,
            painStartSubtitle1: `In che modo è esordito il dolore?`,
            painStartSubtitle2: `Quando è iniziato il dolore (più o meno riconducilo ad una data)`,
            evolutionTitle: `Evoluzione`,
            evolutionSubtitle: `come sta evolvendo il dolore?`,
            evolutionSubtitle2: `Come si comporta il dolore durante la giornata?`,
            evolutionSubtitle3: `Sei in grado di riprodurre il dolore in qualche modo?`,
            evolutionSubtitle4: `Il dolore si è spostato nella zona in cui è cominciato?`,
            descriptionTitle: `Descrizione`,
            descriptionSubtitle: `Con quale/i parola/e descriveresti il tuo dolore?`,
            associatedSignTitle: `Segni associati`,
            associatedSignSubtitle: `E' presente qualcuno dei seguenti segni associati al dolore?`,
            reliefSignTitle: `Fattori allenvianti`,
            reliefSignSubtitle: `Qualcuno dei seguenti fattori allevia il tuo dolore?`,
            aggravatingFactorsTitle: `Fattori aggravanti`,
            aggravatingFactorsSubtitle: `Ci sono fattori che aggracano il tuo dolore?`,
            sistematicalSympthomsTitle: `Sintomi sistemici`,
            sistematicalSympthomsSubtitle: `Manifesti attualmente o hai manifestato nelle ultime 6 settimane qualcuno tra i seguenti sintomi?`,
            stressTitle: `Stress psicologici`,
            stressSubtitle: `Hai avuto recenti stress psicologici?`,
            precedentTitle: `Precedenti`,
            precedentSubtitle: `Hai già sperimentato questo dolore in passato o hai avuto altri problemi in questa stessa regione?`,
            precedentSubtitle2: `Hai svolto altri trattamenti per il tuo attuale problema e se si che effetti hanno avuto e per quanto tempo li hai svolit?`,
            severityTitle: `Severità`,
            severitySubtitle: `su una scala da 0 a 10 dove 0 è "non ho dolore" e 10 è "il massimo dolore che conosco, che livello di dolore precepisci in questo momento?`,
            severitySubtitle2: `su una scala da 0 a 10 dove 0 è "non ho dolore affatto" e 10 è "il massimo dolore che conosco", che livello di dolore hai  percepito mediamente negli ultimi 7 giorni (o da quando e' comparso)?`,
            severitySubtitle3: `su una scala da 0 a 10 dove 0 è "per niente" e 10 è "moltissimo", quanto il tuo dolore influenza la tua vita quotidiana?`,
            medicinalsTItle: `Farmaci`,
            medicinalsSubtitle: `Stai prendendo farmaci per questa condizione?`,
            medicinalsSubtitle2: `Prendi normalmente farmaci per condizioni maggiori?`,
            otherTitle: `Altro`,
            otherSubtitle: `Ci sono altre cose di cui vorresti parlare riguardo al tuo stato di salute generale?`,
            otherSubtitle2: `Credi ci sia un modo per migliorare questa condizione?`,
          },
          step3: {
            title: `Sins`,
            subtitle: `Aggiungere i sins`,
            severityTitle: `Severità`,
            irritabilityTitle: `Irritabilità`,
            originTitle: `Natura`,
            statusTitle: `Stadio`,
            severityRows: [
              { id: 1, name: "Alta" },
              { id: 2, name: "Moderata" },
              { id: 3, name: "Bassa" },
            ],
            irritabilityRows: [
              { id: 4, name: "Alta" },
              { id: 5, name: "Moderata" },
              { id: 6, name: "Bassa" },
            ],
            natureRows: [
              { id: 7, name: "Articolare" },
              { id: 8, name: "Miofasciale" },
              { id: 9, name: "Osseo" },
              { id: 10, name: "Tendineo" },
              { id: 11, name: "Neuropatico" },
              { id: 12, name: "Viscerale" },
            ],
            levelRows: [
              { id: 13, name: "Acuto" },
              { id: 14, name: "Sub-acuto" },
              { id: 15, name: "Cronico" },
            ],
          },
          step4: {
            title: `Assessment`,
            subtitle: `Seleziona l'assessment associato alla regione predentemente selezionata`,
            skipAssesment: `Salta assessment`,
            notes: "Note",
            where: "Dove",
            degree: "Grado",
            type: "Tipo",
            test: "Test",
            result: "Risultato",
            pos: "Positivo",
            neg: "Negativo",
            basicObservationTitle: "Osservazioni di base",
            allinementsObservation: "Osservazione di allienamenti",
            exclusionTests: "Test di esclusione",
            exclusionTestsSubtitle: "Irritabilità o fratture cervicali",
            cervicalArteriaDisfunction: "Disfunzione arteria cervicale",
            radicCervical: "Radicolopatie cervicali",
            specialTest: "Test speciali",
            specialTestSubtitle: "Disfunzione vestibolare",
            monoDisf: "Disfunzione monoemisferica",
            lossROM: "Perdita di ROM C1-C2",
            clickArticolari: "Click articolari temporo-mandibolari",
            movementRestriction:
              "Restrizione di movimento articolare temporo-mandibolare",
            assessmentDetailRequest: {
              title: "Seleziona la regione e la categoria dell'assessment",
              categoryTitle: "Categoria dell'assessment",
            },
          },
          step5: {
            title: `PROMs`,
            subtitle: `Controlla i proms del paziente o demanda a lui questo compito. Il paziente potrà compilarla attraverso la sua app dedicata.`,
            skipProms: `Salta PROMs`,
            sendProms: "Invia",
            chooseProms: "Seleziona PROMs",
            patientDelegation: "Delega al paziente",
            requestToPatient: "Invia la richiesta di compilazione al paziente",
          },
        },
      },
      detail: {
        closePresentation: "Chiudi",
        deletePresentation: "Elimina",
        presentationDelete: {
          content: "Sei sicuro di voler eliminare la presentazione",
          title: "Eliminazione presentazione",
        },
        deleteConfirm: "Sì",
        feedbackSelect: "Ti senti soddisfatto?",
        deleteDeny: "No",
        presentationNumber: `Presentazione n.`,
        harmedRegion: `Regione colpita:`,
        reason: "Motivo",
        start: "Inizio",
        end: "Fine",
        estimatedEnd: "Fine stimata",
        status: "Stato",
        exitCriteriaDialog: { title: "Chiudi presentazione" },
        stepper: {
          step1: {
            title: "Bodymap",
            subtitle: "Seleziona un intervalllo di date",
            from: "Da",
            to: "A",
            filterBy: "Filtra per",
            addSymbol: "Aggiungi simbolo",
          },
          step2: {
            title: "Exit-criteria",
            subtitle: "Inserisci un exit criteria",
            noData:
              "Presentazione chiusa. Non sono stati registrati Exit Criteria.",
            closedPresentation: "Presentazione chiusa.",
            save: "Salva",
            inputLabel: "Exit criteria",
            satisfied: "Soddisfatto",
            notSatisfied: "Non soddisfatto",
          },
          step3: {
            title: "Milestones",
            subtitle: "Inserisci i milestones",
            noData:
              "Presentazione chiusa. Non sono state registrate Milestones.",
            closedPresentation: "Presentazione chiusa.",
            save: "Salva",
            reachingDate: "Data di raggiungimento",
            satisfied: "Soddisfatto",
            notSatisfied: "Non soddisfatto",
          },
          step4: {
            title: `SINS`,
            subtitle: `Aggiungere i SINS`,
            severityTitle: `Severità`,
            irritabilityTitle: `Irritabilità`,
            noData: "Presentazione chiusa. Non sono stati registrati SINS.",
            closedPresentation: "Presentazione chiusa.",
            originTitle: `Natura`,
            statusTitle: `Stadio`,
          },
          step5: {
            title: "Assessment",
            subtitle: "Assegna un assessment",
            noData:
              "Presentazione chiusa. Non sono state registrati Assessment.",
            closedPresentation: "Presentazione chiusa.",
            add: "Inserisci assessment",
            table: {
              header: {
                id: "id",
                region: "regione",
                date: "date",
                actions: "",
              },
            },
          },
          stepPathologies: {
            title: "Malattie",
            noData: "Presentazione chiusa. Non sono state inserite Malattie.",
            closedPresentation: "Presentazione chiusa.",
            close: "Chiudi",
            add: "Inserisci",
            table: {
              header: {
                id: "id",
                type: "nome",
                date: "date",
                actions: "",
              },
            },
          },
          step6: {
            title: "PROMs",
            subtitle: "Assegna un proms",
            noData: "Presentazione chiusa. Non sono stati registrati PROMs.",
            closedPresentation: "Presentazione chiusa.",
            add: "Inserisci",
            close: "Chiudi",
            table: {
              header: {
                id: "id",
                type: "tipo",
                date: "date",
                actions: "",
              },
            },
          },
          step7: {
            title: "Intervista",
            subtitle: `Compila l'intervista del paziente o demanda a lui questo compito. Il paziente potrà compilarla attraverso la sua app dedicata.`,
            regionLabel: `Regione: `,
            noData:
              "Presentazione chiusa. Non sono stati registrate interviste.",
            closedPresentation: "Presentazione chiusa.",
            denyButtLabel: `Annulla`,
            patientDelegTitle: `Delega al paziente`,
            patientDelegSubtitle: `Invia la richiesta di compilazione al paziente.`,
            patientDelegSend: `Invia`,
            medicalDiagnose: `Diagnosi medica`,
            medicalDiagnoseSubitle: `Hai una diagnosi medica per il tuo dolore?`,
            localization: `Localizzazione`,
            localizationSubitle: `Puoi localizzare esattamente il tuo dolore?`,
            localizationSubtitle2: `Il dolore si irradia in altre regioni o è presente in altre regioni del corpo?`,
            redFlagsTitle: `Red Flags`,
            redFlagsSubtitle: `Hai avuto episodi di nausea e vomito ultimamente?`,
            redFlagsSubtitle2: `Hai manifestato disturbi della coordinazione muscolare , disturbi della sensibilità corporea,disturbi visivi, alterato stato mentale ultimamente?`,
            redFlagsSubtitle3: `Hai avuto dolore alla mandibola (claudatico mandibolare) durante i pasti ultimamente?`,
            painStart: `Esordio`,
            painStartSubtitle: `Puoi trovare una causa chiara di esordio del tuo dolore ovvero cosa pensi lo abbia scaturito?`,
            painStartSubtitle1: `In che modo è esordito il dolore?`,
            painStartSubtitle2: `Quando è iniziato il dolore (più o meno riconducilo ad una data)`,
            evolutionTitle: `Evoluzione`,
            evolutionSubtitle: `come sta evolvendo il dolore?`,
            evolutionSubtitle2: `Come si comporta il dolore durante la giornata?`,
            evolutionSubtitle3: `Sei in grado di riprodurre il dolore in qualche modo?`,
            evolutionSubtitle4: `Il dolore si è spostato nella zona in cui è cominciato?`,
            descriptionTitle: `Descrizione`,
            descriptionSubtitle: `Con quale/i parola/e descriveresti il tuo dolore?`,
            associatedSignTitle: `Segni associati`,
            associatedSignSubtitle: `E' presente qualcuno dei seguenti segni associati al dolore?`,
            reliefSignTitle: `Fattori allenvianti`,
            reliefSignSubtitle: `Qualcuno dei seguenti fattori allevia il tuo dolore?`,
            aggravatingFactorsTitle: `Fattori aggravanti`,
            aggravatingFactorsSubtitle: `Ci sono fattori che aggracano il tuo dolore?`,
            sistematicalSympthomsTitle: `Sintomi sistemici`,
            sistematicalSympthomsSubtitle: `Manifesti attualmente o hai manifestato nelle ultime 6 settimane qualcuno tra i seguenti sintomi?`,
            stressTitle: `Stress psicologici`,
            stressSubtitle: `Hai avuto recenti stress psicologici?`,
            precedentTitle: `Precedenti`,
            precedentSubtitle: `Hai già sperimentato questo dolore in passato o hai avuto altri problemi in questa stessa regione?`,
            precedentSubtitle2: `Hai svolto altri trattamenti per il tuo attuale problema e se si che effetti hanno avuto e per quanto tempo li hai svolit?`,
            severityTitle: `Severità`,
            severitySubtitle: `su una scala da 0 a 10 dove 0 è "non ho dolore" e 10 è "il massimo dolore che conosco, che livello di dolore precepisci in questo momento?`,
            severitySubtitle2: `su una scala da 0 a 10 dove 0 è "non ho dolore affatto" e 10 è "il massimo dolore che conosco", che livello di dolore hai  percepito mediamente negli ultimi 7 giorni (o da quando e' comparso)?`,
            severitySubtitle3: `su una scala da 0 a 10 dove 0 e' "per niente" e 10 è "moltissimo", quanto il tuo dolore influenza la tua vita quotidiana?`,
            medicinalsTItle: `Farmaci`,
            medicinalsSubtitle: `Stai prendendo farmaci per questa condizione?`,
            medicinalsSubtitle2: `Prendi normalmente farmaci per condizioni maggiori?`,
            otherTitle: `Altro`,
            otherSubtitle: `Ci sono altre cose di cui vorresti parlare riguardo al tuo stato di salute generale?`,
            otherSubtitle2: `Credi ci sia un modo per migliorare questa condizione?`,
            saveButton: "Salva",
          },
        },
      },
    },
    transfer: {
      title: "Cerca professionista",
      form: {
        title: "Cerca professionista",
        searchButton: "Cerca",
        searchInputLabel:
          "Inserisci l'email dell'utente Powerset che prenderà in carico il tuo assistito:",
        searchInputLabelTop: "Email",
        foundPatients: {
          formTitle: "Professionisti trovati",
          givenName: "Nome",
          familyName: "Cognome",
          city: "Città",
          transferButton: "Trasferisci",
        },
      },
    },
    graphReport: {
      title: "Report grafici",
      subtitle: "Visualizza i report grafici sulle patologie dei tuoi pazienti",
      section: {
        feedback: {
          title: "Workload attività",
          filters: {
            start_date: "Data inizio",
            end_date: "Data fine",
            date_view: "Tipo visualizzazione",
            graph_types: "Tipologia grafico",
            daily: "Giornaliera",
            weekly: "Settimanale",
            monthly: "Mensile",
            "linear graph": "Lineare",
            "radar graph": "Ragnatela",
            activities: "Attività",
            study_parameters: "Parametro di studio",
            studies: "Tipologie di studio",
            parameter_not_compatible:
              "I parametri selezionati non sono compatibili con questo studio",
            chronic_load: "Carico cronico",
            acute_load: "Carico acuto",
            period: "Periodo di studio",
            unlinked_patients: "Pazienti non collegati",
            linked_patients: "Pazienti collegati",
            patients_group: "Pazienti di gruppi",
            previous_values: "Valori precedenti (non zero)",
          },
          lines: {
            "Line": "Linea",
            "Histogram": "Istogramma",
          },
          studies: {
            TEMPORAL: "Timeline",
            MONOTONY: "Monotony",
            STRAIN: "Strain",
            ACWRRA: "ACWR RA",
            ACWREWMA: "ACWR EWMA",
            COMPAREPERC: "Comparazione %",
            COMPAREASS: "Comparazione valore assoluto",
            ZSCORE: "Z-Score",
            SYMMETRY: "Simmetria dx/sx",
            ASYMMETRY: "Asimmetria dx/sx",
            SYMMETRYASS: "Comparazione assoluta dx/sx",
            INDEXES: "Indice mcid",
            CONFIDENCE: "Intervallo di confidenza al 95%",

            ZSCORE_REFERENCE: "Periodo di riferimento",
            ZSCORE_VALUES: "Valori precedenti",
            ZSCORE_FIXED: "Periodo fisso",
            ZSCORE_SUBJECT_REFERENCE: "Periodo di riferimento soggetto",
            ZSCORE_SUBJECT_VALUES: "Valori precedenti soggetto",
            ZSCORE_SUBJECT_FIXED: "Periodo fisso soggetto",
          },
        },
        proms: {
          title: "PROMs",
        },
        disorder: {
          title: "Disordini",
          filters: {
            type_select: "Seleziona tipo di grafico",
            area_description:
              "In questa sezione potrai vedere quali sono le aree più perturbate",
            region_description:
              "In questa sezione potrai vedere quali sono i disordini più presenti",
            disorder_description:
              "In questa sezione potrai vedere quali sono le regioni più perturbate",
            date: "Data",
          },
          types: {
            area: "Aree perturbate",
            region: "Regioni perturbate",
            disorder: "Disordini più frequenti",
          },
        },
        assessment: {
          title: "Misurazioni",
          filters: {
            patients: "Pazienti",
          },
        },
        exposure: {
          title: "Esposizione",
        },
        generalInjury: {
          title: "Infortuni generali",
        },
        trainingInjury: {
          title: "Infortuni durante gli allenamenti",
        },
        matchInjury: {
          title: "Infortuni durante le gare",
        },
        severeInjury: {
          title: "Infortuni gravi",
        },
        muscleInjury: {
          title: "Infortuni muscolari",
        },
        ligamentInjury: {
          title: "Infortuni ai legamenti",
        },
        reinjury: {
          title: "Re-infortuni",
        },
      },
    },
    controlQuestions: {
      insertAllData: "Inserisci tutti i dati",
      defaultSelectValue: "Domande di monitoraggio",
      title: "Feedback",
      subtitle: "Invia la richiesta di un feedback a ",
      deleteDialog: {
        title: "Eliminazione questionario",
        subtitle:
          "Sei sicuro di voler eliminare il questionario? L'operazione è irreversibile",
      },
      responseTable: {
        title: "Risposte di ",
        actions: {
          view: "Visualizza",
        },
      },
      tableHeader: {
        questionnaire: "Questionario",
        visibilityDate: "Data visibilità",
        answerDate: "Data risposta",
      },
      listFreq: {
        oneDay: "1 giorno",
        twoDays: "2 giorni",
        threeDays: "3 giorni",
        fiveDays: "5 giorni",
        sevenDays: "Una settimana",
      },
      sendQuestionButton: "Invia",
      answerDetails: "Dettaglio risposta",
      detailDialog: {
        title: "Dettaglio questionario",
        questTitle: "Nome questionario",
        answerTitle: "Dettagli",
      },
      selectFrequency: {
        title: "Ogni quanti giorni vuoi che venga inviato il questionario?",
        placeholder: "Inserisci Frequenza in giorni...",
      },
      selectProms: {
        title: "Seleziona questionario",
        placeholder: "Seleziona questionario..",
      },
      durationInDays: {
        title: "Quante volte vuoi che venga inviato il questionario",
        placeholder: "Durata questionario..",
      },
    },
    transferPatient: {
      title: "Trasferimento paziente",
      subtitle:
        "Trasferisci questo assistito ad un altro professionista. Attenzione! Non sarà più possibile gestire i dati dell'assistito, ma solo consultare le informazioni registrate da te finora.",
      name: "Nome",
      surname: "Cognome",
      email: "Email",
    },
    resourcesUpload: {
      dd1: "Clicca qui per caricare un file",
      dd2: "Limite di dimensione del file raggiunto",
      title: "Risorse",
      subtitle: "Carica documenti e altri file per il tuo paziente",
      form: {
        title: "Carica i tuoi File",
        subtitle: "Qui puoi caricare i file collegati al paziente",
      },
      errors: {
        uploadLimit: "File troppo grande",
      },
    },
    viewPlan: {
      title: "Piano attività",
      subtitle: "Visualizza il piano del paziente",
      closeButton: "Chiudi",
      addActivity: {
        title: "Aggiungi attività",
        addAndCompile: "Aggiungi e compila",
        backButton: "Chiudi",
        inputForm: {
          chooseActivity: "Scegli l'attività da aggiungere",
          chooseTemplateActivity: "Scegli il template attività da aggiungere",
          chooseLoadParameters: "Scegli i parametri di carico da aggiungere",
          repeatActivity: "Ripeti attività ogni",
          extendGroup: "Estendi gruppo",
          assessmentMotivation: "Oggetto",
          raceMinutes: "Minuti di gara",
          activityReference:
            "Seleziona a cosa si riferisce questa nuova attività",
          selectActivityType: "Seleziona tipi di attività",
          selectTime: "Seleziona ora",
          activityType: "Tipo di attività",
          start_time: "Orario di inizio",
          end_time: "Orario di fine",
          objectiveFocus: "Focus obbiettivi",
          description: "Descrizione",
          addButton: "Aggiungi",
          start_date: "Inserisci la data in cui svolgere l'attività",
          date: "Inserisci la data in cui svolgere l'attività",
          end_date: "Inserisci la data in cui svolgere l'attività",
        },
        inputsError: {
          activity_type: "Devi selezionare il tipo di attività",
          start_time: "Devi selezionare l'orario di inizio",
          end_time: "Devi selezionare l'orario di fine",
          start_date: "Devi selezionare la data di inizio",
          end_date: "Devi selezionare la data di fine",
          time_error: "Devi inserire un orario compreso fra le 5:00 e le 22:00",
        },
      },
      detailActivity: {
        title: "attività",
        backButton: "Chiudi",
        inputForm: {
          referredPresentation: "Riferita alla presentazione id: ",
          referredPresentationOf: "del ",
          referredPresentationHour: "ore",
          medicalVisit: "Visita medica",
          detail: "Dettagli",
        },
      },
    },
  },
  mylab: {
    no_template:
      "Nessun template disponibile. Aggiungine uno cliccando sul pulsante in alto a destra!",
    templateName: "Nome template",
    insertTemplateName: "Inserisci nome template...",
    chooseActivityTemplate: "Scegli il template attività da aggiungere",
    addActivity: "Aggiungi attività",
    selectExercise: "Seleziona esercizio",
    page: {
      title: "MyLab",
      subtitle: "Scegli quale sezione visualizzare",
    },
    dialog: {
      delete: {
        title: "Eliminazione template",
        subtitle: "Sei sicuro di voler eliminare il template?",
        yes: "Sì",
        no: "No",
      },
    },
    tempList: {
      activityPlan: "Piani di attività",
      excerciseSheet: "Schede esercizi",
      assesment: "Assessment",
      handbook: "Prontuari",
      training: "Allenamenti",
      rehab: "Riabilitazioni",
      treatment: "Trattamenti",
      riskFactor: "Fattori di rischio",
      dysfunctionWeights: "Pesi dei disordini",
      action: {
        view: "Visualizza",
        add: "Aggiungi",
        delete: "Cancella",
        manage: "Gestisci",
      },
      toolbar: {
        riskFactors: {
          label: "Fattori di rischio",
        },
      },
    },
    tempDetail: {
      backButtonList: "MyLab",
      activityPlanList: { title: "Piano attività", subtitle: "Piani attività" },
      excerciseSheet: {
        title: "Scheda esercizio",
        subtitle: "Schede esercizi",
      },
      assesment: { title: "Assessment", subtitle: "Assessment" },
      handbook: {
        title: "Prontuario",
        subtitle: "Prontuari",
        categoryTitle: "Categorie selezionate",
      },
      training: { title: "Template allenamento", subtitle: "Allenamenti" },
      treatment: { title: "Template trattamento", subtitle: "Trattamenti" },
      rehab: { title: "Template Riabilitazione", subtitle: "Riabilitazioni" },
      riskFactor: {
        title: "Fattore di rischio",
        subtitle: "Fattori di rischio",
      },
      dysfunctionWeights: {
        title: "Pesi dei disordini",
        subtitle: "Pesi dei disordini",
      },
      action: {
        view: "Visualizza",
        updateButton: "Aggiorna",
      },
      toolbar: {
        add: "Aggiungi",
      },
      table: {
        name: "Nome",
        actions: "",
      },
      activityPlan: {
        textLabels: {
          type: "Tipo attività",
          details: "Dettagli attività",
        },
      },
    },
    riskFactor: {
      toolbar: {
        title: "Fattori di Rischio",
        backButtonList: "Fattori di Rischio",

        subtitle: "In questa sezione puoi gestire i tuoi fattori di rischio",
        actions: {
          add: "Aggiungi fattore di rischio",
        },
      },
      table: {
        actions: {
          view: "Visualizza",
        },
        header: {
          name: "Nome",
          actions: "",
        },
      },
      detail: {
        title: "Modifica un fattore di rischio",
        backButtonList: "Fattori di Rischio",
        name: "Nome",
        subtitle:
          "Qui puoi modificare il fattore di rischio selezionato facendo riferimento al modello 3D",
        select: {
          region: "Seleziona regione",
          area: "Seleziona area",
          structure: "Seleziona la struttra",
          pathology: "Seleziona la patologia",
        },
        toolbar: {
          save: "Salva cambiamenti",
        },
        form: {
          region: "Regione",
          area: "Area",
          structure: "Struttura",
          dysfunction: "Disfunzione",
        },
      },
      add: {
        title: "Aggiungi un fattore di rischio",
        backButtonList: "Fattori di Rischio",

        subtitle:
          "Qui puoi aggiungere un nuovo fattore di rischio associandolo al modello 3d",
        select: {
          region: "Seleziona regione",
          area: "Seleziona area",
          structure: "Seleziona la struttra",
          pathology: "Seleziona la patologia",
          disorder: "Seleziona il disordine",
          dysfunction_category: "Seleziona la disfunzione",
          dysfunction: "Seleziona il tipo di disordine",
          note: "Note",
        },
        toolbar: {
          save: "Aggiungi Fattore di Rischio",
        },
      },
    },
    tempAdd: {
      backButtonList: "MyLab",

      excerciseSheet: {
        back: "Torna indietro",
        hideDataset: "Nascondi Dataset esercizi",
        showDataset: "Visualizza Dataset esercizi",
        dialog: {
          title: "Dettaglio esercizio",
          content: {
            name: "Nome",
            guidance_instructions: "Istruzioni",
            tags: "Tags",
          },
        },
        title: "Nuova scheda esercizi",
        subtitle: "Lista degli esercizi",
        actions: {
          create: "Crea",
          edit: "Modifica",
        },
        inputs: {
          name: "Nome",
          quantity: "Numero di esercizi",
          plan: "Piano",
        },
        list: {
          title: "Esercizi",
          guidance: "Istruzioni",
          header: [
            "Nome esercizio",
            "Serie",
            "Ripetizioni o durata(sec)",
            "Recupero",
            "Carico",
            "Accessori",
            "Modalità di esecuzione",
            "Note",
          ],
        },
      },
      assesment: {
        title: "Nuovo template assessment",
        subtitle: "Inserisci le informazioni per creare un nuovo template",
        actions: {
          create: "Crea",
          edit: "Modifica",
          createHandbook: "Crea prontuario",
        },
        inputs: {
          name: "Nome",
          requiredName: "Inserisci il nome del template",
          section: "Sezioni",
          notes: "Note",
        },
        list: {
          title: "Esercizi",
          header: [
            "Nome esercizio",
            "Serie",
            "Ripetizioni o durata(sec)",
            "Recupero",
            "Carico",
            "Accessori",
            "Modalità di esecuzione",
            "Note",
          ],
        },
      },
      handbook: {
        title: "Nuovo prontuario",
        subtitle: "Inserisci le informazioni per creare un nuovo prontuario",
        category: "Categorie assessment",
        selectElements: "Seleziona gli elementi...",
        actions: {
          create: "Crea",
          print: "Stampa",
          edit: "Modifica",
        },
        inputs: {
          name: "Nome",
          section: "Sezioni",
        },
      },
      training: {
        title: "Nuovo template allenamento",
        subtitle: "Inserisci le informazioni per creare un nuovo template",
        actions: {
          create: "Crea",
          edit: "Modifica",
        },
        inputs: {
          name: "Nome",
          quantity: "Numero di attività",
          totalMinutes: "Minuti totali",
        },
        list: {
          title: "Sezioni",
          header: ["Tipo di attività", "Tempo in minuti", "Descrizione", ""],
        },
      },
      treatment: {
        title: "Nuovo template trattamento",
        subtitle: "Inserisci le informazioni per creare un nuovo trattamento",
        actions: {
          create: "Crea",
          edit: "Modifica",
        },
        inputs: {
          name: "Nome",
          quantity: "Numero di attività",
          totalMinutes: "Minuti totali",
        },
        list: {
          title: "Sezioni",
          header: ["Tipo di attività", "Tempo in minuti", "Descrizione", ""],
        },
      },
      risk_factor: {
        title: "Nuovo fattore di rischio",
        subtitle:
          "Inserisci le informazioni per creare un nuovo fattore di rischio",
        actions: {
          create: "Crea",
          edit: "Modifica",
        },
        inputs: {
          title: "Inserisci i parametri del risk factor",
          name: "Nome",
        },
        list: {
          title: "Sezioni",
          header: ["Tipo di attività", "Tempo in minuti", "Descrizione"],
        },
      },
      dysfunctionWeights: {
        actions: {
          edit: "Modifica",
        },
      },
      action: {
        view: "Visualizza",
      },
      toolbar: {
        add: "Aggiungi",
      },
      activityPlan: {
        title: "Piano attività",
        subtitle: "Per creare un piano inserire nome, durata e piano attività",
        list: {
          title: "Giorni",
          header: ["attività"],
        },

        nameInput: {
          placeholder: "Inserisci nome",
        },
        durationInput: {
          placeholder: "Inserisci durata",
        },
        actions: {
          create: "Crea",
          edit: "Modifica",
          saveButton: "Salva",
        },
        inputs: {
          name: "Nome",
          duration: "Durata piano in giorni",
          plan: "Piano",
        },
        dialog: {
          activity: "Scegli l'attività da inserire",
          title: "attività",
          groupActTitle: "attività di gruppo",
        },
      },
    },
  },
  agency: {
    professionals: {
      title: "Professionisti",
      toolbar: {
        add: "Aggiungi professionisti",
        search: "Cerca",
      },
      table: {
        header: {
          professional: "Professionista",
          specialization: "Specializzazione",
          coupon: "Coupon",
          license: "Licenza",
        },
        actions: {
          remove: "Rimuovi",
        },
      },
      addDialog: {
        title: "Aggiungi professionista",
        email: "Inserisci email",
      },

      detailDialog: {
        title: "Dettaglio professionista",
        familyName: "Nome",
        givenName: "Cognome",
        birthDate: "Data di nascita",
        email: "Email",
        nation: "Nazione",
        province: "Provincia",
        city: "Città",
        address: "Via",
        job: "Professione",
      },
    },
    licenses: {
      title: "Licenze",
      toolbar: {
        search: "Cerca",
        buy: "Acquista licenza",
      },
      table: {
        header: {
          coupon: "Coupon",
          subscription: "Sottoscrizione",
          professional: "Professionista",
          expirationDate: "Scadenza",
          usageStarted: "Inizio utilizzo",
        },
      },
      buyLicenseDialog: {
        title: "Acquisto licenza",
        duration: "Durata (in giorni)",
        type: "Seleziona tipo licenza",
        professional: "Seleziona Professionista",
      },
    },
    account: {
      title: "Account",
      toolbar: {
        logout: "Logout",
        changePassword: "Cambia password",
        modify: "Modifica",
      },
      form: {
        title: "Dettaglio account",
        fields: {
          name: "Nome",
          email: "Email",
          prefix: "Prefisso",
          telephone: "Telefono",
          address: "Indirizzo",
          city: "Città",
          cap: "Cap",
          birthDate: "Anno di nascita",
          province: "Provincia",
          nation: "Nazione",
          fiscalCode: "Codice fiscale",
          piva: "P.iva",
          businessName: "Nome compagnia",
          businessAddress: "Indirizzo sede",
          businessCity: "Città sede",
          businessCap: "Cap sede",
          businessProvince: "Provincia sede",
          businessNation: "Nazione sede",
        },
      },
      changePassword: {
        title: "Cambio password",
        password: "Nuova password",
        confirmPassword: "Conferma nuova password",
        confirm: "Conferma",
      },
    },
  },
  support: {
    title: "Supporto",
    toolbar: {
      faq: "Faq",
    },
    form: {
      scope: "Oggetto",
      text: "Testo",
    },
    actions: {
      send: "Invia",
    },
  },
  faq: {
    title: "Faq",
    toolbar: {
      back: "Supporto",
    },
  },
  library: {
    title: "Libreria",
    no_data: "Nessun dato disponibile",
  },
  calendar: {
    title: "Agenda",
    eventDetailDialog: {
      title: "Elimina altro",
      deleteMessage: "Vuoi davvero eliminare l'appuntamento?",
    },
    addDialog: {
      title: "Altro",
    },
    subtitle: "Visualizza il calendario con gli appuntamenti",
  },
  error: {
    errorMsg:
      "oops... Si è verificato un problema momentaneo. Per favore riprova.",
    lnkGoBack: "Torna alla Dashboard",
    contactAssistenceMsg:
      "Se il problema persiste, contatta il servizio clienti al seguente link ",
    labelLnkToSupport: "Supporto",
    lnkToSupport: "https://www.mypowerset.com/supporto",
  },
};

export const translations = null;
