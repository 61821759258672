import React from "react";
import "../App.css";
import SpText from "../components/atoms/SpText";
import SpTextArea from "../components/atoms/SpTextArea";
import { styled } from "../components/styled";
import { Grid } from "@material-ui/core";
import { faqData } from "./faq/FaqMockupData";
import { labels, psTranslate } from "./shared/translations";

const StyledColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
});

const Faq = (props) => {
  const formControlStyle = { width: "50%" };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <SpText variant="h1">{labels.faq.title}</SpText>
        </Grid>
        <Grid item xs={12}>
          <StyledColumn>
            {/* TODO Contact form for the platorm admins */}
            {faqData.map((faqData, index) => (
              <SpTextArea
                key={index}
                disabled
                label={faqData.title}
                formControlStyle={formControlStyle}
                value={faqData.description}
              />
            ))}
          </StyledColumn>
        </Grid>
      </Grid>
    </>
  );
};

export default Faq;
