import { Grid } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment";
import React, { Children, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import "../../App.css";
import { SpAutocomplete } from "../../components/atoms/SpAutocomplete";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextArea from "../../components/atoms/SpTextArea";
import {
  getAllActivitiesTypes,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../../models/actions/Activity";
import {
  createCalendarActivityOfGroup,
  getAllCalendarActivityByMonitGroup,
  updateCalendarActivityMonitGroup,
} from "../../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../../models/actions/Excercise";
import { getGroupsById } from "../../models/actions/Groups";
import AgendaCalendarEventCard from "../agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "../agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "../agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "../agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "../agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "../agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "../agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "../agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "../agenda/inputDetails/A8_RehabilitationInputDetails";
import ColoredEventCell from "../agenda/ColoredEventCell";
import { getLanguage, labels, psTranslate } from "../shared/translations";
import { styled } from "../../components/styled";
import { log, rollbar, momentDefinition } from "../../utils/common";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
];

const DragAndDropCalendar = withDragAndDrop(Calendar);

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const GroupActivityPlan = ({ setLoading, ...props }) => {
  const [groupsPatients, setGroupsPatients] = useState([]);
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [groupsPatientsSelected, setGroupsPatientsSelected] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [activityFormError, setActivityFormError] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const { groupId } = useParams();
  const [error, setError] = useState("");

  const history = useHistory();

  moment.locale(getLanguage(), momentDefinition);
  const localizer = momentLocalizer(moment);

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const fetchDataInitial = async () => {
    try {
      const [activityTypeResponse, feedbackParametersList, groupsPatients] =
        await Promise.all([
          getAllActivitiesTypes(),
          getAllFeedbackParameters(),
          getGroupsById({ id_group: groupId }),
        ]);

      setActivityTypeList(activityTypeResponse);
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      groupsPatients && setGroupsPatients(groupsPatients.patients);
    } catch (error) {
      rollbar.error("GroupActivityPlan - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const responseEvents = await getAllCalendarActivityByMonitGroup({
        id_group: groupId,
      });

      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(
        " GroupActivityPlan: - fetchData",
        JSON.stringify({
          responseEvents,
        })
      );
      Rollbar.configure({
        checkIgnore: () => true,
      });

      return updateEventsList(responseEvents);
    } catch (error) {
      rollbar.error("GroupActivityPlan - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
    return eventsList;
  };

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setLoadParametersList(
      event.feedbacks?.map((feedback) => feedback.feedback_parameter) ?? []
    );
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const updateEventsList = (eventsListData) => {
    const newEventsList = [];
    eventsListData.map((event, eventIdx) => {
      newEventsList.push({
        id: event?.id,
        title: event?.activity_datum.activity_type?.name,
        description: "",
        start: new Date(event?.start_date),
        end: new Date(event?.start_date),
        start_time: event?.start_time,
        end_time: event?.end_time,
        type: event?.activity_datum?.name,
        event_note: event?.event_note,
        id_presentation: event?.id_presentation,
        presentation: event?.presentation,
        id_activity_type: event?.activity_datum?.activity_type?.id,
        media: event?.activity_datum?.activity_type?.key
          ? require(`../../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
          : null,
        color: event?.color,
        data: event?.activity_datum?.data,
        feedbacks: event?.activity_feedbacks[0]
          ?.activity_resp_parameter_feedbacks
          ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : [],
        archiving_status: event?.archiving_status,
        patients: event?.patients,
        is_from_group_plan: event?.is_from_group_plan,
        id_activity_group: event?.id_activity_group,
      });
    });

    setEventsList(newEventsList);
    return newEventsList;
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "96%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>
        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    setValue("id_activity_type", draggedEvent.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });
    event.start_date = moment(start).format("YYYY-MM-DD");
    await updateActivity(event);
    setEventsList(nextEvents);
  };

  const updateActivity = async (data) => {
    try {
      const updatedActivity = await updateCalendarActivityMonitGroup({
        ...data,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity.message);
        resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity.error);
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data.assessment_motivation,
        };
        break;
      case 2:
        data.details_data = {
          name: data.name,
          treatments: data.treatments,
          treatments_motivation: data.treatments_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 3:
        data.details_data = {
          name: data.name,
          excercises: data.excercises,
          excercises_motivation: data.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data.name,
          training: data.training,
          training_motivation: data.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data.rest_motivation };
        data.feedback_parameter = loadParametersList;
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data.race_motivation,
          race_minutes: data.race_minutes,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 8:
        data.details_data = {
          name: data.name,
          rehabilitations: data.rehabilitations,
          rehabilitation_motivation: data.rehabilitation_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      default:
        break;
    }
    return data;
  };

  const createActivity = async (data) => {
    try {
      if (groupsPatientsSelected.length == 0) {
        setError(labels.groups.errors.selectAtLeastAPatient);
        return null;
      }
      setError(null);
      setValue("ids_patients", groupsPatientsSelected);
      data = createActivityObject(data, getValues("id_activity_type"));

      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(
        " GroupActivityPlan: - createActivity",
        JSON.stringify({
          ...data,
          ids_patients: groupsPatientsSelected,
          is_from_group_plan: false,
          id_group: groupId,
        })
      );
      Rollbar.configure({
        checkIgnore: () => true,
      });

      const createdActivity = await createCalendarActivityOfGroup({
        ...data,
        ids_patients: groupsPatientsSelected,
        is_from_group_plan: false,
        id_group: groupId,
      });
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity.message);
        const newEvents = await resetFields();
        return [createdActivity.id, newEvents];
      } else {
        props.snackbarShowErrorMessage(createdActivity.error);
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }

    return null;
  };

  const resetFields = () => {
    setValue("id_activity_type", null);
    setValue("id_template", null);
    setValue("id_activity_template", null);
    setValue("event_note", "");
    setValue("start_time", null);
    setValue("end_time", null);
    setValue("id_presentation", null);
    setValue("quantity_training", 0);
    setValue("quantity_treatment", 0);
    setValue("quantity_rehabilitation", 0);
    setValue("quantity_excercise", 0);
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setGroupsPatientsSelected([]);
    setLoadParametersList([]);
    return fetchData();
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          register={register}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataInitial();
    fetchData();
  }, []);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push(`/groups/edit/${groupId}`)}
              style={{
                width: 30,
                color: "#31ccad",
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">{labels.groups.toolbar.backToDetail}</SpText>
          </TitleToolbarSection>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <SpCalendarDragAndDropItems
            activitiesAvailable={activityTypeList}
            handleDragStart={handleDragStart}
          />
          <Grid item xs={11} style={{ minHeight: "750px" }}>
            <DragAndDropCalendar
              selectable
              events={eventsList}
              scrollToTime={new Date()}
              defaultDate={new Date()}
              view={"month"}
              onEventDrop={handleEventDrop}
              onDragStart={() => console.log("Drag started")}
              dragFromOutsideItem={
                displayDragItemInCell ? dragFromOutsideItem : null
              }
              onDropFromOutside={onDropFromOutside}
              handleDragStart={handleDragStart}
              onSelectEvent={handleEventDetailSelected}
              onSelectSlot={handleNewEventSelected}
              draggableAccessor={(event) =>
                !(
                  event.archiving_status ===
                    "completed_and_feedback_not_sent" ||
                  event.archiving_status === "completed_and_feedback_sent"
                )
              }
              step={60}
              eventPropGetter={(event, start, end, isSelected) => {
                const backgroundColor = event.color;
                const style = {
                  backgroundColor: backgroundColor,
                  borderRadius: "5px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  border: "0px",
                  display: "block",
                };
                return {
                  style: style,
                };
              }}
              messages={{
                showMore: (total) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onMouseOver={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {`+${total} mostra ancora`}
                  </div>
                ),
              }}
              components={{
                toolbar: CustomToolbar,
                day: { header: MyCustomHeader },
                week: { header: MyCustomHeader },
                month: { header: MyCustomHeader },
                event: ColoredEventCell,
                eventWrapper: AgendaEventWrapper,
              }}
              localizer={localizer}
            />
          </Grid>
        </Grid>
      </Grid>
      {eventDetail && (
        <SpDialog
          variant="bigDialog"
          open={openDetailActivity}
          setOpen={setOpenDetailActivity}
          title={labels.mylab.tempAdd.activityPlan.dialog.title}
        >
          <AgendaCalendarEventCard
            isGroupCard={true}
            event={eventDetail}
            fetchData={fetchData}
            setOpenDetailActivity={setOpenDetailActivity}
            updateActivity={updateActivity}
            openDetailActivity={openDetailActivity}
            createActivityObject={createActivityObject}
            loadParametersData={loadParametersData}
            loadParametersList={loadParametersList}
            setLoadParametersList={setLoadParametersList}
          />
        </SpDialog>
      )}
      <SpDialog
        variant="bigDialog"
        open={openAddActivity}
        setOpen={setOpenAddActivity}
        onCloseFunctions={resetFields}
        title={labels.patient.viewPlan.addActivity.title}
      >
        <form onSubmit={handleSubmit(createActivity)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Grid item style={{ maxWidth: "100%", marginBottom: "1%" }}>
              <SpAutocomplete
                multiple
                style={{ width: "100%" }}
                formControlWidth={"100%"}
                label={
                  labels.groups.groupActivityPlan.addActivity.patientsSelect
                    .label
                }
                value={groupsPatientsSelected}
                selectPlaceholder={
                  labels.groups.groupActivityPlan.addActivity.patientsSelect
                    .placeholder
                }
                onChange={(e, newValue) => {
                  setGroupsPatientsSelected(newValue);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <SpText
                      style={{ marginLeft: "8px" }}
                      variant="h4ComponentLabel"
                    >
                      {`${option.givenName} ${option.familyName}`}
                    </SpText>
                  ))
                }
                options={groupsPatients}
                getOptionLabel={(option) =>
                  `${option.givenName} ${option.familyName}`.toString()
                }
              />
              <SpButton
                buttonType={"accept"}
                style={{ height: 20 }}
                variant="h4ComponentLabel"
                onClick={() =>
                  setGroupsPatientsSelected(
                    groupsPatientsSelected.length === groupsPatients.length
                      ? []
                      : groupsPatients
                  )
                }
                text={
                  groupsPatientsSelected.length === groupsPatients.length
                    ? labels.groups.plan.deselectAll
                    : labels.groups.plan.selectAll
                }
              />
            </Grid>
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}

            <Controller
              render={(props) => (
                <SpSelect
                  label={labels.groups.plan.chooseActivity}
                  style={{ marginTop: "1%" }}
                  value={props.value ?? []}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    setValue("quantity_training", 0);
                    setValue("quantity_treatment", 0);
                    setValue("quantity_rehabilitation", 0);
                    setValue("quantity_excercise", 0);
                  }}
                >
                  {activityTypeList.map((p) => (
                    <SpSelectMenuItem key={p.id} value={p.id}>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require(`../../assets/activities/${p.key}${
                            props.value === p.id ? "" : "_black"
                          }.png`)}
                          style={{ maxWidth: 25, maxHeight: 25 }}
                          alt={"img"}
                        />
                        <SpText
                          color={props.value === p.id ? "white" : "black"}
                          style={{
                            color: "black !important",
                            marginLeft: "5px",
                          }}
                        >
                          {psTranslate(p.name)}
                        </SpText>
                      </div>
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={""}
              name={`id_activity_type`}
              control={control}
            />
            {templatesList.length > 0 && (
              <Controller
                render={(props) => (
                  <SpSelect
                    label={
                      labels.patient.viewPlan.addActivity.inputForm
                        .chooseTemplateActivity
                    }
                    style={{ marginTop: "1%" }}
                    value={props.value}
                    onChange={(e) => props.onChange(e.target.value)}
                  >
                    {templatesList.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.name}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`id_template`}
                control={control}
              />
            )}
            <div style={{ marginTop: "1%", marginBottom: "1%" }}>
              {renderInputDetails()}
            </div>
            <SpTextArea name="event_note" label="Note" inputRef={register} />
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <SpButton
                  buttonType={"accept"}
                  variant="none"
                  text={labels.patient.viewPlan.addActivity.title}
                  style={{ marginTop: "4%", width: "100%" }}
                  type="submit"
                />
              </Grid>
              <Grid item xs={6}>
                <SpButton
                  buttonType={"accept"}
                  variant="none"
                  text={labels.patient.viewPlan.addActivity.addAndCompile}
                  style={{ marginTop: "4%", width: "100%" }}
                  type="submit"
                  onClick={async (e) => {
                    e.preventDefault();
                    setLoading(true);
                    const result = await createActivity(getValues());
                    if (result) {
                      const [idEvent, newEventList] = result;
                      const newEvent = newEventList.find(
                        (evnt) => evnt.id === idEvent
                      );
                      if (newEvent) handleEventDetailSelected(newEvent);
                    }
                    setLoading(false);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </SpDialog>
    </>
  );
};

export default withSnackbar(GroupActivityPlan);
