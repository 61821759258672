import React, { createRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { Grid } from "@material-ui/core";
import { getMonitoringData } from "../../../models/actions/Monitoring";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import SpDialog from "../../../components/atoms/SpDialog";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import AgendaCalendarEventCard from "../../agenda/AgendaCalendarEventCard";
import { psTranslate, labels } from "../../shared/translations";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "#012e29",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#31caad",
    },
  },
}));

const PatientsMonitoringStep4 = (props) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(
    moment().subtract(3, "months")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment());
  const [openDetailActivity, setOpenDetailActivity] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [monitoringData, setMonitoringData] = useState(null);
  const minutesActivitiesCount = useRef(0);
  const scrollingDiv = createRef();
  const classes = useStyles();

  const { patId } = useParams();
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const monitoringData = await getMonitoringData({
        filter_start_date: filterStartDate,
        filter_end_date: filterEndDate,
        id_patient: patId,
      });

      if (monitoringData) {
        setMonitoringData(monitoringData);
        const el = document.getElementById("scrollingDiv");
        if (el) el.scrollLeft = el.scrollWidth;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  //function to manage the click on an event
  const handleEventDetailSelected = (activityData) => {
    let newArray = openDetailActivity;
    minutesActivitiesCount.current = 0;
    const formattedSelectedObj = {
      id: activityData.id,
      title: activityData?.activity_datum?.activity_type.name,
      description: "",
      start: new Date(activityData.start_date),
      end: new Date(activityData.start_date),
      start_time: activityData.start_time,
      end_time: activityData.end_time,
      type: activityData?.activity_datum?.name,
      event_note: activityData.event_note,
      id_presentation: activityData.id_presentation,
      id_activity_type: activityData?.activity_datum?.activity_type?.id,
      media: require(`../../../assets/activities/${activityData?.activity_datum?.activity_type?.key}.png`),
      data: activityData.activity_datum?.data,
      feedbacks: activityData.activity_feedbacks
        ? activityData.activity_feedbacks[0]?.activity_resp_parameter_feedbacks
          ? activityData.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : []
        : [],
    };
    setSelectedActivity(formattedSelectedObj);
    setOpenDetailDialog(true);
    Object(newArray.find((elem) => elem.id == activityData.id)).value = !Object(
      newArray.find((elem) => elem.id == activityData.id)
    ).value;
    setOpenDetailActivity(newArray);
  };

  const SingleCell = (props) => (
    <Grid
      item
      xs
      onClick={
        props.isSelectable
          ? () => handleEventDetailSelected(props.activityData)
          : null
      }
      style={{
        minHeight: 40,
        width: 80,
        border: "1px solid #31caad",
        padding: 4,
        backgroundColor: "transparent",
        textAlign: "center",
      }}
    >
      {props.isSelectable ? (
        <img
          src={require(`../../../assets/activities/${props.activityData?.activity_datum?.activity_type?.key}.png`)}
          style={{ maxWidth: 25, maxHeight: 25 }}
          alt={"img"}
        />
      ) : (
        ""
      )}
    </Grid>
  );

  const activityExists = (activityType, date) =>
    monitoringData?.monitoring?.calendar_activities?.filter(
      (x) =>
        x.keyActivityType === activityType &&
        x.data?.filter((y) => y.date === date)?.length > 0
    )?.length > 0;

  const getActivity = (activityType, date) =>
    activityExists(activityType, date)
      ? monitoringData?.monitoring?.calendar_activities?.filter(
          (x) =>
            x.keyActivityType === activityType &&
            x.data?.filter((y) => y.date === date)?.length > 0
        )[0]?.activity
      : null;

  const renderActivityMonitoring = () =>
    monitoringData?.monitoring?.calendar_status?.map((calendarStatusItem) => {
      return (
        <Grid
          item
          xs
          sm={1}
          container
          direction="column"
          style={{ height: "100%", flexBasis: 0 }}
        >
          <Grid
            item
            xs
            style={{
              minHeight: 40,
              width: 80,
              border: "1px solid #31caad",
              padding: 4,
            }}
          >
            <SpText textAlign={"center"} variant="h4ComponentLabel">
              {calendarStatusItem.date}
            </SpText>
          </Grid>
          {calendarStatusItem.key === "in_illness" ? (
            <Grid
              item
              xs
              style={{
                minHeight: 40,
                width: 80,
                border: "1px solid #31caad",
                padding: 4,
                backgroundColor: "#31cc",
              }}
            />
          ) : (
            <Grid
              item
              xs
              style={{
                minHeight: 40,
                width: 80,
                border: "1px solid #31caad",
                padding: 4,
                backgroundColor:
                  calendarStatusItem.key === "in_presentation"
                    ? "red"
                    : "green",
              }}
            />
          )}
          <SingleCell
            isSelectable={activityExists("assessment", calendarStatusItem.date)}
            activityData={getActivity("assessment", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists("treatment", calendarStatusItem.date)}
            activityData={getActivity("treatment", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists("exercise", calendarStatusItem.date)}
            activityData={getActivity("exercise", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists("training", calendarStatusItem.date)}
            activityData={getActivity("training", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists("rest", calendarStatusItem.date)}
            activityData={getActivity("rest", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists(
              "appointment",
              calendarStatusItem.date
            )}
            activityData={getActivity("appointment", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists("race", calendarStatusItem.date)}
            activityData={getActivity("race", calendarStatusItem.date)}
          />
          <SingleCell
            isSelectable={activityExists(
              "rehabilitation",
              calendarStatusItem.date
            )}
            activityData={getActivity(
              "rehabilitation",
              calendarStatusItem.date
            )}
          />
        </Grid>
      );
    });

  const resetFilters = () => {
    setFilterEndDate(moment());
    setFilterStartDate(moment().subtract(3, "months"));
  };

  const renderActivitySummary = () => {
    return monitoringData?.monitoring?.calendar_activities?.map((row) => (
      <Grid item xs={12} container>
        <Grid
          item
          xs={4}
          style={{ border: "1px solid #31caad", padding: "1%" }}
        >
          <SpText variant="text">
            {psTranslate(row?.activity?.activity_datum?.activity_type?.name)}
          </SpText>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ border: "1px solid #31caad", padding: "1%" }}
        >
          <SpText variant="text">{row?.data?.length}</SpText>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ border: "1px solid #31caad", padding: "1%" }}
        >
          <SpText variant="text">
            {row?.data?.map((x) => x.duration)?.reduce((a, b) => a + b)}
          </SpText>
        </Grid>
      </Grid>
    ));
  };

  useEffect(() => {
    fetchData();
  }, [filterStartDate, filterEndDate]);

  const Legend = () => {
    return (
      <Grid xs={6} container direction="row">
        <Grid
          xs={12}
          container
          direction="row"
          style={{ justifyContent: "flex-end" }}
        >
          <FiberManualRecordIcon
            style={{ cursor: "default", color: "green" }}
          />
          <SpText variant="h4ComponentLabel">
            {labels.patient.monitoring.table.in_good_health}
          </SpText>
          <FiberManualRecordIcon
            style={{ cursor: "default", color: "red", marginLeft: "3px" }}
          />
          <SpText variant="h4ComponentLabel">
            {labels.patient.monitoring.table.in_presentation}
          </SpText>
          <FiberManualRecordIcon
            style={{ cursor: "default", color: "#31cc", marginLeft: "3px" }}
          />
          <SpText variant="h4ComponentLabel">
            {labels.patient.monitoring.table.in_illness}
          </SpText>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container direction="column">
        <div style={{ width: "100%" }}>
          <Grid
            container
            xs={12}
            direction="row"
            style={{ marginBottom: "2%", justifyContent: "center" }}
          >
            <SpButton
              buttonType="accept"
              text={labels.patient.monitoring.table.prevTrimester}
              onClick={() => {
                setFilterStartDate(
                  moment(filterStartDate).subtract(3, "months")
                );
                setFilterEndDate(moment(filterEndDate).subtract(3, "months"));
              }}
            />
            <SpText variant="h1PageSubtitle" style={{ alignSelf: "center" }}>
              {moment(filterStartDate).format("DD/MM/YYYY")} -{" "}
              {moment(filterEndDate).format("DD/MM/YYYY")}
            </SpText>
            <SpButton
              buttonType="accept"
              text={labels.patient.monitoring.table.nextTrimester}
              style={{ marginRight: "0", marginLeft: "3%" }}
              onClick={() => {
                setFilterStartDate(moment(filterStartDate).add(3, "months"));
                setFilterEndDate(moment(filterEndDate).add(3, "months"));
              }}
            />
          </Grid>
          <Grid item xs={12} container spacing={2} style={{ marginBottom: 5 }}>
            <Grid item xs={6}>
              <SpTextInput
                label={labels.patient.monitoring.table.startDate}
                type="date"
                maxValue={"2100-12-31"}
                variant="text"
                style={{ width: "49%", marginRight: "2px" }}
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
              <SpTextInput
                label={labels.patient.monitoring.table.endDate}
                type="date"
                maxValue={"2100-12-31"}
                variant="text"
                style={{ width: "49%" }}
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                style={{ marginTop: 19 }}
                text={labels.patient.monitoring.table.reset}
                onClick={() => {
                  resetFilters();
                  resetFilters();
                }}
              />
            </Grid>
          </Grid>
        </div>

        {monitoringData &&
          monitoringData.monitoring?.calendar_status?.length > 0 && (
            <div style={{ width: "100%" }}>
              <Grid item xs={12} direction="row" container>
                <Grid xs={6}>
                  <SpText variant="h4ComponentLabel">
                    {labels.patient.monitoring.table.patientSituation}
                  </SpText>
                </Grid>
                <Legend />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                style={{ width: "100%", marginBottom: 5 }}
              >
                <Grid item xs={2} container direction="column">
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  />
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.status}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Assessment")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Trattamento")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Esercizio")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Allenamento")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Riposo")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Altro")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Gara")}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {psTranslate("Riabilitazione")}
                    </SpText>
                  </Grid>
                </Grid>
                <Grid
                  id={"scrollingDiv"}
                  ref={scrollingDiv}
                  item
                  xs={10}
                  container
                  direction="row"
                  wrap={"nowrap"}
                  className={classes.root}
                  style={{
                    height: "100%",
                    overflowX: "scroll",
                    maxWidth: 1200,
                  }}
                >
                  {renderActivityMonitoring()}
                </Grid>
              </Grid>
            </div>
          )}

        {monitoringData &&
          monitoringData.monitoring?.calendar_status?.length > 0 && (
            <div style={{ width: "100%" }}>
              <Grid item xs={12}>
                <SpText variant="h4ComponentLabel">
                  {labels.patient.monitoring.table.statusTable}
                </SpText>
              </Grid>
              <Grid item xs={12} container direction="column">
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel" />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInPresentation}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInGoodHealth}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInIllness}
                    </SpText>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.status}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInPresentation}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInGoodHealth}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInIllness}
                    </SpText>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}

        {monitoringData &&
          monitoringData.monitoring?.calendar_activities?.length > 0 && (
            <div style={{ marginTop: "50px", width: "100%" }}>
              <Grid item xs={12}>
                <SpText variant="h4ComponentLabel">
                  {labels.patient.monitoring.table.activityMonitoringTable}
                </SpText>
              </Grid>
              <Grid item xs={12} container direction="column">
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.activity}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.sessionNumber}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.minutesNumber}
                    </SpText>
                  </Grid>
                </Grid>
                {renderActivitySummary()}
              </Grid>
            </div>
          )}
      </Grid>
      <SpDialog
        style={{ display: "flex", flex: 1 }}
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
      >
        <AgendaCalendarEventCard
          event={selectedActivity}
          fetchData={fetchData}
          setOpenDetailActivity={setOpenDetailActivity}
          loadParametersData={[]}
          loadParametersList={[]}
          forceDisabled={true}
        />
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsMonitoringStep4);
