import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import { Grid } from "@material-ui/core";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import SpButton from "../../../components/atoms/SpButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  getAreasByRegion,
  getDisorders,
  getDysfunctionsCategoriesByDisorderTypeAndStructure,
  getStructuresByArea,
  getStructuresByRegion,
  getPathologyByStructure,
  saveSymbolToArea,
  saveSymbolToAreaPresentation,
  saveSymbolToRegion,
  saveSymbolToRegionPresentation,
  saveSymbolToStructure,
  saveSymbolToStructurePresentation,
} from "../../../models/actions/Pathologies";
import { getPresentationsByPatProf } from "../../../models/actions/Presentation";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import { getSortedAndTranslatedArray, rollbar } from "../../../utils/common";

const StyledManagingSymbols = styled(Grid)({
  borderColor: "#31ccad",
  border: "1px solid",
  padding: 5,
});

const commonDateFormat = "YYYY-MM-DD";

const PatientsAddSymbols = ({
  isPastPresentation,
  onBackPressed,
  ...props
}) => {
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [structures, setStructures] = useState([]);
  const [pathologies, setPathologies] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const [structureSelected, setStructureSelected] = useState(null);
  const [refreshMarkers, setRefreshMarkers] = useState(false);
  const [regionSelected, setRegionSelected] = useState(null);
  const [disorderTypes, setDisorderTypes] = useState([]);
  const [dysfunctionsCategories, setDysfunctionsCategories] = useState([]);
  const [possibleDysfunctions, setPossibleDysfunctions] = useState([]);
  const [dysfunctions, setDysfunctions] = useState([]);
  const [disorderSelected, setDisorderSelected] = useState([]);
  const [currentPatientDetail, setCurrentPatientDetail] = useState();
  const [presentations, setPresentations] = useState([]);
  const [addSymbolEnabled, setAddSymbolEnabled] = useState(false);
  const [symbolDate, setSymbolDate] = useState(
    moment(new Date()).format(commonDateFormat)
  );

  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { setLoading } = props;

  const { control, watch, handleSubmit, getValues, setValue, reset } = useForm({
    shouldUnregister: false,
  });

  const watchRegionsSelect = watch("id_region");
  const watchAreasSelect = watch("id_area");
  const watchStructuresSelect = watch("id_structure");
  const watchDisorderTypeSelect = watch("id_disorder_type");
  const watchDysfunctionsCategoriesSelect = watch("id_dysfunction_category");
  const watchDysfunctionSelect = watch("id_dysfunction");
  const watchIsAddPresentationCheckbox = watch("is_add_presentation");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getPresentationsByPatProf({ id_patient: patId });
      setPresentations(response);
      const result = await getRegionAreaStructureLists();
      if (result) {
        setRegions(getSortedAndTranslatedArray(result.regionList));
        setAreas(getSortedAndTranslatedArray(result.areaList));
        setStructures(getSortedAndTranslatedArray(result.structureList));
        const currentDisorderTypes = await getDisorders({
          isPastPresentation: isPastPresentation ? isPastPresentation : false,
        });
        setDisorderTypes(currentDisorderTypes);
      }
    } catch (error) {
      rollbar.error("PatientsAddSymbol - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAreasByRegion = async () => {
    try {
      setAreas([]);
      setStructures([]);
      setDysfunctions([]);
      setDysfunctionsCategories([]);
      formClearFieldsAfter("id_region");

      const id_region = getValues("id_region");
      if (id_region) {
        const currentAreas = await getAreasByRegion({ id_region: id_region });
        setAreas(getSortedAndTranslatedArray(currentAreas));
      }
    } catch (error) {
      rollbar.error("PatientsAddSymbol - fetchAreasByRegion", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchStructuresByRegion = async () => {
    try {
      setDysfunctions([]);
      setDysfunctionsCategories([]);
      formClearFieldsAfter("id_disorder_type");

      const id_region = getValues("id_region");
      if (id_region) {
        const currentStructures = await getStructuresByRegion({
          id_region: id_region,
        });
        setStructures(getSortedAndTranslatedArray(currentStructures));
      }
    } catch (error) {
      rollbar.error("PatientsAddSymbol - fetchStructuresByRegion", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchStructuresByArea = async () => {
    try {
      setStructures([]);
      setDysfunctions([]);
      formClearFieldsAfter("id_area");

      const id_area = getValues("id_area");
      if (id_area) {
        const { structures } = await getStructuresByArea({ id_area: id_area });
        setStructures(getSortedAndTranslatedArray(structures));
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchPathologiesByStructure = async () => {
    try {
      const id_structure = getValues("id_structure");
      if (id_structure) {
        const structure = await getPathologyByStructure({
          id_structure: id_structure,
        });
        if (structure.length > 0) {
          setPathologies(structure[0].pathologies);
        }
      }
    } catch (error) {
      rollbar.error("PatientsAddSymbol - fetchStructuresByArea", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchDysfunctionsCategoriesByDisorderTypeAndStructures = async () => {
    try {
      formClearFieldsAfter("id_disorder_type");

      const structure = getValues("id_structure");
      const disorder_type = getValues("id_disorder_type");
      if (structure && disorder_type) {
        const currentDysfunctionCategories =
          await getDysfunctionsCategoriesByDisorderTypeAndStructure({
            id_structure: structure,
            id_disorder_type: disorder_type,
          });
        const newDysfunctionCategories = currentDysfunctionCategories
          .map((obj) => {
            if (obj.dysfunction) return obj.dysfunction?.dysfunction_category;
          })
          .filter((x) => x)
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

        //Unique select of dysfunctions
        const newDysfunctionsSet = new Set();
        const newDysfunctions = currentDysfunctionCategories
          .filter((x) => x.dysfunction)
          .map((obj) => obj.dysfunction)
          .filter((elem) => {
            const keep = !newDysfunctionsSet.has(elem.id);
            newDysfunctionsSet.add(elem.id);
            return keep;
          });
        setDysfunctionsCategories(newDysfunctionCategories);
        setPossibleDysfunctions(newDysfunctions);
      }
    } catch (error) {
      rollbar.error(
        "PatientsAddSymbol - fetchDysfunctionsCategoriesByDisorderTypeAndStructures",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const addSymbolTo3DModel = async (data) => {
    setLoading(true);
    let resultsData = { ...data, id_patient: patId };
    resultsData.date = moment(
      isPastPresentation ? new Date("01/01/1900") : symbolDate
    ).format(commonDateFormat);
    try {
      let results;
      if (presentationId) {
        resultsData.id_presentation = presentationId;

        if (resultsData.id_structure) {
          results = await saveSymbolToStructurePresentation(resultsData);
        } else {
          if (resultsData.id_area) {
            results = await saveSymbolToAreaPresentation(resultsData);
          } else {
            if (resultsData.id_region) {
              results = await saveSymbolToRegionPresentation(resultsData);
            }
          }
        }
      } else {
        // se e' presente id struttura e' stata inserita una struttura va inserita altrimenti
        // si va verso la sezione meno specifica fino alla regione
        // TODO la struttura e' obbligatoria quindi entrera' sempre nel primo if
        if (resultsData.id_structure) {
          results = await saveSymbolToStructure(resultsData);
        } else {
          if (resultsData.id_area) {
            results = await saveSymbolToArea(resultsData);
          } else {
            if (resultsData.id_region) {
              results = await saveSymbolToRegion(resultsData);
            }
          }
        }
      }

      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        setStructures([]);
        setAreas([]);
        formClearFieldsAfter("");
        setRefreshMarkers(!refreshMarkers);
        setRegionSelected(null);
        setAreaSelected(null);
        setStructureSelected(null);
        setValue("id_structure", null);
        setValue("id_disorder_type", null);
        setValue("is_add_presentation", null);
        setValue("id_pathology", null);
        props.snackbarShowMessage(results.message);
      }
    } catch (error) {
      rollbar.error("PatientsAddSymbol - addSymbolTo3DModel", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const formClearFieldsAfter = (startField) => {
    const ids = [
      "",
      "id_region",
      "id_area",
      "id_structure",
      "id_disorder_type",
      "id_dysfunction_category",
      "id_dysfunction",
      "severity",
    ];
    const startId = ids.findIndex((elem) => elem === startField) + 1;
    if (startId !== 0)
      for (let i = startId; i < ids.length; i++) setValue(ids[i], null);
    setAddSymbolEnabled(false);
  };

  const handleBackClick = () => {
    formClearFieldsAfter("");
    onBackPressed();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchAreasByRegion();
    fetchStructuresByRegion();
  }, [watchRegionsSelect]);
  useEffect(() => {
    fetchStructuresByArea();
  }, [watchAreasSelect]);
  useEffect(() => {
    fetchDysfunctionsCategoriesByDisorderTypeAndStructures();
  }, [watchDisorderTypeSelect]);
  useEffect(() => {
    const newDysfunctions = possibleDysfunctions.filter(
      (dysfunction) =>
        dysfunction.id_dysfunction_category ==
        getValues("id_dysfunction_category")
    );
    setDysfunctions(newDysfunctions);
  }, [watchDysfunctionsCategoriesSelect]);

  useEffect(() => {
    const foundRegion = regions.find((region) => region.key === regionSelected);
    setValue("id_region", foundRegion?.id);
  }, [regionSelected]);

  useEffect(() => {
    const foundArea = areas.find((area) => area.key === areaSelected);
    setValue("id_area", foundArea?.id);
  }, [areaSelected]);

  useEffect(() => {
    //Autofill if only 1 element
    if (dysfunctionsCategories.length === 1)
      setValue("id_dysfunction_category", dysfunctionsCategories[0].id);
  }, [dysfunctionsCategories]);
  useEffect(() => {
    //Autofill if only 1 element
    if (dysfunctions.length === 1)
      setValue("id_dysfunction", dysfunctions[0].id);
  }, [dysfunctions]);

  useEffect(() => {
    fetchPathologiesByStructure();
  }, [watchIsAddPresentationCheckbox]);

  return (
    <>
      <form onSubmit={handleSubmit(addSymbolTo3DModel)}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <SpText variant="h1PageTitle">
              {labels.patient.sideBarPatEdit.addSymbol.toUpperCase()}
            </SpText>
          </Grid>
          <Grid item xs={3}>
            <SpButton
              text={labels.general.back}
              buttonType="accept"
              onClick={handleBackClick}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} container direction="row">
            <StyledManagingSymbols item xs={4} container direction="column">
              {!isPastPresentation && (
                <Grid
                  item
                  style={{
                    maxWidth: "100%",
                    marginTop: "5%",
                    marginBottom: "5%",
                  }}
                >
                  <SpTextInput
                    formControlStyle={{ width: "100%" }}
                    label={labels.bodymap.addSymbol.dateLabel}
                    type="date"
                    variant="text"
                    disabled={isPastPresentation}
                    value={isPastPresentation ? "" : symbolDate}
                    onChange={(e) => setSymbolDate(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item style={{ maxWidth: "100%" }}>
                <SpAutocomplete
                  formControlWidth={"100%"}
                  selectPlaceholder={labels.shared.addSymbol.selectRegion}
                  value={regions.find((el) => el.key == regionSelected) ?? ""}
                  onChange={(e, newValue) => {
                    setRegionSelected(newValue?.key);
                    setValue("id_region", newValue?.id);
                    formClearFieldsAfter("id_region");
                  }}
                  options={regions}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) => option.id === value?.id}
                />
              </Grid>
              <Grid item style={{ maxWidth: "100%" }}>
                <SpAutocomplete
                  formControlWidth={"100%"}
                  selectPlaceholder={labels.shared.addSymbol.selectArea}
                  value={areas.find((el) => el.key == areaSelected) ?? ""}
                  onChange={(e, newValue) => {
                    setAreaSelected(newValue?.key);
                    setValue("id_area", newValue?.id);
                    formClearFieldsAfter("id_area");
                  }}
                  options={areas}
                  getOptionLabel={(option) => psTranslate(option.name)}
                  getOptionSelected={(option, value) => option.id === value?.id}
                />
              </Grid>
              <Grid item style={{ maxWidth: "100%" }}>
                <SpAutocomplete
                  formControlWidth={"100%"}
                  selectPlaceholder={labels.shared.addSymbol.selectStructure}
                  value={
                    structures.find((el) => el.id == structureSelected) ?? ""
                  }
                  onChange={(e, newValue) => {
                    setStructureSelected(newValue?.id);
                    setValue("id_structure", newValue?.id);
                    formClearFieldsAfter("id_structure");
                  }}
                  options={structures}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value?.id}
                />
              </Grid>
              {getValues("id_structure") && (
                <Grid item style={{ maxWidth: "100%" }}>
                  <Controller
                    render={(props) => (
                      <SpAutocomplete
                        formControlWidth={"100%"}
                        selectPlaceholder={
                          labels.shared.addSymbol.selectDisorder
                        }
                        value={disorderTypes.find((el) => el.id == props.value)}
                        onChange={(e, newValue) => {
                          props.onChange(newValue.id);
                          formClearFieldsAfter("id_disorder_type");
                        }}
                        options={disorderTypes}
                        getOptionLabel={(option) => psTranslate(option.name)}
                        getOptionSelected={(option, value) =>
                          option.id === value?.id
                        }
                      />
                    )}
                    defaultValue={""}
                    name={`id_disorder_type`}
                    control={control}
                  />
                </Grid>
              )}
              {getValues("id_disorder_type") && (
                <Grid item style={{ maxWidth: "100%" }}>
                  <Controller
                    render={(props) => (
                      <SpAutocomplete
                        formControlWidth={"100%"}
                        selectPlaceholder={
                          labels.shared.addSymbol.selectDysfunctionCategory
                        }
                        value={dysfunctionsCategories.find(
                          (el) => el.id == props.value
                        )}
                        onChange={(e, newValue) => {
                          props.onChange(newValue.id);
                          formClearFieldsAfter("id_dysfunction_category");
                        }}
                        options={dysfunctionsCategories}
                        getOptionLabel={(option) => psTranslate(option.name)}
                        getOptionSelected={(option, value) =>
                          option.id === value?.id
                        }
                      />
                    )}
                    defaultValue={""}
                    name={`id_dysfunction_category`}
                    control={control}
                  />
                </Grid>
              )}
              {getValues("id_dysfunction_category") && (
                <Grid item style={{ maxWidth: "100%" }}>
                  <Controller
                    render={(props) => (
                      <SpAutocomplete
                        formControlWidth={"100%"}
                        selectPlaceholder={
                          labels.shared.addSymbol.selectDysfunction
                        }
                        value={dysfunctions.find((el) => el.id == props.value)}
                        onChange={(e, newValue) => {
                          props.onChange(newValue.id);
                          formClearFieldsAfter("id_dysfunction");
                        }}
                        options={dysfunctions}
                        getOptionLabel={(option) => psTranslate(option.name)}
                        getOptionSelected={(option, value) =>
                          option.id === value?.id
                        }
                      />
                    )}
                    defaultValue={""}
                    name={`id_dysfunction`}
                    control={control}
                  />
                </Grid>
              )}
              {getValues("id_dysfunction") && (
                <Grid item style={{ maxWidth: "100%" }}>
                  <Controller
                    style={{ minWidth: "100%" }}
                    render={(props) => (
                      <SpSelect
                        formControlWidth={"100%"}
                        value={props.value}
                        selectPlaceholder={
                          labels.shared.addSymbol.selectSeverity
                        }
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          setAddSymbolEnabled(true);
                        }}
                      >
                        {Object.entries(labels.bodymap.severityLabels).map(
                          ([key, value]) => (
                            <SpSelectMenuItem key={key} value={parseInt(key)}>
                              {value}
                            </SpSelectMenuItem>
                          )
                        )}
                      </SpSelect>
                    )}
                    defaultValue={""}
                    name={`severity`}
                    control={control}
                  />
                </Grid>
              )}
              {getValues("severity") &&
                getValues("id_disorder_type") == 2 &&
                !isPastPresentation && (
                  <Grid item style={{ maxWidth: "100%" }}>
                    <SpCheckbox
                      label={labels.patient.addSymbols.isAddPresentation}
                      rightlabel={true}
                      formControlStyle={{
                        justifyContent: "flex-start",
                        marginLeft: "5%",
                        marginTop: "5%",
                      }}
                      checked={getValues("is_add_presentation")}
                      onChange={(evnt) => {
                        setValue("is_add_presentation", evnt.target.checked);
                      }}
                    />
                  </Grid>
                )}
              {getValues("is_add_presentation") && (
                <Grid item style={{ maxWidth: "100%" }}>
                  <Controller
                    render={(props) => (
                      <SpAutocomplete
                        formControlWidth={"100%"}
                        selectPlaceholder={
                          labels.patient.addSymbols.pathologySelect
                        }
                        value={pathologies?.find((el) => el.id == props.value)}
                        onChange={(e, newValue) => {
                          props.onChange(newValue.id);
                        }}
                        options={pathologies}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.id === value?.id
                        }
                      />
                    )}
                    defaultValue={""}
                    name={`id_pathology`}
                    control={control}
                  />
                </Grid>
              )}
              <Grid item style={{ marginTop: "5%" }}>
                <SpButton
                  type={"submit"}
                  text={labels.patient.addSymbols.symbolManaging.add}
                  buttonType={"accept"}
                  disabled={!addSymbolEnabled}
                  style={{ width: "100%" }}
                />
              </Grid>
            </StyledManagingSymbols>

            <Grid
              item
              xs={8}
              style={{ minHeight: "500px", maxHeight: "800px" }}
            >
              <Sp3DModel
                isPastPresentation={isPastPresentation}
                modelBehaviour="MARKER"
                type="PATIENT"
                refreshMarkers={refreshMarkers}
                setSelectedRegion={setRegionSelected}
                setSelectedArea={setAreaSelected}
                selectedRegion={regionSelected}
                selectedArea={areaSelected}
                hideFilters={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default withSnackbar(PatientsAddSymbols);
