import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getAssessmentCount } from "../../../models/actions/Assessment";
import { dateFormat, rollbar } from "../../../utils/common";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsAssessments = ({
  dateRange,
  currActivities,
  setCurrActivities,
  setData,
  setAssessmentsIds,
  ...props
}) => {
  const [assessments, setAssessments] = useState([]);
  const [currAssessments, setCurrAssessments] = useState([]);

  const [assessmentsData, setAssessmentsData] = useState([]);

  const { groupId, patId } = useParams();

  useEffect(async () => {
    try {
      const assessmentsCounts = await getAssessmentCount({
        id_group: groupId,
        id_patients: patId ? [patId] : null,
        startDate: dateRange.start.format(dateFormat),
        endDate: dateRange.end.format(`${dateFormat} 23:59:59`),
      });
      setAssessmentsData(assessmentsCounts);

      //Extract unique assessment names
      const newAssessments = [
        ...new Set(
          Object.values(assessmentsCounts).map((el) => Object.keys(el)[0])
        ),
      ];
      setAssessments(newAssessments);
    } catch (error) {
      rollbar.error("StatisticsAssessments - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, [dateRange]);

  useEffect(() => {
    const assessmentIds = {
      ids_monitoring_assessment: [],
      ids_presentation_assessment: [],
    };

    //Adapt data
    const newData = Object.entries(assessmentsData).reduce(
      (accum, [patientKey, patientData]) => {
        accum[patientKey] = Object.entries(patientData).reduce(
          (accum, [assessmentName, assessmentData]) => {
            if (currAssessments.includes(assessmentName)) {
              //Table data
              accum[
                `${labels.groups.groupReports.secondTable.sessions} ${assessmentName}`
              ] = assessmentData.count;
              //Ids for measurements fetching
              assessmentIds.ids_monitoring_assessment.push(
                ...assessmentData.ids_monitoring_assessment
              );
              assessmentIds.ids_presentation_assessment.push(
                ...assessmentData.ids_presentation_assessment
              );
            }
            return accum;
          },
          {}
        );
        return accum;
      },
      {}
    );
    setData(newData);

    assessmentIds.ids_monitoring_assessment = [
      ...new Set(assessmentIds.ids_monitoring_assessment),
    ];
    assessmentIds.ids_presentation_assessment = [
      ...new Set(assessmentIds.ids_presentation_assessment),
    ];
    setAssessmentsIds(assessmentIds);
  }, [currAssessments]);

  return (
    <SpSelect
      label={labels.groups.groupReports.secondTable.chooseAssessment}
      selectPlaceholder={labels.groups.groupReports.secondTable.selectValue}
      multiple
      formControlStyle={{ minWidth: "100%" }}
      value={currAssessments}
      onChange={(evnt) => setCurrAssessments(evnt.target.value)}
    >
      {assessments.map((assessment) => (
        <SpSelectMenuItem key={assessment} value={assessment}>
          {psTranslate(assessment)}
        </SpSelectMenuItem>
      ))}
    </SpSelect>
  );
};

export default withSnackbar(StatisticsAssessments);
