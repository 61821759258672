import React from "react";
import { Grid } from "@material-ui/core";
import SpCheckbox from "../../../../../components/atoms/SpCheckbox";

const StudySharedControls = ({
  children,
  enabled,
  active,
  setActive,
  title,
  hide = false,
  ...props
}) => {
  //Render
  return (
    <Grid
      container
      item
      xs={12}
      alignItems={"center"}
      style={hide ? { display: "none" } : {}}
    >
      <Grid item xs={12}>
        <SpCheckbox
          label={title}
          customFromLabelStyle={{ marginBottom: 0 }}
          rightlabel={true}
          formControlStyle={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          checked={active}
          onChange={(_, value) => setActive(value)}
          disabled={!enabled}
        />
      </Grid>
      {enabled && active && children && (
        <Grid container item xs={12}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default StudySharedControls;
