import React, { useEffect, useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router";
import { SpList } from "../../../components/bundles/SpList";
import { ArrowBack } from "@material-ui/icons";
import { Box, Divider } from "@material-ui/core";
import {
  createProtocolTemplate,
  getProtocolTemplateById,
  updateProtocolTemplate,
} from "../../../models/actions/Activity";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const StyledRowList = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddProtocol = (props) => {
  const [protocolDetail, setProtocolDetail] = useState();

  const history = useHistory();
  const { register, control, watch, getValues, setValue, handleSubmit, reset } =
    useForm({
      shouldUnregister: false,
    });

  const { idElement } = useParams();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "protocol_template_rows",
    keyName: "id",
    defaultValues: [],
  });

  const watchDuration = watch("quantity");

  useEffect(() => {
    if (!idElement) {
      remove();
      if (parseInt(getValues().quantity) > 20) {
        setValue("quantity", 20);
      } else if (parseInt(getValues().quantity) < 1) {
        setValue("quantity", 1);
      } else {
        for (let i = 0; i < control.getValues().quantity; i++) {
          append({}, false);
        }
      }
      // remove();
      // for (let i = 0; i < control.getValues().quantity; i++) {
      //     append({});
      // }
    }
  }, [watchDuration]);

  const fetchData = async () => {
    if (idElement) {
      try {
        const result = await getProtocolTemplateById({
          id_template: idElement,
        });
        //console.log(result?.protocol_template_rows);
        result?.protocol_template_rows &&
          setValue("protocol_template_rows", result?.protocol_template_rows);
        setValue("quantity", result.quantity);
        setValue("name", result.name);
        setProtocolDetail(result);
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTotalMinutes = () =>
    protocolDetail?.protocol_template_rows?.reduce(
      (prev, cur) => prev + (cur.duration ? parseInt(cur.duration) : 0),
      0
    );

  const editTemplate = async (data) => {
    try {
      const results = await updateProtocolTemplate({
        ...data,
        id_protocol_template: idElement,
      });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/treatment");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async (data) => {
    if (getValues().name === "") {
      props.snackbarShowErrorMessage(labels.mylab.insertTemplateName);
      return;
    }
    try {
      const result = await createProtocolTemplate({ ...data });
      if (result.message) {
        reset();
        props.snackbarShowMessage(result.message);
        history.push("/mylab/treatment");
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const RowProtocol = ({ row, index, headers, control, register }) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        {index > 0 && (
          <Divider
            style={{
              padding: "1px",
              width: "100%",
              backgroundColor: "#31ccad",
            }}
          />
        )}
        <StyledRowList
          item
          xs={12}
          container
          justify="flex-start"
          style={{ marginTop: index > 0 && "1%" }}
        >
          <Grid item xs={4}>
            <SpTextInput
              disabled={idElement}
              name={`protocol_template_rows[${index}].activity`}
              style={{ width: "80%" }}
              inputRef={register}
              value={row?.activity}
            />
          </Grid>

          <Grid item xs={4}>
            <SpTextInput
              disabled={idElement}
              type="number"
              minValue={1}
              name={`protocol_template_rows[${index}].duration`}
              inputRef={register}
              style={{ width: "80%" }}
              value={row?.duration}
            />
          </Grid>

          <Grid item xs={4}>
            <SpTextInput
              disabled={idElement}
              name={`protocol_template_rows[${index}].description`}
              style={{ width: "80%" }}
              inputRef={register}
              value={row?.description}
            />
          </Grid>
        </StyledRowList>
      </div>
    );
  };

  const LabelValue = (props) =>
    props.value ? (
      <Grid direction="column" xs={6} style={{ marginBottom: "5px" }}>
        <SpText variant="text">{props.label}</SpText>
        <SpText variant="h4ComponentLabel">{props.value}</SpText>
      </Grid>
    ) : null;

  const DetailProtocols = (props) => {
    return (
      <Grid container xs={12}>
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: "#31ccad",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        />
        {props.rows.map((item) => (
          <Grid
            container
            xs={4}
            style={{ border: "1px solid #31ccad", padding: "8px" }}
            direction="row"
          >
            <LabelValue
              label={labels.mylab.tempAdd.treatment.list.header[0]}
              value={item.activity}
            />
            <LabelValue
              label={labels.mylab.tempAdd.treatment.list.header[1]}
              value={item.duration}
            />
            <LabelValue
              label={labels.mylab.tempAdd.treatment.list.header[2]}
              value={item.description}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const RenderProtocolMinutesCount = ({ control }) => {
    const watchMinutesTotalCountTreat = useWatch({
      control,
      name: "protocol_template_rows",
    });
    const currentArray = watchMinutesTotalCountTreat;
    let minutesSum = 0;
    currentArray?.forEach((t) => {
      if (typeof t.duration === "string") {
        const output = parseInt(t.duration, 10);
        minutesSum = minutesSum + (Number.isNaN(output) ? 0 : output);
      } else {
        minutesSum = minutesSum + t.duration;
      }
    });
    return (
      <SpText
        variant="h1PageSubtitleColored"
        style={{ display: "inline-block" }}
      >
        {minutesSum} {labels.agenda.inputDetails.totalMins}
      </SpText>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/treatment")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.treatment.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {fields.length > 0 && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                variant="none"
                style={{ marginLeft: "2%" }}
                text={
                  idElement
                    ? labels.mylab.tempAdd.treatment.actions.edit
                    : labels.mylab.tempAdd.treatment.actions.create
                }
                buttonType="accept"
                onClick={() =>
                  handleSubmit(idElement ? editTemplate : saveTemplate)()
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} container style={{ justifyContent: "flex-start" }}>
          <Grid item xs={6}>
            <SpTextInput
              style={{ width: "95%" }}
              name={`name`}
              inputRef={register}
              label={labels.mylab.tempAdd.treatment.inputs.name}
            />
          </Grid>
          <Grid item xs={6}>
            {idElement ? (
              <Grid direction="row" container>
                <SpText
                  variant="h4ComponentLabelColored"
                  style={{ border: "1px solid #31ccad", padding: "8px" }}
                >
                  {labels.mylab.tempAdd.training.inputs.quantity}
                  <Box color={"#fff"}>{protocolDetail?.quantity}</Box>
                </SpText>
                <SpText
                  variant="h4ComponentLabelColored"
                  style={{ border: "1px solid #31ccad", padding: "8px" }}
                >
                  {labels.mylab.tempAdd.treatment.inputs.totalMinutes}
                  <Box color={"#fff"}>{getTotalMinutes()}</Box>
                </SpText>
              </Grid>
            ) : (
              <SpTextInput
                style={{ maxWidth: "40%" }}
                type="number"
                name="quantity"
                minValue={0}
                inputRef={register}
                label={labels.mylab.tempAdd.treatment.inputs.quantity}
                disabled={idElement}
              />
            )}
          </Grid>
        </Grid>

        {getValues("quantity") > 0 && (
          <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <SpText variant="h1PageSubtitle">
                {labels.mylab.tempAdd.treatment.list.title}
              </SpText>
            </Grid>
            {!idElement && (
              <Grid item xs={6} style={{ textAlign: "end" }}>
                <RenderProtocolMinutesCount control={control} />
              </Grid>
            )}
          </Grid>
        )}
        {fields.length > 0 &&
          (idElement ? (
            <DetailProtocols rows={fields} />
          ) : (
            <Grid
              item
              xs={12}
              container
              style={{ border: "1px solid #31ccad" }}
            >
              <Grid item xs={12}>
                <SpList
                  rows={fields}
                  rowKey="id"
                  checkable={false}
                  deletable={false}
                  HeaderRow={
                    <StyledRowList
                      container
                      style={{ justifyContent: "flex-start" }}
                    >
                      {labels.mylab.tempAdd.treatment.list.header.map(
                        (head) => (
                          <Grid item xs={4}>
                            <SpText key={head} variant="listHeader">
                              {head}
                            </SpText>
                          </Grid>
                        )
                      )}
                    </StyledRowList>
                  }
                >
                  <RowProtocol
                    headers={labels.mylab.tempAdd.treatment.list.header}
                    control={control}
                    register={register}
                  />
                </SpList>
              </Grid>
            </Grid>
          ))}
        {fields.length > 0 && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            {!idElement && (
              <SpButton
                text={labels.mylab.tempAdd.treatment.actions.create}
                buttonType="accept"
                variant="h1"
                onClick={() => handleSubmit(saveTemplate)()}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddProtocol);
