import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState, createRef } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { nanoid } from "nanoid";
import SpButton from "../../../components/atoms/SpButton";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { nestedObjectAssign } from "./ReportHelperFns";
import PatientsReportsStep1Feedback from "./PatientsReportsStep1Feedback";
import PatientsReportsStep2PROMs from "./PatientsReportsStep2PROMs";
import PatientsReportsStep3Dysfunctions from "./PatientsReportsStep3Dysfunctions";
import PatientsReportsStep4Measurements from "./PatientsReportsStep4Measurements";
import PatientsReportsStep5Exposure from "./PatientsReportsStep5Exposure";
import PatientsReportsStep6GeneralInjury from "./PatientsReportsStep6GeneralInjury";
import PatientsReportsStep7TrainingInjury from "./PatientsReportsStep7TrainingInjury";
import PatientsReportsStep8SevereInjury from "./PatientsReportsStep8SevereInjury";
import PatientsReportsStep9MuscleInjury from "./PatientsReportsStep9MuscleInjury";
import PatientsReportsStep10LigamentInjury from "./PatientsReportsStep10LigamentInjury";
import PatientsReportsStep11Reinjury from "./PatientsReportsStep11Reinjury";
import PatientsReportsStep12MatchInjury from "./PatientsReportsStep12MatchInjury";
import PatientsReportsPDF from "./PatientsReportsPDF";
import SpLoader from "../../../components/atoms/SpLoader";
import {
  getAnalyticsConfigurationByPatient,
  setAnalyticsConfigurationByPatient,
} from "../../../models/actions/Patients";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";
import {
  downloadScreenshot,
  isFeatureFlagEnabled,
  rollbar,
} from "../../../utils/common";
import printContext from "../../../utils/printContext";

const N_MAX_GRAPHS = 10;

const PatientsReportsConfigurable = (props) => {
  const [selectedType, setSelectedType] = useState({});
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [currentGraphs, setCurrentGraphs] = useState({});
  const [requestSaveFlag, setRequestSaveFlag] = useState(false);
  const [prevConfigurationString, setPrevConfigurationString] = useState("{}");
  const [savedConfiguration, setSavedConfiguration] = useState({});
  const [loadConfiguration, setLoadConfiguration] = useState({});
  const [savedConfigUpdateFlag, setSavedConfigUpdateFlag] = useState(false);
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentType, setCurrentType] = useState({
    feedback: { type: "feedback", value: PatientsReportsStep1Feedback },
    proms: { type: "proms", value: PatientsReportsStep2PROMs },
    disorder: {
      type: "disorder",
      value: PatientsReportsStep3Dysfunctions,
    },
    assessment: {
      type: "assessment",
      value: PatientsReportsStep4Measurements,
    },
  });
  const { patId } = useParams();

  const setConfig = (data) => {
    //This is where the fun happens
    setSavedConfiguration((prev) => nestedObjectAssign(prev, data));
  };

  const reference = {};

  useEffect(async () => {
    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
    let injuryReportFeatureFlag = await isFeatureFlagEnabled("injury-report");
    injuryReportFeatureFlag
      ? setCurrentType({
          ...currentType,
          exposure: {
            type: "exposure",
            value: PatientsReportsStep5Exposure,
          },
          generalInjury: {
            type: "generalInjury",
            value: PatientsReportsStep6GeneralInjury,
          },
          trainingInjury: {
            type: "trainingInjury",
            value: PatientsReportsStep7TrainingInjury,
          },
          matchInjury: {
            type: "matchInjury",
            value: PatientsReportsStep12MatchInjury,
          },
          severeInjury: {
            type: "severeInjury",
            value: PatientsReportsStep8SevereInjury,
          },
          muscleInjury: {
            type: "muscleInjury",
            value: PatientsReportsStep9MuscleInjury,
          },
          ligamentInjury: {
            type: "ligamentInjury",
            value: PatientsReportsStep10LigamentInjury,
          },
          reinjury: {
            type: "reinjury",
            value: PatientsReportsStep11Reinjury,
          },
        })
      : null;
    setSelectedType(currentType[Object.keys(currentType)[0]]);
    try {
      //Fetch config
      const newConfiguration = await getAnalyticsConfigurationByPatient({
        id_patient: patId,
      });
      const jsonConfigString = newConfiguration?.config ?? "{}";
      const parsedConfig = JSON.parse(jsonConfigString);
      setPrevConfigurationString(jsonConfigString);
      setLoadConfiguration(parsedConfig);

      //Set graphs like saved
      const newCurrentGraphs = {};
      Object.entries(parsedConfig).forEach(([key, element]) => {
        newCurrentGraphs[key] = currentType[element.type];
      });
      setCurrentGraphs(newCurrentGraphs);
      localStorage.removeItem("pdfScreenshot");
    } catch (error) {
      rollbar.error("PatientsReportsConfigurable - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  useEffect(async () => {
    setRequestSaveFlag(false);
    try {
      //Save graph data to database
      const newConfig = JSON.stringify(savedConfiguration);
      if (prevConfigurationString != newConfig) {
        setPrevConfigurationString(newConfig);
        await setAnalyticsConfigurationByPatient({
          id_patient: patId,
          config: newConfig,
        });
      }
    } catch (error) {
      rollbar.error("PatientsReportsConfigurable - saveConfig", error);
      props.snackbarShowErrorMessage(error);
    }
  }, [savedConfigUpdateFlag]);

  useEffect(() => {
    setSavedConfigUpdateFlag(!savedConfigUpdateFlag);
  }, [savedConfiguration]);

  const MyDivider = ({ label }) => {
    const _dividerStyle = {
      padding: "1px",
      backgroundColor: "#31ccad",
      marginBottom: "5px",
      marginTop: "5px",
    };
    return (
      <Grid item xs={12} container direction="row">
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
        <Grid item xs={2} container direction="row">
          <SpText
            variant={isPrint ? "h4ComponentLabelPrint" : "h1PageSubtitle"}
          >
            {label}
          </SpText>
        </Grid>
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      {/* Graph array */}
      <Grid xs={12} item container>
        {Object.entries(currentGraphs).map(([key, { type, value: Graph }]) => {
          reference[key] = createRef(null);
          return (
            <Grid container xs={12} item key={key}>
              <Grid container xs={12} item>
                <printContext.Provider value={{ isPrint, setIsPrint }}>
                  {pdfFeatureFlag && (
                    <PatientsReportsPDF
                      reference={reference[key]}
                      label={key}
                      selectedPDF={selectedPDF}
                      setSelectedPDF={setSelectedPDF}
                      hide={false}
                      snackbarShowErrorMessage={props.snackbarShowErrorMessage}
                    ></PatientsReportsPDF>
                  )}
                  <div ref={reference[key]} key={`devGrd ${key}`}>
                    <MyDivider
                      label={labels.patient.graphReport.section[type].title}
                    />
                    <Graph
                      config={loadConfiguration[key]?.value}
                      setConfig={(data) =>
                        setConfig({ [key]: { type: type, value: data } })
                      }
                      requestSaveFlag={requestSaveFlag}
                    />
                  </div>
                </printContext.Provider>
              </Grid>
              <Grid
                container
                xs={12}
                item
                style={{ marginBottom: "2%", marginTop: "10px" }}
              >
                <SpButton
                  text={labels.analytics.remove}
                  onClick={() => {
                    const newGraphs = { ...currentGraphs };
                    delete newGraphs[key];
                    setCurrentGraphs(newGraphs);
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {/* New graph controls */}
      <Grid xs={12} item container style={{ alignItems: "self-end" }}>
        <Grid xs={3} item>
          <SpSelect
            label={""}
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={selectedType}
            onChange={(evnt) => setSelectedType(evnt.target.value)}
          >
            {Object.entries(currentType).map(([key, value]) => (
              <SpSelectMenuItem key={key} value={value}>
                {labels.patient.graphReport.section[key].title}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
        <Grid xs={3} item style={{ marginLeft: "3px" }}>
          <SpButton
            text={labels.analytics.add}
            buttonType="accept"
            onClick={() => {
              setCurrentGraphs({ ...currentGraphs, [nanoid()]: selectedType });
            }}
            disabled={Object.entries(currentGraphs).length >= N_MAX_GRAPHS}
          />
        </Grid>
        <Grid xs item style={{ textAlign: "right" }}>
          {pdfFeatureFlag && selectedPDF.length > 0 && (
            <SpButton
              text={labels.analytics.injuryReport.createPdf}
              buttonType="accept"
              onClick={() => {
                downloadScreenshot(props, setSelectedPDF);
              }}
            />
          )}
          <SpButton
            text={labels.analytics.save}
            buttonType="accept"
            onClick={() => {
              setSavedConfiguration({});
              setRequestSaveFlag(true);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsConfigurable);
