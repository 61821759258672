import React from "react";
import { Controller } from "react-hook-form";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../styled";
import { labels, psTranslate } from "../../../pages/shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpSelect, SpSelectMenuItem } from "../../atoms/SpSelect";
import { Box, Divider, Grid } from "@material-ui/core";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import { nanoid } from "nanoid";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingTop: "3%",
});

const StyledRowListHeader = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const RenderOssQuestionnaires = ({
  currentQuestionnairesList,
  control,
  setValue,
}) => {
  let globalIndex = 0;
  return currentQuestionnairesList.map((questionnairesCategoryItem, idy) => {
    return questionnairesCategoryItem.assessment_assessment_categories[0]?.assessment_elements?.map(
      (elementItem, elementItemIdx) => {
        const gridItemQuestSize =
          75 / elementItem?.assessment_element_questions.length;
        const elementQuestions =
          elementItem?.assessment_element_questions?.sort((a, b) =>
            a.id_assessment_column
              ?.toString()
              .localeCompare(b.id_assessment_column?.toString(), undefined, {
                numeric: true,
              })
          );
        return (
          <>
            <Grid
              key={`questionnairesCategoryItem_${idy}${elementItemIdx}`}
              container
              direction="column"
              style={{
                paddingTop: elementItemIdx === 0 ? "1%" : "",
                marginTop: "2%",
              }}
              spacing={1}
            >
              {elementItemIdx === 0 && (
                <>
                  <Divider
                    key={"divider"}
                    style={{
                      padding: "1px",
                      marginBottom: "2%",
                      backgroundColor: "#31ccad",
                    }}
                  />

                  <Grid
                    key={`container_${idy}${elementItemIdx}`}
                    item
                    xs={12}
                    container
                    direction="row"
                    style={{ paddingTop: "1%" }}
                    spacing={1}
                  >
                    <Grid
                      item
                      style={{
                        paddingRight: "5px",
                        maxWidth: `25%`,
                        flexBasis: `25%`,
                        paddingLeft: "5px",
                        flexGrow: 0,
                      }}
                    >
                      <SpText variant="h2PageSubtitleColored">
                        {psTranslate(questionnairesCategoryItem.name)}
                      </SpText>
                    </Grid>
                    {elementQuestions?.map((question, questIdx) => (
                      <Grid
                        item
                        key={`question_${questIdx}`}
                        style={{
                          maxWidth: `${gridItemQuestSize}%`,
                          flexBasis: `${gridItemQuestSize}%`,
                          paddingLeft: "5px",
                          flexGrow: 0,
                        }}
                      >
                        <SpText variant="h2PageSubtitleColored">
                          {psTranslate(question?.assessment_column?.name)}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              <Grid
                key={`questions_${idy}${elementItemIdx}`}
                item
                container
                xs={12}
                alignItems={"center"}
              >
                <Grid item xs={3} style={{ paddingRight: "5px" }}>
                  <SpText variant="h4ComponentLabel">
                    {psTranslate(elementItem?.name)}{" "}
                    {elementItem?.um ? "(" + elementItem?.um + ")" : ""}
                  </SpText>
                </Grid>
                {elementQuestions?.map((question, questionIdx) => {
                  globalIndex++;
                  if (question.isMultiple) {
                    return question?.assessment_answer_elements?.map(
                      (answer, answerIdx) => (
                        <Grid
                          key={`answer_${answerIdx}`}
                          item
                          style={{
                            maxWidth: `${gridItemQuestSize}%`,
                            flexBasis: `${gridItemQuestSize}%`,
                            flexGrow: 0,
                          }}
                        >
                          <Controller
                            key={globalIndex}
                            label={psTranslate(answer.value)}
                            render={(props) => (
                              <SpCheckbox
                                rightlabel
                                variant="column"
                                onChange={(e) => {
                                  props.onChange(e.target.checked);
                                }}
                                checked={props.value}
                              />
                            )}
                            defaultValue={false}
                            name={`assessmentAnswers[${globalIndex}]`}
                            control={control}
                          />
                        </Grid>
                      )
                    );
                  } else {
                    return (
                      <Grid
                        key={`questionIdx_${questionIdx}`}
                        style={{
                          maxWidth: `${gridItemQuestSize}%`,
                          flexBasis: `${gridItemQuestSize}%`,
                          paddingLeft: "5px",
                          flexGrow: 0,
                        }}
                        container
                      >
                        <Grid item xs={12}>
                          <Controller
                            render={(props) =>
                              question?.type !== "number" ? (
                                <SpSelect
                                  allowDeselect={true}
                                  labelPaddingTop={"0px"}
                                  labelMarginBottom={"0px"}
                                  formControlStyle={{ width: "100%" }}
                                  value={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.value); //Get the answer
                                  }}
                                >
                                  {question?.assessment_answer_elements?.map(
                                    (p) => (
                                      <SpSelectMenuItem key={p.value} value={p}>
                                        {psTranslate(p.value)}
                                        <Box
                                          component="span"
                                          color="red"
                                          display="inline"
                                        >
                                          {p.possible_meaning
                                            ? ` (${psTranslate(
                                                p.possible_meaning
                                              )})`
                                            : ""}
                                        </Box>
                                      </SpSelectMenuItem>
                                    )
                                  )}
                                </SpSelect>
                              ) : (
                                <SpTextInput
                                  type="number"
                                  displayLabel={false}
                                  style={{
                                    marginBottom: "0px",
                                    width: "100%",
                                  }}
                                  value={props.value}
                                  onChange={(e) => {
                                    if (!props.value)
                                      props.onChange(e.target.value);
                                    if (
                                      parseInt(e.target.value) >
                                      question?.max_value
                                    ) {
                                      props.onChange(question?.max_value);
                                    } else if (
                                      parseInt(e.target.value) <
                                      question?.min_value
                                    ) {
                                      props.onChange(question?.min_value);
                                    } else {
                                      props.onChange(e.target.value);
                                    }
                                  }}
                                  name={"numeric_question_" + question?.id}
                                  minValue={question?.min_value}
                                  maxValue={question?.max_value}
                                  step={question?.step}
                                />
                              )
                            }
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].answer`}
                            control={control}
                          />
                          <Controller
                            render={(props) => (
                              <SpTextInput
                                type="number"
                                displayLabel={false}
                                style={{
                                  marginBottom: "0px",
                                  display: "none",
                                }}
                                value={question?.id}
                              />
                            )}
                            defaultValue={question?.id}
                            name={`assessmentAnswers[${globalIndex}].id_assessment_element_question`}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "5px" }}>
                          <Controller
                            render={(props) => (
                              <SpTextInput
                                type="text"
                                displayLabel={false}
                                formControlStyle={{ width: "100%" }}
                                placeholder={
                                  labels.bundles.assessment.renderUtils
                                    .additionalNotes
                                }
                                value={props.value}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                name={"question_note" + question?.id}
                              />
                            )}
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].note`}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Grid
                key={"divider"}
                item
                xs={12}
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <Divider
                  variant="middle"
                  style={{
                    padding: "0.5px",
                    backgroundColor: "#31ccad",
                    margin: "0 auto",
                    width: "25%",
                  }}
                />
              </Grid>
            </Grid>
          </>
        );
      }
    );
  });
};

const RenderOssQuestionnairesTemplate = ({
  currentQuestionnairesList,
  control,
}) => {
  let globalIndex = 0;
  let categoryList = [];
  return currentQuestionnairesList.map((elementItem, elementItemIdx) => {
    const gridItemQuestSize =
      75 / elementItem?.assessment_element_questions?.length;
    const elementNotExists =
      categoryList.filter(
        (x) =>
          x ===
          elementItem?.assessment_assessment_category?.assessment_category?.name
      ).length <= 0;
    if (elementNotExists) {
      categoryList.push(
        elementItem?.assessment_assessment_category?.assessment_category?.name
      );
    }
    const elementQuestions = elementItem?.assessment_element_questions?.sort(
      (a, b) =>
        a.id_assessment_column
          ?.toString()
          .localeCompare(b.id_assessment_column?.toString(), undefined, {
            numeric: true,
          })
    );
    return (
      <StyledColumn key={`main_styled_column`}>
        <Grid key={`main_grid`} container direction="column" spacing={1}>
          <Grid
            container
            direction="column"
            style={{ paddingTop: elementItemIdx === 0 ? "1%" : "" }}
          >
            {elementNotExists && (
              <>
                <Divider
                  key={"divider"}
                  style={{
                    padding: "1px",
                    marginBottom: "2%",
                    backgroundColor: "#31ccad",
                  }}
                />

                <Grid
                  key={"category_grid"}
                  item
                  xs={12}
                  container
                  direction="row"
                  style={{ paddingTop: "1%" }}
                  spacing={1}
                >
                  <Grid
                    item
                    style={{
                      paddingRight: "5px",
                      maxWidth: `25%`,
                      flexBasis: `25%`,
                      paddingLeft: "5px",
                      flexGrow: 0,
                    }}
                  >
                    <SpText variant="h2PageSubtitleColored">
                      {psTranslate(
                        elementItem?.assessment_assessment_category
                          ?.assessment_category?.name
                      )}
                    </SpText>
                  </Grid>
                  {elementQuestions?.map((question, questionIdx) => (
                    <Grid
                      key={`question_${questionIdx}`}
                      item
                      style={{
                        maxWidth: `${gridItemQuestSize}%`,
                        flexBasis: `${gridItemQuestSize}%`,
                        paddingLeft: "5px",
                        flexGrow: 0,
                      }}
                    >
                      <SpText variant="h2PageSubtitleColored">
                        {psTranslate(question?.assessment_column?.name)}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <Grid key={"questions"} direction="column">
              <Grid item container xs={12} alignItems={"center"}>
                <Grid item xs={3} style={{ paddingRight: "5px" }}>
                  <SpText variant="h4ComponentLabel">
                    {psTranslate(elementItem?.name)}{" "}
                    {elementItem?.um ? "(" + elementItem?.um + ")" : ""}
                  </SpText>
                </Grid>
                {elementQuestions?.map((question, questIdx) => {
                  globalIndex++;
                  if (question.isMultiple) {
                    return question?.assessment_answer_elements?.map(
                      (answer, ansIdx) => (
                        <Grid
                          key={`quest_${ansIdx}${questIdx}`}
                          item
                          style={{
                            maxWidth: `${gridItemQuestSize}%`,
                            flexBasis: `${gridItemQuestSize}%`,
                            flexGrow: 0,
                          }}
                        >
                          <Controller
                            key={globalIndex}
                            label={answer.value}
                            render={(props) => (
                              <SpCheckbox
                                rightlabel
                                variant="column"
                                onChange={(e) => {
                                  props.onChange(e.target.checked);
                                }}
                                checked={props.value}
                              />
                            )}
                            defaultValue={false}
                            name={`assessmentAnswers[${globalIndex}]`}
                            control={control}
                          />
                        </Grid>
                      )
                    );
                  } else {
                    return (
                      <Grid
                        key={`quest_${questIdx}`}
                        item
                        style={{
                          maxWidth: `${gridItemQuestSize}%`,
                          flexBasis: `${gridItemQuestSize}%`,
                          paddingLeft: "5px",
                          flexGrow: 0,
                        }}
                        container
                      >
                        <Grid item xs={12}>
                          <Controller
                            render={(props) =>
                              question?.type !== "number" ? (
                                <SpSelect
                                  allowDeselect={true}
                                  labelPaddingTop={"0px"}
                                  labelMarginBottom={"0px"}
                                  formControlStyle={{ width: "100%" }}
                                  value={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.value); //Get the answer
                                  }}
                                >
                                  {question?.assessment_answer_elements?.map(
                                    (p) => (
                                      <SpSelectMenuItem key={p.value} value={p}>
                                        {psTranslate(p.value)}
                                        <Box
                                          component="span"
                                          color="red"
                                          display="inline"
                                        >
                                          {p.possible_meaning
                                            ? ` (${psTranslate(
                                                p.possible_meaning
                                              )})`
                                            : ""}
                                        </Box>
                                      </SpSelectMenuItem>
                                    )
                                  )}
                                </SpSelect>
                              ) : (
                                <SpTextInput
                                  type="number"
                                  displayLabel={false}
                                  style={{
                                    marginBottom: "0px",
                                    width: "100%",
                                  }}
                                  value={props.value}
                                  onChange={(e) => {
                                    if (!props.value)
                                      props.onChange(e.target.value);
                                    if (
                                      parseInt(e.target.value) >
                                      question?.max_value
                                    ) {
                                      props.onChange(question?.max_value);
                                    } else if (
                                      parseInt(e.target.value) <
                                      question?.min_value
                                    ) {
                                      props.onChange(question?.min_value);
                                    } else {
                                      props.onChange(e.target.value);
                                    }
                                  }}
                                  name={"numeric_question_" + question?.id}
                                  minValue={question?.min_value}
                                  maxValue={question?.max_value}
                                  step={question?.step}
                                />
                              )
                            }
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].answer`}
                            control={control}
                          />
                          <Controller
                            render={(props) => (
                              <SpTextInput
                                type="number"
                                displayLabel={false}
                                style={{
                                  marginBottom: "0px",
                                  display: "none",
                                }}
                                value={question?.id}
                              />
                            )}
                            defaultValue={question?.id}
                            name={`assessmentAnswers[${globalIndex}].id_assessment_element_question`}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "5px" }}>
                          <Controller
                            render={(props) => (
                              <SpTextInput
                                type="text"
                                displayLabel={false}
                                formControlStyle={{ width: "100%" }}
                                placeholder={
                                  labels.bundles.assessment.renderUtils
                                    .additionalNotes
                                }
                                value={props.value}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                name={"question_note" + question?.id}
                              />
                            )}
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].note`}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Grid xs={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Divider
                  key={"divider"}
                  variant="middle"
                  style={{
                    padding: "0.5px",
                    backgroundColor: "#31ccad",
                    margin: "0 auto",
                    width: "25%",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledColumn>
    );
  });
};

const RenderOssAnswers = ({ isPrint, assessmentDetailData }) => (
  <Grid container direction="row" style={{ marginTop: "15px" }}>
    {assessmentDetailData?.map((assessmentItem, aIdx) => {
      const gridItemQuestSize = Math.floor(
        12 / (assessmentItem?.columns?.length + 1)
      );
      return (
        <>
          <Grid
            key={`assessmentItem_${aIdx}`}
            item
            xs={12}
            container
            direction="row"
          >
            <Grid xs={12}>
              <Divider
                style={{
                  padding: "1px",
                  backgroundColor: "#31ccad",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} container direction="row">
              <Grid item xs={gridItemQuestSize}>
                <SpText variant={"h2PageSubtitleColored"}>
                  {psTranslate(assessmentItem.category)}
                </SpText>
              </Grid>
              {assessmentItem?.columns?.map((column, colIdx) => (
                <Grid item xs={gridItemQuestSize} key={`col_${colIdx}`}>
                  <SpText variant={"h2PageSubtitleColored"}>
                    {psTranslate(column)}
                  </SpText>
                </Grid>
              ))}
            </Grid>
            <Grid xs={12}>
              <Divider
                style={{
                  padding: "1px",
                  backgroundColor: "#31ccad",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              />
            </Grid>
          </Grid>
          {assessmentItem?.questions?.map((question, qIdx) => (
            <Grid
              key={`q_${qIdx}`}
              item
              xs={12}
              container
              direction="row"
              style={{ marginTop: "8px" }}
            >
              <Grid item xs={12} container direction="row">
                <Grid item xs={gridItemQuestSize} style={{ margin: "auto 0" }}>
                  <SpText
                    variant={
                      isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"
                    }
                  >
                    {psTranslate(question.question)}{" "}
                    {question.um ? `(${question.um})` : ""}
                  </SpText>
                </Grid>
                {question?.answers?.map((answer, aIdx) => (
                  <Grid item key={`ans_${aIdx}`} xs={gridItemQuestSize}>
                    <SpText
                      variant={
                        isPrint ? "h4ComponentLabelPrint" : "h4ComponentLabel"
                      }
                    >
                      {psTranslate(answer.answer)}
                    </SpText>
                    {answer.note && (
                      <SpText
                        variant={isPrint ? "h4ComponentLabelPrint" : "text"}
                      >
                        {answer.note}
                      </SpText>
                    )}
                    {answer.possible_meaning && (
                      <SpText
                        variant={
                          isPrint ? "h4ComponentLabelPrint" : "inputError"
                        }
                      >
                        {psTranslate(answer.possible_meaning)}
                      </SpText>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </>
      );
    })}
  </Grid>
);

const handleCategorySelect = (e, props, handleUpdateCategoriesSelected) => {
  props.onChange(e.target.checked);
  handleUpdateCategoriesSelected();
};
const CategoriesCheckboxes = ({
  control,
  assessmentsCategory,
  handleUpdateCategoriesSelected,
}) => (
  <StyledRow key={"categories_checkboxes"}>
    <StyledRowListHeader container spacing={1}>
      {assessmentsCategory.map((category, idx) => (
        <Grid key={`checkbox_${idx}`} item container xs={6} alignItems="center">
          <Grid item xs={2}>
            <Controller
              style={{ marginTop: "3%" }}
              render={(props) => (
                <SpCheckbox
                  key={nanoid()}
                  rightlabel
                  variant="column"
                  onChange={(e) =>
                    handleCategorySelect(
                      e,
                      props,
                      handleUpdateCategoriesSelected
                    )
                  }
                  checked={props.value}
                />
              )}
              defaultValue={false}
              name={`category[${[category.id]}]`}
              control={control}
            />
          </Grid>
          <Grid item xs={10}>
            <SpText variant="checkListLabel">
              {psTranslate(category.name)}
            </SpText>
          </Grid>
        </Grid>
      ))}
    </StyledRowListHeader>
  </StyledRow>
);

//Section that renders when we want to add a new assessment out of a template
const AddAssessment = ({
  handleUpdateCategoriesSelected,
  handleSubmit,
  saveAnswers,
  props,
  getValues,
  setValue,
  setSelectedCategoriesIds,
  assessmentsCategory,
  setSelectedElement,
  updateQuestionnairesList,
  assessmentsElements,
  assessmentTemplatesList,
  selectedAssessmentElement,
  currentQuestionnairesList,
  selectedCategoriesIds,
  selectedAssessmentTemplate,
  control,
  setSelectedAssessmentTemplate,
  setCurrentQuestionnairesList,
}) => (
  <form onSubmit={handleSubmit(saveAnswers)}>
    <>
      {!props.isRepeatAssessment && (
        <Grid container direction={"column"}>
          {!selectedAssessmentElement && (
            <Grid item xs={4}>
              <Controller
                render={(props) => (
                  <SpSelect
                    allowDeselect={true}
                    label={
                      labels.patient.monitoring.assessments.inputs
                        .selectTemplate
                    }
                    value={props.value}
                    formControlWidth={"100%"}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                      setSelectedAssessmentTemplate(e.target.value);
                      if (e.target.value == -1) {
                        setSelectedAssessmentTemplate(null);
                        setCurrentQuestionnairesList([]);
                      }
                    }}
                  >
                    {assessmentTemplatesList.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {p.name}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`assessmentTemplate`}
                control={control}
              />
            </Grid>
          )}
          {!selectedAssessmentTemplate && (
            <Grid item xs={4}>
              <Controller
                render={(props) => (
                  <SpSelect
                    allowDeselect={true}
                    label={
                      labels.patient.monitoring.assessments.inputs.selectRegion
                    }
                    value={props.value}
                    formControlWidth={"100%"}
                    onChange={(e) => {
                      e.preventDefault();
                      props.onChange(e.target.value);
                      setSelectedElement(e.target.value);
                      if (e.target.value == -1) {
                        setSelectedElement(null);
                        setCurrentQuestionnairesList([]);
                      }
                    }}
                  >
                    {assessmentsElements.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {psTranslate(p.name)}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`assessmentRegion`}
                control={control}
              />
            </Grid>
          )}
        </Grid>
      )}
      {selectedAssessmentElement && (
        <CategoriesCheckboxes
          {...{ control, assessmentsCategory, handleUpdateCategoriesSelected }}
        />
      )}
      {((selectedCategoriesIds &&
        selectedCategoriesIds.length > 0 &&
        !props.isRepeatAssessment) ||
        currentQuestionnairesList.length > 0) &&
        !selectedAssessmentTemplate &&
        !props.isRepeatAssessment && (
          <SpButton
            buttonType={"accept"}
            style={{ marginTop: "4%" }}
            onClick={() => updateQuestionnairesList(selectedCategoriesIds)}
            text={labels.general.update}
          />
        )}
      {selectedAssessmentTemplate && !props.isRepeatAssessment && (
        <SpButton
          style={{ marginTop: "4%" }}
          buttonType={"accept"}
          onClick={() => updateQuestionnairesList(selectedCategoriesIds)}
          text={labels.general.update}
        />
      )}
      {currentQuestionnairesList.length > 0 && selectedAssessmentTemplate ? (
        <RenderOssQuestionnairesTemplate
          currentQuestionnairesList={currentQuestionnairesList}
          control={control}
        />
      ) : (
        <RenderOssQuestionnaires
          currentQuestionnairesList={currentQuestionnairesList}
          control={control}
          setValue={setValue}
        />
      )}
      {currentQuestionnairesList.length > 0 && (
        <StyledRow>
          <SpButton
            style={{ marginTop: "4%" }}
            buttonType="accept"
            text={labels.general.saveAndClose}
            onClick={handleSubmit(saveAnswers)}
          />
        </StyledRow>
      )}
    </>
  </form>
);

//Section that renders the detail of inserted assessment
const DetailAssessment = ({
  props,
  assessmentListCurrentIndex,
  assessmentsFilteredList,
  currentAssessment,
  handleIndexIncrementChange,
  handleIndexDecreaseChange,
}) => (
  <Grid direction="column">
    {!props.isPrint && (
      <Grid item container direction="row" xs={12}>
        <Grid item xs={4}>
          <SpButton
            buttonType="accept"
            text={labels.bundles.assessment.renderUtils.buttons.prev}
            onClick={() => handleIndexIncrementChange()}
            disabled={
              assessmentListCurrentIndex == assessmentsFilteredList.length - 1
            }
          />
        </Grid>
        <Grid item xs={6}>
          <SpButton
            buttonType="accept"
            text={labels.bundles.assessment.renderUtils.buttons.next}
            onClick={() => handleIndexDecreaseChange()}
            disabled={assessmentListCurrentIndex == 0}
          />
        </Grid>
      </Grid>
    )}
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <RenderOssAnswers
        assessmentDetailData={currentAssessment}
        isPrint={props.isPrint}
      />
    </Grid>
  </Grid>
);

export { DetailAssessment, AddAssessment };
