import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { styled } from "../components/styled";
import SpButton from "../components/atoms/SpButton";
import SpTextInput from "../components/atoms/SpTextInput";
import SpCheckbox from "../components/atoms/SpCheckbox";
import { ArrowBack } from "@material-ui/icons";
import { labels } from "./shared/translations";
import SpText from "../components/atoms/SpText";
import { theme } from "../components/theme";
import { withSnackbar } from "../components/atoms/SpSnackBar";
import { registerProfessional } from "../models/actions/Auth";

const Root = styled("div")({
  width: "100%",
  maxHeight: "100%",
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "3%",
});

const StyledRowForm = styled("div")({
  display: "flex",
  flex: 1,
  width: "100%",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "10%",
});

const StyledColumnRegistration = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "60%",
  marginRight: "2%",
  padding: "2%",
  marginTop: "2vh",
  borderRadius: "10px",
  boxShadow: `1px 1px 11px 4px ${theme.colors.primary.lightBlue}`,
  overflowY: "auto",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
});

const formControlStyleCheck = {
  width: "calc(100% - 4%)",
  minWidth: 60,
  paddingLeft: "2%",
  paddingRight: "2%",
  justifyContent: "flex-start",
};

const Registration = (props) => {
  const { control, register, handleSubmit, getValues, reset, errors, watch } =
    useForm();
  const history = useHistory();
  const watchConfirmPassword = watch("confirmPassword");

  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    setConfirmPasswordError(
      getValues("confirmPassword") !== getValues("password")
    );
  }, [watchConfirmPassword]);

  //TODO
  // - input fields monitoring with styles
  const registerUser = async (data) => {
    if (data.companyAccess) {
      try {
        const response = await registerProfessional(data);
        if (response?.data?.message) {
          props.snackbarShowErrorMessage(response.data?.message);
        } else {
          history.push("/agency");
        }
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    } else {
      try {
        const response = await registerProfessional(data);
        if (response?.data?.message) {
          props.snackbarShowErrorMessage(response.data?.message);
        } else {
          history.push("/");
        }
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  return (
    <Root>
      <StyledColumnRegistration>
        <TitleToolbarSection>
          <ArrowBack
            onClick={() => history.push("/login")}
            style={{
              width: 30,
              color: "#31ccad",
              height: 50,
              cursor: "pointer",
            }}
          />
          <SpText variant="h4">
            {labels.registration.actions.backToLogin}
          </SpText>
        </TitleToolbarSection>
        <form onSubmit={handleSubmit(registerUser)}>
          <StyledRowForm>
            <SpText variant="h1PageTitle">{labels.registration.title}</SpText>
          </StyledRowForm>
          {inputError && (
            <StyledRowForm>
              <SpText variant="inputError">{inputError}</SpText>
            </StyledRowForm>
          )}
          <StyledRowForm>
            <SpTextInput
              name={"name"}
              inputRef={register}
              label={labels.registration.form.givenName}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"surname"}
              inputRef={register}
              label={labels.registration.form.familyName}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"birth_date"}
              type="date"
              maxValue={"2100-12-31"}
              inputRef={register}
              label={labels.registration.form.birthDate}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"sex"}
              inputRef={register}
              label={labels.registration.form.sex}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"email"}
              inputRef={register({ required: true })}
              label={labels.registration.form.email}
            />
            {errors.email && (
              <SpText variant="inputError">
                {labels.registration.emailRequired}
              </SpText>
            )}
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"username"}
              inputRef={register}
              label={labels.registration.form.username}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"password"}
              type="password"
              inputRef={register({ required: true })}
              label={labels.registration.form.password}
            />
            {errors.password && (
              <SpText variant="inputError">
                {labels.registration.passwordRequired}
              </SpText>
            )}
          </StyledRowForm>
          <StyledRowForm>
            <SpTextInput
              name={"confirmPassword"}
              type="password"
              inputRef={register}
              label={labels.registration.form.confirmPassword}
            />
            {confirmPasswordError && (
              <SpText variant="inputError">
                {labels.registration.passwordConfirm}
              </SpText>
            )}
          </StyledRowForm>
          <StyledRowForm>
            <Controller
              style={{ marginTop: "3%" }}
              render={(props) => (
                <SpCheckbox
                  formControlStyle={formControlStyleCheck}
                  rightlabel
                  variant="column"
                  label={labels.login.companyAccess}
                  onChange={(e) => {
                    //console.log(e);
                    props.onChange(e.target.checked);
                  }}
                  checked={props.value}
                />
              )}
              defaultValue={false}
              name={`companyAccess`}
              control={control}
            />
          </StyledRowForm>
          <StyledRowForm>
            <SpButton
              text={"Registrati"}
              disabled={confirmPasswordError}
              type="submit"
              buttonType={"accept"}
            />
          </StyledRowForm>
        </form>
      </StyledColumnRegistration>
    </Root>
  );
};

export default withSnackbar(Registration);