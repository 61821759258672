import React from "react";
import { BodyBase, LayoutContainerBase } from "./style";
import "../layout/layout.css";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";

const LayoutBase = (props) => {
  return (
    <LayoutContainerBase>
      <BodyBase>
        {React.Children.map(props.children, (child, index) =>
          React.cloneElement(child, {
            componentName: props.componentName,
          })
        )}
        {/* {props.children} */}
      </BodyBase>
    </LayoutContainerBase>
  );
};

export default withSnackbar(LayoutBase);