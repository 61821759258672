import {
  ActionManager,
  ExecuteCodeAction,
  Vector3,
  Matrix,
  Animation,
  AnimationGroup,
  EasingFunction,
  QuadraticEase,
} from "@babylonjs/core";
import markersConfig from "../Configs/markers";

export default class Marker {
  //Construct a marker object
  //Scene, marker ids, marker type, marker position as MeshRegion, distance from given position, marker click callback
  constructor(
    meshGroup,
    markerObj,
    isLeftPosition,
    onClickCallback,
    onEnterCallback,
    onExitCallback,
    onClickMarkerExpand
  ) {
    this.children = [];
    this._mesh = null;

    this.marker = markerObj;

    this.meshGroup = meshGroup;
    this.isActive = false;
    this.isHovering = false;

    const data = isLeftPosition
      ? markersConfig.data[meshGroup.name].current
      : markersConfig.data[meshGroup.name].child;
    this.lookAtPosition = Vector3.FromArray(data.lookAt);
    this.position = Vector3.FromArray(data.position);
    this.scaling = new Vector3(
      markersConfig.markerScaling,
      markersConfig.markerScaling,
      markersConfig.markerScaling
    );
    this.hoverScaling = new Vector3(
      markersConfig.hoverScaling,
      markersConfig.hoverScaling,
      markersConfig.hoverScaling
    );

    this.onClickMarkerExpand = onClickMarkerExpand;

    //Callbacks
    this._meshOnPointerEnter = new ExecuteCodeAction(
      ActionManager.OnPointerOverTrigger,
      (evnt) => {
        this.setHover(true);
        onEnterCallback(meshGroup.name);
      }
    );
    this._meshOnPointerExit = new ExecuteCodeAction(
      ActionManager.OnPointerOutTrigger,
      (evnt) => {
        this.setHover(false);
        onExitCallback(meshGroup.name);
      }
    );
    this._meshOnPointerClick = new ExecuteCodeAction(
      ActionManager.OnPickTrigger,
      (evnt) => {
        if (this.marker) onClickCallback(this.marker);
        else if (this.children.length === 1)
          onClickCallback(this.children[0].marker);
        else this._onRootMarkerClick();
      }
    );

    //Animation
    let easingFunction = new QuadraticEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    this.positionAnimObj = new Animation(
      "markerPosAnim",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    this.positionAnimObj.setEasingFunction(easingFunction);
    let keys3 = [
      { frame: 0, value: this.position },
      { frame: 60, value: this.position },
    ];
    this.positionAnimObj.setKeys(keys3);
  }

  //Enable/disable mesh click
  setActive(isActive, propagateToNested = false) {
    this.isActive = isActive;
    this._activate(isActive);
    if (propagateToNested)
      this.children.forEach((child) => child.setActive(isActive));
  }

  //Set as selected marker
  setHover(isHovering) {
    this.meshGroup.setHover(isHovering);
    this.isHovering = isHovering;

    const newScale = isHovering ? this.hoverScaling : this.scaling;
    newScale._isDirty = true;
    this._mesh.scaling = newScale;
  }

  updateGraphics(markerPool) {
    //Ensure release
    this._mesh?.release();
    this._mesh = null;

    //Update graphics
    if (this.marker || this.children.length >= 1) {
      let idIcon =
        this.children.length > 1
          ? "multi_current"
          : this.marker
          ? this.marker.id_icon
          : this.children[0].marker.id_icon;
      this._mesh = markerPool.getMeshForMarker(idIcon, this.children.length);

      //Restore transform
      this._mesh.position = this.position;
      this._mesh.scaling = this.scaling;
      this._mesh.lookAt(this.lookAtPosition);

      //Restore active state
      this._activate(this.isActive);

      //Update position
      this.positionAnimation?.dispose();
      this.positionAnimation = new AnimationGroup("positionAnimationGroup");
      this.positionAnimation.addTargetedAnimation(
        this.positionAnimObj,
        this._mesh
      );

      //Update children
      this.children.forEach((child) => {
        child.updateGraphics(markerPool);
      });
    }
  }

  clear() {
    if (this._mesh) {
      this._activate(false);
      this._mesh.release();
      this._mesh = null;
      this.children.forEach((elem) => elem.clear());
      this.children = [];
    }
  }

  animatePositionTo(destPosition) {
    this.positionAnimation.stop();
    let keys3 = [
      { frame: 0, value: this.position },
      { frame: 10, value: destPosition },
    ];
    this.positionAnimObj.setKeys(keys3);
    this.positionAnimation.start();
  }

  onRootMarkerUnclick() {
    this.meshGroup.setHover(false);
    this.children.forEach((child) => child.setActive(false));
  }

  _activate(isActive) {
    if (this._mesh) {
      this._mesh.actionManager.unregisterAction(this._meshOnPointerEnter);
      this._mesh.actionManager.unregisterAction(this._meshOnPointerExit);
      this._mesh.actionManager.unregisterAction(this._meshOnPointerClick);

      if (isActive) {
        this._mesh.actionManager.registerAction(this._meshOnPointerEnter);
        this._mesh.actionManager.registerAction(this._meshOnPointerExit);
        this._mesh.actionManager.registerAction(this._meshOnPointerClick);
      } else this.setHover(false);

      this._mesh.setEnabled(isActive);
    }
  }

  _onRootMarkerClick() {
    this.onClickMarkerExpand(this);

    //Hide all markers
    this.children.forEach((marker) => marker.setActive(true));

    //Spawn child markers
    let i = 0;
    const inc = (Math.PI * 2) / this.children.length;
    this.children.forEach((marker) => {
      marker.setActive(true);

      //Animate to dest position
      const lookDirection = marker.position.subtract(marker.lookAtPosition);
      let destPosition = Vector3.Cross(Vector3.UpReadOnly, lookDirection);
      const rotationMatrix = Matrix.RotationAxis(lookDirection, i * inc);
      destPosition = Vector3.TransformCoordinates(destPosition, rotationMatrix);
      const value = this.children.length * 0.01;
      destPosition = destPosition.multiplyByFloats(value, value, value);
      destPosition = destPosition.add(marker.position);

      //Setup animation
      marker.animatePositionTo(destPosition);
      i++;
    });
  }
}
