import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { styled } from "../../components/styled";
import { Toolbar } from "@material-ui/core";
import { labels, psTranslate } from "../../pages/shared/translations";
import { useHistory } from "react-router";
import log from "../../utils/common";

const Root = styled("div")({
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  padding: "2%",
});

const StyledRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  width: "100%",
  alignItems: "center",
});

const StyledRowMain = styled("div")({
  display: "flex",
  flexDirection: "row",
  flex: "1 1 50%",
  marginTop: "2%",
});

const StyledRowActions = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 2,
  justifyContent: "flex-end",
});

const StyledColumnHalf = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  paddingLeft: "5%",
  paddingRight: "5%",
});

const AgencyAccount = (props) => {
  const [openChangePsw, setOpenChangePsw] = useState(false);
  const { control, register, handleSubmit, getValues, reset } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();

  const updateAgency = () => {
    /* TODO save details  */
    /* TODO check if  */
  };

  //rendering the profile editing form
  const HeaderButtons = () => {
    return (
      <StyledRowActions>
        <SpButton
          buttonType={"accept"}
          type="submit"
          text={labels.agency.account.toolbar.modify}
        ></SpButton>
        <SpButton
          buttonType={"accept"}
          text={labels.agency.account.toolbar.changePassword}
          onClick={() => setOpenChangePsw(true)}
        ></SpButton>
        <SpButton
          buttonType={"accept"}
          onClick={() => history.push("/login")}
          text={labels.agency.account.toolbar.logout}
        ></SpButton>
      </StyledRowActions>
    );
  };

  return (
    <Root>
      <StyledRow>
        <SpText style={{ flex: 1 }} variant="h1">
          {labels.agency.account.title}
        </SpText>
        <HeaderButtons />
      </StyledRow>
      <form onSumbit={handleSubmit(updateAgency())}>
        <StyledRowMain>
          <StyledColumnHalf>
            <SpTextInput
              name="name"
              inputRef={register}
              label={labels.agency.account.form.fields.name}
            ></SpTextInput>
            <SpTextInput
              name="email"
              inputRef={register}
              label={labels.agency.account.form.fields.email}
            ></SpTextInput>
            <SpTextInput
              name="prefix"
              inputRef={register}
              label={labels.agency.account.form.fields.prefix}
            ></SpTextInput>
            <SpTextInput
              name="telephone"
              inputRef={register}
              label={labels.agency.account.form.fields.telephone}
            ></SpTextInput>
            <SpTextInput
              name="address"
              inputRef={register}
              label={labels.agency.account.form.fields.address}
            ></SpTextInput>
            <SpTextInput
              name="city"
              inputRef={register}
              label={labels.agency.account.form.fields.city}
            ></SpTextInput>
            <SpTextInput
              name="cap"
              inputRef={register}
              label={labels.agency.account.form.fields.cap}
            ></SpTextInput>
            <SpTextInput
              name="province"
              inputRef={register}
              label={labels.agency.account.form.fields.province}
            ></SpTextInput>
          </StyledColumnHalf>
          <StyledColumnHalf>
            <SpTextInput
              name="nation"
              inputRef={register}
              label={labels.agency.account.form.fields.nation}
            ></SpTextInput>
            <SpTextInput
              name="fiscalCode"
              inputRef={register}
              label={labels.agency.account.form.fields.fiscalCode}
            ></SpTextInput>
            <SpTextInput
              name="piva"
              inputRef={register}
              label={labels.agency.account.form.fields.piva}
            ></SpTextInput>
            <SpTextInput
              name="businessName"
              inputRef={register}
              label={labels.agency.account.form.fields.businessName}
            ></SpTextInput>
            <SpTextInput
              name="businessAddress"
              inputRef={register}
              label={labels.agency.account.form.fields.businessAddress}
            ></SpTextInput>
            <SpTextInput
              name="businessCap"
              inputRef={register}
              label={labels.agency.account.form.fields.businessCap}
            ></SpTextInput>
            <SpTextInput
              name="businessCity"
              inputRef={register}
              label={labels.agency.account.form.fields.businessCity}
            ></SpTextInput>
            <SpTextInput
              name="businessProvince"
              inputRef={register}
              label={labels.agency.account.form.fields.businessProvince}
            ></SpTextInput>
            <SpTextInput
              name="businessNation"
              inputRef={register}
              label={labels.agency.account.form.fields.businessNation}
            ></SpTextInput>
          </StyledColumnHalf>
        </StyledRowMain>
      </form>
      <SpDialog
        open={openChangePsw}
        setOpen={setOpenChangePsw}
        title={labels.agency.account.changePassword.title}
      >
        <SpTextInput
          disabled
          style={{ paddingRight: "2%", paddingBottom: "2%" }}
          name="password"
          inputRef={register}
          label={labels.agency.account.changePassword.password}
        />
        <SpTextInput
          disabled
          style={{ paddingBottom: "2%" }}
          name="confirmPassword"
          inputRef={register}
          label={labels.agency.account.changePassword.confirmPassword}
        />
        <SpButton
          type="submit"
          buttonType="accept"
          text={labels.agency.account.changePassword.confirm}
        />
      </SpDialog>
    </Root>
  );
};

export default AgencyAccount;