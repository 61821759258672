import { Box, Grid } from "@material-ui/core";
import React from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";

const A7_RaceDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
}) => {
  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant="h4ComponentLabel">{data?.race_motivation}</SpText>
      <Grid direction="row" container style={{ marginBottom: "2%" }}>
        <SpText variant="h4ComponentLabel">
          {labels.patient.viewPlan.addActivity.inputForm.raceMinutes}{" "}
          <Box
            color={theme.colors.primary.lightBlue}
            style={{ display: "inline-block" }}
          >
            {data?.race_minutes}
          </Box>
        </SpText>
      </Grid>
      {allowFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
          />
        ))}
    </>
  );
};

export default A7_RaceDisabledDetails;
