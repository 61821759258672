import React from "react";
import { HeaderAgency } from "../header/Header";
import { Body, LayoutContainer } from "./style";
import "./layout.css";

const LayoutAgency = (props, base) => {
  return (
    <LayoutContainer>
      <HeaderAgency />
      <Body>
        {React.Children.map(props.children, (child, index) =>
          React.cloneElement(child, {
            componentName: props.componentName,
          })
        )}
        {/* {props.children} */}
      </Body>
    </LayoutContainer>
  );
};

export default LayoutAgency;