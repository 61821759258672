import styled from "styled-components";
import BackgroundUrl from "../../../assets/Background_HD.png";

export const SupportContainer = styled.div`
  position: absolute;
  bottom: 25px;
  right: 35px;
`;
export const LayoutContainer = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  background: url(${BackgroundUrl});
  z-index: 1;
  background-size: cover;
  padding-bottom: 1%;
  padding-right: 1%;
`;

export const LayoutContainerBase = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  background: url(${BackgroundUrl});
  z-index: 1;
  background-size: cover;
  padding-right: 1%;
`;

export const Body = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 1% 3%;
  height: 100%;
  max-height: 100%;
  background: transparent;

  ::-webkit-scrollbar-track {
    background: #012e29 !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #31ccad !important;
  }
`;

export const BodyBase = styled.div`
  padding-left: 1%;
  padding-bottom: 3%;
  padding-right: 1%;
  max-height: 100%;
  background: transparent;

  ::-webkit-scrollbar-track {
    background: #012e29 !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #31ccad !important;
  }
`;
