import React from "react";
import { Divider, Grid } from "@material-ui/core";
import { styled } from "../../../../components/styled";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import { nanoid } from "nanoid";

const StyledRowList = styled(Grid)({});

const RowTreatment = ({ row, index, register, disabled = false }) => (
  <div
    key={nanoid()}
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "fit-content",
    }}
  >
    {index > 0 && (
      <Divider
        style={{ padding: "1px", width: "100%", backgroundColor: "#31ccad" }}
      />
    )}
    <StyledRowList
      item
      xs={12}
      container
      justify="flex-start"
      style={{ marginTop: index > 0 && "1%" }}
    >
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <SpTextInput
          formControlStyle={{ width: "99%" }}
          name={`treatments[${index}].activity`}
          inputRef={register}
          defaultValue={row.activity}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4} style={{ textAlign: "center" }}>
        <SpTextInput
          formControlStyle={{ width: "99%" }}
          type="number"
          minValue={1}
          name={`treatments[${index}].duration`}
          inputRef={register}
          defaultValue={row.duration}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4} style={{ textAlign: "center" }}>
        <SpTextInput
          formControlStyle={{ width: "99%" }}
          name={`treatments[${index}].description`}
          inputRef={register}
          defaultValue={row.description}
          disabled={disabled}
        />
      </Grid>
    </StyledRowList>
  </div>
);

export default RowTreatment;
