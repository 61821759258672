import Icon from "../Assets/Images/icon.png";

export default class LoadingScreen {
  show(canvas) {
    const loadingDiv = document.createElement("div");

    loadingDiv.id = "human3d-loading";
    loadingDiv.className = "human3d-loading";
    loadingDiv.style.opacity = "0";
    loadingDiv.style.transition = "opacity 1.5s ease";
    loadingDiv.style.pointerEvents = "none";
    loadingDiv.style.display = "grid";
    loadingDiv.style.gridTemplateRows = "100%";
    loadingDiv.style.gridTemplateColumns = "100%";
    loadingDiv.style.justifyItems = "center";
    loadingDiv.style.alignItems = "center";

    // Generating keyframes
    const style = document.createElement("style");
    style.id = "human3d-loading-style";
    style.type = "text/css";
    let keyFrames = `@-webkit-keyframes spin1 {\
                    0% { -webkit-transform: rotate(0deg);}
                    100% { -webkit-transform: rotate(360deg);}
                }\
                @keyframes spin1 {\
                    0% { transform: rotate(0deg);}
                    100% { transform: rotate(360deg);}
                }`;
    style.innerHTML = keyFrames;
    canvas.parentElement.appendChild(style);

    // Loading img
    let imgBack = new Image();
    imgBack.src = Icon;

    imgBack.style.width = "150px";
    imgBack.style.gridColumn = "1";
    imgBack.style.gridRow = "1";
    imgBack.style.top = "50%";
    imgBack.style.left = "50%";
    imgBack.style.transform = "translate(-50%, -50%)";
    imgBack.style.position = "absolute";

    const imageSpinnerContainer = document.createElement("div");
    imageSpinnerContainer.style.width = "300px";
    imageSpinnerContainer.style.gridColumn = "1";
    imageSpinnerContainer.style.gridRow = "1";
    imageSpinnerContainer.style.top = "50%";
    imageSpinnerContainer.style.left = "50%";
    imageSpinnerContainer.style.transform = "translate(-50%, -50%)";
    imageSpinnerContainer.style.position = "absolute";

    // Loading spinner
    let imgSpinner = new Image();
    imgSpinner.src = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAzOTIgMzkyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzOTIgMzkyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojMDNCOUExO30NCgkuc3Qxe2ZpbGw6bm9uZTt9DQo8L3N0eWxlPg0KPHRpdGxlPlNwaW5uZXJJY29uPC90aXRsZT4NCjxnPg0KCTxnIGlkPSJTcGlubmVyIj4NCgkJPHBhdGggY2xhc3M9InN0MCIgZD0iTTQwLjIsMTI2LjRjMy43LTcuMyw3LjctMTQuNCwxMi0yMS4zbDMuNC01LjFsMy41LTVjMS4yLTEuNiwyLjQtMy4zLDMuNy00LjlzMi41LTMuMiwzLjgtNC44DQoJCQlDNzEuOCw3OSw3Ny40LDczLDgzLjQsNjcuNGM2LTUuNiwxMi40LTEwLjgsMTktMTUuN2MzLjMtMi40LDYuNy00LjgsMTAuMy03czcuMS00LjMsMTAuOC02LjNjNy4zLTQsMTQuNy04LDIyLjUtMTEuNQ0KCQkJYzMuOS0xLjcsNy45LTMuMywxMi00LjZjNC4xLTEuMyw4LjItMi40LDEyLjQtMy4yYzQuMi0wLjksOC41LTEuNCwxMi44LTEuNGM0LjUsMC4xLDksMS40LDEyLjgsMy43YzMsMS45LDQsNS45LDIuMSw5DQoJCQljLTAuNCwwLjctMSwxLjMtMS42LDEuOGwtMC40LDAuM2MtMS44LDEuMy0zLjcsMi4zLTUuOCwzYy0xLjgsMC43LTMuNywxLjMtNS43LDEuNmMtMS45LDAuNC0zLjgsMC42LTUuNywwLjcNCgkJCWMtMSwwLTEuOSwwLjItMi45LDAuM2wtMi45LDAuMmMtMy44LDAuMy03LjYsMC43LTExLjUsMS4xYy0zLjgsMC40LTcuNywwLjgtMTEuNiwxLjRjLTcuOCwxLjEtMTUuNSwyLjktMjIuOSw1LjYNCgkJCWMtMy43LDEuMy03LjMsMy0xMC45LDQuNnMtNy4xLDMuNS0xMC42LDUuNUM5MS43LDY0LjcsNzkuMiw3NSw2OC42LDg3Yy0xLjMsMS41LTIuNiwzLTMuOSw0LjZzLTIuNSwzLjItMy44LDQuN2wtMy41LDQuOQ0KCQkJYy0xLjEsMS43LTIuMiwzLjMtMy4zLDVjLTQuMyw2LjgtOCwxNC0xMC45LDIxLjVjLTAuMywwLjgtMS4yLDEuMi0yLjEsMC45cy0xLjItMS4yLTAuOS0yLjFDNDAuMSwxMjYuNSw0MC4yLDEyNi41LDQwLjIsMTI2LjR6Ig0KCQkJLz4NCgkJPHJlY3QgY2xhc3M9InN0MSIgd2lkdGg9IjM5MiIgaGVpZ2h0PSIzOTIiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==`;

    imgSpinner.style.animation = "spin1 0.75s infinite linear";
    imgSpinner.style.webkitAnimation = "spin1 0.75s infinite linear";
    imgSpinner.style.transformOrigin = "50% 50%";
    imgSpinner.style.webkitTransformOrigin = "50% 50%";

    imageSpinnerContainer.appendChild(imgSpinner);

    loadingDiv.appendChild(imgBack);
    loadingDiv.appendChild(imageSpinnerContainer);

    loadingDiv.style.backgroundColor = "#00000000";
    canvas.parentElement.appendChild(loadingDiv);

    loadingDiv.style.opacity = "1";
  }

  hide(instantly = false) {
    const loadingDiv = document.getElementById("human3d-loading");
    const style = document.getElementById("human3d-loading-style");

    //Real hide function
    const onTransitionEnd = () => {
      if (loadingDiv && loadingDiv.parentElement)
        loadingDiv.parentElement.removeChild(loadingDiv);
      if (style && style.parentElement) style.parentElement.removeChild(style);
    };

    //Animate if possible
    if (loadingDiv) {
      loadingDiv.style.opacity = "0";
      if (!instantly)
        loadingDiv.addEventListener("transitionend", onTransitionEnd);
      else onTransitionEnd();
    }
  }
}
