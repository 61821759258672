import React from "react";
import moment from "moment";
import { Bar, Label, LabelList } from "recharts";
import { barColors, barWithDimension } from "../../../../utils/common";
import { labels, psTranslate } from "../../../shared/translations";
import { SpAutocomplete } from "../../../../components/atoms/SpAutocomplete";
import { Grid, Chip } from "@material-ui/core";
import SpText from "../../../../components/atoms/SpText";

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="16" fill="#FFFFFF" fontWeight="Bold" />;
};

export const createBarschartDataPoints = (labelKey, size, color) => {
  return (
    <Bar barSize={size} dataKey={labelKey} fill={color} stackId="a">
      <LabelList
        dataKey={labelKey}
        position="center"
        content={renderCustomizedLabel}
      />
    </Bar>
  );
};

/**
 * Funzione che ritorna la creazione di una grid contentente l'autocomplete dotato di lista di elementi da visualizzare,
 * elementi visualizzati e azione da abilitare durante il cambio di item selezionato
 *
 * @param placeholder: label da mostrare
 * @param listItemsSelected: lista di item selezionati
 * @param listItemAvailable: lista di tutti gli item disponibili
 * @param functionAfterClick: azione da richiamare quando viene selezionato un item dalla lista
 * @returns {JSX.Element}
 *
 */
export const getAutocomplete = (
  placeholder,
  listItemsSelected,
  listItemAvailable,
  functionAfterClick
) => {
  return (
    <Grid item container xs={6} direction="column" alignItems={"left"}>
      <SpText>{placeholder} </SpText>
      <SpAutocomplete
        multiple
        style={{ width: "100%" }}
        formControlWidth={"100%"}
        selectPlaceholder={placeholder}
        value={listItemAvailable.find((sel) => sel.id == listItemsSelected)}
        onChange={(_, newValue) => functionAfterClick(newValue)}
        displayLabel={false}
        renderTags={(value, getTagProps) =>
          listItemsSelected.map((option, index) => (
            <Chip
              key={option.name}
              style={{ backgroundColor: "#31ccad", color: "white" }}
              label={psTranslate(option.name)}
              size="medium"
              {...getTagProps({ index })}
            />
          ))
        }
        options={listItemAvailable}
        getOptionLabel={(option) => psTranslate(option.name)}
        getOptionSelected={(option, value) => option.id === value?.id}
      />
    </Grid>
  );
};

/**
 * Funzione che crea l'oggetto da visualizzare nel grafico rispetto ai pazienti selezionati e alle regioni selezionate
 *
 * @param selectedInjuryPatient: lista di pazienti selezionati
 * @param regions: lista di regioni selezionate
 * @returns {dataChart,  barChart}: lista di dati da mostrare con le bar definite
 *
 */
export const selectPatientsInjury = (
  selectedInjuryPatient,
  regions,
  areas,
  isPercentage
) => {
  if (!areas || areas.length == []) {
    return injuryPatientsByAttribute(
      selectedInjuryPatient,
      regions,
      "region.id",
      isPercentage
    );
  } else {
    return injuryPatientsByAttribute(
      selectedInjuryPatient,
      areas,
      "area.id",
      isPercentage
    );
  }
};

/**
 * Funzione che crea l'oggetto da visualizzare nel grafico rispetto ai gruppi selezionati e alle regioni selezionate
 *
 * @param selectedInjuryPatient: lista di pazienti selezionati
 * @param regions: lista di regioni selezionate
 * @param areas: lista di aree selezionate
 * @returns {res}: lista di dati da mostrare con le bar definite
 *
 */
export const selectedGroupsInjury = (
  selectedInjuryPatient,
  regions,
  areas,
  isPercentage
) => {
  let res = {};
  if (!areas || areas.length === 0) {
    // raggruppamento per regione
    for (let gruppo of selectedInjuryPatient) {
      let temp = injuryGroupsByRegions([gruppo], regions, true, isPercentage);
      res["barChart"] = temp["barChart"];
      res["dataChart"] =
        res["dataChart"] === undefined
          ? temp["dataChart"]
          : res["dataChart"].concat(temp["dataChart"]);
    }
  } else {
    // raggruppamento per area
    for (let gruppo of selectedInjuryPatient) {
      let temp = injuryGroupsByAreas([gruppo], areas, true, isPercentage);
      res["barChart"] = temp["barChart"];
      res["dataChart"] =
        res["dataChart"] === undefined
          ? temp["dataChart"]
          : res["dataChart"].concat(temp["dataChart"]);
    }
  }

  return res;
};

/**
 * Funzione che crea l'oggetto da visualizzare nel grafico rispetto al gruppo selezionato e alle regioni selezionate
 *
 * @param selectedInjuryPatient: lista di pazienti selezionati
 * @param regions: lista di regioni selezionate
 * @returns {dataChart,  barChart}: lista di dati da mostrare con le bar definite
 *
 */
const injuryGroupsByRegions = (
  selectedInjuryPatient,
  regions,
  isAvarageEnabled,
  isPercentage
) => {
  const result = injuryGroupsByAttribute(
    selectedInjuryPatient,
    regions,
    isAvarageEnabled,
    "region.id",
    isPercentage
  );
  return { dataChart: result.dataChart, barChart: result.barChart };
};

const injuryGroupsByAreas = (
  injuriesPatient,
  areas,
  isAvarageEnabled,
  isPercentage
) => {
  const result = injuryGroupsByAttribute(
    injuriesPatient,
    areas,
    isAvarageEnabled,
    "area.id",
    isPercentage
  );
  return { dataChart: result.dataChart, barChart: result.barChart };
};

export const injuryLocalizationGroups = (
  injuriesPatient,
  regions,
  areas,
  isPercentage
) => {
  if (areas.length === 0) {
    // creo tante bar quante sono le regioni selezionate
    return injuryGroupsByRegions(injuriesPatient, regions, false, isPercentage);
  } else {
    // creo tante bar quante sono le aree selezionate
    return injuryGroupsByAreas(injuriesPatient, areas, false, isPercentage);
  }
};

/**
 * Funzione che crea l'oggetto da visualizzare nel grafico rispetto al gruppo selezionato e alle regioni selezionate
 *
 * @param selectedInjuryPatient: lista di pazienti selezionati
 * @param tissueTypes: lista tissue type selezionati
 * @returns {dataChart,  barChart}: lista di dati da mostrare con le bar definite
 *
 */
export const selectPatientsTissueTypeInjury = (
  selectedInjuryPatient,
  tissueTypes,
  isPercentage
) => {
  return injuryPatientsByAttribute(
    selectedInjuryPatient,
    tissueTypes,
    "pathology.id_tissue_type",
    isPercentage
  );
};

export const injuryGroupsByTissueTypes = (
  injuriesPatient,
  tissueTypes,
  isAvarageEnabled,
  isPercentage
) => {
  return injuryGroupsByAttribute(
    injuriesPatient,
    tissueTypes,
    isAvarageEnabled,
    "pathology.id_tissue_type",
    isPercentage
  );
};

export const injuryPatientsByMechanis = (
  selectedInjuryPatient,
  mechanisms,
  isPercentage
) => {
  return injuryPatientsByAttribute(
    selectedInjuryPatient,
    mechanisms,
    "mechanism",
    isPercentage
  );
};

export const injuryGroupsByMechanism = (
  injuriesPatient,
  mechanisms,
  isAvarageEnabled,
  isPercentage
) => {
  return injuryGroupsByAttribute(
    injuriesPatient,
    mechanisms,
    isAvarageEnabled,
    "mechanism",
    isPercentage
  );
};

const calculateGravity = (injuriesPatient) => {
  injuriesPatient.forEach(({ patients }) => {
    patients.forEach(({ injuries }) => {
      injuries.forEach((injury) => {
        if (injury.end_date) {
          const diffDate = moment(injury.end_date).diff(
            moment(injury.start_date),
            "days"
          );
          if (diffDate < 4 && diffDate > 0) injury["duration"] = 1;
          if (diffDate < 8 && diffDate > 3) injury["duration"] = 2;
          if (diffDate < 29 && diffDate > 7) injury["duration"] = 3;
          if (diffDate > 28) injury["duration"] = 4;
        } else if (injury.estimated_end_date) {
          const diffDate = moment(injury.estimated_end_date).diff(
            moment(injury.start_date),
            "days"
          );
          if (diffDate < 4 && diffDate > 0) injury["duration"] = 1;
          if (diffDate < 8 && diffDate > 3) injury["duration"] = 2;
          if (diffDate < 29 && diffDate > 7) injury["duration"] = 3;
          if (diffDate > 28) injury["duration"] = 4;
        } else {
          injury["duration"] = 0;
        }
      });
    });
  });
  return injuriesPatient;
};

export const injuryPatientsByGravity = (
  selectedInjuryPatient,
  gravities,
  isPercentage
) => {
  selectedInjuryPatient = calculateGravity([
    {
      patients: selectedInjuryPatient,
    },
  ]);
  selectedInjuryPatient = selectedInjuryPatient[0].patients;
  return injuryPatientsByAttribute(
    selectedInjuryPatient,
    gravities,
    "duration",
    isPercentage
  );
};

export const injuryGroupsByGravity = (
  injuriesPatient,
  gravities,
  isAvarageEnabled,
  isPercentage
) => {
  injuriesPatient = calculateGravity(injuriesPatient);
  return injuryGroupsByAttribute(
    injuriesPatient,
    gravities,
    isAvarageEnabled,
    "duration",
    isPercentage
  );
};

export const injuryPatientsByReinjury = (
  selectedInjuryPatient,
  reinjuries,
  isPercentage
) => {
  return injuryPatientsByAttribute(
    selectedInjuryPatient,
    reinjuries,
    "reinjury",
    isPercentage
  );
};

export const injuryGroupsByReinjury = (
  injuriesPatient,
  reinjuries,
  isAvarageEnabled,
  isPercentage
) => {
  return injuryGroupsByAttribute(
    injuriesPatient,
    reinjuries,
    isAvarageEnabled,
    "reinjury",
    isPercentage
  );
};

const index = (obj, is, value) => {
  if (typeof is == "string") return index(obj, is.split("."), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0) return obj;
  else {
    let ret = obj[is[0]] ? index(obj[is[0]], is.slice(1), value) : null;
    return ret;
  }
};

export const injuryPatientsByAttribute = (
  selectedInjuryPatient,
  attributes,
  attributeName,
  isPercentage
) => {
  let bars = [];
  let resultArray = [];
  for (let { givenName, familyName, injuries } of selectedInjuryPatient) {
    let item = {};
    item["groupDate"] = givenName + " " + familyName;
    // inizializzo il mio item con tutte i mechanism
    item[labels.analytics.injuryReport.graphs.altro] = 0;
    for (let injury of injuries) {
      if (
        attributes.find(({ id }) => id === index(injury, attributeName)) ===
        undefined
      )
        item[labels.analytics.injuryReport.graphs.altro]++;
    }
    for (let attribute of attributes) {
      const nInjury = injuries.filter(
        (injury) => index(injury, attributeName) === attribute.id
      ).length;
      if (nInjury > 0) {
        if (attribute.name) {
          item[psTranslate(attribute.name)] = item[psTranslate(attribute.name)]
            ? item[psTranslate(attribute.name)] + nInjury
            : nInjury;
        } else {
          item[attribute.key] = item[attribute.key]
            ? item[attribute.key] + nInjury
            : nInjury;
        }
      }
    }
    if (isPercentage) {
      let sum = 0;
      for (let key in item) {
        if (key !== "groupDate") sum += item[key];
      }
      for (let key in item) {
        if (key !== "groupDate")
          item[key] = sum > 0 ? ((item[key] / sum) * 100).toFixed(1) : 0;
      }
    }
    resultArray.push(item);
  }

  for (let i = 0; i < attributes.length; i++) {
    bars.push(
      createBarschartDataPoints(
        attributes[i].name
          ? psTranslate(attributes[i].name)
          : attributes[i].key,
        barWithDimension(resultArray.length),
        barColors[Object.keys(barColors)[i]]
      )
    );
  }

  // bar for other regions
  bars.push(
    createBarschartDataPoints(
      labels.analytics.injuryReport.graphs.altro,
      barWithDimension(resultArray.length),
      "#008B8B"
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

const injuryGroupsByAttribute = (
  injuriesPatient,
  listAttribute,
  isAvarageEnabled,
  attributeName,
  isPercentage
) => {
  let bars = [];
  let resultArray = [];
  for (let { group, name, patients } of injuriesPatient) {
    let item = {};
    item["groupDate"] = group ? group.name : name;
    item[labels.analytics.injuryReport.graphs.altro] = 0;
    for (let patient of patients) {
      patient = patient.patient ? patient.patient : patient;
      let injuriesPatientList = patient?.injuries;
      for (let injury of injuriesPatientList) {
        if (
          listAttribute.find(
            ({ id }) => id === index(injury, attributeName)
          ) === undefined
        )
          item[labels.analytics.injuryReport.graphs.altro]++;
      }
    }
    for (let patient of patients) {
      patient = patient.patient ? patient.patient : patient;
      for (let attribute of listAttribute) {
        const nInjury = patient.injuries.filter(
          (injury) => index(injury, attributeName) === attribute.id
        ).length;
        if (nInjury > 0) {
          if (attribute.name) {
            item[psTranslate(attribute.name)] = item[
              psTranslate(attribute.name)
            ]
              ? item[psTranslate(attribute.name)] + nInjury
              : nInjury;
          } else {
            item[attribute.key] = item[attribute.key]
              ? item[attribute.key] + nInjury
              : nInjury;
          }
        }
      }
    }
    resultArray.push(item);
  }
  // Funzione commentata in quanto potrebbe essere necessaria in futuro per calcolare la media
  // if (isAvarageEnabled) {
  //   resultArray.forEach((item) => {
  //     let lengthIt = injuriesPatient.find(({ group, name }) =>
  //       group ? group?.name === item["groupDate"] : name === item["groupDate"]
  //     ).patients.length;
  //     for (let key in item) {
  //       if (key !== "groupDate") {
  //         item[key] = item[key] / lengthIt;
  //       }
  //     }
  //   });
  // }

  if (isPercentage) {
    resultArray.forEach((item) => {
      let lengthIt = 0;
      let tempPat = injuriesPatient.find(({ group, name }) =>
        group ? group?.name === item["groupDate"] : name === item["groupDate"]
      ).patients;
      tempPat.forEach(({ patient, injuries }) => {
        lengthIt += patient ? patient.injuries.length : injuries.length;
      });
      for (let key in item) {
        if (key !== "groupDate") {
          item[key] = lengthIt ? ((item[key] / lengthIt) * 100).toFixed(1) : 0;
        }
      }
    });
  }
  // creo tutte le bar char
  for (let i = 0; i < listAttribute.length; i++) {
    bars.push(
      createBarschartDataPoints(
        listAttribute[i].name
          ? psTranslate(listAttribute[i].name)
          : listAttribute[i].key,
        barWithDimension(resultArray.length),
        barColors[Object.keys(barColors)[i]]
      )
    );
  }

  // bar for other regions
  bars.push(
    createBarschartDataPoints(
      labels.analytics.injuryReport.graphs.altro,
      barWithDimension(resultArray.length),
      "#008B8B"
    )
  );
  return { dataChart: resultArray, barChart: bars };
};
