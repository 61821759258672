import {
  ArcRotateCamera,
  Animation,
  AnimationGroup,
  EasingFunction,
  QuadraticEase,
  Vector3,
} from "@babylonjs/core";

export default class HumanCamera {
  constructor(canvas, scene) {
    this.cameraTargetStartPos = new Vector3(0, 0.85, 0);

    //PARAMS:a, b, radius, target pos, scene
    //a and b are hrot and vrot(from 0 to PI)
    let camera = new ArcRotateCamera(
      "MainCamera",
      0,
      1.4,
      3,
      this.cameraTargetStartPos,
      scene
    );
    this.camera = camera;
    camera.allowUpsideDown = false;

    //Setup clipping planes
    camera.minZ = 0.1;
    camera.maxZ = 10.0;

    camera.lowerRadiusLimit = 0.5;
    camera.upperRadiusLimit = 3.5;

    //Disable XY dragging & zoom
    camera.panningSensibility = 0;
    camera.wheelPrecision = 999999;
    camera.pinchPrecision = 999999;

    //Auto rotation behaviour (auto rotation when idle)
    this.enableRotation(true);

    camera.useFramingBehavior = false;

    //Attach camera controls
    camera.attachControl(canvas, true);

    //Animations
    let easingFunction = new QuadraticEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

    this.radiusAnimation = new Animation(
      "cameraRadiusAnim",
      "radius",
      60,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    this.radiusAnimation.setEasingFunction(easingFunction);
    this._updateAnim(
      this.radiusAnimation,
      this.camera.radius,
      this.camera.radius
    );

    this.targetAnimation = new Animation(
      "cameraTargetAnim",
      "target",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    this.targetAnimation.setEasingFunction(easingFunction);
    this._updateAnim(
      this.targetAnimation,
      this.camera.target,
      this.camera.target
    );

    this.betaAnimation = new Animation(
      "cameraBetaAnim",
      "beta",
      60,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );
    this._updateAnim(this.betaAnimation, this.camera.beta, this.camera.beta);

    this.zoomAnimation = new AnimationGroup("zoomAnimationGroup");
    this.zoomAnimation.addTargetedAnimation(this.targetAnimation, this.camera);
    this.zoomAnimation.addTargetedAnimation(this.radiusAnimation, this.camera);
    this.zoomAnimation.addTargetedAnimation(this.betaAnimation, this.camera);
  }

  enableRotation(rotateOn) {
    this.camera.useAutoRotationBehavior = rotateOn;
    if (rotateOn) {
      this.camera.autoRotationBehavior.idleRotationSpeed = 0.35;
      this.camera.autoRotationBehavior.idleRotationWaitTime = 250; //ms
      this.camera.autoRotationBehavior.idleRotationSpinupTime = 1000; //ms
    }
  }

  zoomTo(destPos, instantly = false) {
    //Stop pending animations
    this.zoomAnimation.stop();

    //dest == null means restore to start position
    let radiusDest = destPos ? 0.5 : 3;
    destPos = destPos ? destPos : this.cameraTargetStartPos;

    if (!instantly) {
      this._updateAnim(this.radiusAnimation, this.camera.radius, radiusDest);
      this._updateAnim(this.targetAnimation, this.camera.target, destPos);
      this._updateAnim(this.betaAnimation, this.camera.beta, this.camera.beta); //Ensure beta stay the same as before

      //Do actual zooming
      if (
        this.camera.radius !== radiusDest ||
        !this.camera.target.equals(destPos)
      ) {
        this.zoomAnimation.reset();
        this.zoomAnimation.play();
      }
    } else {
      const beta = this.camera.beta;
      this.camera.target = destPos;
      this.camera.radius = radiusDest;
      this.camera.beta = beta; //Ensure beta stay the same as before
    }
  }

  getPosition() {
    return this.camera.position;
  }

  _updateAnim(anim, start, end) {
    let keys3 = [
      { frame: 0, value: start },
      { frame: 60, value: end },
    ];
    anim.setKeys(keys3);
  }
}
