import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpIconButton from "../../components/atoms/SpIconButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpButton from "../../components/atoms/SpButton";
import SpSearch from "../../components/atoms/SpSearch";
import { isMobile } from "react-device-detect";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { Avatar, Button as MuiButton, Grid, Tooltip } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpTextInput from "../../components/atoms/SpTextInput";
import {
  ArrowBack,
  CloudUpload as MuiCloudUpload,
  DeleteForever,
} from "@material-ui/icons";
import { findPatients } from "../../models/actions/Patients";
import {
  addResourceToServer,
  getProfessionalByEmail,
  getResourceFromServer,
} from "../../models/actions/Professionals";
import {
  deletePatientGroup,
  deleteProfessionalGroup,
  getGroupsById,
  setGroupProfileImage,
  updateGroup,
} from "../../models/actions/Groups";
import { spacing } from "@material-ui/system";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import { rollbar } from "../../utils/common";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const StyledRow = styled("div")({
  display: "flex",
  flex: "1 1 100%",
});

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const StyledWrapperPatientsList = styled(Grid)({
  background: "transparent",
  border: "1px solid #31ccad",
  padding: "3%",
});
const StyledRowPatientFound = styled(Grid)({
  margin: "2%",
});
const rolesRows = [
  { id: 1, name: labels.groups.roles.admin, value: "admin" },
  { id: 2, name: labels.groups.roles.reader, value: "reader" },
];

const GroupsDetail = (props) => {
  const [openProfessional, setOpenAddProfessional] = useState(false);
  const [openPatient, setOpenAddPatient] = useState(false);
  const [openDeleteProfessional, setOpenDeleteProfessional] = useState(false);
  const [openDeletePatient, setOpenDeletePatient] = useState(false);
  const [searchStringPat, setSearchStringPat] = useState(null);
  const [searchStringProf, setSearchStringProf] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [usersFound, setUsersFound] = useState([]);
  const [patientsList, setPatients] = useState([]);
  const [professionalsList, setProfessionals] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [currentProfessionalRole, setCurrentProfessionalRole] = useState(null);
  const [profileImage, setProfileImg] = useState();
  const { groupId } = useParams();
  const classes = useStyles();
  const { setLoading } = props;

  const { reset, control, register, handleSubmit, getValues, setValue } =
    useForm({
      shouldUnregister: false,
    });

  const {
    fields: fieldsPatient,
    append: appendPatient,
    remove: removePatient,
  } = useFieldArray({
    control: control,
    key: "id",
    name: "patients",
  });

  const {
    fields: fieldsProf,
    append: appendProf,
    remove: removeProfessional,
  } = useFieldArray({
    control: control,
    key: "id",
    name: "professionals",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const groupResults = await getGroupsById({ id_group: groupId });
      setPatients(groupResults.patients);
      setProfessionals(groupResults.professionals);
      if (groupResults.file) {
        const profileBase64 = await getResourceFromServer({
          file: groupResults.file,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }
      let formattedProfessionalArray = [];
      groupResults.professionals.map((prof) => {
        if (prof.id == localStorage.getItem("userId"))
          setCurrentProfessionalRole(prof.professional_group.role);
        formattedProfessionalArray.push({
          id: prof.id,
          professional: prof,
          role: prof.professional_group.role,
        });
      });
      reset({
        id: groupResults?.id,
        name: groupResults.name,
        description: groupResults.description,
        patients: groupResults.patients,
        professionals: formattedProfessionalArray,
      });
    } catch (e) {
      props.snackbarShowErrorMessage(e);
      rollbar.error("GroupsDetail - fetchData", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const resetRole = () => {
    setSelectedRole(null);
  };

  useEffect(() => {
    resetRole();
  }, [openProfessional]);

  //upload group image functions
  const uploadProfileImage = async (file) => {
    try {
      //setting up the file to be uploaded
      if (file) {
        const data = new FormData();
        data.append("file", file);
        const resourceFileData = await addResourceToServer(data);
        if (resourceFileData) {
          const fileData = {
            name: resourceFileData[0].key.replace("bucketFolder/", ""),
            extension: resourceFileData[0].contentType.substring(
              resourceFileData[0].contentType.indexOf("/") + 1,
              resourceFileData[0].contentType.length
            ),
            size: resourceFileData[0].size,
            url: resourceFileData[0].location,
          };
          const result = await setGroupProfileImage({
            id_group: groupId,
            file: fileData,
          });
          if (result) {
            props.snackbarShowMessage(result.message);
            fetchData();
          }
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - uploadProfileImage", error);
    }
  };

  const onFileChange = (e) => {
    uploadProfileImage(e.target.files[0]);
    fetchData();
  };

  const handleClick = (event) => {
    if (profileImage) {
      event.preventDefault();
      setProfileImg(null);
    }
  };

  const updateGroupData = async (data) => {
    try {
      const results = await updateGroup(data);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - updateGroupData", error);
    }
  };

  const addProfessionalToGroup = async (professional) => {
    try {
      if (!selectedRole) {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.selectRole
        );
        return;
      }
      if (
        fieldsProf.filter((prof) => prof?.id == professional?.id).length <= 0
      ) {
        appendProf({
          id: professional?.id,
          professional: professional,
          role: rolesRows[selectedRole - 1].value,
        });
        setUsersFound([]);
        setSearchString(null);
        setSelectedRole(null);
        updateGroupData(getValues());
        setOpenAddProfessional(false);
      } else {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.professionalAlreadyExists
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - addProfessionalToGroup", error);
    }
  };

  const addPatientToGroup = async (patient) => {
    try {
      if (fieldsPatient.filter((prof) => prof?.id == patient?.id).length <= 0) {
        appendPatient({ id: patient?.id, patient: patient });
        setUsersFound([]);
        setSearchString(null);
        setSelectedRole(null);
        setOpenAddPatient(false);
        await updateGroup(getValues());
      } else {
        props.snackbarShowErrorMessage(
          labels.groups.groupDetail.errors.patientAlreadyExists
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - addPatientToGroup", error);
    }
  };

  const searchPatients = async () => {
    if (searchString) {
      try {
        await findPatients({ pattern: searchString }).then(async (response) => {
          if (response.length > 0) {
            setUsersFound(response);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("GroupsDetail - searchPatients", error);
      }
    }
  };

  const searchProfessionals = () => {
    if (searchString) {
      try {
        getProfessionalByEmail({ email: searchString }).then((response) => {
          if (response.length > 0) {
            setUsersFound(response);
          } else {
            setUsersFound([]);
          }
        });
      } catch (error) {
        props.snackbarShowErrorMessage(error);
        rollbar.error("GroupsDetail - searchProfessionals", error);
      }
    }
  };

  const removePatientFromServer = async (answer) => {
    try {
      if (answer) {
        const idx = selectedPatient?.index;
        const profListNoCurrent = professionalsList.filter(
          (prof) => prof.id !== localStorage.getItem("userId")
        );
        if (selectedPatient?.patient) {
          const result = await deletePatientGroup({
            id_patient: selectedPatient?.patient,
            id_group: groupId,
            professionals: profListNoCurrent,
          });
          if (result.message) {
            removePatient(idx);
            props.snackbarShowMessage(result.message);
          }
        }
      } else {
        const idx = selectedPatient?.index;
        if (selectedPatient?.patient) {
          const result = await deletePatientGroup({
            id_patient: selectedPatient?.patient,
            id_group: groupId,
          });
          if (result.message) {
            removePatient(idx);
            props.snackbarShowMessage(result.message);
          }
        }
      }
      setOpenDeletePatient(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removePatientFromServer", error);
    }
  };

  const removeProfessionalFromServer = async (answer) => {
    try {
      if (answer) {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
            patients: patientsList,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      } else {
        const idx = selectedProfessional?.index;
        if (selectedProfessional?.professional) {
          const result = await deleteProfessionalGroup({
            id_professional_to_delete: selectedProfessional?.professional,
            id_group: groupId,
          });
          if (result.message) {
            props.snackbarShowMessage(result.message);
            removeProfessional(idx);
          }
        }
      }
      setOpenDeleteProfessional(false);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("GroupsDetail - removeProfessionalFromServer", error);
    }
  };

  const resetStateDialog = () => {
    setUsersFound(null);
    setSearchString(null);
  };

  const history = useHistory();

  // Columns
  const headCellsPatient = [
    {
      id: "patientName",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.name,
    },
    {
      id: "patientStatus",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.status,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupDetail.addPatientTable.header.actions,
    },
  ];

  // Columns
  const headCellsProfessional = [
    {
      id: "professionalName",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.name,
    },
    {
      id: "professionalRole",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.role,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: labels.groups.groupAdd.addProfessionalTable.header.actions,
    },
  ];

  const filterPat = (e) => {
    setSearchStringPat(e.currentTarget.value);
    let newDisplayFilters = [];
    newDisplayFilters = patientsList?.filter((patient) =>
      `${patient.givenName} ${patient.familyName}`
        .toString()
        .trim()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().trim().toLowerCase())
    );
    setValue("patients", newDisplayFilters);
    if (e.currentTarget.value === "") setValue("patients", patientsList);
  };
  const filterProf = (e) => {
    setSearchStringProf(e.currentTarget.value);
    let newDisplayFilters = [];
    newDisplayFilters = professionalsList?.filter((professional) =>
      `${professional.name ? professional.name : ""} ${
        professional.surname ? professional.surname : ""
      }`
        .toString()
        .trim()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().trim().toLowerCase())
    );
    setValue("professionals", newDisplayFilters);
    if (e.currentTarget.value === "")
      setValue("professionals", professionalsList);
  };

  const GroupAddProfessionalRow = ({
    row,
    rowKey,
    index,
    handleSelectedClick,
  }) => {
    let { professional, role } = row;
    if (!professional) {
      professional = row;
    }
    return (
      <>
        <SpTableRow role="checkbox" key={professional?.id} tabIndex={index}>
          <SpTableCell>
            <SpText variant="tableText">{`${
              professional ? professional?.name : row.name
            } ${professional ? professional?.surname : row.surname}`}</SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">{role}</SpText>
          </SpTableCell>
          <SpTableCell align={"right"}>
            {professional?.id !== parseInt(localStorage.getItem("userId")) &&
              currentProfessionalRole == "admin" && (
                <SpIconButton
                  buttonType={"accept"}
                  onClick={() => {
                    setSelectedProfessional({
                      professional: professional ? professional?.id : row.id,
                      index: index,
                    });
                    setOpenDeleteProfessional(true);
                  }}
                >
                  <DeleteForever />
                </SpIconButton>
              )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  const prevent = (e) => {
    if (e.keyCode === 13) e.preventDefault();
  };

  const GroupAddPatientRow = ({ row, index }) => {
    const { patient } = row;
    const isInPresentation =
      row.presentations?.length > 0 &&
      row.presentations?.filter((p) => !p.end_date)?.length > 0;
    return (
      <>
        <SpTableRow role="checkbox" key={patient?.id} tabIndex={index}>
          <SpTableCell>
            <SpIconAndTextButton
              isIconRight={true}
              text={`${patient ? patient?.givenName : row.givenName} ${
                patient ? patient?.familyName : row.familyName
              }`}
              textVariant={"tableText"}
              onClick={() =>
                history.push(`/patients/edit/${patient ? patient?.id : row.id}`)
              }
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#31ccad",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <Tooltip
              style={{ fontSize: "1rem !important" }}
              title={
                isInPresentation
                  ? labels.groups.groupDetail.in_presentation
                  : labels.groups.groupDetail.in_good_health
              }
            >
              <SpIconButton variant="lightBlue" small>
                <FiberManualRecordIcon
                  style={{
                    cursor: "default",
                    color: isInPresentation ? "red" : "green",
                  }}
                />
              </SpIconButton>
            </Tooltip>
          </SpTableCell>
          <SpTableCell align={"right"}>
            {currentProfessionalRole == "admin" && (
              <SpIconButton
                buttonType={"accept"}
                onClick={() => {
                  setSelectedPatient({
                    patient: patient ? patient?.id : row.id,
                    index: index,
                  });
                  setOpenDeletePatient(true);
                }}
              >
                <DeleteForever />
              </SpIconButton>
            )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(updateGroupData)}>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container alignItems={"center"}>
            <Grid item xs={8}>
              <TitleToolbarSection>
                <ArrowBack
                  onClick={() => history.push("/groups")}
                  style={{
                    width: 30,
                    color: "#31ccad",
                    height: 50,
                    cursor: "pointer",
                  }}
                />
                <SpText variant="h1">{labels.groups.toolbar.back}</SpText>
              </TitleToolbarSection>
            </Grid>
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                buttonType={"accept"}
                onClick={() => history.push(`/groups/activity/${groupId}`)}
                text={labels.groups.toolbar.activityPlan}
              />
              <SpButton
                buttonType={"accept"}
                onClick={() => history.push(`/groups/statistics/${groupId}`)}
                text={labels.groups.toolbar.statistics}
              />
              <SpButton
                buttonType={"accept"}
                type="submit"
                disabled={currentProfessionalRole !== "admin"}
                variant="none"
                text={labels.groups.groupDetail.actions.save}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid container spacing={4} direction={"column"}>
              <Grid item container spacing={2} alignItems={"center"}>
                <Grid item xs={2}>
                  <Avatar
                    imgProps={{
                      style: {
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "cover",
                      },
                    }}
                    className={classes.largeAvatar}
                    src={profileImage ? profileImage : ""}
                  />
                  {currentProfessionalRole == "admin" && (
                    <>
                      <input
                        accept="image/*"
                        hidden
                        id="avatar-image-upload"
                        type="file"
                        name="file"
                        onChange={onFileChange}
                      />
                      <label
                        htmlFor="avatar-image-upload"
                        style={{ color: "white", textAlign: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="white"
                          component="span"
                          mb={2}
                          onClick={handleClick}
                        >
                          <UploadIcon mr={2} />
                        </Button>
                      </label>
                    </>
                  )}
                </Grid>
                <Grid item container direction="column" xs={10} spacing={4}>
                  <Grid item xs={12}>
                    <SpTextInput
                      variant="fullWidth"
                      disabled={currentProfessionalRole !== "admin"}
                      label={labels.groups.groupDetail.name}
                      name="name"
                      inputRef={register}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <SpText variant="h1PageTitle">
                      {labels.groups.groupDetail.addPatientTable.title}
                    </SpText>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={2}>
                      <StyledRow>
                        <SpSearch
                          onKeyDown={prevent}
                          onChange={(e) => filterPat(e)}
                          value={searchStringPat}
                        />
                      </StyledRow>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={10}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <SpButton
                        variant="h1PageTitle"
                        buttonType={"accept"}
                        disabled={currentProfessionalRole !== "admin"}
                        text={
                          labels.groups.groupAdd.addPatientTable
                            .addPatientButton
                        }
                        onClick={() => setOpenAddPatient(true)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SpTable
                      style={{ minWidth: "100%" }}
                      headCells={headCellsPatient}
                      rows={fieldsPatient}
                      rowKey="id"
                      padding={false}
                      notCheckable={false}
                      tableContainerMaxHeight={
                        isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                      }
                    >
                      <GroupAddPatientRow />
                    </SpTable>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <SpText variant="h1PageTitle">
                      {labels.groups.groupDetail.addProfessionalTable.title}
                    </SpText>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={2}>
                      <StyledRow>
                        <SpSearch
                          onKeyDown={prevent}
                          onChange={(e) => filterProf(e)}
                          value={searchStringProf}
                        />
                      </StyledRow>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      style={{ justifyContent: "flex-end" }}
                    >
                      <SpButton
                        buttonType={"accept"}
                        variant="h1PageTitle"
                        disabled={currentProfessionalRole !== "admin"}
                        text={
                          labels.groups.groupAdd.addProfessionalTable
                            .addProfessionalButton
                        }
                        onClick={() => setOpenAddProfessional(true)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <SpTable
                      style={{ minWidth: "100%" }}
                      headCells={headCellsProfessional}
                      rows={fieldsProf}
                      rowKey="id"
                      padding={false}
                      notCheckable={false}
                      tableContainerMaxHeight={
                        isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                      }
                    >
                      <GroupAddProfessionalRow />
                    </SpTable>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SpDialog
          style={{ padding: "1%" }}
          open={openPatient}
          setOpen={setOpenAddPatient}
          onCloseFunctions={resetStateDialog}
          title={labels.groups.groupDetail.searchPatient}
        >
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              xs={12}
              style={{ alignItems: "self-end", marginBottom: "10px" }}
            >
              <SpTextInput
                value={searchString}
                label={labels.patient.patientLink.inputSearch.label2}
                onChange={(e) => setSearchString(e.currentTarget.value)}
              />
              <SpButton
                text={labels.patient.patientLink.inputSearch.buttons.search}
                style={{ marginLeft: "10px" }}
                variant="none"
                buttonType="accept"
                onClick={() => searchPatients()}
              />
            </Grid>

            <StyledWrapperPatientsList
              item
              container
              direction="column"
              xs={12}
              style={{ marginBottom: "2%" }}
            >
              {usersFound ? (
                usersFound.map((patientFound) => (
                  <StyledRowPatientFound
                    item
                    container
                    direction={"row"}
                    xs={12}
                  >
                    <Grid item xs={6} alignSelf={"center"}>
                      <SpText variant="text">{`${patientFound.givenName} ${patientFound.familyName} - ${patientFound.email}`}</SpText>
                    </Grid>
                    <Grid item xs={6}>
                      <SpButton
                        text={
                          labels.groups.groupAdd.addPatientTable.dialog
                            .addProfessionalButton
                        }
                        variant="none"
                        style={{ width: "100%" }}
                        buttonType="accept"
                        onClick={() => addPatientToGroup(patientFound)}
                      />
                    </Grid>
                  </StyledRowPatientFound>
                ))
              ) : (
                <SpText variant={"text"}>
                  {
                    labels.groups.groupAdd.addPatientTable.dialog
                      .usersFoundPlaceholder
                  }
                </SpText>
              )}
            </StyledWrapperPatientsList>
          </Grid>
        </SpDialog>
        <SpDialog
          style={{ padding: "1%" }}
          open={openProfessional}
          setOpen={setOpenAddProfessional}
          onCloseFunctions={resetStateDialog}
          title={labels.groups.groupDetail.searchProfessional}
        >
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              xs={12}
              style={{ alignItems: "self-end", marginBottom: "10px" }}
            >
              <SpSelect
                label={labels.groups.groupDetail.assignRole}
                selectPlaceholder={labels.groups.groupDetail.chooseRole}
                value={selectedRole}
                onChange={(e) => {
                  setSelectedRole(e.target.value);
                }}
              >
                {rolesRows.map((p) => (
                  <SpSelectMenuItem key={p.id} value={p.id}>
                    {psTranslate(p.name)}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
              <SpTextInput
                value={searchString}
                label={
                  labels.groups.groupAdd.addProfessionalTable.dialog.searchText
                }
                style={{ marginLeft: "10px" }}
                onChange={(e) => setSearchString(e.currentTarget.value)}
              />
              <SpButton
                text={labels.patient.patientLink.inputSearch.buttons.search}
                style={{ marginLeft: "10px" }}
                variant="none"
                buttonType="accept"
                onClick={() => searchProfessionals()}
              />
            </Grid>
            <StyledWrapperPatientsList
              item
              container
              direction="column"
              xs={12}
              style={{ marginBottom: "2%" }}
            >
              {usersFound ? (
                usersFound.map((professional) => (
                  <StyledRowPatientFound
                    item
                    container
                    direction={"row"}
                    xs={12}
                  >
                    <Grid item xs={6} alignSelf={"center"}>
                      <SpText variant="text">{`${professional.name} ${professional.surname} - ${professional.email}`}</SpText>
                    </Grid>
                    <Grid item xs={6}>
                      <SpButton
                        text={
                          labels.groups.groupAdd.addProfessionalTable.dialog
                            .addProfessionalButton
                        }
                        buttonType="accept"
                        variant="none"
                        style={{ width: "100%" }}
                        onClick={() => addProfessionalToGroup(professional)}
                      />
                    </Grid>
                  </StyledRowPatientFound>
                ))
              ) : (
                <SpText variant={"text"}>
                  {
                    labels.groups.groupAdd.addProfessionalTable.dialog
                      .usersFoundPlaceholder
                  }
                </SpText>
              )}
            </StyledWrapperPatientsList>
          </Grid>
        </SpDialog>
      </form>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deletePatientDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deletePatientDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={() => removePatientFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => removePatientFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
      <SpDialog
        style={{ padding: "1%" }}
        open={openDeleteProfessional}
        setOpen={setOpenDeleteProfessional}
        onCloseFunctions={resetStateDialog}
        title={labels.groups.groupDetail.deleteProfessionalDialog.title}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.groups.groupDetail.deleteProfessionalDialog.subtitle}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.yes}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(true)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                text={labels.groups.groupDetail.deleteProfessionalDialog.no}
                buttonType="accept"
                onClick={() => removeProfessionalFromServer(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(GroupsDetail);
