import React from "react";
import { useParams } from "react-router-dom";
import AddActivity from "../../pages/mylab/MyLabAddTemp/AddActivity";
import AddAssesment from "../../pages/mylab/MyLabAddTemp/AddAssesment";
import AddExcercise from "../../pages/mylab/MyLabAddTemp/AddExcercise";
import AddHandbook from "../../pages/mylab/MyLabAddTemp/AddHandbook";
import AddProtocol from "./MyLabAddTemp/AddProtocol";
import AddTraining from "../../pages/mylab/MyLabAddTemp/AddTraining";
import AddRiskFactor from "../../pages/mylab/MyLabAddTemp/AddRiskFactor";
import AddRehab from "./MyLabAddTemp/AddRehab";
import AddDysfunctionWeights from "./MyLabAddTemp/AddDysfunctionWeights";

const MyLabAddTemp = (props) => {
  const { sectionName } = useParams();
  const { setLoading } = props;

  switch (sectionName) {
    case "activity-plan":
      return <AddActivity />;
    case "assesment":
      return <AddAssesment />;
    case "excercise":
      return <AddExcercise />;
    case "training":
      return <AddTraining />;
    case "treatment":
      return <AddProtocol />;
    case "rehab":
      return <AddRehab />;
    case "handbook":
      return <AddHandbook />;
    case "risk_factor":
      return <AddRiskFactor setLoading={setLoading} />;
    case "dysfunction_weights":
      return <AddDysfunctionWeights />;
    default:
      break;
  }
};

export default MyLabAddTemp;
