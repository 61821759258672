import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpIconAndTextButton from "../../components/atoms/SpIconAndTextButton";
import SpButton from "../../components/atoms/SpButton";
import { styled } from "../../components/styled";
import { labels, psTranslate } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import SpIconButton from "../../components/atoms/SpIconButton";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { SpTable, SpTableCell, SpTableRow } from "../../components/bundles";
import { isMobile } from "react-device-detect";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Grid, Tooltip } from "@material-ui/core";
import { getPresentationsByPatProf } from "../../models/actions/Presentation";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";
import moment from "moment";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  marginBottom: "5%",
});

const PatientsPresentations = (props) => {
  const [displayPresentations, setDisplayPresentation] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();

  //Fetching presentation data
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getPresentationsByPatProf({ id_patient: patId });
      setDisplayPresentation(response.reverse());
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const headCellsPresentation = [
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableReason,
      isAction: false,
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableStart,
      isAction: false,
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableEnd,
      isAction: false,
    },
    {
      id: "estimated_end_date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableEstimatedEnd,
      isAction: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableStatus,
      isAction: false,
    },
    {
      id: "satisfaction",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableSatisfaction,
      isAction: false,
    },
  ];

  //rendering the profile editing form
  const PresentationRow = ({ row }) => {
    let bodyPartName = "";
    if (row?.structure?.name) {
      bodyPartName = `${psTranslate(row?.structure?.name)}`;
    } else {
      if (row?.area?.name) {
        bodyPartName = `${psTranslate(row?.area?.name)}`;
      } else {
        if (row?.region?.name) {
          bodyPartName = `${psTranslate(row?.region?.name)}`;
        }
      }
    }
    return (
      <>
        <SpTableRow tabIndex={-1}>
          <SpTableCell>
            <SpIconAndTextButton
              isIconRight={true}
              textVariant="tableText"
              text={
                row?.pathology?.name
                  ? psTranslate(row?.pathology?.name) +
                    " " +
                    bodyPartName?.toLowerCase()
                  : psTranslate(row?.type) + " " + bodyPartName?.toLowerCase()
              }
              onClick={() =>
                history.push(`/patients/edit/${patId}/presentation/${row.id}`)
              }
            >
              <ArrowForwardIosIcon
                style={{
                  width: 10,
                  color: "#31ccad",
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </SpIconAndTextButton>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row.start_date
                ? moment(row.start_date).format("DD/MM/yyyy")
                : ""}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row.end_date && moment(row.end_date).format("DD/MM/yyyy")}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row.estimated_end_date &&
                moment(row.estimated_end_date).format("DD/MM/yyyy")}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <Tooltip
              style={{ fontSize: "1rem !important" }}
              title={
                row.end_date
                  ? labels.patient.presentation.closed
                  : labels.patient.presentation.open
              }
            >
              <SpIconButton variant="lightBlue" small>
                <FiberManualRecordIcon
                  style={{
                    cursor: "default",
                    color: row.end_date ? "green" : "red",
                  }}
                />
              </SpIconButton>
            </Tooltip>
          </SpTableCell>
          <SpTableCell>
            {row.end_date && (
              <SpText variant="tableText">{row.satisfaction}</SpText>
            )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            item
            xs={2}
            style={{ position: "sticky", top: 0, height: "100%" }}
          >
            <PatientsEditSidebar
              props={props}
              patId={patId}
              history={history}
              currentPatient={currentPatient}
            />
          </Grid>
          <Grid item xs={10}>
            <Grid item container xs={12}>
              <Grid item xs={8}>
                <SpText variant="h1PageTitle">
                  {labels.patient.presentation.title.toUpperCase()}
                </SpText>
              </Grid>
              <Grid
                item
                xs={4}
                justify="flex-end"
                style={{ alignSelf: "center" }}
              >
                <SpButton
                  onClick={() =>
                    history.push(`/patients/edit/${patId}/presentations/add`)
                  }
                  style={{ width: "100%" }}
                  buttonType="accept"
                  text={labels.patient.presentation.newPresentation.toUpperCase()}
                />
              </Grid>
            </Grid>

            {displayPresentations && displayPresentations?.length === 0 && (
              <Grid container item xs={12} style={{ marginTop: "1%" }}>
                <SpText variant="h1PageSubtitle">
                  {labels.patient.presentation.no_presentations}
                </SpText>
              </Grid>
            )}
            {displayPresentations && displayPresentations?.length > 0 && (
              <StyledTableColumn>
                <SpTable
                  headCells={headCellsPresentation}
                  rows={displayPresentations}
                  pagination={true}
                  rowKey="id"
                  padding={false}
                  notCheckable={false}
                  tableContainerMaxHeight={
                    isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                  }
                >
                  <PresentationRow />
                </SpTable>
              </StyledTableColumn>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(PatientsPresentations);
