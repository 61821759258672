import React, { useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Grid from "@material-ui/core/Grid";
import SpButton from "../../../components/atoms/SpButton";
import { labels } from "../../shared/translations";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { useHistory, useParams } from "react-router";
import { ArrowBack } from "@material-ui/icons";
import { Divider } from "@material-ui/core";
import {
  saveAssessmentTemplate,
  updateAssessmentTemplate,
} from "../../../models/actions/Assessment";
import AddAssessmentTemplate from "../../../components/bundles/assessment/AddAssessmentTemplate";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddAssessment = (props) => {
  const [templateDetailData, setTemplateDetailData] = useState(null);
  const [assessmentElements, setAssessmentElements] = useState({});
  const [name, setName] = useState("");
  const history = useHistory();

  const { idElement } = useParams();

  const editTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const results = await updateAssessmentTemplate({
        name: name,
        id_assessment_template: idElement,
      });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/assesment");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const data = {};
      data.name = name;
      data.assessment_element_ids = []
        .concat(...Object.values(assessmentElements))
        .map((elem) => elem.id);
      data.is_handbook = 0;
      const results = await saveAssessmentTemplate({ ...data });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/assesment");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const createHandbookTemplate = async () => {
    try {
      if (!name) throw labels.mylab.tempAdd.assesment.inputs.requiredName;

      const data = {};
      data.name = name;
      data.assessment_element_ids = []
        .concat(templateDetailData?.AssesmentElement)
        .map((elem) => elem.id);
      data.is_handbook = 1;
      const results = await saveAssessmentTemplate({ ...data });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.push("/mylab/assesment");
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  return (
    <>
      <Grid
        direction="column"
        container
        spacing={2}
        style={{ paddingLeft: "1%" }}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab/assesment")}
                style={{
                  width: 30,
                  color: "#31ccad",
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: "#fff" }}>
                  {labels.mylab.tempDetail.assesment.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {idElement && (
            <Grid
              item
              xs={2}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                text={labels.mylab.tempAdd.assesment.actions.edit}
                buttonType="accept"
                onClick={() => (idElement ? editTemplate : saveTemplate)()}
              />
            </Grid>
          )}
          {idElement && (
            <Grid
              item
              xs={2}
              alignItems={"center"}
              style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                text={labels.mylab.tempAdd.assesment.actions.createHandbook}
                buttonType="accept"
                onClick={() => createHandbookTemplate()}
              />
            </Grid>
          )}
          {!idElement && Object.keys(assessmentElements).length > 0 && (
            <Grid
              item
              xs={4}
              spacing={0}
              style={{ paddingLeft: "0" }}
              container
              justify={"flex-end"}
            >
              <SpButton
                style={{ marginLeft: "2%" }}
                buttonType="accept"
                variant="h1"
                text={labels.mylab.tempAdd.assesment.actions.create}
                onClick={() => saveTemplate()}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <SpTextInput
            style={{ width: "95%" }}
            name={`name`}
            label={labels.mylab.tempAdd.assesment.inputs.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {!name && (
            <SpText variant="inputError">
              {labels.mylab.tempAdd.assesment.inputs.requiredName}
            </SpText>
          )}
        </Grid>
        <Divider
          style={{
            padding: "1px",
            margin: "2%",
            marginLeft: "0",
            width: "100%",
            backgroundColor: "#31ccad",
          }}
        />
        <AddAssessmentTemplate
          setTemplateDetailData={setTemplateDetailData}
          assessmentElements={assessmentElements}
          setAssessmentElements={setAssessmentElements}
          setName={setName}
        />
        {Object.keys(assessmentElements).length > 0 && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            {!idElement && (
              <SpButton
                text={labels.mylab.tempAdd.treatment.actions.create}
                buttonType="accept"
                variant="h1"
                onClick={() => saveTemplate()}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddAssessment);
